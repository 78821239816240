import { ReactElement } from 'react'
import { Box, Divider } from '@mui/material'

export function KBSidePanelDivider(): ReactElement {
    return (
        <Box sx={{ py: 1.25 }}>
            <Divider />
        </Box>
    )
}
