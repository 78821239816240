import { Stack } from '@mui/material'
import { SxProps } from '@mui/material/styles'
import {
    FileBrowseInput,
    FileInfo,
    FileInfoTag,
    UploadActionBar,
    UploadActionBarActionType,
    UploadActionBarState,
    UploadFileType,
} from 'components/file'
import { RoundedLinearProgress } from 'components/progress/RoundedLinearProgress'
import { UploadState, useUploadFile } from 'hooks/upload/useUploadFile'
import { observer } from 'mobx-react-lite'
import { ReactElement, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatBytes } from 'util/file-size'
import { toPercent } from 'util/percent'
import { FileWell } from './FileWell'
import { FileChangeHandler } from './types'

export type UploadUrl = {
    uploadUrl: string
}

export type GetUploadUrl = (fileInfo?: FileInfo) => Promise<UploadUrl>

export type StartUpload = (getUploadUrl: GetUploadUrl, contentType?: string) => Promise<void>

export type FileUploaderHandler = {
    status: () => void
    startUpload: StartUpload
}

export type FileUploaderProps = {
    avatarInitial?: string
    fileType?: UploadFileType
    onChange?: FileChangeHandler
    setUploadState?: (uploadState: UploadState | undefined) => void
    isFileValid?: (file?: File) => boolean
    helperText: string
    fileSuggestionText?: string
    suggested?: ReactElement
    existingFileName?: string
    height?: string
    sx?: SxProps
}

export const FileUploader = observer(
    forwardRef(function FileUploader(
        {
            fileType,
            avatarInitial,
            onChange,
            setUploadState,
            isFileValid,
            helperText,
            fileSuggestionText,
            suggested,
            existingFileName,
            height = '170px',
            sx,
        }: FileUploaderProps,
        ref,
    ): ReactElement {
        const { t } = useTranslation()
        const inputRef = useRef<HTMLInputElement>(null)

        const [file, setFile] = useState<File>()
        const [uploadFile, uploadState, progress, error] = useUploadFile()

        // to be called when user clicks save and submits the form
        const startUpload: StartUpload = useCallback(
            async (getUploadUrl: GetUploadUrl, contentType?: string) => {
                const uploadUrl = await getUploadUrl(file)
                if (uploadUrl?.uploadUrl) {
                    await uploadFile(uploadUrl?.uploadUrl, file, contentType)
                }
            },
            [file, uploadFile],
        )

        const onFile = useCallback(
            (value?: File) => {
                setFile(value)
                onChange?.(value)
            },
            [onChange],
        )

        const reset = () => {
            if (!inputRef || !inputRef.current) {
                return
            }
            inputRef.current.value = ''
            onFile(undefined)
        }

        const showSuggested = (): boolean => !file || (!!fileSuggestionText && file && !isFileValid?.(file))

        const getProgressStr = (): string => {
            if (!file || progress === 0) {
                return ''
            }

            if (progress === 1) {
                return t('file.uploadSuccessful')
            }

            return `${formatBytes(file.size * progress, 0)} / ${formatBytes(file.size, 0)}`
        }

        const handleActionBarAction = (action: UploadActionBarActionType) => {
            switch (action) {
                case 'remove':
                    if (file) {
                        reset()
                    }
                    break
                case 'cancel':
                    // TODO: to be implemented
                    break
                default:
                    break
            }
        }

        const getActionBarState = useCallback((): UploadActionBarState => {
            switch (uploadState) {
                case 'in-progress':
                    return 'in-progress'
                case 'error':
                    return 'error'
                case 'completed':
                    return 'success'
                default:
                    return 'none'
            }
        }, [uploadState])

        useEffect(() => {
            setUploadState?.(uploadState)
        }, [setUploadState, uploadState])

        useImperativeHandle(
            ref,
            () => ({
                getUploadState() {
                    return uploadState
                },
                startUpload(getUploadUrl: GetUploadUrl, contentType?: string) {
                    return startUpload(getUploadUrl, contentType)
                },
            }),
            [startUpload, uploadState],
        )

        return (
            <FileWell height={height} sx={sx} onChange={onFile}>
                {/* file input */}
                {uploadState === 'idle' && (
                    <Stack sx={{ width: 1, height: 1, mt: 0 }} justifyContent="center">
                        <FileBrowseInput
                            fileType={fileType}
                            existingFileName={existingFileName}
                            helperText={helperText}
                            suggestionText={fileSuggestionText}
                            suggested={suggested}
                            inputRef={inputRef}
                            fileInfo={file}
                            onFileChanged={onFile}
                            showSuggested={showSuggested()}
                        />
                    </Stack>
                )}

                {uploadState !== 'idle' && (
                    <Stack spacing={2} sx={{ mx: 2.5, flex: 1, width: '100%', p: 2 }} justifyContent="space-between">
                        <Stack spacing={2} justifyContent="center" sx={{ flex: 1 }}>
                            <FileInfoTag initials={avatarInitial} fileInfo={file} />

                            {/* progress bar */}
                            {uploadState === 'in-progress' && <RoundedLinearProgress value={toPercent(progress)} />}
                        </Stack>
                        <UploadActionBar
                            sx={{ ml: 2.2, mr: 1 }}
                            visible={file !== undefined}
                            description={getProgressStr()}
                            state={getActionBarState()}
                            onAction={handleActionBarAction}
                            error={error}
                        />
                    </Stack>
                )}
            </FileWell>
        )
    }),
)
