import { ReactElement, useState, MouseEvent } from 'react'
import { IconButton, Popover } from '@mui/material'
import { Error, HelpOutline } from '@mui/icons-material'
import { useInjection } from 'inversify-react'
import { observer } from 'mobx-react-lite'
import { ErrorList } from '../error/ErrorList'
import { Environment } from '../../config/environment'
import TYPES from '../../config/inversify.types'
import { useRedirect } from '../../hooks/useRedirect'
import { useErrorStore } from '../../hooks/error/useErrorStore'

export const SupportMenu = observer(() => {
    const store = useErrorStore()
    const redirect = useRedirect()
    const environment = useInjection<Environment>(TYPES.Environment)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const hasErrors = store.errors.length > 0

    const popoverClose = () => {
        store.clearReadErrors()
        setAnchorEl(null)
    }

    const gotToUserGuide = () => {
        redirect(environment.userGuideUrl, '_blank')
    }

    const onClearErrors = () => {
        store.clear()
        setAnchorEl(null)
    }

    const onClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (!hasErrors) {
            gotToUserGuide()
            return
        }
        setAnchorEl(event.currentTarget)
    }

    const getIcon = (): ReactElement =>
        hasErrors ? <Error sx={{ color: 'white' }} /> : <HelpOutline sx={{ color: 'white' }} />

    const open = Boolean(anchorEl)

    return (
        <>
            <IconButton size="small" onClick={onClick}>
                {getIcon()}
            </IconButton>

            <Popover
                anchorEl={anchorEl}
                open={open}
                onClose={popoverClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <ErrorList errors={store.errors} onGetHelpClicked={gotToUserGuide} onClear={onClearErrors} />
            </Popover>
        </>
    )
})
