import { FunctionComponent, MouseEvent, ReactNode, SVGProps } from 'react'

export declare type KBNavItemType = 'contained' | 'full'

export declare type KBNavItemVariant = 'dense' | 'regular'

export type KBNavItemProps = {
    path: string
    onClick?: (event: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void
    children?: ReactNode
    type?: KBNavItemType
    defaultTextColor?: string
    activeTextColor?: string
    activeColor?: string
    hoverColor?: string
    icon?: FunctionComponent<SVGProps<SVGSVGElement>>
    variant?: KBNavItemVariant
}
