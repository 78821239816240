import { Icon, SvgIcon } from '@mui/material'
import { ItemTypeIconVariant } from 'model/app'
import { ReactElement } from 'react'
import { DetectedIcon, EventOccurrenceIcon, EventSpanIcon, StaticIcon } from './Icon'

export type ItemTypeIconProps = {
    variant: ItemTypeIconVariant
    color?: string
}

export function ItemTypeIcon({ variant, color }: ItemTypeIconProps): ReactElement {
    const iconForVariant = (v: ItemTypeIconVariant): ReactElement => {
        switch (v) {
            case 'detected':
                return <DetectedIcon />
            case 'static':
                return <StaticIcon />
            case 'event-occurrence':
                return <EventOccurrenceIcon />
            case 'event-span':
                return <EventSpanIcon />
            default:
                // supports mui icon names ie: verified_user, thumb_up etc.  May be used when server returns icon_name
                return <Icon>{variant}</Icon>
        }
    }

    return (
        <SvgIcon sx={{ color }} data-testid={`${variant}-icon`}>
            {iconForVariant(variant)}
        </SvgIcon>
    )
}
