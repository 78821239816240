import { createContext, ReactElement, useEffect } from 'react'
import { useErrorStore } from '../../hooks/error/useErrorStore'
import { KibsiHttpError } from '../../service/api/http.error'
import logger from '../../logging/logger'

const UnhandledErrorContext = createContext(undefined)

export type UnhandedErrorProviderProps = {
    children: ReactElement
}

export function UnhandedErrorProvider({ children }: UnhandedErrorProviderProps): ReactElement {
    const errorStore = useErrorStore()

    useEffect(() => {
        function handleRejection(event: PromiseRejectionEvent) {
            // KibsiHttpError are handled and are added to ErrorStore by usePromiseState
            if (!(event.reason instanceof KibsiHttpError)) {
                logger.warn('unhandled rejection', event.reason)
            }
        }

        window.addEventListener('unhandledrejection', handleRejection)
        return () => {
            window.removeEventListener('unhandledrejection', handleRejection)
        }
    }, [errorStore])

    return <UnhandledErrorContext.Provider value={undefined}>{children}</UnhandledErrorContext.Provider>
}
