import { ReactElement } from 'react'
import { Checkbox } from '@mui/material'
import { KBPanelBasicItem, KBPanelBasicItemProps } from './KBPanelBasicItem'
import { WithDataProps } from '../../types'

export type KBCheckBoxItemProps = {
    checked?: boolean
    indeterminate?: boolean
    onChange?: (value: boolean) => void
    disabled?: boolean
} & KBPanelBasicItemProps

export function KBCheckBoxItem({
    checked,
    indeterminate,
    onChange,
    disabled,
    ...props
}: WithDataProps<KBCheckBoxItemProps>): ReactElement {
    return (
        <KBPanelBasicItem
            secondary={
                <Checkbox
                    sx={{ p: 0 }}
                    checked={checked}
                    indeterminate={indeterminate}
                    size="small"
                    onChange={(e) => {
                        onChange?.(e.target.checked)
                    }}
                    disabled={disabled}
                    inputProps={{ 'aria-label': 'checkbox' }}
                />
            }
            data-testid="checkbox-item"
            {...props}
        />
    )
}
