import { inject, injectable } from 'inversify'
import { PaginationRequest, PaginationResponse, TagKibsiClient } from '@kibsi/ks-client-sdk'
import type { ResourceTagCreate, Tag, TagCreate } from '@kibsi/ks-search-tags-types'
import type { RequestStore } from 'store/request.store'
import { TagService } from './tag.service'
import TYPES from '../../config/inversify.types'

export type TagApi = Pick<
    InstanceType<typeof TagKibsiClient>,
    'listTags' | 'createTag' | 'getTag' | 'tagResource' | 'removeTagFromResource'
>

@injectable()
export class TagServiceImpl implements TagService {
    constructor(@inject(TYPES.RequestStore) private req: RequestStore, @inject(TYPES.TagApi) private api: TagApi) {}

    listTags(request?: PaginationRequest): Promise<PaginationResponse<Tag>> {
        return this.req.once(`tag.list.${JSON.stringify(request)}`, () => this.api.listTags(request))
    }

    createTag(tag: TagCreate): Promise<Tag> {
        return this.api.createTag(tag)
    }

    getTag(tagId: string): Promise<Tag> {
        return this.api.getTag(tagId)
    }

    createTaggedResource(resource: ResourceTagCreate): Promise<void> {
        return this.api.tagResource(resource)
    }

    removeTagFromResource(tagId: string, resourceId: string): Promise<void> {
        return this.api.removeTagFromResource(tagId, resourceId)
    }
}
