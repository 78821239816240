import { makeAutoObservable } from 'mobx'
import { EventItemType, EventType } from '@kibsi/ks-application-types'
import { KibsiEvent } from '@kibsi/ks-history-types'
import log from 'logging/logger'
import { EventService } from 'service'
import { Event } from './event'
import { IEventGroup } from './event.group'
import { activeThenStartTimeSort } from './sorting'
import { Deployment } from '../deployment'

export class HistoryEventGroup implements IEventGroup {
    private items: Map<string, Event> = new Map()
    private count = 0
    private startTs?: number

    constructor(
        readonly itemType: EventItemType,
        private deployments: Deployment[],
        private eventService: EventService,
        gridEvents: KibsiEvent[],
    ) {
        gridEvents.forEach((event) => this.addEvent(event))

        makeAutoObservable(this)
    }

    get id(): string {
        return this.itemType.id
    }

    get name(): string {
        return this.itemType.name
    }

    get recent(): Event[] {
        return this.values.sort(activeThenStartTimeSort)
    }

    get counter(): number {
        return this.count
    }

    get startDate(): Date | undefined {
        return this.startTs ? new Date(this.startTs) : undefined
    }

    private get values(): Event[] {
        return Array.from(this.items.values())
    }

    get isSpan(): boolean {
        return this.eventType === 'span'
    }

    get isOccurrence(): boolean {
        return this.eventType === 'occurrence'
    }

    private get eventType(): EventType | undefined {
        return this.itemType.event.eventType
    }

    private addEvent(dto: KibsiEvent): void {
        if (this.items.has(dto.id)) {
            return
        }

        const { deploymentId } = dto
        const deployment = this.deployments.find((d) => d.deploymentId === deploymentId)
        if (!deployment) {
            log.warn(`cannot add history event to group due to missing deployment ${deploymentId}`)
            return
        }

        const event = new Event(dto, deployment, this.eventService)

        this.items.set(event.id, event)
        this.count++
    }
}
