import { ContainerModule } from 'inversify'
import { Environment } from 'config/environment'
import T from 'config/inversify.types'

import { createKibsiApiClient } from './kibsi.api'
import { KibsiAuthConfigProvider } from './kibsi.auth.config'

export const KibsiApiModule = new ContainerModule((bind) => {
    bind(T.KibsiApiConfig).to(KibsiAuthConfigProvider).inSingletonScope()
    bind(T.KibsiApi).toDynamicValue(({ container }) => {
        const env = container.get<Environment>(T.Environment)
        const config = container.get<KibsiAuthConfigProvider>(T.KibsiApiConfig)

        return createKibsiApiClient(env, config.getConfig.bind(config))
    })
})
