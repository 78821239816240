import { inject, injectable } from 'inversify'
import { makeAutoObservable, runInAction } from 'mobx'
import type { TenantService } from 'service/tenant'
import T from '../../config/inversify.types'
import { Tenant } from './tenant'

@injectable()
export class TenantStore {
    private self?: Tenant

    constructor(@inject(T.TenantService) private service: TenantService) {
        makeAutoObservable(this)
    }

    async loadSelfTenant(): Promise<void> {
        const dto = await this.service.getSelf()
        runInAction(() => {
            this.self = new Tenant(dto)
        })
    }

    getSelfTenant(): Tenant | undefined {
        return this.self
    }
}
