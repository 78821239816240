import type { AxiosError } from 'axios'

export type KibsiErrorData = {
    message: string
    errorCode?: string
    additionalDetails?: unknown
}

export class KibsiHttpError extends Error {
    readonly statusCode?: number
    readonly errorCode?: string
    readonly additionalDetails?: unknown
    readonly requestId?: string
    readonly url?: string

    constructor(err: AxiosError<KibsiErrorData | string | undefined>) {
        super(typeof err.response?.data === 'string' ? err.response?.data : err.response?.data?.message)

        if (err.response) {
            const { data } = err.response
            const { errorCode, additionalDetails } = data as KibsiErrorData
            this.statusCode = err.response.status
            this.errorCode = errorCode
            this.requestId = err.config.headers?.['x-kibsi-request-id']
            this.additionalDetails = additionalDetails
        }

        if (err.request) {
            this.url = err.request.responseURL as string
        }
    }
}
