import type {
    ModelArchitecture as ModelArchitectureDto,
    ModelArgs,
    PostProcessorDef,
    PreProcessorDef,
} from '@kibsi/ks-application-types'

import { toJS } from 'mobx'
import { ArchitectureKey, DEFAULT_ARCHITECTURES } from './arch'
import { FromDto, ToDto } from '../interfaces'
import { getModelSizeTypeFromString, ModelSizeType } from './model.size'

export class ModelArchitecture
    implements FromDto<ModelArchitectureDto>, ToDto<ModelArchitectureDto>, ModelArchitectureDto
{
    constructor(private dto: ModelArchitectureDto) {}

    get key(): ArchitectureKey {
        return this.dto.key
    }

    get name(): string {
        const archId = this.key.substring(0, this.key.length - 1)
        const architecture = DEFAULT_ARCHITECTURES.find((a) => a.id === archId)
        return architecture ? architecture.name : archId
    }

    get size(): ModelSizeType {
        return getModelSizeTypeFromString(this.key[this.key.length - 1])
    }

    get preProcessor(): PreProcessorDef | undefined {
        return this.dto.preProcessor
    }

    get postProcessor(): PostProcessorDef | undefined {
        return this.dto.postProcessor
    }

    get modelArgs(): ModelArgs | undefined {
        return this.dto.modelArgs
    }

    fromDto(dto: ModelArchitectureDto): void {
        this.dto = { ...dto }
    }

    toDto(): ModelArchitectureDto {
        return toJS(this.dto)
    }
}
