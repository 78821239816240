import { injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'
import { KibsiError } from './error'
import logger from '../../logging/logger'

@injectable()
export class ErrorStore {
    private networkErrors: KibsiError[] = []

    constructor() {
        makeAutoObservable(this)
    }

    addError(err: KibsiError): void {
        logger.info('adding error', err)
        this.networkErrors.unshift(err)
    }

    get errors(): KibsiError[] {
        return this.networkErrors
    }

    clear(): void {
        this.networkErrors = []
    }

    clearReadErrors(): void {
        this.networkErrors = this.networkErrors.filter((err) => !err.isRead)
    }
}
