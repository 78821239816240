import { ContainerModule } from 'inversify'
import { ModelStore } from 'store/model'
import TYPES from '../../config/inversify.types'
import { DetectorServiceImpl } from './detector.service.impl'
import { DetectorStore } from '../../store/detector'

export const DetectorModule = new ContainerModule((bind) => {
    bind(TYPES.DetectorService).to(DetectorServiceImpl).inSingletonScope()
    bind(TYPES.DetectorStore).to(DetectorStore).inSingletonScope()
    bind(TYPES.ModelStore).to(ModelStore).inSingletonScope()
})
