import { inject, injectable } from 'inversify'
import { makeAutoObservable, runInAction } from 'mobx'
import type { TagService } from 'service/tag/tag.service'
import type { ResourceType, Tag as TagDto } from '@kibsi/ks-search-tags-types'
import logger from 'logging/logger'
import { Tag } from 'model/tag'
import TYPES from '../../config/inversify.types'

export interface TaggableResource {
    readonly id: string
    readonly tags: Map<string, Tag>
    readonly resourceType: ResourceType
}

@injectable()
export class TagStore {
    private tags = new Map<string, TagDto>()

    constructor(@inject(TYPES.TagService) private service: TagService) {
        makeAutoObservable(this)
    }

    async tagList(): Promise<Tag[]> {
        const tagList = await this.service.listTags({ pageSize: 100 })
        this.tags.clear()
        tagList.results.forEach((t) => {
            this.tags.set(t.tagId, t)
        })

        return Array.from(this.tags.values())
    }

    async tagResource(tag: Tag, resource: TaggableResource): Promise<void> {
        const tagDto = await this.addTag(tag)
        runInAction(() => {
            resource.tags.set(tagDto.tagId, tagDto)
        })
        await this.createTaggedResource(resource)
    }

    getTag(id: string): TagDto | undefined {
        return this.tags.get(id)
    }

    removeTagFromResource({ tagId }: Tag, resource: TaggableResource): Promise<void> {
        resource.tags.delete(tagId)
        return this.service.removeTagFromResource(tagId, resource.id)
    }

    private async addTag(tag: Tag): Promise<TagDto> {
        logger.debug('add tag', tag)

        const tagDto = this.getTag(tag.tagId)
        if (!tagDto) {
            return this.createTag(tag)
        }
        return tagDto
    }

    private async createTaggedResource(resource: TaggableResource): Promise<void> {
        return this.service.createTaggedResource({
            resourceId: resource.id,
            resourceType: resource.resourceType,
            tagIds: Array.from(resource.tags.keys()),
        })
    }

    private async createTag({ tagId, name }: Tag): Promise<TagDto> {
        let tagDto: TagDto

        // add tag globally
        try {
            tagDto = await this.service.createTag({
                tagId,
                name,
            })
        } catch (e) {
            logger.warn(e)
            tagDto = await this.service.getTag(tagId)
        }

        runInAction(() => {
            this.tags.set(tagDto.tagId, tagDto)
        })

        return tagDto
    }
}
