import { makeAutoObservable } from 'mobx'
import { HeatmapGridValue } from '@kibsi/ks-history-types'
import { Dimension } from 'util/dimension'
import { HeatmapChartData } from 'model/heatmap'

export class HeatmapValue {
    gridValues: HeatmapGridValue[] = []
    gridPixels = 1
    loading = false

    constructor(public itemTypeId: string, private dimension?: Dimension) {
        makeAutoObservable(this)
    }

    get gridXMax(): number {
        if (this.dimension) {
            return this.dimension.width / this.gridPixels
        }
        return 0
    }

    get gridYMax(): number {
        if (this.dimension) {
            return this.dimension.height / this.gridPixels
        }
        return 0
    }

    get chartData(): HeatmapChartData {
        const values = this.gridValues
            .filter(
                (gv) =>
                    gv.gridX > 0 &&
                    gv.gridX < this.gridXMax &&
                    gv.gridY > 0 &&
                    gv.gridY < this.gridYMax &&
                    gv.value > 0,
            )
            .map(({ gridX, gridY, value }) => ({
                x: gridX,
                y: gridY,
                value,
            }))

        return {
            itemTypeId: this.itemTypeId,
            data: values,
            gridXMax: this.gridXMax,
            gridYMax: this.gridYMax,
        }
    }
}
