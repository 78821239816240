import type { AttributeTypeDef } from '@kibsi/ks-application-types'

export function createRegionAttribute(): AttributeTypeDef {
    return {
        id: 'region',
        name: 'region',
        value: {
            valueType: 'region',
        },
    }
}

export function createNameAttribute(): AttributeTypeDef {
    return {
        id: 'name',
        name: 'name',
        value: {
            schema: 'text',
            valueType: 'value',
            defaultValue: '',
        },
    }
}
