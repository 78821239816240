import { ReactElement } from 'react'
import { Box, CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import { CheckCircle, Close, DeleteOutlined, Error } from '@mui/icons-material'
import { SxProps } from '@mui/material/styles'
import { FieldError } from 'react-hook-form'

export type UploadActionBarActionType = 'cancel' | 'remove'
export type UploadActionBarState = 'none' | 'success' | 'error' | 'in-progress'

export type UploadActionBarProps = {
    visible?: boolean
    state?: UploadActionBarState
    error?: Error | FieldError
    description?: string
    onAction?: (action: UploadActionBarActionType) => void
    sx?: SxProps
}

export function UploadActionBar({
    visible = true,
    description,
    state = 'none',
    error,
    onAction,
    sx,
}: UploadActionBarProps): ReactElement {
    const renderDesc = () => {
        const style = { fontSize: '13px', fontWeight: 500, mb: 1 }
        if (error) {
            return <Typography sx={{ color: 'error.main', ...style }}>{error.message}</Typography>
        }
        return <Typography sx={style}>{description}</Typography>
    }

    const renderState = (): ReactElement | undefined => {
        switch (state) {
            case 'success':
                return <CheckCircle color="success" />
            case 'error':
                return <Error color="error" />
            case 'in-progress':
                return <CircularProgress size="16px" />
            default:
                return undefined
        }
    }

    const renderActionBtn = (): ReactElement | undefined => {
        switch (state) {
            case 'in-progress':
                return (
                    <IconButton onClick={() => onAction?.('cancel')}>
                        <Close />
                    </IconButton>
                )
            default:
                return (
                    <IconButton
                        onClick={() => onAction?.('remove')}
                        sx={(theme) => ({ color: theme.palette.action.active })}
                    >
                        <DeleteOutlined />
                    </IconButton>
                )
        }
    }

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ visibility: visible ? 'visible' : 'hidden', ...sx }}
        >
            {renderDesc()}
            <Stack direction="row" alignItems="center">
                {renderState()}
                <Box sx={{ visibility: error || !visible ? 'hidden' : 'visible' }}>{renderActionBtn()}</Box>
            </Stack>
        </Stack>
    )
}
