import { ReactElement } from 'react'
import { Box } from '@mui/material'
import { WithAriaProps, WithDataProps } from '../../types'

export type KBIndicatorProps = {
    size?: string
    color: string
}

export function KBIndicator({
    size = '12px',
    color,
    ...props
}: WithDataProps<WithAriaProps<KBIndicatorProps>>): ReactElement {
    return (
        <Box sx={{ backgroundColor: color, width: size, height: size, borderRadius: `calc(${size}/2)` }} {...props} />
    )
}
