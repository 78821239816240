/* eslint-disable no-param-reassign, no-bitwise */

export function getInitals(name: string | undefined): string {
    if (!name) {
        return 'UU' // Unknown User
    }

    const names = name.split(' ')
    const initials = names.map((n) => n.slice(0, 1))
    if (initials.length <= 1) {
        return initials[0]
    }

    return `${initials[0]}${initials[initials.length - 1]}`
}

export const determineBackgroundColor = (userName?: string): string => {
    if (!userName) {
        return backgroundColors[0]
    }
    const hash = Math.abs(hashCode(userName))
    const index = hash % backgroundColors.length
    return backgroundColors[index]
}

const hashCode = (s: string) =>
    s.split('').reduce<number>((a, b) => {
        a = (a << 5) - a + b.charCodeAt(0)
        return a & a
    }, 0)

const backgroundColors: string[] = [
    '#CC486D',
    '#AE3F65',
    '#804690',
    '#563E8D',
    '#494B9C',
    '#4F79B0',
    '#5F9FCF',
    '#6ECBD1',
    '#6ED1B9',
    '#6ED196',
]
