import { Radio, RadioProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ReactElement } from 'react'

// Customize a 'span' element to represent the unfilled version of a Radio Icon

const EmptyRadioIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
}))

// Customize the EmptyRadioIcon to become the filled version.
// Good example of how you can override an existing styled element
const FilledRadioIcon = styled(EmptyRadioIcon)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
    width: 10,
    height: 10,
    '&:before': {
        width: 20,
        height: 20,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.primary.main,
    },
}))

export function KBRadio(props: RadioProps): ReactElement {
    return <Radio icon={<EmptyRadioIcon />} checkedIcon={<FilledRadioIcon />} {...props} />
}
