import { FloorPlanKibsiClient, PaginationRequest } from '@kibsi/ks-client-sdk'
import { FloorPlan, FloorPlanCreate, FloorPlanCriteria, FloorPlanUpdate } from '@kibsi/ks-tenant-types'
import axios from 'axios'
import { inject, injectable } from 'inversify'
import log from 'logging/logger'
import TYPES from '../../config/inversify.types'
import { RequestStore } from '../../store/request.store'
import { FloorPlanService } from './floor-plan.service'

export type FloorPlanApi = Pick<
    InstanceType<typeof FloorPlanKibsiClient>,
    'create' | 'delete' | 'list' | 'read' | 'update' | 'getImageDownloadUrl' | 'getImageUploadUrl'
>

@injectable()
export class FloorPlanServiceImpl implements FloorPlanService {
    constructor(
        @inject(TYPES.RequestStore) private req: RequestStore,
        @inject(TYPES.FloorPlanApi) private api: FloorPlanApi,
    ) {}

    async list(siteId: string, request: PaginationRequest<FloorPlanCriteria>): Promise<FloorPlan[]> {
        log.debug('list floor plans', request)

        return this.req.once(`floorPlan.list.${siteId}`, async () => {
            const { results } = await this.api.list({ ...request, criteria: { siteId } })
            return results
        })
    }

    async get(id: string): Promise<FloorPlan> {
        return this.req.once(`floorPlan.get.${id}`, () => this.api.read(id))
    }

    async create(floorPlan: FloorPlanCreate): Promise<FloorPlan> {
        return this.api.create(floorPlan)
    }

    async update(update: FloorPlanUpdate): Promise<FloorPlan> {
        return this.api.update(update)
    }

    async delete(floorPlanId: string, cascade?: boolean): Promise<void> {
        return this.api.delete(floorPlanId, {
            ...(cascade && {
                params: {
                    cascade,
                },
            }),
        })
    }

    async getImage(floorPlanId: string): Promise<ArrayBuffer> {
        return this.req.once(`floorPlan.image.${floorPlanId}`, async () => {
            const { downloadUrl } = await this.api.getImageDownloadUrl(floorPlanId)

            const res = await axios.get<ArrayBuffer>(downloadUrl, { responseType: 'arraybuffer' })
            return res.data
        })
    }

    async saveImage(floorPlanId: string, data: ArrayBuffer, contentType: string): Promise<void> {
        const response = await this.api.getImageUploadUrl(floorPlanId, contentType)

        const { uploadUrl } = response

        await axios.put(uploadUrl, data, {
            headers: {
                'Content-Type': contentType,
            },
        })
    }
}
