{
  "alert": {
    "error": "エラー",
    "notDefined": "{{label}}は定義されていません",
    "notFound": "{{label}}が見つかりません"
  },
  "navigation": {
    "dashboard": "ダッシュボード",
    "applications": "アプリケーション",
    "sites": "サイト",
    "account": "アカウント",
    "logout": "ログアウト"
  },
  "dashboard": {
    "greeting": "ダッシュボードへようこそ"
  },
  "action": {
    "close": "Close",
    "create": "Create",
    "save": "Save",
    "cancel": "Cancel",
    "edit": "Edit",
    "delete": "消去",
    "searchEllipsis": "探す...",
    "notFound": "Not found",
    "next": "Next",
    "back": "Back",
    "discard": "Discard",
    "skip": "スキップ",
    "continue": "継続する",
    "undo": "元に戻す",
    "redo": "やり直し",
    "reset": "リセット",
    "clear": "クリア"
  },
  "common": {
    "createdBy": "{{name}}に よって作成されました",
    "published": "Published"
  },
  "application": {
    "createApplication": "アプリケーションを作成する",
    "applicationImageAlt": "Application Image",
    "additionalOptions": "Additional options",
    "create": {
      "title": "New Application",
      "description": "Enter your basic information",
      "input": {
        "name": "Name",
        "description": "Description"
      },
      "rules": {
        "name": "Application name cannot be empty"
      }
    },
    "lastEdited": "Last edited",
    "versions": {
      "title": "Versions",
      "publishNew": "Publish New",
      "hide": "隠れる",
      "viewOlder": "古いものを見る",
      "create": {
        "dialogTitle": "Add to version history",
        "input": {
          "name": "Title",
          "description": "Notes",
          "placeholder": {
            "name": "Give it a title",
            "description": "Describe what changed"
          }
        },
        "rules": {
          "name": "Version name cannot be empty"
        }
      }
    },
    "builder": {
      "addStaticItem": {
        "name": "Static Item",
        "description": "A stationary item that is permanently in camera view. (e.g. table, parking spot)"
      },
      "addDetectedItem": {
        "name": "Detected Item",
        "description": "An item that is automatically detected and tracked within the camea view. (e.g. person, car)"
      },
      "addEventItem": {
        "name": "Event",
        "description": " An item that is created based on a start and end condition. (e.g. safety violation, occupancy exceeded)"
      },
      "region": "Region",
      "autoAddRegion": "Automatically adds region property to the static item"
    },
    "noApplicationsTitle": "You do not have any applications yet.",
    "noApplicationsMessage": "When you create an application you will be able to view and edit them all in one spot. Click on the plus on the top left to create a new application or start one from here.",
    "itemType": {
      "type": "タイプ",
      "static": {
        "createTitle": "Add a new static item",
        "createDescription": "A static item type is used when it is undesirable or not possible to have the item dynamically created and removed by the system. Specific items of this type are created during application deployment.",
        "input": {
          "name": "Name"
        },
        "rules": {
          "name": "Static item name cannot be empty"
        }
      },
      "detected": {
        "createTitle": "Add a new detected item",
        "createDescription": "A detected item is created based on an observation from the configured detector. Items of this type are automatically created and removed based on configured rules.",
        "enhancerTitle": "エンハンサーを選択",
        "input": {
          "name": "Name"
        },
        "rules": {
          "name": "Detected item name cannot be empty",
          "detector": "Select a detector type"
        }
      },
      "event": {
        "createTitle": "新しいイベント",
        "editTitle": "イベントの編集",
        "createDescription": "イベントの説明を作成する",
        "subheader": {
          "startCondition": "開始条件を追加します",
          "endCondition": "終了条件を追加します (随意)",
          "options": "より多くの出力オプションを選択してください"
        },
        "input": {
          "name": "名前",
          "sources": "ソース"
        },
        "options": {
          "atBeginning": "最初に",
          "atEnd": "最後に",
          "entireTime": "ずっと",
          "onAction": "トリガーされたアクション",
          "saveImage": "画像を保存",
          "saveVideo": "ビデオを保存",
          "duration": "間隔",
          "timeBeforeBeginning": "始める前の時間",
          "timeAfterEnding": "終了後の時間"
        },
        "addEndCondition": "終了条件を追加",
        "rules": {
          "name": "イベント名を空にすることはできません",
          "source": "ソースを選択"
        }
      }
    },
    "attribute": {
      "input": {
        "name": "名前",
        "type": "タイプ",
        "default": "デフォルト値",
        "placeholder": {
          "number": "番号を入力してください",
          "region": "アプリケーションのデプロイ中にリージョンの値を指定します",
          "text": "テキストを入力してください"
        }
      },
      "rules": {
        "name": "プロパティ名を空にすることはできません"
      },
      "addNewTitle": "Add a new property",
      "suggestions": "Suggestions",
      "addManually": "Add manually",
      "enhancer": {
        "title": "Enhancer",
        "description": "Enhancer description",
        "addNewTitle": "Add an enhancer",
        "selectTitle": "Select an enhancer",
        "briefDescription": "Maecenas sed diam eget risus varius blandit sit amet non magna. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.",
        "emptyResults": "No compatible enhancers found",
        "input": {
          "name": "Name",
          "type": "Type",
          "placeholder": {
            "name": "Enhancer name"
          },
          "rules": {
            "name": "Enhancer name cannot be empty",
            "enhancerId": "An enhancer must be selected"
          }
        }
      },
      "expression": {
        "addNewTitle": "Build an expression",
        "title": "Formula expression",
        "description": "Formula expression description",
        "briefDescription": "Maecenas sed diam eget risus varius blandit sit amet non magna. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum."
      },
      "stateMachine": {
        "title": "State machine",
        "description": "State machine description"
      },
      "value": {
        "title": "Simple",
        "editTitle": "プロパティを編集",
        "description": "Simple attribute description",
        "name": "Property Name"
      },
      "relationship": {
        "title": "Relationship",
        "addHeader": "{{name}} に関係を追加します",
        "editHeader": "{{name}} との関係を編集する",
        "description": "Relationship description",
        "source": "ソース",
        "target": "対象"
      },
      "relationships": "関係",
      "display": {
        "title": "属性を表示",
        "noneSelected": "(選んでください)"
      }
    }
  },
  "site": {
    "createSite": "サイトを作成する",
    "stream": {
      "create": { "button": "カメラを作成する" }
    },
    "change": "サイトを変更する",
    "deployment": {
      "create": {
        "button": "配置を作成",
        "title": "新しい展開",
        "description": "既存のアプリケーションを選択する"
      }
    },
    "count": "{{sites}} Sites"
  },
  "deployments": "Deployments",
  "deployment": {
    "count": "{{deployments}} Deployments",
    "configure": "デプロイメントの構成",
    "edit": "編集",
    "splash": {
      "altImage": "デプロイメントの構成",
      "mainTitle": "デプロイメントを稼働させます。",
      "title": "一度設定してデプロイメントを起動します",
      "message": "デプロイメントを開始する前に、さらにいくつかの詳細が必要です。 右上のメニューで変数を再確認するか、ここから展開プロセスに直接進んでください。"
    },
    "config": {
      "title": "{{name}}のセットアップ",
      "menu": {
        "application": "申し込み",
        "applicationName": "アプリケーション名",
        "streams": "カメラ",
        "staticItems": "静的アイテム",
        "variables": "変数"
      },
      "button": {
        "launch": "発売",
        "finishLater": "後で終了",
        "nextStep": "次のステップ"
      },
      "appVer": {
        "chooseVersion": "バージョンを選択してください",
        "newestVersion": "最新バージョン",
        "olderVersions": "古いバージョン"
      },
      "stream": {
        "selectCamera": "カメラを選択してください"
      },
      "staticItems": {
        "title": "静的アイテムを追加します",
        "dialog": {
          "title": "{{name}} を削除する",
          "text": "このアイテムを削除しようとしています。 続行すると、アイテムとその属性は破棄されます",
          "cancel": "取り消す",
          "continue": "継続する"
        },
        "enhancer": {
          "selectRegion": "Select Region (Optional)",
          "entireScreen": "Entire screen",
          "custom": "Custom"
        },
        "drawRegion": "描画領域"
      },
      "launch": {
        "title": "展開を開始する",
        "text": "デプロイメントを開始しようとしています。 このサイトにすでに展開している場合は、上書きされ、データが消去されます。",
        "cancel": "取り消す",
        "continue": "継続する"
      },
      "discard": {
        "title": "ドラフト設定を破棄する",
        "text": "このデプロイメントへの変更を破棄しようとしています。"
      }
    },
    "appVersion": "{{name}} Version {{version}}"
  },
  "stream": {
    "noSnapshot": "スナップショットが見つかりません"
  },
  "account": {
    "greeting": "こんにちは {{name}}"
  },
  "dateTime": {
    "default": "{{date, datetime}}",
    "lastUpdated": "最終更新日 {{date, datetime}}",
    "published": "公開日 {{date, datetime}}",
    "startedOn": "{{date, datetime}} {{time, datetime}} 開始"
  },
  "expression": {
    "name": "Expression name",
    "title": "Expression",
    "visual": "Visual",
    "advanced": "Advanced",
    "javascript": "Javascript"
  }
}
