import { ReactElement } from 'react'
import { Card, Stack, StackProps } from '@mui/material'
import { sxs } from '@kibsi/ui-components'

export function AccountContainer({ children, sx, ...props }: StackProps): ReactElement {
    return (
        <Stack alignItems="center" {...props} sx={sxs({ mx: 3, mt: 3, minWidth: '450px' }, sx)}>
            <Card sx={{ p: 3, maxWidth: '1100px', width: 1 }}>{children}</Card>
        </Stack>
    )
}
