import { Box, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { MutableRefObject, ReactElement, useEffect, useState } from 'react'
import { ArrowBack } from '@mui/icons-material'
import { KBLoadingButton } from '../button'

/**
 * You can provide controls for the stepper to drive when next and previous are clicked
 *
 * @see KBSlideStack
 */
export interface KBStepControls<ID = string> {
    next: () => void
    previous: () => void
    goTo: (id: ID) => void
}

export interface KBStepActionCheck {
    (success: boolean): void
}

export type KBStepControllerProps = {
    initialStep?: number
    nextLabel?: string
    nextDisabled?: boolean
    nextLoading?: boolean
    showNext?: boolean
    previousLabel?: string
    previousDisabled?: boolean
    previousLoading?: boolean
    showPrevious?: boolean
    onNext?: (stepAction: KBStepActionCheck) => void
    onPrevious?: (stepAction: KBStepActionCheck) => void
    onChange?: (step: number) => void
    controls?: MutableRefObject<KBStepControls | undefined>
}

export function KBStepController({
    initialStep = 0,
    nextLabel = 'Next',
    nextDisabled = false,
    nextLoading = false,
    showNext = true,
    previousLabel = 'Back',
    previousDisabled = false,
    previousLoading = false,
    showPrevious,
    onNext,
    onPrevious,
    onChange,
    controls,
}: KBStepControllerProps): ReactElement {
    const theme = useTheme()
    const [activeStep, setActiveStep] = useState(initialStep)

    useEffect(() => {
        onChange?.(activeStep)
    }, [activeStep]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between" alignItems="end">
            <Box sx={{ minWidth: '100px' }}>
                {showPrevious && (
                    <KBLoadingButton
                        data-testid="previous_button"
                        variant="text"
                        startIcon={<ArrowBack />}
                        onClick={() => {
                            onPrevious?.((success) => {
                                if (success) {
                                    setActiveStep(activeStep - 1)
                                    controls?.current?.previous()
                                }
                            })
                        }}
                        disabled={previousDisabled}
                        loading={previousLoading}
                        sx={{ color: theme.palette.text.primary }}
                    >
                        <Typography>{previousLabel}</Typography>
                    </KBLoadingButton>
                )}
            </Box>
            {showNext && (
                <KBLoadingButton
                    data-testid="next_button"
                    disabled={nextDisabled}
                    loading={nextLoading}
                    onClick={() => {
                        onNext?.((success) => {
                            if (success) {
                                setActiveStep(activeStep + 1)
                                controls?.current?.next()
                            }
                        })
                    }}
                >
                    {nextLabel}
                </KBLoadingButton>
            )}
        </Stack>
    )
}
