import { useMemo } from 'react'
import { createTheme, Theme } from '@mui/material/styles'
import { componentsOverride } from './componentsOverride'

let theme: Theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#863fff',
            light: '#c3a3fa',
            dark: '#5217b9',
        },
        secondary: {
            main: '#37D2B6',
            light: 'rgba(82, 60, 168, 0.5)',
        },
        text: {
            primary: '#383156',
            secondary: 'rgba(56, 49, 86, 0.7)',
            tertiary: 'rgba(0, 0, 0, 0.5)',
            disabled: 'rgba(0, 0, 0, 0.25)',
        },
        highlight: {
            main: '#FF9330',
        },
        offWhite: {
            main: '#FAFAFA',
        },
        separation: {
            main: '#CCCCCC',
        },
    },
    drawer: {
        small: '300px',
        medium: '542px',
        large: '1050px',
    },
})

theme = createTheme(theme, {
    components: componentsOverride(theme),
})

const useDarkTheme = (): Theme => useMemo<Theme>(() => theme, [])

export default useDarkTheme
