import type { AxiosRequestConfig as ReqConfig } from 'axios'
import type {
    Application,
    ApplicationCriteriaWithUIOptions,
    Deployment,
    DeploymentCriteriaWithUiOptions,
    DeploymentDetails,
    Stream,
    StreamCriteriaWithUiOptions,
} from '@kibsi/ks-ui-types'
import {
    KibsiClient,
    KibsiClientParams,
    PaginationRequest as Req,
    PaginationResponse as Resp,
} from '@kibsi/ks-client-sdk'

export interface UiApi {
    // deployments
    listDeployments(
        request?: Req<DeploymentCriteriaWithUiOptions>,
        config?: ReqConfig,
    ): Promise<Resp<DeploymentDetails>>
    readDeployment(deploymentId: string, config?: ReqConfig): Promise<Deployment>

    // applications
    listApplications(request?: Req<ApplicationCriteriaWithUIOptions>, config?: ReqConfig): Promise<Resp<Application>>
    readApplication(applicationId: string, config?: ReqConfig): Promise<Application>

    // streams
    listStreams(siteId: string, request?: Req<StreamCriteriaWithUiOptions>, config?: ReqConfig): Promise<Resp<Stream>>
    readStream(streamId: string, config?: ReqConfig): Promise<Stream>
}

export class UiKibsiClient extends KibsiClient implements UiApi {
    constructor(params: KibsiClientParams) {
        super(params, 'ui')
    }

    listDeployments(
        request: Req<DeploymentCriteriaWithUiOptions> = {},
        config: ReqConfig = {},
    ): Promise<Resp<DeploymentDetails>> {
        const { continuationToken, pageSize, sort, criteria } = request
        const { siteId, appId, versionId, ids, upgradeToVersionId, includeDefinition, streamId, tagIds } =
            criteria ?? {}

        return this.get(`/deployments`, {
            ...config,
            params: {
                continuationToken,
                pageSize,
                sort,
                siteId,
                appId,
                versionId,
                ids: ids?.join(','),
                upgradeToVersionId,
                includeDefinition,
                streamId,
                tagIds: tagIds?.join(','),
            },
        })
    }

    readDeployment(deploymentId: string, config: ReqConfig = {}): Promise<Deployment> {
        return this.get(`/deployments/${deploymentId}`, config)
    }

    listApplications(
        request: Req<ApplicationCriteriaWithUIOptions> = {},
        config: ReqConfig = {},
    ): Promise<Resp<Application>> {
        const { continuationToken, pageSize, sort, criteria } = request
        const { published, ids, q, tagIds } = criteria ?? {}

        return this.get('/applications', {
            ...config,
            params: {
                continuationToken,
                pageSize,
                sort,
                published,
                ids: ids?.join(','),
                q,
                tagIds: tagIds?.join(','),
            },
        })
    }

    readApplication(applicationId: string, config: ReqConfig = {}): Promise<Application> {
        return this.get(`/applications/${applicationId}`, config)
    }

    listStreams(
        siteId: string,
        request: Req<StreamCriteriaWithUiOptions> = {},
        config: ReqConfig = {},
    ): Promise<Resp<Stream>> {
        const { continuationToken, pageSize, sort, criteria } = request
        const { ids, q, tagIds } = criteria ?? {}

        return this.get('/streams', {
            ...config,
            params: {
                continuationToken,
                siteId,
                pageSize,
                sort,
                ids: ids?.join(','),
                q,
                tagIds: tagIds?.join(','),
            },
        })
    }

    readStream(streamId: string, config: ReqConfig = {}): Promise<Stream> {
        return this.get(`/streams/${streamId}`, config)
    }
}
