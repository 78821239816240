import { Device as DeviceDto } from '@kibsi/ks-tenant-types'
import { inject, injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'
import type { DeviceService } from 'service/device'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from 'store/domain'
import TYPES from '../../config/inversify.types'
import { Device } from './device'

@injectable()
export class DeviceStore {
    private devices: AsyncDomainStore<Device, DeviceDto>

    constructor(@inject(TYPES.DeviceService) private service: DeviceService) {
        this.devices = new AsyncFromDtoDomainStore<DeviceDto, Device>(
            (id) => service.get(id),
            (_, data) => new Device(data, service),
        )

        makeAutoObservable<DeviceStore, 'service'>(this, {
            service: false,
        })
    }

    async loadList(deviceId: string): Promise<Device[]> {
        const dtos = await this.service.list(deviceId, { pageSize: 100 })

        return this.devices.setAll(dtos.map((s) => [s.id, s]))
    }

    get list(): Device[] {
        return this.devices.values()
    }

    getDevicesBySiteId(siteId: string): Device[] {
        return this.list.filter((device) => device.siteId === siteId)
    }

    loadDevice(id: string): Promise<Device> {
        return this.devices.load(id)
    }

    getDevice(id: string): Device | undefined {
        return this.devices.get(id)
    }

    async claimDevice(siteId: string, code: string): Promise<Device> {
        const dto = await this.service.claim(siteId, code)

        return this.devices.set(dto.id, dto)
    }
}
