import type { EventEmitter2 } from 'eventemitter2'
import { makeAutoObservable } from 'mobx'
import { inject, injectable } from 'inversify'
import T from 'config/inversify.types'
import log from 'logging/logger'

import { Deployment } from '../deployment'
import { VdbClient } from './vdb.client'

@injectable()
export class VdbClientManager {
    private client?: VdbClient

    constructor(@inject(T.AppEventBus) private appbus: EventEmitter2) {
        log.info('[VdbClientManager] new')
        makeAutoObservable(this)
    }

    start(deployment: Deployment): void {
        if (this.client?.id !== deployment.deploymentId) {
            this.stop()

            log.info('[VdbClientManager] Starting')
            this.client = new VdbClient(deployment, this.appbus)
            this.client.start()
        }
    }

    stop(): void {
        if (this.client) {
            log.info('[VdbClientManager] Stopping')
            this.client.stop()
            this.client = undefined
        }
    }

    get current(): VdbClient | undefined {
        return this.client
    }
}
