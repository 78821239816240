import { inject, injectable } from 'inversify'
import { DetectorKibsiClient, PaginationRequest, PaginationResponse } from '@kibsi/ks-client-sdk'
import {
    Detector,
    DetectorCreate,
    DetectorUpdate,
    DetectorCriteria,
    DetectorMappings,
    Model,
    ModelCreate,
    ModelCriteria,
    ModelUpdate,
    UploadUrl,
} from '@kibsi/ks-application-types'
import { DetectorService } from './detector.service'
import TYPES from '../../config/inversify.types'
import { RequestStore } from '../../store/request.store'

export type DetectorApi = Pick<
    InstanceType<typeof DetectorKibsiClient>,
    | 'list'
    | 'listDetectorEnhancers'
    | 'listModels'
    | 'createModel'
    | 'getModel'
    | 'deleteModel'
    | 'getModelUploadUrl'
    | 'createDetector'
    | 'updateDetector'
    | 'getDetector'
    | 'deleteDetector'
    | 'getDetectorsForModel'
    | 'updateModel'
    | 'createDetectorEnhancer'
    | 'addDetectorEnhancers'
    | 'removeDetectorEnhancer'
>

@injectable()
export class DetectorServiceImpl implements DetectorService {
    constructor(
        @inject(TYPES.RequestStore) private req: RequestStore,
        @inject(TYPES.DetectorApi) private api: DetectorApi,
    ) {}

    list(request?: PaginationRequest<DetectorCriteria>): Promise<PaginationResponse<Detector>> {
        return this.req.once(`detectors.list`, () => this.api.list(request))
    }

    listDetectorEnhancers(detectorId: string, request?: PaginationRequest): Promise<Detector[]> {
        return this.req.once(`detectors.listDetectorEnhancers.${detectorId}`, async () => {
            const { results } = await this.api.listDetectorEnhancers(detectorId, request)
            return results
        })
    }

    getDetectorsForModel(modelId: string, request?: PaginationRequest): Promise<PaginationResponse<Detector>> {
        return this.req.once(`detectors.getDetectorForModel.${modelId}`, () =>
            this.api.getDetectorsForModel(modelId, request),
        )
    }

    listModels(request: PaginationRequest<ModelCriteria>): Promise<PaginationResponse<Model>> {
        return this.req.once('detectors.listModels', () => this.api.listModels(request))
    }

    createModel(entity: ModelCreate): Promise<Model> {
        return this.api.createModel(entity)
    }

    getModel(modelId: string): Promise<Model> {
        return this.req.once(`detectors.getModel.${modelId}`, async () => this.api.getModel(modelId))
    }

    deleteModel(modelId: string, cascade?: boolean): Promise<void> {
        return this.api.deleteModel(modelId, {
            ...(cascade && {
                params: {
                    cascade,
                },
            }),
        })
    }

    getModelUploadUrl(modelId: string): Promise<UploadUrl> {
        return this.api.getModelUploadUrl(modelId)
    }

    createDetector(entity: DetectorCreate): Promise<Detector> {
        return this.api.createDetector(entity)
    }

    updateDetector(entity: DetectorUpdate): Promise<Detector> {
        return this.api.updateDetector(entity)
    }

    getDetector(detectorId: string): Promise<Detector> {
        return this.req.once(`detectors.get.${detectorId}`, async () => this.api.getDetector(detectorId))
    }

    deleteDetector(detectorId: string, cascade?: boolean): Promise<void> {
        return this.api.deleteDetector(detectorId, {
            ...(cascade && {
                params: {
                    cascade,
                },
            }),
        })
    }

    updateModel(entity: ModelUpdate): Promise<Model> {
        return this.api.updateModel(entity)
    }

    createDetectorEnhancer(detectorId: string, enhancerId: string): Promise<void> {
        return this.api.createDetectorEnhancer(detectorId, enhancerId)
    }

    addDetectorEnhancers(detectorId: string, enhancers: DetectorMappings): Promise<void> {
        return this.api.addDetectorEnhancers(detectorId, enhancers)
    }

    removeDetectorEnhancer(detectorId: string, enhancerId: string): Promise<void> {
        return this.api.removeDetectorEnhancer(detectorId, enhancerId)
    }
}
