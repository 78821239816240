import { ContainerModule } from 'inversify'
import { MediaViewerSettings } from 'store/event/mediaViewerSettings'
import T from '../../config/inversify.types'
import { EventServiceImpl } from './event.service.impl'
import { EventStore, LiveEventsManager } from '../../store/event'

export const EventModule = new ContainerModule((bind) => {
    bind(T.EventService).to(EventServiceImpl).inSingletonScope()
    bind(T.EventStore).to(EventStore).inSingletonScope()
    bind(T.LiveEventsManager).to(LiveEventsManager).inSingletonScope()
    bind(T.MediaViewerSettings).to(MediaViewerSettings).inSingletonScope()
})
