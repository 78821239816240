import { Box, Checkbox, CheckboxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ReactElement } from 'react'

// An alternate way to style components without having to use 'styled' see KBRadio

const emptyStyle = (theme: Theme) => ({
    width: '15px',
    height: '15px',
    border: 2,
    borderColor: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
    backgroundColor: 'lightGray',
})

const fillStyle = (theme: Theme) => ({
    ...emptyStyle(theme),
    borderRadius: '25%',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
})

export function KBCheckBox(props: CheckboxProps): ReactElement {
    return (
        <Checkbox
            data-testid="kb_checkbox"
            sx={(theme) => ({
                color: theme.palette.primary.main,
            })}
            {...props}
            icon={<Box sx={emptyStyle} />}
            checkedIcon={<Box sx={fillStyle} />}
        />
    )
}
