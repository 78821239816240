import { Resource, ResourceLanguage } from 'i18next'
import translateEN from './locales/en/translation.json'
import translateJA from './locales/ja/translation.json'

const resources: Resource = {
    en: {
        translation: translateEN as ResourceLanguage,
    },
    ja: {
        translation: translateJA as ResourceLanguage,
    },
}

export default resources
