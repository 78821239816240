import { ReactElement } from 'react'
import { Box, Chip } from '@mui/material'
import { List } from '@mui/icons-material'

import { Expression } from './types'

// Made up types that will help determine styling of pills.
// identifiers are the lighter pills. operator and literal get darker pills
export type TokenType = 'identifier' | 'operator' | 'literal'

export interface Token {
    type: TokenType
    label: string
}

export type Tokens = (Token | Token[])[]

export interface VisualEditorProps {
    expression: Expression
}

/**
 * Basically a read only view of the expression. Selecting suggestions is handled by the parent component.
 */
export function VisualEditor({ expression }: VisualEditorProps): ReactElement {
    const tokens = tokenize(expression)

    // for now use static pills
    const pills = tokens && (
        <>
            <Chip
                size="small"
                label="Diners"
                icon={<List />}
                sx={{
                    backgroundColor: 'secondary.light',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    color: 'common.black',
                    mx: 0.5,
                    mr: 0,
                    '& .MuiChip-icon': { ml: 1 },
                }}
            />

            <Chip
                size="small"
                label="Age"
                sx={{
                    backgroundColor: 'secondary.light',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    color: 'common.black',
                    mx: 0.5,
                    '& .MuiChip-icon': { ml: 1 },
                }}
            />

            <Chip
                size="small"
                label="anyMatch"
                sx={{
                    backgroundColor: 'secondary.main',
                    color: 'common.black',
                    mx: 0.5,
                    '& .MuiChip-icon': { ml: 1 },
                }}
            />
        </>
    )
    return <Box>{pills}</Box>
}

/**
 * A function to convert the ast into tokens that can then be used to create pills / chips.
 */
// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
function tokenize(_expression: Expression): Tokens {
    return []
}
