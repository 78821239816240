import { ReactElement } from 'react'
import { KBObjectPopup } from './KBObjectPopup'

export type KBValueProps = {
    value: unknown
}

export function KBValue({ value }: KBValueProps): ReactElement | null {
    if (value === undefined || value === null) {
        return null
    }

    if (Array.isArray(value) && value.length === 1 && typeof value[0] !== 'object') {
        return <> {`${value[0]}`}</>
    }

    if (typeof value === 'object') {
        return <KBObjectPopup value={value} renderValue={(v) => <KBValue value={v} />} />
    }

    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    return <> {`${value}`}</>
}
