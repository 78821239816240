import { ReactElement, ReactNode } from 'react'
import { Button } from '@mui/material'
import { Close } from '@mui/icons-material'
import { WithDataProps } from '@kibsi/ui-components'
import { useTranslation } from 'react-i18next'

export interface CancelButtonProps {
    label?: string
    icon?: ReactNode
    onCancel?: () => void
}

export function CancelButton({ label, icon, onCancel, ...data }: WithDataProps<CancelButtonProps>): ReactElement {
    const { t } = useTranslation()
    const startIcon = icon ?? <Close />
    const text = label ?? t('action.cancel')

    return (
        <Button
            startIcon={startIcon}
            onClick={onCancel}
            size="large"
            sx={{
                '&.MuiButton-root': {
                    color: 'text.secondary',
                    textTransform: 'capitalize',
                },
            }}
            {...data}
        >
            {text}
        </Button>
    )
}
