import { languages } from 'monaco-editor'

/**
 * Kibsi expression language for the monaco editor
 *
 * Provides tokenization for the Kibsi grammar (https://bitbucket.org/teamkibsi/expression-parser/src/master/src/grammar.ne)
 *
 * https://microsoft.github.io/monaco-editor/monarch.html
 */
export const kibsi: languages.IMonarchLanguage = {
    defaultToken: 'invalid',
    keywords: ['null', 'true', 'false'],
    brackets: [
        { open: '(', close: ')', token: 'delimiter.parenthesis' },
        { open: '<', close: '>', token: 'delimiter.angle' },
        { open: '[', close: ']', token: 'delimiter.square' },
    ],
    operators: ['=', '<', '>', '<=', '>=', '+', '-', '*', '/'],
    symbols: /[=<>+\-*/,]+/,
    escapes: /\\(?:["'\\/bfnrt]|u[a-fA-F0-9]{4})/,
    tokenizer: {
        root: [
            [
                /[$a-zA-Z_][a-zA-Z_0-9]*/,
                {
                    cases: {
                        '@keywords': 'keyword',
                        '@default': 'identifier',
                    },
                },
            ],
            [/[ \t\n\v\f]+/, ''],
            [/[()[\]]/, '@brackets'],
            [/[<>](?!@symbols)/, '@brackets'],
            [/@symbols/, { cases: { '@operators': 'delimiter', '@default': '' } }],
            [/-?\d+([.]\d+)?/, 'number'],
            [/[.]/, 'delimiter'],
            { include: '@string' },
        ],

        string: [
            [/"([^"\\]|\\.)*$/, 'string.invalid'],
            [/'([^'\\]|\\.)*$/, 'string.invalid'],
            [/"/, 'string', '@dstring'],
            [/'/, 'string', '@sstring'],
        ],

        dstring: [
            [/[^\\"]+/, 'string'],
            [/@escapes/, 'string.escape'],
            [/\\./, 'string.escape.invalid'],
            [/"/, 'string', '@pop'],
        ],

        sstring: [
            [/[^\\']+/, 'string'],
            [/@escapes/, 'string.escape'],
            [/\\./, 'string.escape.invalid'],
            [/'/, 'string', '@pop'],
        ],
    },
}
