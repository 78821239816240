import { ReactElement } from 'react'

import { Slider, Stack, StackProps } from '@mui/material'
import { Add, Remove } from '@mui/icons-material'

export interface ZoomSliderProps {
    value?: number
    onChange?: (value: number) => void
}

export function ZoomSlider({ value = 1, onChange }: ZoomSliderProps): ReactElement {
    const onZoom = (_event: Event, zoom: number | number[]) => {
        const [z] = [zoom].flat()

        onChange?.(z / 100)
    }

    const zoom = Math.max(0.01, Math.min(2, value)) * 100

    const sliderSx = {
        color: 'grey.400',
        '& .MuiSlider-track': {
            border: 'none',
            opacity: 0.38,
        },
        '& .MuiSlider-thumb': {
            backgroundColor: '#fff',
            border: '1px solid',
            borderColor: 'primary.main',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },
            '&:before': {
                display: 'none',
            },
        },
    }

    return (
        <Stack direction="row" alignItems="center" gap={1.25} color="text.secondary">
            <ControlButton alignItems="flex-end">
                <Remove fontSize="inherit" />
            </ControlButton>

            <Stack width={320}>
                <Slider value={zoom} onChange={onZoom} min={1} max={200} defaultValue={100} sx={sliderSx} />
            </Stack>

            <ControlButton>
                <Add fontSize="inherit" />
            </ControlButton>
        </Stack>
    )
}

function ControlButton(props: StackProps) {
    return <Stack justifyContent="center" width={30} height={30} fontSize="0.9em" {...props} />
}
