import type { LocalRepository } from 'repositories/local'
import { NodePosition, PanZoom } from 'model/canvas'
import { injectable } from 'inversify'

@injectable()
export class CanvasRepo {
    constructor(private storage: LocalRepository) {}

    protected setPosition(key: string, position?: NodePosition): void {
        this.storage.set(key, position)
    }

    protected getPosition(key: string): NodePosition | undefined {
        return this.storage.get(key)
    }

    protected setPanAndZoom(key: string, value?: PanZoom): void {
        this.storage.set(key, value)
    }

    protected getPanAndZoom(key: string): PanZoom | undefined {
        return this.storage.get(key)
    }
}
