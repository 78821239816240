import { useEffect } from 'react'
import type m from 'monaco-editor'
import { Monaco } from '@monaco-editor/react'

export interface Marker {
    message: string
    startColumn: number
    endColumn: number
    severity: 'warning' | 'error'
}

export function useMarkers(monaco: Monaco | null, markers: Marker[] = []): void {
    useEffect(() => {
        if (monaco) {
            const [model] = monaco.editor.getModels()

            monaco.editor.setModelMarkers(
                model,
                'owner',
                markers.map((m) => convertMarker(monaco, m)),
            )
        }
    }, [monaco, markers])
}

export function convertMarker(monaco: Monaco, marker: Marker): m.editor.IMarkerData {
    const { message, severity, startColumn: start, endColumn: end } = marker

    return {
        message,
        startLineNumber: 1,
        startColumn: start,
        endLineNumber: 1,
        endColumn: end,
        severity: convertSeverity(monaco, severity),
    }
}

export function convertSeverity(monaco: Monaco, severity: Marker['severity']): m.MarkerSeverity {
    switch (severity) {
        case 'warning':
            return monaco.MarkerSeverity.Warning
        case 'error':
            return monaco.MarkerSeverity.Error
        default:
            return monaco.MarkerSeverity.Info
    }
}
