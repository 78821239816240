import { Grid, Stack, Typography } from '@mui/material'
import React, { ReactElement } from 'react'
import { useSearchParams } from 'react-router-dom'

export function LoginErrorPage(): ReactElement {
    const [searchParams] = useSearchParams()

    const params = []

    for (const [key, value] of searchParams.entries()) {
        params.push([key, value])
    }

    return (
        <Stack sx={{ m: 2, p: 2 }} spacing={2}>
            <Typography variant="h3">Login Error</Typography>
            <Grid container>
                {params.map(([key, val]) => (
                    <React.Fragment key={key}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2">{key}:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2">{val}</Typography>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Stack>
    )
}
