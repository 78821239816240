import { Event } from './event'

/**
 * Sort events by end time descending
 */
export function endTimeSort(a: Event, b: Event): number {
    return (b.endDate?.valueOf() ?? 0) - (a.endDate?.valueOf() ?? 0)
}

/**
 * Sort events by start time descending
 */
export function startTimeSort(a: Event, b: Event): number {
    return b.startDate.valueOf() - a.startDate.valueOf()
}

/**
 * Sort events with active first then by start time descending
 */
export function activeThenStartTimeSort(a: Event, b: Event): number {
    if (a.isActive !== b.isActive) {
        return a.isActive ? -1 : 1
    }

    return startTimeSort(a, b)
}
