import type { SelfPasswordUpdate } from '@kibsi/ks-tenant-types'
import T from 'config/inversify.types'
import { useLogin } from 'hooks/auth/useLogin'
import { useInjection } from 'inversify-react'
import logger from 'logging/logger'
import { CHANGE_PASSWORD_FLOW, PASSWORD_CHANGE_ACCEPTED_AUTH_TIME } from 'pages/profile/types'
import { useEffect } from 'react'
import { UserService } from 'service/user'
import { KibsiHttpError } from 'service/api/http.error'
import { Status, usePromiseStateLater } from '../usePromiseState'

export function useChangePassword(): [
    undefined | Status<void>,
    (selfPasswordUpdate: SelfPasswordUpdate) => Promise<void>,
] {
    const login = useLogin(PASSWORD_CHANGE_ACCEPTED_AUTH_TIME, CHANGE_PASSWORD_FLOW)
    const service = useInjection<UserService>(T.UserService)

    const [status, setStatus] = usePromiseStateLater<void>()

    const changePassword = (selfPasswordUpdate: SelfPasswordUpdate): Promise<void> => {
        const promise = service.updateSelfPassword(selfPasswordUpdate)
        setStatus(promise)
        return promise
    }

    useEffect(() => {
        if (status?.state === 'rejected') {
            const errorVal = status.value
            if (errorVal instanceof KibsiHttpError) {
                if (errorVal.statusCode === 401 && errorVal.message.includes('auth_time')) {
                    // expected: UnauthorizedError: Invalid id_token; auth_time too long ago.
                    // this will call login again

                    login().catch((e) => logger.error(e))
                }
            }
        }
    }, [login, status?.state, status?.value])

    return [status, changePassword]
}
