import {
    ItemType,
    Proximity,
    ProximityOptions,
    RelationDef,
    RelationshipAttr as AppRelAttr,
    AttributeTypeDef,
} from '@kibsi/ks-application-types'
import { ProximityAnchorOverride, Region, RegionAttr, StaticItem } from '@kibsi/ks-deployment-types'
import { getDisplayAttributeLabel } from 'model/static.item'
import { makeAutoObservable } from 'mobx'
import { NamedPolygon, Point } from 'model/draw'
import { AnchorPosition } from 'service/application'
import type { Deployment } from './deployment'

export interface AnchorDefinition {
    detectedItemType: ItemType
    targetItemType: ItemType
    staticInstance?: StaticItem
    targetRegion?: Region
    override?: ProximityAnchorOverride
}

export class RelationshipAttribute {
    private configuredOverrides: Record<string, ProximityAnchorOverride> = {}
    private proximity: Proximity

    constructor(
        private deployment: Deployment,
        private itemTypeId: string,
        private attribute: AppRelAttr,
        public streamId: string,
    ) {
        makeAutoObservable(this)
        this.proximity = this.getProxmity(attribute)
        this.initOverrides()
    }

    get id(): string {
        return this.attributeId
    }

    get attributeId(): string {
        return this.attribute.id
    }

    get attributeName(): string {
        return this.attribute.name
    }

    get itemType(): ItemType | undefined {
        return this.deployment.getItemTypeById(this.itemTypeId)
    }

    get defaultPosition(): AnchorPosition | undefined {
        return this.proximity.options?.anchor?.position
    }

    get defaultRelation(): RelationDef {
        return this.attribute.value.relation
    }

    get defaultProximity(): Proximity {
        return this.proximity
    }

    get defaultProximityOptions(): ProximityOptions | undefined {
        return this.proximity.options
    }

    get relationItemType(): ItemType | undefined {
        return this.deployment.getItemTypeById(this.defaultRelation.itemTypeId)
    }

    get anchorDefinitions(): AnchorDefinition[] {
        const { itemTypeId: targetItemTypeId, relationType } = this.defaultRelation
        const detectedItemType = this.itemType
        if (!detectedItemType) {
            return []
        }

        const targetItemType = this.relationItemType
        if (relationType === 'proximity' && targetItemType?.generationType === 'detected') {
            return [
                {
                    detectedItemType,
                    targetItemType,
                    override: this.configuredOverrides[this.getOverrideKey()],
                },
            ]
        }

        if (targetItemType?.generationType === 'static') {
            const regionId = this.defaultProximity?.regionAttrId
            const staticInstances = this.deployment.staticItems.filter((si) => si.itemTypeId === targetItemTypeId)
            return staticInstances.flatMap((si) => {
                const regionAttr = si.attributes?.find((attr) => attr.id === regionId) as RegionAttr | undefined
                if (!regionAttr) {
                    return []
                }

                return regionAttr.value
                    .filter((ra) => ra.streamId === this.streamId)
                    .flatMap((ra) => ({
                        detectedItemType,
                        targetItemType,
                        staticInstance: si,
                        targetRegion: ra.region,
                        override: this.configuredOverrides[this.getOverrideKey(si.id, ra.region.id)],
                    }))
            })
        }

        return []
    }

    get overrides(): ProximityAnchorOverride[] {
        return Object.values(this.configuredOverrides)
    }

    get proximityRegion(): AttributeTypeDef | undefined {
        const { regionAttrId } = this.proximity
        if (!regionAttrId) {
            return undefined
        }
        const owingSide = this.deployment.getItemTypeById(this.itemTypeId)
        let staticItem: ItemType | undefined
        if (owingSide?.generationType === 'static') {
            staticItem = owingSide
        } else {
            staticItem = this.deployment.getItemTypeById(this.attribute.value.relation.itemTypeId)
        }

        return staticItem?.attributes?.find((attr) => attr.id === regionAttrId && attr.value.valueType === 'region')
    }

    get hasProximityRegion(): boolean {
        return this.proximityRegion !== undefined
    }

    /**
     * @param definition - current anchor definition that will be overridden.
     * @param position - if position is undefined, the override is removed.
     * @returns
     */
    public updateAnchorOverride(definition: AnchorDefinition, position?: AnchorPosition): AnchorDefinition {
        const { staticInstance, targetRegion } = definition
        const key = this.getOverrideKey(staticInstance?.id, targetRegion?.id)
        // add update override
        if (position) {
            const override: ProximityAnchorOverride = {
                streamId: this.streamId,
                staticInstanceId: staticInstance?.id,
                regionId: targetRegion?.id,
                anchor: { anchorType: 'boundingBox', position },
            }
            this.configuredOverrides[key] = override
            this.deployment.updateProximityRelationAnchorPosition(
                this.itemTypeId,
                this.streamId,
                this.attribute,
                this.overrides,
            )
            return {
                ...definition,
                override,
            }
        }

        // remove override
        delete this.configuredOverrides[key]

        this.deployment.updateProximityRelationAnchorPosition(
            this.itemTypeId,
            this.streamId,
            this.attribute,
            Object.values(this.configuredOverrides),
        )

        const updatedDef = {
            ...definition,
        }
        delete updatedDef.override

        return updatedDef
    }

    /**
     * Remove all overrides for all relationships related to this detected item.
     */
    public clearAllOverrides(): void {
        this.configuredOverrides = {}

        this.deployment.updateProximityRelationAnchorPosition(this.itemTypeId, this.streamId, this.attribute, [])
    }

    /**
     * Returns a unique identifier for the anchor override
     *
     * @param staticInstanceId
     * @param regionId
     */
    public getOverrideKey(staticInstanceId?: string, regionId?: string): string {
        let key = `${this.attribute.id}`
        if (staticInstanceId) {
            key = `${this.attribute.id}:${staticInstanceId}:${regionId}`
        }
        return key
    }

    /**
     * Returns Polygon regions configured for a static instance region
     *
     * @param staticInstanceId
     */
    public staticRegions(staticInstanceId: string): NamedPolygon[] {
        const staticInstance = this.deployment.staticItems.find((si) => si.id === staticInstanceId)
        if (!staticInstance) {
            return []
        }

        const { regionAttrId } = this.proximity
        const regionAttr = staticInstance.attributes?.find(
            (attr) => attr.valueType === 'region' && attr.id === regionAttrId,
        ) as RegionAttr
        if (!regionAttr) {
            return []
        }
        const regionAttachments = regionAttr.value.filter(
            (regionAttachment) => regionAttachment.streamId === this.streamId,
        )

        const staticItemType = this.deployment.getItemTypeById(staticInstance.itemTypeId)
        const name = getDisplayAttributeLabel(staticInstance, staticItemType)

        return regionAttachments.map(({ region: { id, path } }) => ({
            id,
            name,
            polygon: path as Point[],
        }))
    }

    public staticInstanceName(staticInstanceId: string): string {
        const staticInstance = this.deployment.staticItems.find((si) => si.id === staticInstanceId)
        if (!staticInstance) {
            throw new Error(`No static instance with ID ${staticInstance} exists.`)
        }

        const nameAttr = staticInstance.attributes?.find((attr) => attr.valueType === 'value' && attr.id === 'name')
        return (nameAttr?.value as string) || this.relationItemType?.name || 'unknown'
    }

    private getProxmity(attribute: AppRelAttr): Proximity {
        const { relation } = attribute.value
        const { relationType } = relation

        let proximity: Proximity | undefined
        if (relationType === 'proximity') {
            proximity = relation.proximity
        }

        const targetItemType = this.relationItemType
        const owningSideRel = targetItemType?.attributes?.find(
            (attr) => attr.id === attribute.value.relation?.attributeId,
        )
        if (
            relationType === 'bidirectional' &&
            owningSideRel?.value.valueType === 'relationship' &&
            owningSideRel.value.relation.relationType === 'proximity'
        ) {
            proximity = owningSideRel.value.relation.proximity
        }

        if (!proximity) {
            throw new Error('No proxmity defined on relationship with type `proximity`')
        }
        return proximity
    }

    private initOverrides(): void {
        const {
            itemTypeId: relTargetItemId,
            relationType,
            attributeId: targetAttributeId,
        } = this.attribute.value.relation
        if (relationType === 'proximity') {
            const dic = this.deployment.detectableItemConfigs.find(
                (itemConfig) => itemConfig.itemTypeId === this.itemTypeId && itemConfig.streamId === this.streamId,
            )

            if (!dic) {
                return
            }

            const deployRelAttr = dic.attributes?.find((attr) => attr.id === this.attribute.id)
            if (deployRelAttr) {
                this.configuredOverrides = deployRelAttr.value.reduce<Record<string, ProximityAnchorOverride>>(
                    (prev, current) => {
                        const key = this.getOverrideKey(current.staticInstanceId, current.regionId)
                        prev[key] = current
                        return prev
                    },
                    {},
                )
            }

            return
        }

        const relatedItemType = this.deployment.getItemTypeById(relTargetItemId)
        if (relationType === 'bidirectional' && relatedItemType?.generationType === 'static') {
            const staticInstances = this.deployment.staticItems.filter((si) => si.itemTypeId === relTargetItemId)
            const relAttrsOverride = staticInstances.flatMap((si) =>
                si.attributes.filter((attr) => attr.valueType === 'relationship' && attr.id === targetAttributeId),
            )
            const overrides = relAttrsOverride.flatMap((relAttr) => relAttr.value as Array<ProximityAnchorOverride>)
            this.configuredOverrides = overrides.reduce<Record<string, ProximityAnchorOverride>>((prev, current) => {
                const key = this.getOverrideKey(current.staticInstanceId, current.regionId)
                prev[key] = current
                return prev
            }, {})
        }
    }
}
