import { comparer, makeObservable, observable, override, reaction, runInAction } from 'mobx'
import { IDisposer } from 'mobx-utils'
import { AppModelDefinition as AppDefDto } from '@kibsi/ks-application-types'
import { ApplicationService } from 'service'
import log from 'logging/logger'

import { ApplicationDef } from './application.def'
import { AppHistory } from './history'

export class EditableApplicationDef extends ApplicationDef {
    private reactions: IDisposer[] = []
    private skipUpdate = false
    private skipHistory = false

    constructor(id: string, appDef: AppDefDto, private service: ApplicationService, private history: AppHistory) {
        super(id, appDef)

        makeObservable<this, 'skipUpdate' | 'skipHistory'>(this, {
            skipHistory: observable,
            skipUpdate: observable,
            fromDto: override,
        })

        this.bindReactions()
    }

    fromDto(dto: AppDefDto): void {
        super.fromDto(dto)
        this.skipUpdate = true
        this.skipHistory = true
    }

    replace(dto: AppDefDto): void {
        super.fromDto(dto)
    }

    private bindReactions(): void {
        this.reactions.push(
            reaction(
                () => this.toDto(),
                (partial) => {
                    if (!this.skipUpdate) {
                        this.update(partial)
                    }

                    if (!this.skipHistory) {
                        this.history.push({ def: partial })
                    }
                },
                { equals: comparer.structural },
            ),
            reaction(
                () => ({
                    source: this.history.source,
                    def: this.history.current?.def,
                }),
                ({ source, def }) => {
                    if (['undo', 'redo'].includes(source) && def) {
                        runInAction(() => {
                            this.fromDto(def)
                            this.skipUpdate = false
                        })
                    }
                },
                {
                    equals: comparer.structural,
                },
            ),
            reaction(
                () => this.skipUpdate || this.skipHistory,
                (skip) => {
                    if (skip) {
                        runInAction(() => {
                            this.skipUpdate = false
                            this.skipHistory = false
                        })
                    }
                },
            ),
        )
    }

    private update(dto: AppDefDto): void {
        const result = this.service.setModelDefinition(this.id, dto)

        result.catch((e) => {
            log.error('update failed', e)
        })
    }
}
