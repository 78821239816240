import { injectable } from 'inversify'
import { EventEmitter } from 'events'
import {
    SocketConnectionEvent,
    SocketConnectionListener,
    SocketMessageEvent,
    SocketMessageListener,
    SocketService,
    SOCKET_CONNECTION_EVENT,
    SOCKET_MESSAGE_EVENT,
} from './socket.service'
import logger from '../../logging/logger'

@injectable()
export class SocketServiceImpl implements SocketService {
    private ws: WebSocket | undefined
    private emitter = new EventEmitter()

    get host(): string | undefined {
        return this.ws?.url
    }

    connect(host: string): void {
        logger.info('WS.connect', host)

        if (this.ws && this.host !== host) {
            this.ws.close()
        }

        this.ws = new WebSocket(host)

        this.ws.onopen = () => {
            logger.log(`connection made on: ${this.host}`)
            this.emitter.emit(SOCKET_CONNECTION_EVENT, {
                connected: true,
            })
        }

        this.ws.onclose = (event) => {
            logger.log(`connection closed on: ${this.host}, code: ${event.code} reason: ${event.reason}`)
            this.emitter.emit(SOCKET_CONNECTION_EVENT, {
                connected: false,
                code: event.code,
                reason: event.reason,
            })

            // TODO: re-connect logic
        }

        this.ws.onmessage = (event) => {
            logger.trace(`socket message received ${event.data}`)
            this.emitter.emit(SOCKET_MESSAGE_EVENT, event.data)
        }

        this.ws.onerror = (event) => {
            logger.error(event)
        }
    }

    disconnect(): void {
        this.ws?.close()
    }

    socket(): WebSocket | undefined {
        return this.ws
    }

    listen(
        event: SocketConnectionEvent | SocketMessageEvent,
        listener: SocketConnectionListener | SocketMessageListener,
    ): void {
        logger.info(`listen on ${event}`)
        this.emitter.on(event, listener)
    }

    removeListener(
        event: SocketConnectionEvent | SocketMessageEvent,
        listener: SocketConnectionListener | SocketMessageListener,
    ): void {
        logger.info(`listen off ${event}`)
        this.emitter.removeListener(event, listener)
    }
}
