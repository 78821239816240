import { KBDrawerContainer, KBDrawerTitle, KBForm, KBLoadingButton } from '@kibsi/ui-components'
import { Alert, AlertTitle, Box, Checkbox, FormControlLabel, FormGroup, Stack, TextField } from '@mui/material'
import { useInviteUser } from 'hooks/user'
import logger from 'logging/logger'
import { observer } from 'mobx-react-lite'
import { FormEvent, ReactElement, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type InviteFormData = {
    email: string
    userName: string
}

type InviteUserProps = {
    onClose: () => void
}

const isValidEmail = (email: string): boolean =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email,
    )

function InviteUser({ onClose }: InviteUserProps): ReactElement | null {
    const { t } = useTranslation()

    const [addAnother, setAddAnother] = useState<boolean>(false)
    const [status, inviteUser] = useInviteUser()

    const { control, handleSubmit, reset } = useForm<InviteFormData>({
        defaultValues: { email: '', userName: '' },
    })

    const onInvite = () => {
        handleSubmit(
            async (data) => {
                logger.debug('InviteUserPanel,invite', data)

                await inviteUser(data.email, data.userName)
                logger.info('inviting:', data.email)
                logger.debug('add another:', addAnother)
                reset()
                if (!addAnother) {
                    onClose()
                }
            },
            (e) => logger.warn('error on submit', e),
        )().catch((e) => logger.error(e))
    }
    const onAddAnother = () => {
        setAddAnother(!addAnother)
    }

    const rejectedErrorToString = (): string | undefined => {
        if (status?.state !== 'rejected') {
            return undefined
        }

        const errorVal = status?.value

        logger.warn('status?.value', errorVal)

        if (!errorVal) {
            return 'unknown'
        }

        if (errorVal instanceof Error) {
            return errorVal.message
        }

        return errorVal as string
    }

    const handleEmailValidation = (email: string): string | boolean => {
        if (!isValidEmail(email)) {
            return t('profile.invite.email.invalid')
        }
        return true
    }

    return (
        <KBDrawerContainer
            size="medium"
            header={<KBDrawerTitle title={t('profile.invite.header')} />}
            description={t('profile.invite.description')}
            onTopRightIconClicked={onClose}
            footer={
                <Stack direction="row" justifyContent="space-between" alignItems="end" alignContent="center">
                    <Box>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox onClick={onAddAnother} />}
                                label={t('profile.invite.addAnother')}
                            />
                        </FormGroup>
                    </Box>
                    <KBLoadingButton
                        loading={status?.state === 'pending'}
                        variant="contained"
                        size="large"
                        sx={{ textTransform: 'none' }}
                        onClick={onInvite}
                        data-testid="invite-user-button"
                    >
                        {t('profile.invite.button')}
                    </KBLoadingButton>
                </Stack>
            }
        >
            <KBForm
                onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    e?.preventDefault()
                    onInvite()
                }}
            >
                <Stack spacing={3}>
                    {status?.state === 'rejected' && (
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {rejectedErrorToString()}
                        </Alert>
                    )}
                    <Controller
                        name="userName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                autoFocus
                                type="text"
                                fullWidth
                                variant="standard"
                                inputProps={{
                                    'data-testid': 'invite-userName',
                                }}
                                label={t('profile.invite.userName.label')}
                                error={!!error}
                                helperText={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: t('profile.invite.email.required'),
                            validate: handleEmailValidation,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                autoFocus
                                type="email"
                                fullWidth
                                variant="standard"
                                inputProps={{
                                    'data-testid': 'invite-email',
                                }}
                                label={t('profile.invite.email.label')}
                                error={!!error}
                                helperText={error?.message}
                            />
                        )}
                    />
                </Stack>
            </KBForm>
        </KBDrawerContainer>
    )
}

export default observer(InviteUser)
