import { action, computed, makeObservable, observable } from 'mobx'
import type {
    AppModelDefinition as AppDefDto,
    AppSchemaVersion,
    EventItemType,
    ItemType as ItemTypeDto,
} from '@kibsi/ks-application-types'
import { isDetectedItemType, isEventItemType, isStaticItemType } from '@kibsi/ks-application-types'
import { DomainStore, FromDtoDomainStore } from '../domain'
import type { FromDto, ToDto } from '../interfaces'
import { ItemType } from './item.type'
import type { AttributeType } from './attribute.type'
import { createNameAttribute, createRegionAttribute } from './attribute.factory'

export type AddItemTypeOptions = {
    addRegion?: boolean
}

export class ApplicationDef implements ToDto<AppDefDto>, FromDto<AppDefDto>, AppDefDto {
    private items: DomainStore<ItemType, ItemTypeDto>

    readonly version?: AppSchemaVersion

    constructor(readonly id: string, appDef: AppDefDto) {
        const { itemTypes, schemaVersion } = appDef
        this.version = schemaVersion
        this.items = new FromDtoDomainStore<ItemTypeDto, ItemType>((_, data) => new ItemType(this, data))

        this.setItems(itemTypes)

        makeObservable<this, 'items'>(this, {
            items: observable,
            itemTypes: computed,
            addItemType: action,
            deleteItemType: action,
            toDto: observable,
            fromDto: action,
        })
    }

    get itemTypes(): ItemType[] {
        return this.items.values()
    }

    get locations(): ItemType[] {
        return this.itemTypes.filter(
            (item) =>
                item.getAttributesByTypes(['location']).length > 0 ||
                (item.generationType === 'event' &&
                    this.itemTypes.find((i) => i.id === item.event?.sourceItemId)?.generationType === 'detected'),
        )
    }

    getRelationshipTargets(itemType: ItemTypeDto): ItemType[] {
        const allowRelations = (it: ItemTypeDto) => isDetectedItemType(it) || isStaticItemType(it)

        // prettier-ignore
        return this.itemTypes
            .filter(allowRelations)
            // don't return self as a target
            .filter((item) => item.id !== itemType.id)
    }

    getItemType(id: string): ItemType | undefined {
        return this.items.get(id)
    }

    addItemType(itemType: ItemTypeDto): ItemType {
        if (isEventItemType(itemType)) {
            return this.addEventItemType(itemType)
        }

        return this.items.set(itemType.id, itemType)
    }

    addItemTypeWithAttr(itemType: ItemTypeDto, { addRegion }: AddItemTypeOptions): ItemType {
        const nameAttr = createNameAttribute()
        const attributes = [nameAttr]

        if (addRegion) {
            attributes.push(createRegionAttribute())
        }

        itemType.attributes = attributes
        itemType.displayAttributeId = nameAttr.id

        return this.addItemType(itemType)
    }

    deleteItemType(itemType: ItemType): void {
        if (this.items.delete(itemType.id)) {
            itemType.destroy()
        }
    }

    addEventItemType(eventItemType: EventItemType): ItemType {
        const { id } = eventItemType
        const existing: ItemType | undefined = this.items.get(id)
        // preserve existing actions, if present
        if (existing?.event?.actions) {
            eventItemType.event.actions = existing.event.actions
        }
        return this.items.set(id, eventItemType)
    }

    getEnhancerCount(): number {
        if (this.itemTypes.length < 1) {
            return 0
        }

        const staticAndDetected = this.itemTypes.filter(
            (it) => it.generationType === 'detected' || it.generationType === 'static',
        )

        return staticAndDetected.reduce((prev, item) => {
            const enhancerAttrs = item.attributes.filter((attr) => attr.value.valueType === 'enhancer')
            return prev + enhancerAttrs.length
        }, 0)
    }

    getDetectorCount(includeEnhancers = true): number {
        if (!this.itemTypes) {
            return 0
        }

        const detectedItems = this.itemTypes.filter((it) => it.generationType === 'detected')
        const detectorCount = detectedItems.reduce((prev, item) => prev + item.detector.length, 0)

        if (includeEnhancers) {
            return detectorCount + this.getEnhancerCount()
        }

        return detectorCount
    }

    countDetectorsPerItem(): Record<string, number> {
        if (!this.itemTypes) {
            return {}
        }

        const staticAndDetected = this.itemTypes.filter(
            (it) => it.generationType === 'detected' || it.generationType === 'static',
        )

        return staticAndDetected.reduce<Record<string, number>>((prev, item) => {
            let count = 0
            if (item.generationType === 'detected') {
                count = item.detector.length
            }

            count += item.attributes.filter((attr) => attr.value.valueType === 'enhancer').length

            prev[item.name] = count

            return prev
        }, {})
    }

    exceedsDetectorLimit(detectorsAllowed?: number): boolean {
        if (!detectorsAllowed) {
            return false // unlimited == 0, undefined will also == unlimited at this time.
        }

        return this.getDetectorCount(true) > detectorsAllowed
    }

    toDto(): AppDefDto {
        return {
            schemaVersion: this.version,
            itemTypes: this.items.values().map((item) => item.toDto()),
        }
    }

    fromDto({ itemTypes }: AppDefDto): void {
        this.setItems(itemTypes)
    }

    moveItem(from: string, to: string): void {
        const { items } = this

        if (items.has(to)) {
            throw new Error(`DuplicateItemId[${to}]`)
        }

        items.move(from, to)

        this.attributes.forEach((attr) => {
            if (attr.isRelationship()) {
                const { relation } = attr.getRelationshipValue()

                if (relation.itemTypeId === from) {
                    relation.itemTypeId = to
                }
            } else if (attr.isCounter()) {
                const count = attr.getCountDef()

                if (count.targetType === 'item' && count.targetTypeId === from) {
                    count.targetTypeId = to
                }
            }
        })

        this.itemTypes.forEach((item) => {
            if (item.isEvent) {
                if (item.event?.sourceItemId === from) {
                    item.event.sourceItemId = to
                }
            }
        })
    }

    get attributes(): AttributeType[] {
        return this.itemTypes.flatMap((itemType) => itemType.attributes)
    }

    /**
     * This is a computed observable to identify when a relationship has changed
     *
     * this grabs the non-owning relationship attribute
     */
    get nonOwningRelationships(): AttributeType[] {
        return this.attributes.filter(
            (attr) => attr.value.valueType === 'relationship' && attr.value.relation.attributeId,
        )
    }

    private setItems(items: ItemTypeDto[]) {
        this.items.setAll(items.map((item) => [item.id, item]))
    }
}
