import {
    DashboardBasic,
    Dashboard as DashboardDto,
    DashboardUpdate,
    HeatmapOptions as HeatmapOptionsDto,
    QuickSightOptions as QuickSightOptionsDto,
} from '@kibsi/ks-tenant-types'
import type { Mat } from '@techstark/opencv-js'
import logger from 'logging/logger'
import { comparer, makeAutoObservable, reaction, toJS } from 'mobx'
import { IDisposer } from 'mobx-utils'
import { EventItemType } from '@kibsi/ks-application-types'
import { GridEventParams } from '@kibsi/ks-history-types'
import { BaseImage } from 'model/baseImage'
import { DashboardService } from 'service/dashboard/dashboard.service'
import { DashboardType } from 'service/dashboard/types'
import { Deployment, DeploymentStore } from 'store/deployment'
import { HistoryService } from 'service/history'
import { HeatmapCell } from 'store/history/heatmapCell'
import { HistoryEventGroup } from 'store/event/history.event.group'
import { EventService } from 'service'
import { Stream, StreamStore } from 'store/stream'
import { HeatmapData } from './heatmap.data'
import { HeatmapOptions } from './heatmap.options'
import { QuickSightOptions } from './quick-sight.options'
import { HeatmapDashboardUpdate } from './types'
import { StaticRegions } from '../deployment/static.regions'
import { FloorPlan, FloorPlanStore } from '../floor-plan'
import { FromDto, ToDto } from '../interfaces'
import { transformRegionsWithMatrix } from '../../pages/dashboards/heatmap/utils'

export type DashboardDataUpdate = Omit<DashboardBasic, 'dashboardType'>

export class Dashboard implements ToDto<DashboardDto>, FromDto<DashboardDto> {
    private reactions: IDisposer[] = []
    private itemRegionsList: StaticRegions[] = []
    private heatmapFloorPlan?: FloorPlan
    private streamBase?: Stream
    private heatmapBaseImage?: BaseImage
    private heatmapFloorPlanMatrix?: Mat
    private heatmapData?: HeatmapData

    readonly id: string
    options?: HeatmapOptions | QuickSightOptions

    constructor(
        private dto: DashboardDto,
        private service: DashboardService,
        private floorPlanStore: FloorPlanStore,
        private historyService: HistoryService,
        private deploymentStore: DeploymentStore,
        private eventService: EventService,
        private streamStore: StreamStore,
    ) {
        this.id = dto.dashboardId

        makeAutoObservable<Dashboard, 'service' | 'reactions'>(this, {
            id: false,
            service: false,
            reactions: false,
        })

        this.setOptions(this.dto.options)
        this.bindReactions()
    }

    get name(): string {
        return this.dto.dashboardName
    }

    set name(value: string) {
        this.dto.dashboardName = value
    }

    get description(): string | undefined {
        return this.dto.dashboardDescription
    }

    set description(value: string | undefined) {
        this.dto.dashboardDescription = value
    }

    get type(): DashboardType {
        return this.dto.dashboardType
    }

    get siteId(): string {
        return this.dto.siteId
    }

    get heatmapOptions(): HeatmapOptions | undefined {
        if (this.type === 'heatmap') {
            return this.options as HeatmapOptions
        }
        return undefined
    }

    get quickSightOptions(): QuickSightOptions | undefined {
        if (this.type === 'quickSight') {
            return this.options as QuickSightOptions
        }
        return undefined
    }

    get baseImageId(): string | undefined {
        return this.heatmapOptions?.floorPlanId ?? this.heatmapOptions?.streamId
    }

    get baseImageType(): 'floorPlan' | 'stream' | undefined {
        if (this.heatmapOptions?.floorPlanId) {
            return 'floorPlan'
        }

        if (this.heatmapOptions?.streamId) {
            return 'stream'
        }

        return undefined
    }

    /**
     * Retrieve floor plan so matrix can be used to transform StaticRegions
     */
    async getFloorPlan(): Promise<FloorPlan | undefined> {
        if (!this.heatmapFloorPlan && this.heatmapOptions?.floorPlanId) {
            this.heatmapFloorPlan = await this.floorPlanStore.getFloorPlan(this.heatmapOptions.floorPlanId)
            this.heatmapBaseImage = this.heatmapFloorPlan
        }

        return this.heatmapFloorPlan
    }

    async getStream(): Promise<Stream | undefined> {
        if (!this.streamBase && this.heatmapOptions?.streamId) {
            this.streamBase = await this.streamStore.loadStream(this.heatmapOptions?.streamId)
            this.heatmapBaseImage = this.streamBase
        }

        return this.streamBase
    }

    async getBaseImage(): Promise<BaseImage | undefined> {
        if (!this.heatmapBaseImage) {
            if (this.heatmapOptions?.floorPlanId) {
                await this.getFloorPlan()
            } else if (this.heatmapOptions?.streamId) {
                await this.getStream()
            }
        }

        return this.heatmapBaseImage
    }

    get floorPlan(): FloorPlan | undefined {
        return this.heatmapFloorPlan
    }

    /**
     * Maintains the list of regions to be displayed on the heatmap
     */
    checkRegions(regions: StaticRegions): void {
        const staticRegions = this.itemRegionsList.find((ir) => ir.id === regions.id)
        if (!staticRegions) {
            this.itemRegionsList.push(regions)
        } else {
            staticRegions.regions = regions.regions
        }
    }

    /**
     * If there's a floor plan associated with the dashboard, transform the set of regions determined by the floor plan matrix; Base Image === FloorPlan
     * otherwise, just return the itemRegionList untransformed; Base Image === Stream
     */
    get heatmapRegions(): StaticRegions[] {
        if (this.floorPlan) {
            return transformRegionsWithMatrix(this.itemRegionsList, this.getFloorPlanMatrix())
        }
        return this.itemRegionsList
    }

    async getHeatmapData(): Promise<HeatmapData | undefined> {
        if (this.heatmapData) {
            return this.heatmapData
        }

        const bi = await this.getBaseImage()
        if (!bi) {
            return undefined
        }

        this.heatmapData = new HeatmapData(this.id, this.historyService, this.heatmapOptions, bi.dimensions)
        return this.heatmapData
    }

    async getHeatmapEvents(
        itemTypeId: string,
        startTime: number,
        endTime: number,
        cell: HeatmapCell,
    ): Promise<HistoryEventGroup> {
        const start = new Date(startTime).toISOString()
        const end = new Date(endTime).toISOString()
        const hmOptions = this.heatmapOptions
        if (!hmOptions) {
            throw new Error('undefined heatmap options')
        }

        await this.getBaseImage()
        if (!this.heatmapBaseImage) {
            throw new Error('undefined floor plan or stream.')
        }

        const grid = hmOptions.gridSize || 'small'
        const deploymentIds = hmOptions.deploymentIds || []
        const options: GridEventParams = {
            deploymentIds,
            itemTypes: [itemTypeId],
            grid,
            gridX: cell.chartX,
            gridY: cell.chartY,
        }

        const promises: Promise<any>[] = deploymentIds.map((did) => this.deploymentStore.loadDeployment(did))
        promises.push(
            this.historyService.heatmapGridEvents(
                this.heatmapBaseImage.id,
                start,
                end,
                options,
                this.heatmapFloorPlan ? 'floorPlan' : 'stream',
            ),
        )
        const results = await Promise.all(promises)
        const gridEvents = results.pop()
        const itemTypeFromDeployments = results.map((deployment: Deployment) => deployment.getItemTypeById(itemTypeId))
        const itemType = itemTypeFromDeployments.find((item) => item !== undefined)
        if (!itemType) {
            throw new Error(`cannot find event itemType with id ${itemTypeId}`)
        }

        if (!itemType.event) {
            throw new Error(`itemType withg id ${itemTypeId} is not an event.`)
        }

        return new HistoryEventGroup(itemType as EventItemType, results, this.eventService, gridEvents)
    }

    // NOTE: We only allow updating heatmaps from the EditForm
    async update(entity: HeatmapDashboardUpdate): Promise<void> {
        const update = {
            dashboardId: this.id,
            dashboardType: this.type,
            dashboardDescription: entity.dashboardDescription,
            dashboardName: entity.dashboardName,
            options: {
                ...this.options?.toDto(),
                ...entity.options,
            } as HeatmapOptionsDto,
        } as DashboardUpdate

        const dto = await this.service.update(update)

        this.fromDto(dto)
    }

    async getUrl(): Promise<string> {
        const { url } = await this.service.getDashboardUrl(this.id)
        logger.debug('dashboard url', url)
        return url
    }

    toDto(): DashboardDto {
        return {
            ...toJS(this.dto),
            options: this.options?.toDto(),
        } as DashboardDto
    }

    fromDto(dto: DashboardDto): void {
        this.dto = { ...dto, dashboardId: this.id }
        this.setOptions(this.dto.options)
    }

    private reactionDataUpdate(): DashboardDataUpdate {
        return {
            dashboardName: this.name,
            dashboardDescription: this.description,
            options: this.options?.toDto(),
        }
    }

    private setOptions(options?: HeatmapOptionsDto | QuickSightOptionsDto): void {
        this.heatmapData = undefined

        if (this.type === 'heatmap') {
            this.options = new HeatmapOptions(options as HeatmapOptionsDto)
        }

        if (this.type === 'quickSight') {
            this.options = new QuickSightOptions(options as QuickSightOptionsDto)
        }
    }

    private getFloorPlanMatrix(): Mat | undefined {
        try {
            if (!this.heatmapFloorPlanMatrix) {
                this.heatmapFloorPlanMatrix = this.floorPlan?.streams[0]?.matrix
            }
        } catch (e) {
            logger.warn(e)
        }

        return this.heatmapFloorPlanMatrix
    }

    private bindReactions(): void {
        this.reactions.push(
            reaction(
                () => this.reactionDataUpdate(),
                (update) => {
                    this.service
                        .update({
                            dashboardId: this.id,
                            dashboardType: this.type,
                            ...update,
                        } as DashboardUpdate)
                        .catch(() => {})
                },
                {
                    equals: comparer.structural,
                },
            ),
        )
    }
}
