import { ReactElement } from 'react'
import { ChevronRight } from '@mui/icons-material'
import { Fab, FabProps } from '@mui/material'
import { sxs } from '@kibsi/ui-components'

export type ArrowButtonProps = {
    show?: boolean
    direction: 'left' | 'right'
} & FabProps

export function ArrowButton({ show, direction, sx, ...props }: ArrowButtonProps): ReactElement {
    const size = 20
    const style = {
        transform: direction === 'right' ? `rotate(0deg)` : `rotate(180deg)`,
        transition: 'transform 150ms ease',
        fontSize: '18px',
    }

    return (
        <Fab
            sx={sxs(
                {
                    backgroundColor: 'white',
                    minHeight: 0,
                    height: size,
                    width: size,
                    color: 'primary.main',
                    transition: 'opacity 150ms',
                    opacity: show ? 1 : 0,
                },
                sx,
            )}
            data-testid="arrow-button"
            {...props}
        >
            <ChevronRight sx={style} />
        </Fab>
    )
}
