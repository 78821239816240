import { inject, injectable } from 'inversify'
import { DashboardKibsiClient, PaginationRequest } from '@kibsi/ks-client-sdk'
import TYPES from 'config/inversify.types'
import log from 'logging/logger'
import { Dashboard, DashboardCreate, DashboardCriteria, DashboardUpdate, DashboardUrl } from '@kibsi/ks-tenant-types'
import { DashboardService } from './dashboard.service'
import { RequestStore } from '../../store/request.store'

export type DashboardApi = Pick<
    InstanceType<typeof DashboardKibsiClient>,
    'create' | 'delete' | 'list' | 'read' | 'update' | 'getDashboardUrl'
>

@injectable()
export class DashboardServiceImpl implements DashboardService {
    constructor(
        @inject(TYPES.RequestStore) private req: RequestStore,
        @inject(TYPES.DashboardApi) private api: DashboardApi,
    ) {}

    async list(siteId: string, request: PaginationRequest<DashboardCriteria>): Promise<Dashboard[]> {
        log.debug('list dashboards', request)

        return this.req.once(`dashboard.list.${siteId}`, async () => {
            const { results } = await this.api.list({ ...request, criteria: { siteId } })
            return results
        })
    }

    create(dashboard: DashboardCreate): Promise<Dashboard> {
        return this.api.create(dashboard)
    }

    get(id: string): Promise<Dashboard> {
        return this.req.once(`dashboard.get.${id}`, () => this.api.read(id))
    }

    update(update: DashboardUpdate): Promise<Dashboard> {
        return this.api.update(update)
    }

    delete(dashboardId: string, cascade?: boolean): Promise<void> {
        return this.api.delete(dashboardId, {
            ...(cascade && {
                params: {
                    cascade,
                },
            }),
        })
    }

    getDashboardUrl(id: string): Promise<DashboardUrl> {
        return this.req.once(`dashboard.getUrl.${id}`, () => this.api.getDashboardUrl(id))
    }
}
