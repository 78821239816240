import type { FromDto } from '../interfaces'
import type { DomainFactory, DomainLoader } from './store'
import { AsyncDomainStoreImpl } from './store.async'
import { populate } from './fromdto'

export class AsyncFromDtoDomainStore<Dto, Domain extends FromDto<Dto>> extends AsyncDomainStoreImpl<Dto, Domain> {
    constructor(loader: DomainLoader<Dto>, factory: DomainFactory<Dto, Domain>) {
        super(loader, factory, populate)
    }
}
