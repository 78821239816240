import { ItemTypeIconVariant } from 'model/app'

export function getIconTypeFromId(id: string): string {
    // id {tenant}.{identifier}:{version}
    const comp = id.split(':')
    return comp[0].split('.')[1] ?? ''
}

export const colorForItemType = (variant: ItemTypeIconVariant): string => {
    switch (variant) {
        case 'detected':
            return '#37D29B' // Secondary/Main
        case 'static':
            return '#8152EC' // Primary/Main
        case 'event-occurrence':
        case 'event-span':
            // TODO: integrate theme.palette.highlight
            return '#FF9330' // Highlight/Highlight
        default:
            return '#000'
    }
}
