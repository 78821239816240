import { KBDrawer } from '@kibsi/ui-components'
import { RequireAuth } from 'auth/RequireAuth'
import { ReactElement } from 'react'
import ChangePassword from './ChangePassword'
import { CHANGE_PASSWORD_FLOW, PASSWORD_CHANGE_ACCEPTED_AUTH_TIME } from './types'

type ChangePasswordPanelProps = {
    open: boolean
    onClose: () => void
}

function ChangePasswordPanel({ open, onClose }: ChangePasswordPanelProps): ReactElement | null {
    return (
        <KBDrawer open={open} variant="persistent" belowToolbar>
            {open && (
                <RequireAuth authTime={PASSWORD_CHANGE_ACCEPTED_AUTH_TIME} flow={CHANGE_PASSWORD_FLOW}>
                    <ChangePassword onClose={onClose} />
                </RequireAuth>
            )}
        </KBDrawer>
    )
}

export default ChangePasswordPanel
