import axios from 'axios'
import { useCallback, useState } from 'react'
import type { FileInfo } from '../../components/file'
import logger from '../../logging/logger'

export type UploadState = 'idle' | 'initiated' | 'in-progress' | 'completed' | 'error'

export function useUploadFile(): [
    (uploadUrl?: string, fileInfo?: File, contentType?: string) => Promise<void>,
    UploadState,
    number,
    Error | undefined,
] {
    const [uploadState, setUploadState] = useState<UploadState>('idle')
    const [progress, setProgress] = useState(0)
    const [error, setError] = useState<Error | undefined>()

    const uploadFile = useCallback(
        async (uploadUrl?: string, fileInfo?: FileInfo, contentType?: string): Promise<void> => {
            if (!uploadUrl || !fileInfo) {
                return
            }

            setUploadState('initiated')
            setError(undefined)

            logger.debug(uploadUrl, fileInfo, contentType)

            await axios
                .put(uploadUrl, fileInfo, {
                    params: { Key: fileInfo.name, ContentType: fileInfo.type },
                    headers: { 'Content-Type': contentType ?? (fileInfo.type || 'application/octet-stream') }, // NOTE: Content-Type must match the Content-Type when generating presigned url
                    onUploadProgress: (progressEvent: ProgressEvent) => {
                        const uploadProgress = progressEvent.loaded / progressEvent.total
                        setProgress(uploadProgress)
                        setUploadState('in-progress')
                    },
                })
                .then(() => {
                    setUploadState('completed')
                })
                .catch((e) => {
                    setError(e)
                    setUploadState('error')
                    logger.error(e)
                    throw e
                })
        },
        [],
    )

    return [uploadFile, uploadState, progress, error]
}
