import { IdToken, useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import logger from '../logging/logger'

const useIdToken = (): [string, IdToken | undefined] => {
    const { getIdTokenClaims } = useAuth0()
    const [idToken, setIdToken] = useState<string>('')
    const [idTokenClaims, setIdTokenClaims] = useState<IdToken>()

    useEffect(() => {
        getIdTokenClaims()
            .then((idClaims) => {
                setIdTokenClaims(idClaims)

                // eslint-disable-next-line no-underscore-dangle
                setIdToken(idClaims?.__raw ?? '')
            })
            .catch((e) => logger.error(e))
    }, [getIdTokenClaims])

    return [idToken, idTokenClaims]
}

export { useIdToken }
