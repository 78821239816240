const CONFIG = {
    Environment: Symbol('Environment'),
    AuthConfig: Symbol('AuthConfig'),
}

const API = {
    ApiClient: Symbol('ApiClient'),
    ApplicationApi: Symbol('ApplicationApi'),
    StreamApi: Symbol('StreamApi'),
    DetectorApi: Symbol('DetectorApi'),
    SiteApi: Symbol('SiteApi'),
    DeploymentApi: Symbol('DeploymentApi'),
    EntitlementApi: Symbol('EntitlementApi'),
    EventApi: Symbol('EventApi'),
    TimeApi: Symbol('TimeApi'),
    UserApi: Symbol('UserApi'),
    UiApi: Symbol('UiApi'),
    KibsiApi: Symbol('KibsiApi'),
    KibsiApiConfig: Symbol('KibsiApiConfig'),
    RealtimeDataApi: Symbol('RealtimeDataApi'),
    TenantApi: Symbol('TenantApi'),
    HistoryApi: Symbol('HistoryApi'),
    DeviceApi: Symbol('DeviceApi'),
    FloorPlanApi: Symbol('FloorPlanApi'),
    DashboardApi: Symbol('DashboardApi'),
    IntegrationApi: Symbol('IntegrationApi'),
    TagApi: Symbol('TagApi'),
}

const REPOSITORIES = {
    LocalRepository: Symbol('LocalRepository'),
    AppCanvasRepo: Symbol('AppCanvasRepo'),
    SmCanvasRepo: Symbol('SmCanvasRepo'),
}

const SERVICE = {
    ApplicationService: Symbol('ApplicationService'),
    StreamService: Symbol('StreamService'),
    DetectorService: Symbol('DetectorService'),
    SiteService: Symbol('SiteService'),
    DeploymentService: Symbol('DeploymentService'),
    DeploymentUiConfigService: Symbol('DeploymentUiConfigService'),
    EntitlementService: Symbol('EntitlementService'),
    EventService: Symbol('EventService'),
    UserService: Symbol('UserService'),
    FooService: Symbol('FooService'),
    RealtimeDataService: Symbol('RealtimeDataService'),
    SocketService: Symbol('SocketService'),
    PasswordService: Symbol('PasswordService'),
    PasswordValidatorFactory: Symbol('PasswordService'),
    HistoryService: Symbol('HistoryService'),
    VideoStreamService: Symbol('VideoStreamService'),
    TenantService: Symbol('TenantService'),
    AppEventBus: Symbol('AppEventBus'),
    DeviceService: Symbol('DeviceService'),
    FloorPlanService: Symbol('FloorPlanService'),
    DashboardService: Symbol('DashboardService'),
    CloudIntegrationService: Symbol('CloudIntegrationService'),
    TagService: Symbol('TagService'),
}

const STORE = {
    ApplicationManager: Symbol('ApplicationManager'),
    ApplicationStore: Symbol('ApplicationStore'),
    ApplicationVersionStore: Symbol('ApplicationVersionStore'),
    StreamStore: Symbol('StreamStore'),
    DetectorStore: Symbol('DetectorStore'),
    SiteStore: Symbol('SiteStore'),
    DeploymentStore: Symbol('DeploymentStore'),
    LaunchConfigStore: Symbol('LaunchConfigStore'),
    DeploymentUiConfigStore: Symbol('DeploymentUiConfigStore'),
    EventStore: Symbol('EventStore'),
    UserStore: Symbol('UserStore'),
    RequestStore: Symbol('RequestStore'),
    FooStore: Symbol('FooStore'),
    RealtimeDataStore: Symbol('RealtimeDataStore'),
    PasswordStore: Symbol('PasswordStore'),
    ModelStore: Symbol('ModelStore'),
    SnapshotStore: Symbol('SnapshotStore'),
    TenantStore: Symbol('TenantStore'),
    SnapshotManager: Symbol('SnapshotManager'),
    LiveEventsManager: Symbol('LiveEventsManager'),
    MediaViewerSettings: Symbol('MediaViewerSettings'),
    FilterStore: Symbol('FilterStore'),
    ErrorStore: Symbol('ErrorStore'),
    DeviceStore: Symbol('DeviceStore'),
    FloorPlanStore: Symbol('FloorPlanStore'),
    DashboardStore: Symbol('DashboardStore'),
    CloudIntegrationStore: Symbol('CloudIntegrationStore'),
    VdbManager: Symbol('VdbManager'),
    DeploymentManager: Symbol('DeploymentManager'),
    TagStore: Symbol('TagStore'),
}

const TYPES = {
    ...CONFIG,
    ...API,
    ...REPOSITORIES,
    ...SERVICE,
    ...STORE,
}

export default TYPES
