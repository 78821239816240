import { ReactElement } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, ButtonProps } from '@mui/material'
import { sxs } from 'utils/mui'

// NOTE: we can't use LoadingButtonProps due to a bug, so we use ButtonProps and add what we need to expose
// https://github.com/mui/material-ui/issues/30038
export type KBLoadingButtonProps = ButtonProps & {
    loading?: boolean
    loadingPosition?: 'start' | 'end' | 'center'
}

type IconPlaceHolderProps = {
    show: boolean
}

function IconPlaceHolder({ show }: IconPlaceHolderProps) {
    return <Box sx={{ width: show ? '24px' : 0 }} />
}

export function KBLoadingButton({
    children,
    sx,
    loading = false,
    loadingPosition,
    disabled,
    variant = 'contained',
    ...props
}: KBLoadingButtonProps): ReactElement {
    // The button grows to fit the loading indicator depending on the loadingPosition
    const startIcon = loadingPosition === 'start' ? <IconPlaceHolder show={loading} /> : undefined
    const endIcon = loadingPosition === 'end' ? <IconPlaceHolder show={loading} /> : undefined

    return (
        <LoadingButton
            sx={sxs({ mt: 3 }, sx)}
            loadingPosition={loadingPosition}
            loading={loading}
            disabled={loading || disabled}
            variant={variant}
            startIcon={startIcon}
            endIcon={endIcon}
            {...props}
        >
            <Box
                sx={{
                    typography: 'inherit',
                    textTransform: 'none',
                }}
            >
                {children}
            </Box>
        </LoadingButton>
    )
}
