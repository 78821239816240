import { ReactElement } from 'react'
import {
    AccessTime,
    Backpack,
    BakeryDining,
    Bathroom,
    Bed,
    Book,
    BreakfastDining,
    Cake,
    Chair,
    Checkroom,
    Circle,
    ContentCut,
    Cookie,
    Countertops,
    DirectionsBike,
    DirectionsBoat,
    DirectionsBus,
    DirectionsCarOutlined,
    DownhillSkiing,
    Elderly,
    EmojiPeople,
    Engineering,
    EventSeat,
    Face,
    Fastfood,
    FireExtinguisher,
    Flatware,
    Flight,
    Groups,
    Hexagon,
    InvertColors,
    Keyboard,
    Kitchen,
    Laptop,
    LocalBar,
    LocalDrink,
    LocalFlorist,
    LocalParking,
    LocalPizza,
    LocalShipping,
    Luggage,
    Male,
    Microwave,
    Mouse,
    PersonOutlined,
    PetsOutlined,
    RamenDining,
    SettingsRemote,
    ShoppingBag,
    Skateboarding,
    Smartphone,
    Snowboarding,
    SportsBaseball,
    SportsTennis,
    Surfing,
    TableRestaurant,
    Toys,
    Traffic,
    Train,
    Tv,
    Twitter,
    TwoWheeler,
    Umbrella,
    VisibilityOutlined,
} from '@mui/icons-material'
import { SvgIcon } from '@mui/material'

import { SxProps } from '@mui/material/styles'
import Forklift from '../../assets/svg/icon/forklift.svg'
import Union from '../../assets/svg/icon/union.svg'

export type DetectorIconProps = {
    type: string
    color?: string
    sx?: SxProps
}

export function DetectorIcon({ type, color, sx }: DetectorIconProps): ReactElement {
    const iconForType = (t: string) => {
        switch (t) {
            case 'boat':
                return <DirectionsBoat />
            case 'car':
                return <DirectionsCarOutlined />
            case 'cat':
            case 'pet':
            case 'dog':
                return <PetsOutlined />
            case 'person':
                return <PersonOutlined />
            case 'bicycle':
            case 'bike':
                return <DirectionsBike />
            case 'age':
                return <Elderly />
            case 'gender':
                return <Male />
            case 'clothing':
                return <Checkroom />
            case 'pose':
                return <EmojiPeople />
            case 'color':
                return <InvertColors />
            case 'hair':
            case 'head':
            case 'head_enhancer':
                return <Face />
            case 'forklift':
                return <Forklift />
            case 'plate':
                return <Circle />
            case 'cleanliness':
                return <Cookie />
            case 'ppe':
            case 'ppe_enhancer':
                return <Engineering />
            case 'crowded':
                return <Groups />

            case 'motorcycle':
                return <TwoWheeler />

            case 'airplane':
                return <Flight />

            case 'bus':
                return <DirectionsBus />

            case 'train':
                return <Train />

            case 'truck':
                return <LocalShipping />

            case 'trafficlight':
                return <Traffic />

            case 'firehydrant':
                // TODO: need a fire hydrant
                // FireHydrantAlt not available.
                return <FireExtinguisher />

            case 'stopsign':
                return <Hexagon />

            case 'parkingmeter':
                // TODO: need a better icon.
                return <LocalParking />

            case 'bench':
                // TODO: need better bench.
                return <EventSeat />

            case 'bird':
                // TODO: need a bird
                return <Twitter />

            case 'backpack':
                return <Backpack />

            case 'umbrella':
                return <Umbrella />

            case 'handbag':
                // TODO: better handbag
                return <ShoppingBag />

            case 'suitcase':
                return <Luggage />

            case 'frisbee':
                return <Circle />
            case 'skis':
                return <DownhillSkiing />

            case 'snowboard':
                return <Snowboarding />

            case 'sportsball':
                return <SportsBaseball />

            case 'baseballbat':
            case 'baseballglove':
                return <SportsBaseball />

            case 'skateboard':
                return <Skateboarding />

            case 'surfboard':
                return <Surfing />

            case 'tennisracket':
                return <SportsTennis />

            case 'bottle':
            case 'wineglass':
                return <LocalBar />

            case 'cup':
                return <LocalDrink />
            case 'fork':
            case 'knife':
            case 'spoon':
                return <Flatware />

            case 'bowl':
                return <RamenDining />

            case 'sandwich':
            case 'hotdog':
                return <Fastfood />

            case 'pizza':
                return <LocalPizza />
            case 'donut':
                return <BakeryDining />
            case 'cake':
                return <Cake />
            case 'chair':
            case 'couch':
                return <Chair />
            case 'pottedplant':
                return <LocalFlorist />

            case 'bed':
                return <Bed />

            case 'diningtable':
                return <TableRestaurant />

            case 'toilet':
                // TODO: toilet icon
                return <Bathroom />
            case 'tv':
                return <Tv />

            case 'laptop':
                return <Laptop />

            case 'mouse':
                return <Mouse />

            case 'remote':
                return <SettingsRemote />

            case 'keyboard':
                return <Keyboard />

            case 'cellphone':
                return <Smartphone />

            case 'microwave':
                return <Microwave />

            case 'toaster':
                return <BreakfastDining />

            case 'sink':
                return <Countertops />

            case 'refrigerator':
                return <Kitchen />

            case 'book':
                return <Book />
            case 'clock':
                return <AccessTime />

            case 'scissors':
                return <ContentCut />
            case 'teddybear':
                return <Toys />
            case 'union':
            case 'static-items':
                return <Union />

            // TODO: consider specific icons
            case 'kite':
            case 'tie':
            case 'banana':
            case 'apple':
            case 'orange':
            case 'broccoli':
            case 'carrot':
            case 'vase':
            case 'oven':
            case 'hairdrier':
            case 'toothbrush':
            case 'horse':
            case 'sheep':
            case 'cow':
            case 'elephant':
            case 'bear':
            case 'zebra':
            case 'giraffe':
            default:
                return <VisibilityOutlined />
        }
    }

    return <SvgIcon sx={{ color, ...sx }}>{iconForType(type)}</SvgIcon>
}
