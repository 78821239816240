import { Bolt, MoreVert } from '@mui/icons-material'
import { Box, IconButton, Stack, SvgIcon, Typography } from '@mui/material'
import { ReactElement, ReactNode } from 'react'
import { KBTooltip } from '../tooltip'

export type KBLiveDataCardProps = {
    color?: string
    title?: ReactNode
    subtitle?: ReactNode
    secondaryTitle?: string
    secondarySubtitle?: string
    secondarySubtitleToolTip?: string
    showIcon?: boolean
    showSecondaryIcon?: boolean
    icon?: ReactElement
    secondaryIcon?: ReactElement
    selected: boolean
    onContextMenu?: (elem: HTMLElement) => void
}

export type LiveStatusIndicatorProps = {
    color: string
}

export function LiveStatusIndicator({ color }: LiveStatusIndicatorProps): ReactElement {
    const size = '15px'
    return <Box sx={{ backgroundColor: color, width: size, height: size, borderRadius: `calc(${size}/2)` }} />
}

export function KBLiveDataCard({
    selected = false,
    color = 'primary.main',
    title,
    subtitle,
    secondaryTitle,
    secondarySubtitle,
    secondarySubtitleToolTip,
    showIcon = false,
    showSecondaryIcon = false,
    icon = <LiveStatusIndicator color={selected ? 'white' : color || 'primary.main'} />,
    secondaryIcon = <Bolt />,
    onContextMenu,
}: KBLiveDataCardProps): ReactElement {
    const backgroundColor = selected ? color || 'primary.main' : 'white'
    const cardColor = selected ? 'white' : color || 'primary.main'
    const borderRadius = '4px'

    const selectedStyle = {
        boxShadow: 2,
    }

    const style = {
        borderRadius,
        width: '172px',
        backgroundColor: 'white',
        '&:hover': {
            boxShadow: 2,
        },
        cursor: 'default',
        ...(selected && selectedStyle),
    }

    const titleStyle = {
        fontSize: '16px',
        fontWeight: '500',
    }

    const subtitleStyle = {
        fontSize: '12px',
        fontWeight: '400',
    }

    return (
        <Stack sx={style}>
            {/* primary */}
            <Box
                sx={{
                    color: cardColor,
                    backgroundColor,
                    px: '10px',
                    pt: '10px',
                    pb: '4px',
                    borderRadius: `${borderRadius} ${borderRadius} 0px 0px`,
                }}
            >
                <Stack direction="row" justifyContent="space-between">
                    {/* title */}
                    <Typography sx={titleStyle} data-testid="live-data-card-title">
                        {title}
                    </Typography>

                    {/* indicators */}
                    <Stack direction="row" spacing="5px" alignItems="center">
                        {showSecondaryIcon && (
                            <SvgIcon sx={(theme) => ({ color: theme.palette.grey['600'], fontSize: '18px' })}>
                                {secondaryIcon}
                            </SvgIcon>
                        )}
                        {showIcon && icon}
                        {!!onContextMenu && (
                            <IconButton
                                onClick={(e) => onContextMenu?.(e.currentTarget)}
                                sx={{ p: 0.5, color: cardColor }}
                            >
                                <MoreVert />
                            </IconButton>
                        )}
                    </Stack>
                </Stack>
                {/* subtitle */}
                {subtitle && (
                    <Box sx={subtitleStyle} data-testid="live-data-card-sub-title">
                        {subtitle}
                    </Box>
                )}
            </Box>
            {/* secondary */}
            <Stack sx={{ mt: 1, px: '10px', pb: '10px' }}>
                <Typography sx={titleStyle} data-testid="live-data-card-secondary-title">
                    {secondaryTitle}
                </Typography>

                <KBTooltip
                    title={secondarySubtitleToolTip || ''}
                    data-testid="live-data-card-secondary-sub-title-tooltip"
                >
                    <Typography sx={subtitleStyle} noWrap data-testid="live-data-card-secondary-sub-title">
                        {secondarySubtitle}
                    </Typography>
                </KBTooltip>
            </Stack>
        </Stack>
    )
}
