import { EventEmitter2, Listener } from 'eventemitter2'
import { inject, injectable } from 'inversify'
import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import type { DeploymentARTSnapshot } from '@kibsi/ks-realtime-data-types'
import { AppFrame, ErrorPayload, FrameDataRequest, FrameDataResponse } from '@kibsi/vdb-types'
import { Range } from 'model/date'
import T from 'config/inversify.types'
import Logger from 'logging/logger'

import { Deployment } from '../deployment'
import { ArtItemStateEvent } from './snapshot'

const log = Logger.getLogger('RTD')

@injectable()
export class RealtimeDataStore {
    readonly channelId: string
    private channel: BroadcastChannel
    private deployment?: Deployment
    private playing = false
    private live = false
    private snapshots = new Set<string>()
    private videos = new Set<string>()
    private detections = new Set<string>()
    private listeners: Listener[] = []
    private appFrame?: AppFrame
    private bounds?: Range
    private requestId?: string

    constructor(@inject(T.AppEventBus) private appbus: EventEmitter2) {
        makeAutoObservable<RealtimeDataStore, 'appbus'>(this, {
            appbus: false,
        })

        reaction(() => this.dataRequest, this.sendDataRequest.bind(this))

        this.channelId = nanoid()
        this.channel = new BroadcastChannel(`deployment.${this.channelId}`)
        this.channel.addEventListener('message', this.onDebuggerMsg.bind(this))
    }

    get id(): string | undefined {
        return this.deployment?.deploymentId
    }

    get isLive(): boolean {
        return this.live
    }

    get isPlaying(): boolean {
        return this.live || this.playing
    }

    get frame(): AppFrame | undefined {
        return this.appFrame
    }

    get range(): Range | undefined {
        return this.bounds
    }

    start(deployment: Deployment): void {
        if (this.deployment !== deployment) {
            const id = deployment.deploymentId

            this.stop()

            log.info('[RTD] Starting', id)
            this.deployment = deployment

            this.add(
                this.appbus.on(`${id}/vdb/connect`, this.onConnect.bind(this), { objectify: true }),
                this.appbus.on(`${id}/vdb/snapshot`, this.onSnapshot.bind(this), { objectify: true }),
                this.appbus.on(`${id}/vdb/change`, this.onChange.bind(this), { objectify: true }),
                this.appbus.on(`${id}/vdb/vrt/frame`, this.mergeFrame.bind(this), { objectify: true }),
                this.appbus.on(`${id}/vdb/drt/frame`, this.mergeFrame.bind(this), { objectify: true }),
                this.appbus.on(`${id}/vdb/meta/bounds`, this.onBounds.bind(this), { objectify: true }),
            )

            this.live = true
        }
    }

    stop(): void {
        if (this.deployment !== undefined) {
            log.info('[RTD] Stopping', this.id)
            this.deployment = undefined
            this.listeners.forEach((l) => l.off())
            this.listeners = []
            this.snapshots.clear()
            this.videos.clear()
            this.detections.clear()
            this.playing = false
            this.live = false
            this.bounds = undefined
            this.appFrame = undefined
        }
    }

    startData(): () => void {
        log.info('[RTD] starting snapshots')
        return createClaim(this.snapshots)
    }

    startVideo(): () => void {
        log.info('[RTD] starting vrt')
        return createClaim(this.videos)
    }

    startDetections(): () => void {
        log.info('[RTD] starting drt')
        return createClaim(this.detections)
    }

    async pause(refFrameTime: number, opts?: Partial<FrameDataRequest>): Promise<void> {
        await this.sendFrameRequest('pause', refFrameTime, opts)
    }

    async previous(refFrameTime: number): Promise<void> {
        await this.sendFrameRequest('previous', refFrameTime)
    }

    async next(refFrameTime: number): Promise<void> {
        await this.sendFrameRequest('next', refFrameTime)
    }

    async play(refFrameTime: number, opts?: Partial<FrameDataRequest>): Promise<void> {
        this.live = false
        this.playing = true

        const replyTo = nanoid()

        this.requestId = replyTo

        this.add(
            this.appbus.on(
                `${this.id}/vdb/${replyTo}`,
                (frame: AppFrame | string) => {
                    if (this.requestId !== replyTo) {
                        log.warn('unknown playback frame', { expected: this.requestId, actual: replyTo })
                        return
                    }

                    if (this.playing) {
                        if (typeof frame === 'string') {
                            log.info('eof')
                            this.playing = false
                        } else {
                            this.setFrame(frame)
                        }
                    }
                },
                { objectify: true },
            ),
        )

        this.appbus.emit(`${this.id}/vdb`, 'frame:request', <FrameDataRequest>{
            vrt: { dimension: {} },
            drt: true,
            art: true,
            ...opts,
            command: 'play',
            replyTo,
            refFrameTime,
        })
    }

    async resume(): Promise<void> {
        await this.sendFrameRequest('pause', this.frame?.frameTime ?? Date.now(), {
            art: false,
            drt: false,
            vrt: { dimension: { width: 0, height: 0 } },
        })

        runInAction(() => {
            this.live = true
        })
    }

    private get dataRequest() {
        return {
            live: this.live,
            snapshot: this.snapshots.size > 0,
            vrt: this.videos.size > 0,
            drt: this.detections.size > 0,
        }
    }

    private sendDataRequest() {
        const { id, dataRequest } = this

        if (id) {
            this.appbus.emit(`${this.id}/vdb`, 'live:request', dataRequest)
        }
    }

    private sendFrameRequest(
        command: FrameDataRequest['command'],
        refFrameTime: number,
        req: Partial<FrameDataRequest> = {},
    ): Promise<AppFrame> {
        this.playing = false
        this.live = false

        const requestId = nanoid()

        this.requestId = requestId

        return new Promise((resolve) => {
            this.appbus.once(`${this.id}/vdb/${requestId}`, (event: FrameDataResponse<unknown>) => {
                resolve(this.onFrameResp(requestId, event))
            })

            this.appbus.emit(`${this.id}/vdb`, 'frame:request', <FrameDataRequest>{
                vrt: { dimension: {} },
                drt: true,
                art: true,
                replyTo: requestId,
                ...req,
                command,
                refFrameTime,
            })
        })
    }

    private onConnect() {
        this.sendDataRequest()
    }

    private onBounds(data: Range) {
        log.info('onBounds', data)
        this.bounds = data
    }

    private onSnapshot(snapshot: DeploymentARTSnapshot) {
        if (this.live) {
            this.appbus.emit('art/snapshot', snapshot)
        }
    }

    private onChange(change: ArtItemStateEvent) {
        if (this.live) {
            this.appbus.emit('art/itemstate', change)
        }
    }

    private async onFrameResp(requestId: string, event: FrameDataResponse<unknown>): Promise<AppFrame> {
        if (event.statusCode === 200) {
            const frame = event.payload as AppFrame

            if (this.requestId === requestId) {
                this.setFrame(frame)
            }

            return frame
        }

        const error = event.payload as ErrorPayload

        throw new Error(error.message)
    }

    private onDebuggerMsg(event: MessageEvent<string>): void {
        const { data } = event

        if (data === 'ping') {
            this.sendDebugMessage()
        }
    }

    private mergeFrame(frame: AppFrame): void {
        if (this.live) {
            this.appFrame = { ...this.appFrame, ...frame }

            if (this.bounds) {
                this.bounds.end = frame.frameTime
            } else {
                this.bounds = {
                    start: frame.frameTime,
                    end: frame.frameTime,
                }
            }

            this.sendDebugMessage()
        }
    }

    private setFrame(frame: AppFrame): void {
        log.debug('frame', frame)

        const { art, drt, vrt, ...headers } = frame

        this.appFrame = frame

        if (art) {
            this.appbus.emit('art/snapshot', { ...headers, ...art })
        }

        this.sendDebugMessage()
    }

    private add(...listener: (Listener | EventEmitter2)[]) {
        this.listeners.push(...(listener as Listener[]))
    }

    private sendDebugMessage() {
        if (this.appFrame) {
            this.channel.postMessage(JSON.stringify(this.appFrame))
        }
    }
}

function createClaim(claims: Set<string>): () => void {
    const claim = nanoid()

    claims.add(claim)

    return () => {
        claims.delete(claim)
    }
}
