import { ReactElement } from 'react'
import { Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

import { KBLoadingButton, KBLoadingButtonProps } from '../loading-button'

export interface KBBackButtonProps extends KBLoadingButtonProps {}

export function KBBackButton({ children, ...buttonProps }: KBBackButtonProps): ReactElement {
    return (
        <KBLoadingButton variant="text" sx={{ color: 'text.primary' }} startIcon={<ArrowBack />} {...buttonProps}>
            <Typography>{children}</Typography>
        </KBLoadingButton>
    )
}
