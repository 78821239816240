import type {
    ApplicationVersionDefinition,
    ApplicationVersionDetails,
    ApplicationVersionUpdate,
    Audit,
} from '@kibsi/ks-application-types'
import { makeAutoObservable } from 'mobx'
import { ApplicationService } from 'service'
import { FromDto } from 'store/interfaces'
import { ApplicationDef } from './application.def'

export interface AppVersionDto extends ApplicationVersionDetails {
    versionDefinition?: ApplicationVersionDefinition
}

export class AppVersion implements FromDto<AppVersionDto> {
    private appDef?: ApplicationDef

    constructor(private dto: AppVersionDto, private service: ApplicationService) {
        makeAutoObservable<AppVersion, 'service'>(this, {
            service: false,
        })

        this.setDefinition(dto.versionDefinition)
    }

    get appId(): string {
        return this.dto.appId
    }

    get versionId(): string {
        return this.dto.versionId
    }

    get versionNumber(): number {
        return this.dto.versionNumber
    }

    get versionDescription(): string | undefined {
        return this.dto.versionDescription
    }

    get versionDefinition(): ApplicationVersionDefinition | undefined {
        return this.dto.versionDefinition
    }

    get isDisabled(): boolean {
        return this.dto.isDisabled
    }

    get created(): Audit {
        return this.dto.created
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    get applicationDefinition(): ApplicationDef | undefined {
        return this.appDef
    }

    fromDto(dto: AppVersionDto): void {
        // Versions are mostly immutable.
        // Only some parts can be updated
        this.dto.isDisabled = dto.isDisabled
        this.dto.lastUpdated = dto.lastUpdated

        if (!this.versionDefinition) {
            this.setDefinition(dto.versionDefinition)
        }
    }

    async toggleDisabled(): Promise<void> {
        await this.setDisabled(!this.isDisabled)
    }

    async setDisabled(isDisabled: boolean): Promise<void> {
        const { versionId } = this

        const update: ApplicationVersionUpdate = {
            versionId,
            isDisabled,
        }

        const dto = await this.service.updateVersion(versionId, update)

        this.fromDto(dto)
    }

    async getDefinition(): Promise<ApplicationVersionDefinition> {
        if (this.versionDefinition !== undefined) {
            return this.versionDefinition
        }

        const { versionDefinition } = await this.service.getVersion(this.versionId)

        this.setDefinition(versionDefinition)

        return versionDefinition
    }

    private setDefinition(definition?: ApplicationVersionDefinition): void {
        if (definition && !this.appDef) {
            this.dto.versionDefinition = definition
            this.appDef = new ApplicationDef(this.versionId, definition.definition)
        }
    }
}
