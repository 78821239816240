import { StreamCreate, StreamUpdate, UploadUrl } from '@kibsi/ks-camera-types'
import { PaginationRequest, PaginationResponse, StreamKibsiClient } from '@kibsi/ks-client-sdk'
import { inject, injectable } from 'inversify'
import { StreamCriteriaWithUiOptions, Stream } from '@kibsi/ks-ui-types'
import TYPES from '../../config/inversify.types'
import { RequestStore } from '../../store/request.store'
import { StreamService } from './stream.service'
import type { UiApi } from '../ui'

export type StreamApi = Pick<
    InstanceType<typeof StreamKibsiClient>,
    | 'listStreamsForSite'
    | 'getStream'
    | 'createStream'
    | 'updateStream'
    | 'getSnapshot'
    | 'getLiveSnapshot'
    | 'saveSnapshot'
    | 'deleteStream'
    | 'getStreamFileUploadUrl'
>

@injectable()
export class StreamServiceImpl implements StreamService {
    constructor(
        @inject(TYPES.RequestStore) private req: RequestStore,
        @inject(TYPES.StreamApi) private api: StreamApi,
        @inject(TYPES.UiApi) private ui: UiApi,
    ) {}

    list(
        siteId: string,
        request?: PaginationRequest<StreamCriteriaWithUiOptions>,
    ): Promise<PaginationResponse<Stream>> {
        return this.req.once(`streams.list.${JSON.stringify(request)}`, () => this.ui.listStreams(siteId, request))
    }

    get(id: string): Promise<Stream> {
        return this.req.once(`streams.get.${id}`, () => this.api.getStream(id))
    }

    create(newStream: StreamCreate): Promise<Stream> {
        return this.api.createStream(newStream)
    }

    update(stream: StreamUpdate): Promise<Stream> {
        return this.api.updateStream(stream.streamId, stream)
    }

    delete(streamId: string, cascade?: boolean): Promise<void> {
        return this.api.deleteStream(streamId, {
            ...(cascade && {
                params: {
                    cascade,
                },
            }),
        })
    }

    getSnapshot(id: string): Promise<ArrayBuffer> {
        return this.req.once(`streams.get.${id}.snapshot`, () =>
            this.api.getSnapshot(id).catch((reason: any) => {
                if (reason.statusCode === 404) {
                    return new ArrayBuffer(0)
                }

                throw reason
            }),
        )
    }

    getLiveSnapshot(streamId: string): Promise<ArrayBuffer> {
        return this.api.getLiveSnapshot(streamId)
    }

    async saveSnapshot(streamId: string, data: ArrayBuffer, type: string): Promise<void> {
        const blob = new Blob([data], { type })

        await this.api.saveSnapshot(streamId, blob)
    }

    getStreamFileUploadUrl(streamId: string, contentType: string): Promise<UploadUrl> {
        return this.req.once(`streams.get.${streamId}.uploadUrl`, async () =>
            this.api.getStreamFileUploadUrl(streamId, contentType),
        )
    }
}
