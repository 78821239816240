import { Box, Stack, SvgIcon, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import { KBNavItemProps } from './KBNavItemProps'

export function KBNavItem({
    path,
    children,
    onClick,
    defaultTextColor = 'text.secondary',
    activeTextColor = 'primary.main',
    hoverColor = 'primary.light',
    activeColor,
}: KBNavItemProps): ReactElement {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{
                height: '100%',
                marginLeft: '5px',
                marginRight: '5px',
            }}
            onClick={onClick}
        >
            <NavLink to={path} style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: isActive ? activeTextColor : defaultTextColor,
                            padding: '5px 10px 5px 10px',
                            borderRadius: '3px',
                            backgroundColor: isActive ? activeColor : null,
                            '&:hover': {
                                color: activeTextColor,
                                backgroundColor: hoverColor,
                            },
                        }}
                    >
                        {children}
                    </Typography>
                )}
            </NavLink>
        </Box>
    )
}

export function KBNavItemFullHeight({
    path,
    onClick,
    children,
    defaultTextColor = 'primary.contrastText',
    activeTextColor = 'white',
    hoverColor = 'primary.dark',
    icon: Icon,
    variant = 'dense',
}: KBNavItemProps): ReactElement {
    // TODO: not sure how else to get the app bar height
    const appBarHeight = variant === 'dense' ? '48px' : '64px'

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{
                height: appBarHeight,
            }}
            onClick={onClick}
        >
            <NavLink to={path} style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                    <Typography
                        variant="subtitle2"
                        sx={{
                            lineHeight: appBarHeight,
                            color: isActive ? activeTextColor : defaultTextColor,
                            padding: '0 10px 0 10px',
                            borderRadius: '3px',
                            backgroundColor: isActive ? hoverColor : 'inherit',
                            '&:hover': {
                                color: activeTextColor,
                                backgroundColor: hoverColor,
                            },
                        }}
                    >
                        <Stack direction="row">
                            {children}
                            {/* Icon */}
                            <Box display="flex" flexDirection="column" justifyContent="center" sx={{ ml: '2px' }}>
                                {Icon && <SvgIcon component={Icon} />}
                            </Box>
                        </Stack>
                    </Typography>
                )}
            </NavLink>
        </Box>
    )
}
