import { KBAppBar } from '@kibsi/ui-components'
import { Close } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import KibsiLogoDarkIcon from '../../../assets/svg/kibsi-logo-dark.svg'

export type BuilderAppBarProps = {
    title?: string
    // eslint-disable-next-line react/no-unused-prop-types
    onItemClicked?: () => void
    onClose: () => void
    fontColor?: string
}

export function BuilderAppBar({
    title = '',
    onClose,
    fontColor = 'primary.contrastText',
}: BuilderAppBarProps): ReactElement {
    return (
        <KBAppBar
            color="primary.main"
            elevation={0}
            startElement={
                <Stack direction="row" alignItems="center">
                    <Link to="/applications" style={{ lineHeight: 0 }}>
                        <KibsiLogoDarkIcon />
                    </Link>
                    <Typography variant="h6" color={fontColor} sx={{ ml: '30px' }}>
                        {title}
                    </Typography>
                </Stack>
            }
        >
            {/* Close Button */}
            <Box display="flex" flexDirection="column" justifyContent="center">
                <IconButton onClick={onClose} sx={{ color: fontColor }}>
                    <Close />
                </IconButton>
            </Box>
        </KBAppBar>
    )
}
