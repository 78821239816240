import { SyntheticEvent } from 'react'
import { cloneEvent } from './cloneEvent'

export type FormEvent<T> = Event & {
    target: {
        name: string
        value: T
    }
}

export function cloneAsFormEvent<T>(event: SyntheticEvent, name: string, value: unknown): FormEvent<T> {
    const cloned = cloneEvent(event)

    Object.defineProperty(cloned, 'target', {
        writable: true,
        value: { name, value },
    })

    return cloned as unknown as FormEvent<T>
}
