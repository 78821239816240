import { QuickSightFilter, QuickSightOptions as QuickSightOptionsDto } from '@kibsi/ks-tenant-types'
import { makeAutoObservable, toJS } from 'mobx'
import { FromDto, ToDto } from '../interfaces'

export class QuickSightOptions implements FromDto<QuickSightOptionsDto>, ToDto<QuickSightOptionsDto> {
    constructor(private dto: QuickSightOptionsDto) {
        makeAutoObservable(this)
    }

    get quickSightId(): string {
        return this.dto.quickSightId
    }

    get appId(): string | undefined {
        return this.dto.appId
    }

    get deploymentIds(): string[] {
        return this.dto.deploymentIds || []
    }

    set deploymentIds(newDeploymentIds: string[]) {
        this.dto.deploymentIds = newDeploymentIds
    }

    get filters(): QuickSightFilter[] {
        return this.dto.filters || []
    }

    toDto(): QuickSightOptionsDto {
        return toJS(this.dto)
    }

    fromDto(dto: QuickSightOptionsDto): void {
        this.dto = dto
    }
}
