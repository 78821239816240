import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { IDisposer } from 'mobx-utils'

const APP_BAR_COLLAPSED_STORAGE_KEY = 'APP_BAR_COLLAPSED'
export const APP_BAR_COLLAPSED_WIDTH = 60
export const APP_BAR_EXPANDED_WIDTH = 120

export class AppBarSettings {
    private reactions: IDisposer[] = []
    private isCollapsed: boolean

    constructor() {
        this.isCollapsed = this.readCollapsed()
        makeAutoObservable(this)
        this.bindReactions()
    }

    toggle(): void {
        runInAction(() => {
            this.isCollapsed = !this.isCollapsed
        })
    }

    get collapsed(): boolean {
        return this.isCollapsed
    }

    private readCollapsed(): boolean {
        return window.localStorage.getItem(APP_BAR_COLLAPSED_STORAGE_KEY) === 'true' ?? false
    }

    private writeCollapsed(value: boolean) {
        window.localStorage.setItem(APP_BAR_COLLAPSED_STORAGE_KEY, String(value))
    }

    get width(): number {
        return this.collapsed ? APP_BAR_COLLAPSED_WIDTH : APP_BAR_EXPANDED_WIDTH
    }

    private bindReactions() {
        this.reactions.push(
            reaction(
                () => this.collapsed,
                (c) => {
                    this.writeCollapsed(c)
                },
            ),
        )
    }
}
