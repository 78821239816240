import { FormEvent, ReactElement } from 'react'
import { Box, BoxProps } from '@mui/material'

/**
 * This is a wrapper to prevent the page from POST (or what the user sees as a page refresh)
 */
export function KBForm({ onSubmit, children, autoComplete = 'off', ...rest }: BoxProps<'form'>): ReactElement {
    const newOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e?.preventDefault()
        onSubmit?.(e)
    }

    return (
        <Box component="form" onSubmit={newOnSubmit} autoComplete={autoComplete} {...rest}>
            {children}
        </Box>
    )
}
