import T from 'config/inversify.types'
import { inject, injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'
import type { DeploymentUiConfigDto } from 'model/deployment.ui-config'
import type { DeploymentUiConfigService } from 'service/deployment/deployment.ui-config.service'
import type { DomainStore } from 'store/domain'
import { FromDtoDomainStore } from 'store/domain'
import { DeploymentUIConfig } from './deployment.ui-config'

@injectable()
export class DeploymentUiConfigStore {
    private configs: DomainStore<DeploymentUIConfig, DeploymentUiConfigDto>

    constructor(@inject(T.DeploymentUiConfigService) private service: DeploymentUiConfigService) {
        this.configs = new FromDtoDomainStore<DeploymentUiConfigDto, DeploymentUIConfig>(
            (id, dto) => new DeploymentUIConfig(id, dto, this.service),
        )

        makeAutoObservable<DeploymentUiConfigStore, 'service'>(this, {
            service: false,
        })
    }

    load(id: string): DeploymentUIConfig {
        const config = this.service.get(id)
        return this.configs.set(id, config)
    }

    get(id: string): DeploymentUIConfig {
        const config = this.configs.get(id)
        if (!config) {
            return this.load(id)
        }

        return config
    }
}
