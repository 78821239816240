import { Redo, Undo } from '@mui/icons-material'
import { Fab, Stack, SxProps } from '@mui/material'
import { useTheme, Theme } from '@mui/material/styles'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'

export type KBUndoButtonProps = {
    sx?: SxProps<Theme>
    controller?: {
        canUndo: boolean
        canRedo: boolean
        undo(): void
        redo(): void
    }
}

function UndoButton({ sx, controller }: KBUndoButtonProps): ReactElement {
    const theme = useTheme()
    const color = theme.palette.grey[500]
    const backgroundColor = 'white'

    // the original disabled background color value is : rgba(0, 0, 0, 0.12)
    // need to override because the rgba uses an alpha value that causes an opacity value. The Opacity value causes the element to be transparent
    const disabledBackgroundColor = 'rgb(220,220,220)'

    return (
        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={sx}>
            <Fab
                size="small"
                sx={{
                    color,
                    backgroundColor,
                    '&.Mui-disabled': {
                        backgroundColor: disabledBackgroundColor,
                    },
                }}
                onClick={() => controller?.undo()}
                disabled={!controller?.canUndo}
            >
                <Undo />
            </Fab>
            <Fab
                size="small"
                sx={{
                    color,
                    backgroundColor,
                    '&.Mui-disabled': {
                        backgroundColor: disabledBackgroundColor,
                    },
                }}
                onClick={() => controller?.redo()}
                disabled={!controller?.canRedo}
            >
                <Redo />
            </Fab>
        </Stack>
    )
}

export const KBUndoButton = observer(UndoButton)
