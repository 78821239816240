/* eslint-disable no-bitwise */

export const generateColorFromText = (text: string): string => {
    let hash = 0
    for (let i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash)
    }

    const c = (hash & 0x00ffffff).toString(16).toUpperCase()
    return '00000'.substring(0, 6 - c.length) + c
}

export function buildLinearGradient(hue = 252): string {
    const color1 = `hsl(${hue}, 53%, 34%)`
    const color2 = `hsl(${hue + 7}, 69%, 47%)`
    const color3 = `hsl(${hue + 11}, 100%, 62%)`
    const color4 = `hsl(${hue + 9}, 89%, 59%)`
    const color5 = `hsl(${hue + 7}, 73%, 53%)`

    return `linear-gradient(
        ${color1}, 
        ${color1}, 
        ${color2}, 
        ${color3}, 
        ${color4}, 
        ${color5}, 
        ${color1}
    )`
}
