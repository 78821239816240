import { Stack, ToggleButton, ToggleButtonProps, Typography } from '@mui/material'
import { ReactElement } from 'react'

export type KBToggleButtonProps = {
    title?: string
    icon?: ReactElement
} & ToggleButtonProps

export function KBToggleButton({ title, icon, ...props }: KBToggleButtonProps): ReactElement {
    return (
        <ToggleButton sx={{ textTransform: 'none', padding: '4px 10px 4px 6px' }} {...props}>
            <Stack direction="row" spacing={1} alignItems="center">
                {icon}
                <Typography variant="subtitle2" noWrap>
                    {title}
                </Typography>
            </Stack>
        </ToggleButton>
    )
}
