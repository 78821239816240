import { makeAutoObservable } from 'mobx'
import { inject, injectable } from 'inversify'
import { ApplicationVersionCreate } from '@kibsi/ks-application-types'
import T from '../../config/inversify.types'
import type { ApplicationService } from '../../service'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from '../domain'
import { PaginatedList, PaginatedListImpl, createDomainPageAction } from '../pagination'
import { AppVersion, AppVersionDto } from './version'

export type AppVersionList = PaginatedList<AppVersion>

@injectable()
export class AppVersionsStore {
    private versions: AsyncDomainStore<AppVersion, AppVersionDto>

    constructor(@inject(T.ApplicationService) private service: ApplicationService) {
        this.versions = new AsyncFromDtoDomainStore(
            (id) => service.getVersion(id),
            (_, data) => new AppVersion(data, service),
        )

        makeAutoObservable<AppVersionsStore, 'service'>(this, {
            service: false,
        })
    }

    async createVersion(appId: string, version: ApplicationVersionCreate): Promise<AppVersion> {
        const dto = await this.service.createVersion(appId, version)

        return this.versions.set(dto.versionId, dto)
    }

    async listVersions(appId: string): Promise<AppVersionList> {
        const { versions, service } = this

        const action = createDomainPageAction(
            versions,
            service.listVersions.bind(service, appId),
            (dto) => dto.versionId,
        )

        const list = new PaginatedListImpl(action, { pageSize: 100, sort: ['versionNumber-'] })

        await list.more()

        return list
    }

    async getVersion(versionId: string): Promise<AppVersion> {
        const dto = await this.service.getVersion(versionId)

        return this.versions.set(versionId, dto)
    }
}
