import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { LocalizationProvider, TimePicker, TimePickerProps, renderTimeViewClock } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

export type KBTimePickerProps = Omit<TimePickerProps<DateTime>, 'value' | 'onChange'> & {
    value?: Date
    onChange?: (value: Date) => void
    pickerFieldWidth?: string
    dataTestIdPrefix?: string // `${dataTestIdPrefix}-time-input`
}

const timePickerFieldWidth = '120px'

export const KBTimePicker = observer(function KBTimePicker({
    value,
    onChange,
    pickerFieldWidth,
    dataTestIdPrefix,
    ...props
}: KBTimePickerProps): ReactElement {
    const luxonValue = DateTime.fromJSDate(value ?? new Date())

    const handleChange = (time: DateTime | null) => {
        if (time?.isValid) {
            onChange?.(time.toJSDate())
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} dateFormats={{ fullTime12h: 'hh:mm a' }}>
            <TimePicker
                value={luxonValue}
                onChange={handleChange}
                viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                }}
                closeOnSelect
                slotProps={{
                    textField: {
                        variant: 'standard',
                        sx: { width: pickerFieldWidth ?? timePickerFieldWidth },
                        inputProps: { 'data-testid': `${dataTestIdPrefix}-time-input` },
                    },
                    actionBar: {
                        sx: { padding: 1 },
                    },
                }}
                {...props}
            />
        </LocalizationProvider>
    )
})
