import type { AxiosRequestConfig } from 'axios'
import { injectable } from 'inversify'
import { nanoid } from 'nanoid'
import { KIBSI_REQUEST_ID_HEADER } from '@kibsi/ks-client-sdk'
import log from 'logging/logger'

export interface TokenProvider {
    (): Promise<string>
}

@injectable()
export class KibsiAuthConfigProvider {
    private tokenProvider?: TokenProvider

    async getConfig(): Promise<AxiosRequestConfig> {
        const token = await this.internalTokenProvider()

        return getAuthConfig(token)
    }

    /**
     * Hook to set the token provider from an external source, i.e. from react or an auth sdk
     */
    setTokenProvider(provider: TokenProvider): void {
        log.info(`setting token provider`)
        this.tokenProvider = provider
    }

    /**
     * This is the internal token provider from the wrapper.
     */
    private async internalTokenProvider(): Promise<string> {
        if (this.tokenProvider) {
            return this.tokenProvider()
        }

        log.warn('token provider does not exist')

        return ''
    }
}

function getAuthConfig(token?: string): AxiosRequestConfig {
    log.trace(`token value: ${token}`)

    return {
        timeout: 29000,
        headers: {
            Authorization: getAuthHeader(token),
            [KIBSI_REQUEST_ID_HEADER]: nanoid(),
        },
    }
}

function getAuthHeader(token?: string): string {
    if (token) {
        return `Bearer ${token}`
    }

    return ''
}
