import spacetime from 'spacetime'
import soft from 'timezone-soft'
import type { Timezone } from './types'

export const getSuffixLabel = (str: string): string => {
    const a = str?.split('/') ?? []

    if (a.length < 2) {
        return ''
    }

    return a.reduce((prev, val, index) => {
        if (index === 0) {
            return ''
        }
        if (index === 1) {
            return val.replace('_', ' ')
        }

        return `${val.replace('_', ' ')}, ${prev}`
    })
}

export const toTimezone = (str: string): Timezone => {
    const now = spacetime.now(str)
    const tz = now.timezone()

    const tzStrings = soft(str)

    const suffix = getSuffixLabel(str)

    const abbr = now.isDST() ? tzStrings[0].daylight?.abbr : tzStrings[0].standard?.abbr
    const altName = now.isDST() ? tzStrings[0].daylight?.name : tzStrings[0].standard?.name

    const min = tz.current.offset * 60

    // eslint-disable-next-line no-bitwise
    const strOffset = `${min < 0 ? '-' : '+'}${String((Math.abs(min) / 60) ^ 0).padStart(2, '0')}:${
        min % 60 === 0 ? '00' : Math.abs(min % 60)
    }`

    const short = `${altName ?? tzStrings[0].standard?.name} - ${suffix}`
    const label = `(GMT${strOffset}) ${short}`

    return {
        value: tz.name,
        label,
        short,
        offset: tz.current.offset,
        abbrev: abbr,
        altName,
    }
}
