import { lazy, ReactElement, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { t } from 'i18next'

import { App } from './App'
import { RequireAuth } from './auth/RequireAuth'
import { AppTitle } from './components/navigation/app-title'
import { LoginPage } from './pages/login'
import UserAccounts from './pages/profile/UserAccounts'
import { UserProfile } from './pages/profile/UserProfile'

const AuthRedirectOrgPage = lazy(() => import('./auth/AuthRedirectOrgPage'))
const AuthRedirectPage = lazy(() => import('./auth/AuthRedirectPage'))
const ClaimMarketplacePage = lazy(() => import('./marketplace/ClaimMarketplacePage'))

const ApplicationsPage = lazy(() => import('./pages/applications/ApplicationsPage'))
const ApplicationBuilder = lazy(() => import('./pages/applications/Builder'))

const SitesPage = lazy(() => import('./pages/sites/SitesPage'))
const SitePage = lazy(() => import('./pages/sites/SitePage'))
const SitePageRouting = lazy(() => import('./pages/sites/SitePageRouting'))
const SiteStreams = lazy(() => import('./pages/streams/Streams'))
const DeploymentsPage = lazy(() => import('./pages/deployments/DeploymentsPage'))
const DeploymentPage = lazy(() => import('./pages/deployments/DeploymentPage'))

const EventPage = lazy(() => import('./pages/events/EventPage'))
const Event = lazy(() => import('./pages/events/Event'))

const ModelsPage = lazy(() => import('./pages/detectors/ModelsPage'))
const ModelPage = lazy(() => import('./pages/detectors/ModelPage'))

const DevicesPage = lazy(() => import('./pages/devices/Devices'))
const FloorPlansPage = lazy(() => import('./pages/floor-plans/FloorPlans'))
const FloorPlanMappingEditor = lazy(() => import('./pages/floor-plans/FloorPlanMappingEditor'))

const DashboardsPage = lazy(() => import('./pages/dashboards/DashboardsPage'))
const DashboardPage = lazy(() => import('./pages/dashboards/DashboardPage'))

const AccountPage = lazy(() => import('./pages/profile/AccountPage'))
const CloudIntegrationPage = lazy(() => import('./pages/cloud-integrations/CloudIntegrations'))
const DebuggerPage = lazy(() => import('./pages/debugger/DebuggerPage'))

const PlaygroundRoutes = lazy(() => import('./pages/playground/PlaygroundRoutes'))

/**
 * Routing Component
 *
 * @returns {ReactElement} <KBRoutes> <KBRoutes/>
 */
export function KBRoutes(): ReactElement {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            {/* NOTE: because this title is declared closest to the root, it serves as a fallback */}
            <AppTitle title={t('kibsi')} />
            <Routes>
                <Route path="auth-redirect-org" element={<AuthRedirectOrgPage />} />
                <Route path="auth-redirect" element={<AuthRedirectPage />} />
                {/* Login Route has higher priority because login must be exempt from auth checking */}
                <Route path="login" element={<LoginPage />} />
                <Route
                    path="debugger"
                    element={
                        <RequireAuth>
                            <DebuggerPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <App />
                        </RequireAuth>
                    }
                >
                    {/* Navigation to the root domain will redirect you to the applications */}
                    <Route index element={<Navigate replace to="/applications" />} />
                    <Route path="models">
                        {/* model list */}
                        <Route index element={<ModelsPage />} />

                        {/* detector list */}
                        <Route path=":modelId/*" element={<ModelPage />} />
                    </Route>

                    <Route path="sites">
                        {/* Site List Page */}
                        <Route index element={<SitesPage />} />

                        {/* Site pages */}
                        <Route path=":siteId/*" element={<SitePage />}>
                            <Route index element={<SitePageRouting />} />
                            <Route path="streams" element={<SiteStreams />} />
                            <Route path="deployments">
                                <Route index element={<DeploymentsPage />} />
                                <Route path=":deploymentId/*" element={<DeploymentPage />} />
                            </Route>
                            <Route path="devices" element={<DevicesPage />} />
                            <Route path="floor-plans">
                                <Route index element={<FloorPlansPage />} />
                                <Route path=":floorPlanId/*" element={<FloorPlanMappingEditor />} />
                            </Route>
                            <Route path="dashboards">
                                <Route index element={<DashboardsPage />} />
                                <Route path=":dashboardId/*" element={<DashboardPage />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route path="account" element={<AccountPage />}>
                        <Route index element={<Navigate replace to="profile" />} />
                        <Route path="profile" element={<UserProfile />} />
                        <Route path="user-accounts" element={<UserAccounts />} />
                        <Route path="integrations" element={<CloudIntegrationPage />} />
                    </Route>

                    <Route path="applications" element={<ApplicationsPage />} />
                    <Route path="applications/:applicationId">
                        <Route index element={<Navigate replace to="builder" />} />
                        <Route path="builder" element={<ApplicationBuilder />} />
                    </Route>

                    <Route path="claim-marketplace/:marketplaceToken" element={<ClaimMarketplacePage />} />

                    {/* Exploratory UI here */}
                    <Route path="playground/*" element={<PlaygroundRoutes />} />
                </Route>

                <Route
                    path="deployments/:deploymentId/events"
                    element={
                        <RequireAuth>
                            <EventPage />
                        </RequireAuth>
                    }
                >
                    <Route path=":eventId" element={<Event />} />
                </Route>

                {/* All unresolved routes will be redirected to the /applications */}
                <Route path="*" element={<Navigate to="/applications" replace />} />
            </Routes>
        </Suspense>
    )
}
