import { MutableRefObject, ReactElement, useEffect, useState } from 'react'
import { Box, Slide, Stack, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

import { KBStepControls } from './KBStepController'

export type SlideItem<ID = string> = {
    id: ID
    element: ReactElement
}

export type SlidingStackProps = {
    initialIndex?: number
    items?: SlideItem[]
    length?: number
    controls?: MutableRefObject<KBStepControls | undefined>
    mountOnEnter?: boolean
    unmountOnExit?: boolean
    stackSx?: SxProps<Theme>
    slideSx?: SxProps<Theme>
}

export type SlideDirection = 'previous' | 'next'

export function KBSlideStack({
    initialIndex = 0,
    items = [],
    length = items.length,
    controls,
    mountOnEnter = true,
    unmountOnExit = true,
    stackSx,
    slideSx,
}: SlidingStackProps): ReactElement {
    const [currentIndex, setCurrentIndex] = useState(initialIndex ?? 0)
    const [slideDirection, setSlideDirection] = useState<SlideDirection>()

    useEffect(() => {
        if (!controls) {
            return
        }

        // By assigning actions to the controls, the stepper may now control KBSlideStack
        controls.current = {
            next: nextSlide,
            previous: previousSlide,
            goTo: goToSlide,
        }
    })

    const nextSlide = () => {
        let nextIndex = currentIndex + 1
        if (nextIndex > length - 1) {
            nextIndex = length - 1
        }
        setSlideDirection('next')
        setCurrentIndex(nextIndex)
    }

    const previousSlide = () => {
        let previousIndex = currentIndex - 1
        if (previousIndex < 0) {
            previousIndex = 0
        }
        setSlideDirection('previous')
        setCurrentIndex(previousIndex)
    }

    const goToSlide = (id: string) => {
        const index = items.findIndex((item) => item.id === id)
        if (index > -1) {
            // if the page exists

            if (index > currentIndex) {
                setSlideDirection('next')
                setCurrentIndex(index)
            } else if (index < currentIndex) {
                setSlideDirection('previous')
                setCurrentIndex(index)
            }
        }
    }

    const directionForIndex = (index: number): 'left' | 'right' | 'up' | 'down' => {
        if (slideDirection === 'previous') {
            return index <= currentIndex ? 'right' : 'left'
        }
        return index < currentIndex ? 'right' : 'left'
    }

    return (
        <Stack sx={{ position: 'relative', ...stackSx }}>
            {items.map(({ id, element }, index) => (
                <Slide
                    direction={directionForIndex(index)}
                    key={id}
                    in={currentIndex === index}
                    mountOnEnter={mountOnEnter}
                    unmountOnExit={unmountOnExit}
                    appear={slideDirection !== undefined}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            ...slideSx,
                        }}
                        data-testid={`KBSlideState-${id}`}
                    >
                        {element}
                    </Box>
                </Slide>
            ))}
        </Stack>
    )
}
