import { ReactElement } from 'react'
import { Stack, StackProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { asSxArray } from '@kibsi/ui-components'
import { useFileDragHandler } from 'hooks/file'

export interface FileWellProps extends Omit<StackProps, 'onChange'> {
    height?: string
    onChange?: (file?: File) => void
}

export function FileWell({ onChange, height = '170px', sx, ...props }: FileWellProps): ReactElement {
    const theme = useTheme()

    const { active, ...dragProps } = useFileDragHandler({ onChange })

    return (
        <Stack
            sx={[
                {
                    height,
                    border: active
                        ? `1px solid ${theme.palette.highlight.main}`
                        : `1px dashed ${theme.palette.separation.main};`,
                    borderRadius: '4px',
                },
                ...asSxArray(sx),
            ]}
            justifyContent="center"
            alignItems="center"
            {...dragProps}
            {...props}
        />
    )
}
