import { Integration, IntegrationCreate as CloudIntegrationCreate } from '@kibsi/ks-tenant-types'
import TYPES from 'config/inversify.types'
import { inject, injectable } from 'inversify'
import type { CloudIntegrationService } from 'service/cloud-integration'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from 'store/domain'
import { CloudIntegration } from './cloud-integration'

@injectable()
export class CloudIntegrationStore {
    private cloudIntegrations: AsyncDomainStore<CloudIntegration, Integration>

    constructor(@inject(TYPES.CloudIntegrationService) private service: CloudIntegrationService) {
        this.cloudIntegrations = new AsyncFromDtoDomainStore<Integration, CloudIntegration>(
            (id) => service.read(id),
            (_, data) => new CloudIntegration(data, service),
        )
    }

    async loadIntegrations(): Promise<CloudIntegration[]> {
        const dtos = await this.service.list()

        return this.cloudIntegrations.setAll(dtos.map((ci) => [ci.integrationId, ci]))
    }

    get list(): CloudIntegration[] {
        return this.cloudIntegrations.values()
    }

    async loadIntegration(id: string): Promise<CloudIntegration> {
        const dto = await this.service.read(id)

        return this.cloudIntegrations.set(dto.integrationId, dto)
    }

    getIntegration(id: string): CloudIntegration | undefined {
        return this.cloudIntegrations.get(id)
    }

    async create(entity: CloudIntegrationCreate): Promise<CloudIntegration> {
        const dto = await this.service.create(entity)

        return this.cloudIntegrations.set(dto.integrationId, dto)
    }

    async delete(entity: CloudIntegration, cascade?: boolean): Promise<void> {
        await this.service.delete(entity, cascade)
        this.cloudIntegrations.delete(entity.id)
    }
}
