import { inject, injectable } from 'inversify'
import { IntegrationKibsiClient } from '@kibsi/ks-client-sdk'
import { Integration as CloudIntegration, Integration } from '@kibsi/ks-tenant-types'
import TYPES from 'config/inversify.types'
import { RequestStore } from 'store/request.store'
import { CloudIntegrationService } from './cloud-integration.service'

export type IntegrationApi = Pick<
    InstanceType<typeof IntegrationKibsiClient>,
    'list' | 'read' | 'create' | 'update' | 'delete'
>

@injectable()
export class CloudIntegrationServiceImpl implements CloudIntegrationService {
    constructor(
        @inject(TYPES.RequestStore) private req: RequestStore,
        @inject(TYPES.IntegrationApi) private api: IntegrationApi,
    ) {}

    async list(): Promise<Integration[]> {
        return this.req.once(`integration.list`, async () => {
            const { results } = await this.api.list()
            return results
        })
    }

    async read(entityId: string): Promise<CloudIntegration> {
        return this.req.once(`integration.get.${entityId}`, async () => this.api.read(entityId))
    }

    async create(entity: CloudIntegration): Promise<CloudIntegration> {
        return this.api.create(entity)
    }

    async update(entity: CloudIntegration): Promise<void> {
        return this.api.update(entity)
    }

    async delete(entity: CloudIntegration, cascade?: boolean): Promise<void> {
        return this.api.delete(entity.integrationId, {
            ...(cascade && {
                params: {
                    cascade,
                },
            }),
        })
    }
}
