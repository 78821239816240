import { inject, injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'
import { LaunchApi } from '@kibsi/ks-deployment-types'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from '../domain'
import { LaunchConfig } from './launch-config'
import T from '../../config/inversify.types'
import type { DeploymentService } from '../../service'

@injectable()
export class LaunchConfigStore {
    private configs: AsyncDomainStore<LaunchConfig, LaunchApi>

    constructor(@inject(T.DeploymentService) service: DeploymentService) {
        this.configs = new AsyncFromDtoDomainStore<LaunchApi, LaunchConfig>(
            (id) => service.getLaunch(id),
            (_, data) => new LaunchConfig(data),
        )

        makeAutoObservable<LaunchConfigStore, 'service'>(this, {
            service: false,
        })
    }

    async getConfig(id: string): Promise<LaunchConfig> {
        if (this.configs.has(id)) {
            return this.configs.get(id) as LaunchConfig
        }

        return this.configs.load(id)
    }
}
