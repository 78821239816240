import type { AsyncDomainStore, DomainFactory, DomainLoader, Populator } from './store'
import { DomainStoreImpl } from './store.impl'

export class AsyncDomainStoreImpl<Dto, Domain>
    extends DomainStoreImpl<Dto, Domain>
    implements AsyncDomainStore<Domain, Dto>
{
    constructor(
        private loader: DomainLoader<Dto>,
        factory: DomainFactory<Dto, Domain>,
        populator: Populator<Domain, Dto>,
    ) {
        super(factory, populator)
    }

    async load(id: string): Promise<Domain> {
        const data = await this.loader(id)

        return this.set(id, data)
    }
}
