import { ReactElement } from 'react'
import {
    AutoFixHighOutlined,
    Cable,
    FontDownloadOutlined,
    Numbers,
    RotateRight,
    FormatListNumbered,
    ClassOutlined,
    SatelliteOutlined,
    TrackChangesOutlined,
} from '@mui/icons-material'
import { SvgIcon } from '@mui/material'
import { AttributeValueType } from 'store/app/attribute.type'
import { DetectedIcon, StaticIcon } from './Icon'

export type AttrIconProps = {
    type: AttributeValueType
    color?: string
}

export function AttrTypeIcon({ type, color }: AttrIconProps): ReactElement {
    const iconForType = (t: AttributeValueType) => {
        switch (t) {
            case 'region':
                return <StaticIcon />
            case 'detector':
                return <DetectedIcon />
            case 'enhancer':
                return <AutoFixHighOutlined />
            case 'expression':
                return <FontDownloadOutlined />
            case 'stateMachine':
                return <RotateRight />
            case 'relationship':
                return <Cable />
            case 'count':
                return <FormatListNumbered />
            case 'class':
                return <ClassOutlined />
            case 'location':
                return <SatelliteOutlined />
            case 'confidence':
                return <TrackChangesOutlined />
            case 'value':
            default:
                return <Numbers />
        }
    }

    return <SvgIcon sx={{ color }}>{iconForType(type)}</SvgIcon>
}
