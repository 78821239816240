import { t } from 'i18next'
import type { Action as ActionDto, EventLocation, EventState, MediaMetadata } from '@kibsi/ks-event-types'
import { EventAction } from './event.action'
import { dateAsShortTimeStrWithSeconds } from '../../util/date-time'

export type MediaType = 'video' | 'image'
export type CaptureTrigger = 'event' | 'action'

export class ItemEventMediaMetadata implements MediaMetadata {
    readonly id: string
    readonly eventActions: EventAction[]

    constructor(private readonly dto: MediaMetadata) {
        this.id = dto.id
        this.eventActions = dto.actions?.map((a) => new EventAction(a)) || []
    }

    get eventId(): string {
        return this.dto.eventId
    }

    get captureTime(): number {
        return this.dto.captureTime
    }

    get duration(): number | undefined {
        return this.dto.duration
    }

    get type(): MediaType {
        return this.dto.type
    }

    get captureTrigger(): CaptureTrigger[] {
        return this.dto.captureTrigger
    }

    get stateTrigger(): EventState | undefined {
        return this.dto.stateTrigger
    }

    get url(): string | undefined {
        return this.dto.url
    }

    get thumbnail(): string | undefined {
        return this.dto.thumbnail
    }

    get actions(): ActionDto[] | undefined {
        return this.dto.actions
    }

    get locations(): EventLocation[] {
        return this.dto.locations || []
    }

    getDisplayTime(): string {
        return dateAsShortTimeStrWithSeconds(new Date(this.captureTime))
    }

    getEventActions(): EventAction[] {
        return this.eventActions
    }

    getMediaTitle(): string {
        return t(`event.media.${this.stateTrigger}`)
    }
}
