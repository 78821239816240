import { Box, Drawer, DrawerProps, Toolbar } from '@mui/material'
import { PropsWithChildren, ReactElement, SyntheticEvent } from 'react'

export declare type KBDrawerVariant = 'permanent' | 'persistent' | 'temporary'

export type KBDrawerProps = {
    open?: boolean
    anchor?: 'left' | 'top' | 'right' | 'bottom'
    minWidth?: number | string
    onClose?: (reason?: string) => void
    onDrawerClosed?: () => void
    variant?: 'permanent' | 'persistent' | 'temporary'
    hideBackdrop?: boolean
    belowToolbar?: boolean
}

export function KBDrawer({
    anchor = 'right',
    open = false,
    minWidth = 50,
    variant = 'temporary',
    onClose,
    onDrawerClosed,
    hideBackdrop,
    children,
    belowToolbar = false,
}: PropsWithChildren<KBDrawerProps>): ReactElement {
    const drawerProps: DrawerProps = {
        open,
        anchor,
        variant: variant as KBDrawerVariant,
        hideBackdrop,
        SlideProps: {
            onTransitionEnd: () => {
                if (!open) {
                    onDrawerClosed?.()
                }
            },
        },
    }

    return (
        <Drawer
            data-testid="kb-drawer"
            {...drawerProps}
            onClose={(_: SyntheticEvent, reason: string) => {
                // NOTE: no longer using onBackdropClick, onClose will return reason instead
                onClose?.(reason)
            }}
            sx={{
                minWidth,
                [`& .MuiDrawer-paper`]: {
                    minWidth,
                    overflow: 'hidden',
                },
            }}
        >
            {belowToolbar && <Toolbar variant="dense" />}
            <Box display="flex" flexGrow={1} sx={{ overflowY: 'auto' }}>
                {children}
            </Box>
        </Drawer>
    )
}
