import { isValidElement, FunctionComponent, ReactElement, ReactNode } from 'react'
import { Box, Stack, SvgIconProps, SxProps } from '@mui/material'
import { KBValue } from '../value'
import { KBIndicator } from './KBIndicator'

/**
 * properties used to stylize the icon and info of the KBPanelDetails
 * used to highlight the icon and info text when we hover over the Media
 */
export type KBPanelDetailStyles = {
    titleSx?: SxProps
    infoSx?: SxProps
    iconSx?: SxProps
}

export type KBPanelDetailProps = {
    title?: ReactNode
    info?: ReactNode
    icon?: FunctionComponent<SvgIconProps>
    styles?: KBPanelDetailStyles
    'data-testid'?: string
    inset?: boolean // if there is no icon,  show the spacing before the text
    dense?: boolean
    sx?: SxProps
}

export function KBPanelDetail({
    title,
    icon: Icon,
    info = '',
    styles: detailStyles,
    'data-testid': dataTestId,
    inset = false,
    dense = false,
    sx,
}: KBPanelDetailProps): ReactElement {
    const { titleSx, infoSx, iconSx } = detailStyles || {}

    const styles: Record<string, SxProps> = {
        icon: {
            color: 'action.active',
            mr: 3,
            ...iconSx,
        },
        title: {
            overflow: 'hidden',
            typography: 'body2',
            whiteSpace: 'nowrap',
            paddingLeft: !Icon && inset ? '48px' : 0,
            ...titleSx,
        },
        info: {
            overflow: 'hidden',
            typography: 'body2',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: 'text.secondary',
            ...infoSx,
        },
    }

    const renderInfo = (): ReactNode => {
        if (info === undefined || info === null) {
            return ''
        }

        if (typeof info === 'boolean') {
            return (
                <KBIndicator
                    color={info ? 'success.main' : 'error.main'}
                    data-testid={`${dataTestId}-indicator-${info}`}
                />
            )
        }

        if (isValidElement(info)) {
            return info
        }

        if (typeof info === 'object') {
            return <KBValue value={info} />
        }

        return info
    }

    return (
        <Stack
            sx={{ mt: dense ? 0 : 1, mb: 1, ...sx }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            data-testid={dataTestId}
        >
            <Stack direction="row" alignItems="center" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {Icon && <Icon sx={styles.icon} />}
                <Box sx={styles.title} data-testid={`${dataTestId}-title`}>
                    {title}
                </Box>
            </Stack>
            <Box sx={styles.info} data-testid={`${dataTestId}-info`}>
                {renderInfo()}
            </Box>
        </Stack>
    )
}
