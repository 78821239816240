import { ContainerModule } from 'inversify'
import { PasswordStore } from 'store/password'
import Types from '../../config/inversify.types'
import { PasswordServiceImpl } from './password.service.impl'
import { PasswordValidatorFactory } from './password.validator.factory'

export const PasswordModule = new ContainerModule((bind) => {
    bind(Types.PasswordValidatorFactory).to(PasswordValidatorFactory).inSingletonScope()
    bind(Types.PasswordService).to(PasswordServiceImpl).inSingletonScope()
    bind(Types.PasswordStore).to(PasswordStore).inSingletonScope()
})
