import LRUCache from 'lru-cache'
import { Entitlement, Usage } from '@kibsi/ks-entitlement-types'
import TYPES from 'config/inversify.types'
import { inject, injectable } from 'inversify'
import { RequestStore } from 'store/request.store'
import type { EntitlementApi, EntitlementService } from './entitlement.service'

@injectable()
export class EntitlementServiceImpl implements EntitlementService {
    private entitlements: LRUCache<number, Entitlement[]>

    constructor(
        @inject(TYPES.RequestStore) private req: RequestStore,
        @inject(TYPES.EntitlementApi) private api: EntitlementApi,
    ) {
        this.entitlements = new LRUCache({
            ttl: 600_000,
            max: 1000,
            fetchMethod: () => this.api.getEntitlements(),
        })
    }

    listEntitlements(): Promise<Entitlement[]> {
        return this.req.once('entitlement.list', () => this.entitlements.fetch(0) as Promise<Entitlement[]>)
    }

    listUsages(): Promise<Usage[]> {
        return this.req.once('entitlement.usage.list', async () => this.api.listFeatureUsage())
    }

    getUsage(featureId: string): Promise<Usage> {
        return this.req.once(`entitlement.usage.get.${featureId}`, async () => this.api.getFeatureUsage(featureId))
    }

    async isEntitled(featureId: string): Promise<boolean> {
        const entitlements = await this.listEntitlements()

        const entitlement = entitlements.find((e) => e.featureId === featureId)

        // Note: this doesn't actually check against usage, but it could in the future.
        return isEntitled(entitlement)
    }
}

function isEntitled(entitlement?: Entitlement) {
    const { featureType, value } = entitlement ?? {}

    if (featureType === 'RANGE' && typeof value === 'number') {
        return value >= 0
    }

    if (featureType === 'SWITCH' && typeof value === 'boolean') {
        return value
    }

    return false
}
