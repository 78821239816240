import { ItemType } from '@kibsi/ks-application-types'
import { LaunchApi as Dto, StaticItem } from '@kibsi/ks-deployment-types'
import { FromDto } from '../interfaces'

export class LaunchConfig implements FromDto<Dto> {
    readonly launchId: string
    constructor(private dto: Dto) {
        this.launchId = dto.launchId
    }

    get staticItems(): StaticItem[] {
        return this.dto.deploymentDefinition.staticItems
    }

    get itemTypes(): ItemType[] {
        return this.dto.appVersionDefinition?.definition.itemTypes ?? []
    }

    fromDto(dto: Dto): void {
        this.dto = { ...dto, launchId: this.launchId }
    }
}
