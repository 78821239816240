import { runInAction } from 'mobx'
import { PaginationRequest } from '@kibsi/ks-client-sdk'
import { DomainStore } from '../domain'
import { PageAction } from './interfaces'

export type GetId<T> = (item: T) => string

export function createDomainPageAction<T, C, Dto>(
    store: DomainStore<T, Dto>,
    service: PageAction<Dto, C>,
    getId: GetId<Dto>,
): PageAction<T, C> {
    return async (req?: PaginationRequest<C>) => {
        const { results, nextContinuationToken } = await service(req)

        const items = runInAction(() => results.map((dto) => store.set(getId(dto), dto)))

        return {
            results: items,
            nextContinuationToken,
        }
    }
}
