import { SvgIcon, SvgIconProps } from '@mui/material'
import { ReactElement } from 'react'
import { VisibilityOutlined } from '@mui/icons-material'
import EventOccurrenceSvg from '../../assets/svg/icon/event-occurence.svg'
import EventSpanSvg from '../../assets/svg/icon/event-span.svg'
import ObjectSvg from '../../assets/svg/icon/object.svg'
import RocketSvg from '../../assets/svg/icon/rocket.svg'
import UnionSvg from '../../assets/svg/icon/union.svg'
import KibsiLogoDarkIcon from '../../assets/svg/kibsi-logo-dark.svg'
import OneForSpecific from '../../assets/svg/icon/one-for-specific.svg'
import ToggleOffSvg from '../../assets/svg/icon/toggle-off.svg'
import ToggleOnSvg from '../../assets/svg/icon/toggle-on.svg'
import CountCurrentSvg from '../../assets/svg/icon/count_current.svg'
import CountMaxSvg from '../../assets/svg/icon/count_max.svg'
import CountMinSvg from '../../assets/svg/icon/count_min.svg'
import FloorPlanSvg from '../../assets/svg/icon/floor-plan.svg'
import LayoutHorizontalSvg from '../../assets/svg/icon/layout-horizontal.svg'
import LayoutVerticalSvg from '../../assets/svg/icon/layout-vertical.svg'
import HeatmapSvg from '../../assets/svg/icon/heatmap.svg'
import ModelSvg from '../../assets/svg/icon/model.svg'
import { CustomSvg } from './CustomSvg'

export function StaticIcon(): ReactElement {
    return <SvgIcon component={ObjectSvg} />
}

export function DetectedIcon(): ReactElement {
    return <VisibilityOutlined />
}

export function EventOccurrenceIcon(): ReactElement {
    return <SvgIcon component={EventOccurrenceSvg} />
}

export function EventSpanIcon(): ReactElement {
    return <SvgIcon component={EventSpanSvg} />
}

export function LaunchIcon(): ReactElement {
    return <SvgIcon component={RocketSvg} />
}

export function UnionIcon(): ReactElement {
    return <SvgIcon component={UnionSvg} />
}

export function KibsiLogoIcon({ sx, ...props }: SvgIconProps): ReactElement {
    return <CustomSvg viewBox="0 0 28 28" svg={KibsiLogoDarkIcon} fontSize="24px" sx={sx} {...props} />
}

export function OneForSpecificIcon({ sx, ...props }: SvgIconProps): ReactElement {
    return <CustomSvg viewBox="0 0 24 25" svg={OneForSpecific} fontSize="24px" sx={sx} {...props} />
}

export function ToggleOffIcon({ sx, ...props }: SvgIconProps): ReactElement {
    return <CustomSvg viewBox="0 0 24 24" svg={ToggleOffSvg} fontSize="24px" sx={sx} {...props} />
}

export function ToggleOnIcon({ sx, ...props }: SvgIconProps): ReactElement {
    return <CustomSvg viewBox="0 0 24 24" svg={ToggleOnSvg} fontSize="24px" sx={sx} {...props} />
}

export function StaticUnionIcon({ sx, ...props }: SvgIconProps): ReactElement {
    return <CustomSvg viewBox="0 0 17 20" svg={UnionSvg} fontSize="24px" sx={sx} {...props} />
}

export function CountCurrentIcon({ sx, ...props }: SvgIconProps): ReactElement {
    return <CustomSvg viewBox="0 0 20 20" svg={CountCurrentSvg} fontSize="20px" sx={sx} {...props} />
}

export function CountMinIcon({ sx, ...props }: SvgIconProps): ReactElement {
    return <CustomSvg viewBox="0 0 20 20" svg={CountMinSvg} fontSize="20px" sx={sx} {...props} />
}

export function CountMaxIcon({ sx, ...props }: SvgIconProps): ReactElement {
    return <CustomSvg viewBox="0 0 20 20" svg={CountMaxSvg} fontSize="20px" sx={sx} {...props} />
}

export function FloorPlanIcon({ sx, ...props }: SvgIconProps): ReactElement {
    return <CustomSvg viewBox="0 0 16 16" svg={FloorPlanSvg} fontSize="16px" sx={sx} {...props} />
}

export function LayoutHorizontalIcon({ sx, ...props }: SvgIconProps): ReactElement {
    return <CustomSvg viewBox="0 0 20 20" svg={LayoutHorizontalSvg} fontSize="20px" sx={sx} {...props} />
}

export function LayoutVerticalIcon({ sx, ...props }: SvgIconProps): ReactElement {
    return <CustomSvg viewBox="0 0 20 20" svg={LayoutVerticalSvg} fontSize="20px" sx={sx} {...props} />
}

export function HeatmapIcon({ sx, ...props }: SvgIconProps): ReactElement {
    return <CustomSvg viewBox="0 0 20 20" svg={HeatmapSvg} fontSize="20px" sx={sx} {...props} />
}

export function ModelIcon({ sx, ...props }: SvgIconProps): ReactElement {
    return <CustomSvg viewBox="0 0 36 36" svg={ModelSvg} fontSize="36px" sx={sx} {...props} />
}
