import { useInjection } from 'inversify-react'
import T from 'config/inversify.types'
import { User, UserStore } from 'store/user'
import { Status, usePromiseState, usePromiseValue } from '../usePromiseState'

export function useUsers(): [Status, User[], () => void] {
    const store = useInjection<UserStore>(T.UserStore)
    const [status, setStatus] = usePromiseState(() => store.loadList())
    const list = usePromiseValue(status, [])

    const refresh = () => {
        setStatus(store.loadList())
    }

    return [status, list, refresh]
}
