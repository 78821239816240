import { Status, usePromiseState } from 'hooks/usePromiseState'
import { PasswordPolicy } from 'password-sheriff'
import { usePasswordStore } from './usePasswordStore'

export function usePasswordValidator(): [Status, PasswordPolicy | undefined, () => void] {
    const store = usePasswordStore()
    const [status, setStatus] = usePromiseState(() => store.getOrLoadValidator())

    const refresh = () => {
        setStatus(store.loadPasswordValidator())
    }

    return [status, store.passwordValidator, refresh]
}
