import type { AxiosError } from 'axios'
import { KibsiHttpError } from './http.error'

export function errorHandler(err: AxiosError): never {
    if (err.response) {
        throw new KibsiHttpError(err)
    }

    throw err
}
