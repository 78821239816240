import { Divider, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material'
import { ReactElement, ReactNode, useMemo } from 'react'
import { usePriorityTimezones } from './usePriorityTimezones'

export type KBTimezoneSelectProps = Omit<SelectProps<string>, 'onChange'> & {
    priority?: string | string[]
    onChange?: (newTimezone: string) => void
}

export function KBTimezoneSelect({ value, label, priority, onChange, ...rest }: KBTimezoneSelectProps): ReactElement {
    const handleChange = (event: SelectChangeEvent<string>) => {
        onChange?.(event.target.value)
    }

    const [priorityList, restList] = usePriorityTimezones(priority)

    const itemOptions = useMemo((): ReactNode => {
        const ret = []

        if (priorityList.length > 0) {
            ret.push(
                priorityList.map((zone) => (
                    <MenuItem key={zone.value} value={zone.value}>
                        {zone.label}
                    </MenuItem>
                )),
            )
            ret.push(<Divider key="divider" />)
        }

        ret.push(
            restList.map((zone) => (
                <MenuItem key={zone.value} value={zone.value}>
                    {zone.label}
                </MenuItem>
            )),
        )

        return ret
    }, [priorityList, restList])

    return (
        <Select {...rest} onChange={handleChange} value={value} label={label} data-testid="select-timezone">
            {itemOptions}
        </Select>
    )
}
