import logger from 'logging/logger'
import { comparer, makeAutoObservable, reaction, toJS } from 'mobx'
import type { IDisposer } from 'mobx-utils'
import type { DeploymentUiConfigDto } from 'model/deployment.ui-config'
import { DeploymentUiConfigService } from 'service/deployment/deployment.ui-config.service'
import type { FromDto, ToDto } from 'store/interfaces'

export class DeploymentUIConfig implements ToDto<DeploymentUiConfigDto>, FromDto<DeploymentUiConfigDto> {
    private reactions: IDisposer[] = []
    private skipUpdate = false

    constructor(
        readonly deploymentId: string,
        private dto: DeploymentUiConfigDto,
        private service: DeploymentUiConfigService,
    ) {
        makeAutoObservable<DeploymentUIConfig, 'service' | 'reactions'>(this, {
            service: false,
            reactions: false,
        })

        this.bindReactions()
    }

    getEventTypeColor(type: string): string {
        return this.dto.eventTypes?.[type]?.color || ''
    }

    isEventTypeHideIndividual(type: string): boolean {
        return this.dto.eventTypes?.[type]?.hideIndividual || false
    }

    updateEventTypeColor(type: string, value: string): void {
        if (!this.dto.eventTypes) {
            this.dto.eventTypes = {}
        }

        if (!this.dto.eventTypes[type] || typeof this.dto.eventTypes[type] !== 'object') {
            this.dto.eventTypes[type] = {}
        }

        this.dto.eventTypes[type].color = value
    }

    updateEventTypeHideIndividual(type: string, value: boolean): void {
        if (!this.dto.eventTypes) {
            this.dto.eventTypes = {}
        }

        if (!this.dto.eventTypes[type] || typeof this.dto.eventTypes[type] !== 'object') {
            this.dto.eventTypes[type] = {}
        }

        this.dto.eventTypes[type].hideIndividual = value
    }

    get hiddenEventTypes(): string[] {
        const configEventTypes = this.dto.eventTypes ?? {}
        return Object.entries(configEventTypes)
            .filter(([, et]) => et.hideIndividual)
            .map(([key]) => key)
    }

    fromDto(dto: DeploymentUiConfigDto): void {
        this.dto = { ...dto }
        this.skipUpdate = true
    }

    toDto(): DeploymentUiConfigDto {
        return toJS(this.dto)
    }

    /**
     * this is the data to check for an update reaction
     * reactions are determined when a property is accessed. So to test whether a reaction has happened, we need to observer the properties that have been accessed
     *
     * @returns
     */
    private reactionDataUpdate() {
        return {
            eventTypes: Object.entries(this.dto.eventTypes ?? {}).map(
                ([key, value]) => `${key}:${JSON.stringify(value)}`,
            ),
        }
    }

    private bindReactions(): void {
        this.reactions.push(
            // to check if the deployment needs to update via reactions, we have to access all the properties that are updatable
            reaction(
                () => this.reactionDataUpdate(),
                () => {
                    if (!this.skipUpdate) {
                        this.update()
                    }
                },
                {
                    // comparer to do a deep equal comparison whether to fire a reaction
                    equals: comparer.structural,
                },
            ),
            reaction(
                () => this.skipUpdate,
                (skip) => {
                    if (skip) {
                        this.resetSkip()
                    }
                },
            ),
        )
    }

    private update(): void {
        logger.debug('updating deployment ui config', this.deploymentId)
        this.service.save(this.deploymentId, this.toDto())
    }

    private resetSkip() {
        this.skipUpdate = false
    }
}
