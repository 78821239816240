import { KibsiEvent } from '@kibsi/ks-event-types'
import { inject, injectable } from 'inversify'
import { ItemType } from '@kibsi/ks-application-types'
import { ARTItemState } from '@kibsi/common'
import { UiItemState } from 'hooks/snapshot'
import T from '../../config/inversify.types'
import type { EventService } from '../../service'
import type { Deployment } from '../deployment'
import { Event } from './event'
import { ItemEventMediaMetadata } from './event.media.metadata'

@injectable()
export class EventStore {
    constructor(@inject(T.EventService) private service: EventService) {}

    create(deployment: Deployment, dto: KibsiEvent): Event {
        return new Event(dto, deployment, this.service)
    }

    createEventByARTItemState(deployment: Deployment, itemType: ItemType, item: ARTItemState): Event {
        return this.create(deployment, {
            ...item,
            deploymentId: deployment.deploymentId,
            itemType: itemType.id,
        } as KibsiEvent)
    }

    createEventByUiItemState(deployment: Deployment, itemType: ItemType, item: UiItemState): Event {
        return this.create(deployment, {
            ...item,
            id: item.identifier as string,
            deploymentId: deployment.deploymentId,
            itemType: itemType.id,
        } as unknown as KibsiEvent)
    }

    async getEvent(deployment: Deployment, eventId: string): Promise<Event> {
        const dto = await this.service.get(deployment.deploymentId, eventId)

        return new Event(dto, deployment, this.service)
    }

    async getMediaMetadata(deploymentId: string, eventId: string): Promise<ItemEventMediaMetadata[]> {
        const result = await this.service.getMediaMetadata(deploymentId, eventId)

        return result.map((a) => new ItemEventMediaMetadata(a))
    }
}
