import { Alert } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import { PropsWithChildren, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { KBLoading } from './KBLoading'

export type KBSuspenseProps = {
    state?: 'pending' | 'fulfilled' | 'rejected'

    /**
     * The size of the loading component. If using a number, the pixel unit is assumed. If using a string, you need to provide the CSS unit, e.g '3rem'.
     */
    size?: number | string
    sx?: SxProps<Theme>
    rejected?: ReactElement
    rejectedText?: string
    pendingElement?: ReactElement
}

export function KBSuspense({
    state,
    children,
    rejected,
    rejectedText,
    pendingElement,
    ...rest
}: PropsWithChildren<KBSuspenseProps>): ReactElement {
    const { t } = useTranslation()

    if (state === undefined || state === 'pending') {
        return pendingElement ?? <KBLoading loading sx={{ height: '50vh' }} {...rest} />
    }

    if (state === 'rejected') {
        if (rejected) {
            return rejected
        }

        if (rejectedText) {
            return <Alert severity="error">{rejectedText}</Alert>
        }

        return <Alert severity="error">{t('alert.unknown', 'Unknown Rejection')}</Alert>
    }

    return <> {children}</>
}
