import { MissingResult, PasswordPolicy } from 'password-sheriff'
import { useCallback, useMemo } from 'react'
import { FieldPathValue, FieldValues, Validate } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type ValidationRules = Record<string, Validate<FieldPathValue<FieldValues, string>>>

export function useValidatePassword(validator: PasswordPolicy | undefined): ValidationRules {
    const { t } = useTranslation(undefined, { keyPrefix: 'profile.password.rules' })

    const buildMessage = useCallback(
        (result: MissingResult): string => {
            const map = result.format ? { ...result.format } : {}
            const str = t(result.code, map)

            if (!result.items) {
                return str
            }
            return `${str} ${result.items
                .filter((item) => !item.verified)
                .map((item) => t(item.code))
                .join(', ')}`
        },
        [t],
    )

    const validationRules = useMemo(() => {
        if (!validator) {
            return {}
        }
        const explains = validator.explain()
        const rules = explains.reduce((prev, curr) => {
            prev[curr.code] = (password: string) => {
                const { verified, rules: misingRules } = validator.missing(password)
                if (verified || !misingRules) {
                    return true
                }

                // find correct rule
                const currRule = misingRules.find((rule) => rule.code === curr.code)
                if (!currRule) {
                    return true
                }

                return currRule.verified || buildMessage(currRule)
            }
            return prev
        }, {} as ValidationRules)

        return rules
    }, [buildMessage, validator])

    return validationRules
}
