import { FunctionComponent, ReactElement, SVGProps } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export type CustomSvgProps = {
    viewBox: string
    fontSize?: string
    svg: FunctionComponent<SVGProps<SVGSVGElement>>
} & Omit<SvgIconProps, 'fontSize'>
export function CustomSvg({ viewBox, fontSize, svg: SVG, sx, ...props }: CustomSvgProps): ReactElement {
    return (
        <SvgIcon viewBox={viewBox} sx={{ fontSize, ...sx }} {...props}>
            <SVG />
        </SvgIcon>
    )
}
