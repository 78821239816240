import { inject, injectable } from 'inversify'
import type { LocalRepository } from 'repositories/local'
import { CanvasRepo } from 'repositories/canvas.repo'
import { NodePosition, PanZoom } from 'model/canvas'
import T from 'config/inversify.types'
import { ItemType } from './item.type'

@injectable()
export class SmCanvasRepo extends CanvasRepo {
    constructor(@inject(T.LocalRepository) storage: LocalRepository) {
        super(storage)
    }

    getStatePosition(item: ItemType, attrId: string | undefined, state: string): NodePosition | undefined {
        return this.getPosition(getPositionKey(item, attrId, state))
    }

    setStatePosition(item: ItemType, attrId: string | undefined, state: string, position: NodePosition): void {
        this.setPosition(getPositionKey(item, attrId, state), position)
    }

    getPanZoom(item: ItemType, attrId: string): PanZoom | undefined {
        return this.getPanAndZoom(getPanZoomKey(item, attrId))
    }

    setPanZoom(item: ItemType, attrId: string, value: PanZoom): void {
        this.setPanAndZoom(getPanZoomKey(item, attrId), value)
    }
}

function getPositionKey(item: ItemType, attrId: string = '', state: string = ''): string {
    const { appId, id: itemId } = item

    return `STATE_NODE_POS:${appId}:${itemId}:${attrId}:${state}`
}

function getPanZoomKey(item: ItemType, attrId: string): string {
    const { appId, id: itemId } = item

    return `SM_CANVAS_PZ:${appId}:${itemId}:${attrId}`
}
