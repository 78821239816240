import { KBNavigation } from '@kibsi/ui-components'
import { useTranslation } from 'react-i18next'
import { useInjection } from 'inversify-react'
import { Environment } from '../../config/environment'
import TYPES from '../../config/inversify.types'

/**
 * These navigations are specific to the kibsi main navigations
 */
export const useMainNavigations = (): KBNavigation[] => {
    const environment = useInjection<Environment>(TYPES.Environment)
    const { t } = useTranslation()

    const navigations = [
        {
            title: t('navigation.applications'),
            path: 'applications',
        },
        {
            title: t('navigation.sites'),
            path: 'sites',
        },
        {
            title: t('navigation.detectors'),
            path: 'models',
        },
    ]

    if (environment.enablePlayground) {
        // if the environment variable ENABLE_PLAYGROUND is set to true, add the playground menu
        navigations.unshift({ title: 'Playground', path: 'playground' })
    }

    return navigations
}
