import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogHeader } from 'components/dialog'

export interface EditorHeaderProps {
    onClose?: () => void
}

export function EditorHeader({ onClose }: EditorHeaderProps): ReactElement {
    const { t } = useTranslation()

    return <DialogHeader title={t('profile.picture.title')} onClose={onClose} />
}
