import type { Audit, Site as SiteDto } from '@kibsi/ks-tenant-types'
import logger from 'logging/logger'
import { makeAutoObservable, reaction, toJS } from 'mobx'
import { IDisposer } from 'mobx-utils'
import { SiteService } from 'service/site'
import { FromDto, ToDto } from 'store/interfaces'

export type SiteEditable = {
    name: string
    description?: string
    timezone?: string
}

export class Site implements ToDto<SiteDto>, FromDto<SiteDto>, SiteDto {
    private reactions: IDisposer[] = []
    readonly siteId: string

    constructor(private dto: SiteDto, private service: SiteService) {
        this.siteId = dto.siteId

        makeAutoObservable<Site, 'service' | 'reactions'>(this, {
            siteId: false,
            service: false,
            reactions: false,
        })

        this.bindReactions()
    }

    get name(): string {
        return this.dto.siteName
    }

    set name(siteName: string) {
        this.dto.siteName = siteName
    }

    // NOTE: not sure if this will change or not but most dto has name and description, but not itemName and itemDescription
    get siteName(): string {
        return this.name
    }

    get description(): string | undefined {
        return this.dto.siteDescription
    }

    set description(siteDescription: string | undefined) {
        this.dto.siteDescription = siteDescription
    }

    get siteDescription(): string | undefined {
        return this.description
    }

    get siteTimezone(): string | undefined {
        return this.dto.siteTimezone
    }

    set siteTimezone(siteTimezone: string | undefined) {
        this.dto.siteTimezone = siteTimezone
    }

    get counts(): SiteDto['counts'] {
        return this.dto.counts
    }

    get created(): Audit {
        return this.dto.created
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    get editable(): SiteEditable {
        return {
            name: this.name,
            description: this.description,
            timezone: this.siteTimezone,
        }
    }

    toDto(): SiteDto {
        return toJS(this.dto)
    }

    fromDto(dto: SiteDto): void {
        this.dto = { ...dto, siteId: this.siteId }
    }

    private bindReactions(): void {
        this.reactions.push(
            // calls the service update when name or desc changes
            reaction(
                () => this.editable,
                (partial) => {
                    logger.info('editing site', partial)
                    this.service
                        .update({
                            siteId: this.siteId,
                            siteName: partial.name,
                            siteDescription: partial.description,
                            siteTimezone: partial.timezone,
                        })
                        .catch((e) => logger.warn(e))
                },
            ),
        )
    }
}
