/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { isValidElement, cloneElement, Children, MouseEvent, PropsWithChildren, ReactElement } from 'react'
import { isFragment } from 'react-is'
import { SelectChangeEvent, Stack, StackProps } from '@mui/material'

import { cloneAsFormEvent } from '../../../utils/react'

interface KBButtonSelectPropsBase<T = unknown> extends Omit<StackProps, 'onChange'> {
    name: string
    value?: T
    onChange?: (e: SelectChangeEvent<T>) => void
}

export type KBButtonSelectProps<T> = PropsWithChildren<KBButtonSelectPropsBase<T>>

export function KBButtonSelect<T>({ name, value, children, onChange, ...rest }: KBButtonSelectProps<T>): ReactElement {
    const getValue = (child: ReactElement): T | undefined =>
        (child.props['data-value'] ?? child.props.value) as T | undefined

    const onClickItem = (child: ReactElement) => (event: MouseEvent) => {
        const selected = getValue(child)

        if (child.props.onClick) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            child.props.onClick(event)
        }

        if (value !== selected) {
            if (onChange) {
                onChange(cloneAsFormEvent<T>(event, name, selected))
            }
        }
    }

    const items = Children.toArray(children).map((child) => {
        if (!isValidElement(child) || isFragment(child)) {
            return null
        }

        return cloneElement(child, {
            onClick: onClickItem(child),
            selected: getValue(child) === value,
        })
    })

    return (
        <Stack direction="row" flexWrap="wrap" spacing={2} {...rest}>
            {items}
        </Stack>
    )
}
