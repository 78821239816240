import { ReactElement, useState, SyntheticEvent, MouseEvent } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import { KBTooltip, WithDataProps } from '@kibsi/ui-components'
import type { KibsiError } from '../../store/error/error'
import { dateAsMonthDayYearsTime, relativeDateTime } from '../../util/date-time'
import { ErrorCode } from './ErrorCode'
import { ErrorDetails } from './ErrorDetails'

export type ErrorItemProps = {
    error: KibsiError
}

export function ErrorItem({ error, ...data }: WithDataProps<ErrorItemProps>): ReactElement {
    const [expanded, setExpanded] = useState(false)

    const onChange = (e: SyntheticEvent) => {
        e.stopPropagation()
        setExpanded(!expanded)
        error.read()
    }

    const onErrorCodeClicked = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        error.read()
    }

    return (
        <Accordion
            disableGutters
            expanded={expanded}
            onChange={onChange}
            sx={{
                boxShadow: 0,
                '&::before': {
                    height: 0,
                },
            }}
            {...data}
        >
            <AccordionSummary
                sx={{
                    '& .MuiAccordionSummary-content': {
                        margin: 0,
                    },
                    pl: 1.25,
                }}
                expandIcon={<ExpandMoreOutlined />}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: 1, mr: 1 }}>
                    <Stack direction="row" alignItems="center" spacing={1} sx={{ overflow: 'hidden' }}>
                        <ErrorCode error={error} onClick={onErrorCodeClicked} />
                        <KBTooltip placement="top" arrow title={error.summary || ''}>
                            <Typography
                                noWrap
                                data-testid="error-item-summary"
                                sx={{ textOverflow: 'ellipsis', overflow: 'hidden', width: 255 }}
                            >
                                {error.summary}
                            </Typography>
                        </KBTooltip>
                    </Stack>

                    <KBTooltip placement="left" arrow title={dateAsMonthDayYearsTime(error.date)}>
                        <Typography
                            variant="body2"
                            color="action.active"
                            sx={{ textAlign: 'right' }}
                            data-testid="error-item-date"
                        >
                            {relativeDateTime(error.date)}
                        </Typography>
                    </KBTooltip>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 0 }}>
                <ErrorDetails error={error} />
            </AccordionDetails>
        </Accordion>
    )
}
