import { FunctionComponent, ReactElement } from 'react'
import { Box, ButtonBase, Stack, SvgIconProps, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { NavLink } from 'react-router-dom'

export type MenuItemProps = {
    path: string
    title?: string
    icon?: FunctionComponent<SvgIconProps>
    collapsed?: boolean
    height?: number
    color?: string
}

export function MenuItem({
    path,
    title,
    icon: Icon,
    height = 120,
    collapsed = false,
    color,
}: MenuItemProps): ReactElement {
    const selIndicatorWidth = 3
    const activeTextColor = '#FFF'
    const activeColor = '#FFF'

    const activeStyle = (active: boolean) => ({
        backgroundColor: active ? alpha(activeColor, 0.2) : null,
        '&:hover': {
            backgroundColor: active ? alpha(activeColor, 0.3) : alpha(activeColor, 0.1),
        },
    })

    return (
        <Box sx={{ position: 'relative', height }}>
            <NavLink to={path} style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                    <ButtonBase sx={{ width: 1, position: 'relative' }}>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={[{ height, transition: 'background 250ms, color 250ms' }, activeStyle(isActive)]}
                            flex={1}
                        >
                            {Icon && <Icon sx={{ color: activeTextColor }} />}
                            {!collapsed && (
                                <Typography
                                    sx={{
                                        color: 'white',
                                        textAlign: 'center',
                                        fontWeight: 500,
                                        fontSize: '13px',
                                    }}
                                >
                                    {title}
                                </Typography>
                            )}
                        </Stack>
                        {/* indicator */}
                        {isActive && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    backgroundColor: color ?? 'primary.main',
                                    height,
                                    width: selIndicatorWidth,
                                    right: -selIndicatorWidth,
                                }}
                            />
                        )}
                    </ButtonBase>
                )}
            </NavLink>
        </Box>
    )
}
