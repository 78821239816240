import { AttributeTypeDef, ItemType } from '@kibsi/ks-application-types'
import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import { QueryFilter } from '@kibsi/ks-history-types'
import { Deployment } from '../deployment'
import { AttributeValueType } from '../app/attribute.type'

export type Operator = '=' | '!=' | '>' | '>=' | '<' | '<=' | '~' | 'isTrue' | 'isFalse'
export type FilterValue = boolean | number | string | string[] | undefined

export interface FilterData {
    internalId: string
    itemTypeId?: string
    attributeId?: string
    operator?: Operator
    value?: FilterValue
    enabled: boolean
    isNew?: boolean
}

export type FilterUpdate = Omit<FilterData, 'internalId' | 'enabled'>

export class Filter {
    constructor(private data: FilterData, public deployment: Deployment) {
        makeAutoObservable(this)
    }

    static create(deployment: Deployment): Filter {
        // default to first itemType in list
        const itemType = deployment.getOrderedItemTypes()[0]

        return new Filter(
            {
                internalId: nanoid(),
                itemTypeId: itemType?.id,
                attributeId: undefined,
                operator: undefined,
                value: undefined,
                enabled: true,
                isNew: true,
            },
            deployment,
        )
    }

    get id(): string {
        return this.data.internalId
    }

    get itemTypeId(): string | undefined {
        return this.data.itemTypeId
    }

    get attributeId(): string | undefined {
        return this.data.attributeId
    }

    get itemType(): ItemType | undefined {
        return this.itemTypeId ? this.deployment.getItemTypeById(this.itemTypeId) : undefined
    }

    get attributes(): AttributeTypeDef[] {
        return this.itemType?.attributes || []
    }

    get attribute(): AttributeTypeDef | undefined {
        return this.attributeId ? this.itemType?.attributes?.find((attr) => attr.id === this.attributeId) : undefined
    }

    get valueType(): AttributeValueType | undefined {
        return this.attribute?.value.valueType
    }

    get operator(): Operator | undefined {
        return this.data.operator
    }

    get value(): FilterValue {
        return this.data.value
    }

    set enabled(a: boolean) {
        this.data.enabled = a
    }

    get enabled(): boolean {
        return this.data.enabled
    }

    get isNew(): boolean | undefined {
        return this.data.isNew
    }

    update(update: FilterUpdate): void {
        this.data = {
            ...this.data,
            ...update,
        }
    }

    get displayName(): string {
        let value = this.value !== undefined ? String(this.value) : undefined
        if (this.operator === 'isTrue' || this.operator === 'isFalse') {
            value = undefined
        }

        if (Array.isArray(this.value)) {
            value = this.value.join(' | ')
        }

        return [this.itemType?.name, this.attribute?.name ?? this.attributeId, this.operator, value]
            .filter((v) => v)
            .join(' ')
    }

    toDto(): FilterData {
        return this.data
    }

    toQuery(): QueryFilter | undefined {
        if (!this.itemTypeId || !this.attributeId || !this.operator) {
            return undefined
        }

        let op = this.operator
        let v: FilterValue = this.value !== undefined ? this.value : ''

        if (op === 'isFalse') {
            op = '='
            v = false
        }

        if (op === 'isTrue') {
            op = '='
            v = true
        }

        return {
            itemType: this.itemTypeId,
            condition: {
                type: 'field',
                field: this.attributeId,
                operator: op,
                value: v,
                cast: this.valueType === 'expression',
            },
        }
    }
}
