import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx'
import { fromPromise, IDisposer, IPromiseBasedObservable } from 'mobx-utils'
import { UploadUrl } from '@kibsi/ks-application-types'
import type { Audit, StreamMetadata, StreamResolution, StreamUpdate } from '@kibsi/ks-camera-types'
import { Stream as StreamDto } from '@kibsi/ks-ui-types'
import { StreamService } from 'service/stream'
import { BaseImage } from 'model/baseImage'
import { Dimension } from 'util/dimension'
import logger from 'logging/logger'
import { ResourceType } from '@kibsi/ks-search-tags-types'
import { FromDto, ToDto } from '../interfaces'
import { ImageDataBuffer } from '../data-buffer'
import { TaggableResource } from '../tag/tag.store'
import { Tag } from '../../model/tag'
import { tagsFromDto } from '../tag/util'

export type StreamOrientation = 'landscape' | 'portrait'
export type StreamSourceType = 'Stream' | 'Upload'
export type StreamConnectivityType = 'DIRECT_TO_CLOUD' | 'EDGE'

export class Stream implements ToDto<StreamDto>, FromDto<StreamDto>, BaseImage, TaggableResource {
    private reactions: IDisposer[] = []
    private skipUpdate = false
    readonly streamId: string
    readonly tags = new Map<string, Tag>()
    readonly resourceType: ResourceType = 'stream'

    snapshot?: ImageDataBuffer
    initialized: IPromiseBasedObservable<void> = fromPromise.resolve()

    constructor(private dto: StreamDto, private service: StreamService) {
        this.streamId = dto.streamId
        tagsFromDto(this.tags, dto)

        makeAutoObservable<Stream, 'service' | 'reactions'>(this, {
            streamId: false,
            service: false,
            reactions: false,
        })

        this.bindReactions()
    }

    get id(): string {
        return this.streamId
    }

    get name(): string {
        return this.streamName
    }

    set name(value: string) {
        this.dto.streamName = value
    }

    get streamName(): string {
        return this.dto.streamName
    }

    get description(): string | undefined {
        return this.streamDescription
    }

    set description(value: string | undefined) {
        this.dto.streamDescription = value
    }

    get streamDescription(): string | undefined {
        return this.dto.streamDescription
    }

    get streamType(): StreamSourceType {
        return this.dto.streamType
    }

    get connectivity(): StreamConnectivityType {
        return this.dto.connectivity
    }

    get url(): string | undefined {
        return this.dto.url
    }

    get isUploadComplete(): boolean {
        return this.dto.isUploadComplete
    }

    get created(): Audit {
        return this.dto.created
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    get siteId(): string {
        return this.dto.siteId
    }

    get frameConsumptionRate(): number | undefined {
        return this.dto.frameRate?.consumptionRate
    }

    get streamMetadata(): StreamMetadata | undefined {
        return this.dto.streamMetadata
    }

    get resolution(): StreamResolution | undefined {
        return this.dto.streamMetadata?.resolution
    }

    get orientation(): StreamOrientation {
        if (!this.resolution) {
            return 'landscape'
        }
        return this.resolution.height < this.resolution.width ? 'landscape' : 'portrait'
    }

    get image(): ImageDataBuffer | undefined {
        return this.snapshot
    }

    getImage(): Promise<ImageDataBuffer | undefined> {
        return this.getSnapshot()
    }

    get dimensions(): Dimension | undefined {
        return this.resolution
    }

    async refresh(): Promise<void> {
        const result = await this.service.get(this.streamId)
        runInAction(() => {
            this.dto = result
            this.skipUpdate = true
        })
    }

    toDto(): StreamDto {
        return toJS(this.dto)
    }

    fromDto(dto: StreamDto): void {
        this.dto = { ...dto, streamId: this.streamId }
        this.skipUpdate = true
    }

    async update(stream: StreamUpdate): Promise<void> {
        const dto = await this.service.update({ ...stream, streamId: this.streamId })

        this.fromDto(dto)
    }

    async getSnapshot(): Promise<ImageDataBuffer | undefined> {
        if (!this.snapshot) {
            const buffer = await this.service.getSnapshot(this.streamId)
            this.setSnapshotData(buffer)
        }
        return this.snapshot
    }

    async setSnapshot(data: ArrayBuffer): Promise<void> {
        await this.service.saveSnapshot(this.streamId, data, 'image/jpeg')

        this.setSnapshotData(data)
    }

    async getLiveSnapshot(): Promise<ArrayBuffer> {
        return this.service.getLiveSnapshot(this.streamId)
    }

    getStreamFileUploadUrl(contentType: string): Promise<UploadUrl> {
        return this.service.getStreamFileUploadUrl(this.streamId, contentType)
    }

    initializeSnapshot(): void {
        // This is a background action
        this.initialized = fromPromise(
            this.setInitialSnapshot().catch((e) => {
                logger.warn('Unable to capture and save the initial stream snapshot', e)
            }),
        )
    }

    private editable() {
        return {
            streamName: this.name,
            streamDescription: this.description,
        }
    }

    private async updatePartial(): Promise<void> {
        logger.debug('updating stream', this.streamId)
        const dto = await this.service.update({ ...this.toDto(), streamId: this.streamId })
        this.fromDto(dto)
    }

    private setSnapshotData(data: ArrayBuffer) {
        if (this.snapshot) {
            this.snapshot.buffer = data
        } else {
            this.snapshot = new ImageDataBuffer(data)
        }
    }

    private async setInitialSnapshot(): Promise<void> {
        await this.waitForUploadComplete()

        const buffer = await this.getLiveSnapshot()

        const snapshot = new ImageDataBuffer(buffer)

        await this.service.saveSnapshot(this.streamId, buffer, 'image/jpeg')

        runInAction(() => {
            this.snapshot = snapshot
        })
    }

    /**
     * There is a lag between the ui completing the video upload and the s3 notification.
     * This will refresh its status until the server returns a completed upload status.
     */
    private async waitForUploadComplete(): Promise<void> {
        const startedTs = Date.now()

        while (this.streamType === 'Upload' && !this.isUploadComplete) {
            if (Date.now() - startedTs > 10_000) {
                // Don't wait around forever
                throw new Error('Upload failure')
            }

            // eslint-disable-next-line no-await-in-loop
            await new Promise((resolve) => {
                setTimeout(resolve, 1000)
            })

            // eslint-disable-next-line no-await-in-loop
            await this.refresh()
        }
    }

    private bindReactions(): void {
        this.reactions.push(
            reaction(
                () => this.editable(),
                () => {
                    if (!this.skipUpdate) {
                        this.updatePartial().catch(() => {})
                    }
                },
            ),
            reaction(
                () => this.skipUpdate,
                (skip) => {
                    if (skip) {
                        this.skipUpdate = false
                    }
                },
            ),
        )
    }
}
