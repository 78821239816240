import { ItemType } from '@kibsi/ks-application-types'
import { RegionAttr, StaticItem } from '@kibsi/ks-deployment-types'
import { StaticRegions } from 'store/deployment/static.regions'
import { NamedPolygon, Point } from './draw'

type Flatten<Type> = Type extends Array<infer Item> ? Item : Type

export type AttrValue = Flatten<StaticItem['attributes']>

export function getDisplayAttributeLabel(si: StaticItem, item?: ItemType, defaultLabel = ''): string {
    const displayAttr = si.attributes.find((attr) => attr.id === item?.displayAttributeId)

    if (displayAttr && displayAttr.valueType === 'value') {
        return `${displayAttr.value}`
    }

    return defaultLabel
}

export function getRegionsForStream(statics: StaticItem[], items: ItemType[], streamId: string): NamedPolygon[] {
    return statics.flatMap((si) => getRegions(si, getItem(items, si.itemTypeId), streamId))
}

export function getRegions(si: StaticItem, item?: ItemType, streamId?: string): NamedPolygon[] {
    const name = getDisplayAttributeLabel(si, item)

    return si.attributes
        .filter(isRegionAttr)
        .flatMap((attr) => attr.value)
        .filter((ra) => ra.streamId === streamId)
        .map((ra) => ({
            id: ra.region.id,
            name,
            type: item?.name,
            polygon: ra.region.path as Point[],
        }))
}

export function getStaticRegions(staticItem: StaticItem, itemType?: ItemType, streamId?: string): StaticRegions {
    const name = getDisplayAttributeLabel(staticItem, itemType)

    const regions = staticItem.attributes.filter(isRegionAttr).map((ra) => {
        const regionName = itemType?.attributes?.find((attr) => attr.id === ra.id)?.name ?? ''

        const polygons = ra.value
            .filter((r) => r.streamId === streamId)
            .map((r) => r.region)
            .map((r, index) => ({
                id: r.id,
                name: `${regionName} ${index}`,
                polygon: r.path as Point[],
            }))

        return {
            id: ra.id,
            name: regionName,
            polygons,
        }
    })

    return new StaticRegions(staticItem.id, name, regions, itemType)
}

export function isRegionAttr(attr: AttrValue): attr is RegionAttr {
    return attr.valueType === 'region'
}

function getItem(items: ItemType[], id: string): ItemType | undefined {
    return items.find((i) => i.id === id)
}
