import { TenantKibsiClient } from '@kibsi/ks-client-sdk'
import { Tenant } from '@kibsi/ks-tenant-types'
import { inject, injectable } from 'inversify'
import TYPES from '../../config/inversify.types'
import { RequestStore } from '../../store/request.store'
import { TenantService } from './tenant.service'

export type TenantApi = Pick<InstanceType<typeof TenantKibsiClient>, 'read' | 'claimMarketplace'>

@injectable()
export class TenantServiceImpl implements TenantService {
    constructor(
        @inject(TYPES.RequestStore) private req: RequestStore,
        @inject(TYPES.TenantApi) private api: TenantApi,
    ) {}

    getSelf(): Promise<Tenant> {
        return this.req.once(`tenant.get.self`, () => this.api.read())
    }

    async claimMarketplaceAccount(marketplaceToken: string): Promise<void> {
        await this.api.claimMarketplace(marketplaceToken)
    }
}
