import { makeAutoObservable } from 'mobx'

export class ImageDataBuffer {
    constructor(private data: ArrayBuffer, private mimeType = 'image/jpeg') {
        makeAutoObservable(this)
    }

    get buffer(): ArrayBuffer {
        return this.data
    }

    set buffer(value: ArrayBuffer) {
        this.data = value
    }

    get base64(): string {
        return Buffer.from(this.data).toString('base64')
    }

    get dataUrl(): string {
        return `data:${this.mimeType};base64,${this.base64}`
    }
}
