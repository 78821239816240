import type { DeploymentCreate, DeploymentCriteria } from '@kibsi/ks-deployment-types'
import { Site } from '@kibsi/ks-tenant-types'
import { PaginationRequest } from '@kibsi/ks-client-sdk'
import { DeploymentCriteriaWithUiOptions } from '@kibsi/ks-ui-types'
import { inject, injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'
import type { DeploymentService } from 'service/deployment'
import { PaginatedList, PaginatedListImpl, createDomainPageAction } from 'store/pagination'
import T from '../../config/inversify.types'
import { toUiDeployment } from '../../model/deployment'
import type { StreamService } from '../../service/stream'
import { parseErrorMessage } from '../../util/error'
import { AppVersion } from '../app'
import { AsyncDomainStore, AsyncDomainStoreImpl } from '../domain'
import { AnyDto, Deployment, destructure, populate, setRefs } from './deployment'

export type DeploymentList = PaginatedList<Deployment>

@injectable()
export class DeploymentStore {
    private deployments: AsyncDomainStore<Deployment, AnyDto>

    constructor(
        @inject(T.DeploymentService) private service: DeploymentService,
        @inject(T.StreamService) private streamService: StreamService,
    ) {
        this.deployments = new AsyncDomainStoreImpl<AnyDto, Deployment>(
            (id) => service.get(id),
            (_, data) => this.initDeployment(data),
            populate,
        )

        makeAutoObservable<DeploymentStore, 'service'>(this, {
            service: false,
        })
    }

    async loadListFromCriteria(
        criteria: DeploymentCriteriaWithUiOptions,
        req?: PaginationRequest<DeploymentCriteria>,
    ): Promise<DeploymentList> {
        const { deployments, service } = this
        const action = createDomainPageAction(deployments, service.list.bind(service), (dto) => dto.deploymentId)

        const list = new PaginatedListImpl(action, { pageSize: 100, ...req, criteria })

        await list.more()

        return list
    }

    async loadListFromSite(siteId: string): Promise<DeploymentList> {
        return this.loadListFromCriteria({ siteId })
    }

    async loadListByApp(
        appId: string,
        upgradeToVersionId?: string,
        includeDefinition?: boolean,
    ): Promise<Deployment[]> {
        const { items } = await this.loadListFromCriteria(
            { appId, upgradeToVersionId, includeDefinition },
            { sort: ['deploymentName'] },
        )
        return items
    }

    async loadListByStreamId(streamId: string, includeDefinition?: boolean): Promise<Deployment[]> {
        const { items } = await this.loadListFromCriteria({ streamId, includeDefinition }, { sort: ['deploymentName'] })
        return items
    }

    async loadListByIds(deploymentIds: string[]): Promise<Deployment[]> {
        if (deploymentIds.length === 0) {
            return []
        }

        const { items } = await this.loadListFromCriteria({ ids: deploymentIds }, { pageSize: deploymentIds.length })
        return items
    }

    loadDeployment(id: string): Promise<Deployment> {
        return this.deployments.load(id)
    }

    getDeployment(id: string): Promise<Deployment | undefined> {
        return this.service.get(id).then((d) => this.initDeployment(d))
    }

    async deleteDeployment(deploymentId: string, cascade?: boolean): Promise<void> {
        await this.service.delete(deploymentId, cascade)
        this.deployments.delete(deploymentId)
    }

    async createDeployment(deployment: DeploymentCreate): Promise<Deployment> {
        const dto = await this.service.create(deployment)

        return this.deployments.set(dto.deploymentId, toUiDeployment(dto))
    }

    async copyDeployment(deployment: Deployment, deploymentName: string): Promise<Deployment> {
        if (!deployment.isEditable()) {
            await this.loadDeployment(deployment.deploymentId)
        }

        const deploymentDescription = deployment.description ?? ''
        const { siteId, appId, versionId } = deployment

        const copy = await this.createDeployment({ deploymentName, deploymentDescription, siteId, appId, versionId })
        await this.service.update({ ...deployment.editable, deploymentName, deploymentId: copy.deploymentId })

        return copy
    }

    async createDeploymentByAppVersion(appVersion: AppVersion, site: Site): Promise<Deployment> {
        const { siteId, siteName } = site
        const appName = appVersion.versionDefinition?.name

        const createDeploymentData: DeploymentCreate = {
            appId: appVersion.appId,
            siteId,
            versionId: appVersion.versionId,
            deploymentName: `${siteName} ${appName}`,
            deploymentDescription: `Newly created Deployment for Site ${siteName} Application ${appName}`,
        }
        return this.createDeployment(createDeploymentData)
    }

    async launchDeployment(deployment: Deployment): Promise<Deployment> {
        const { deploymentId } = deployment
        try {
            await this.service.launch(deploymentId)
        } catch (err) {
            throw new Error(parseErrorMessage(err))
        }

        return this.loadDeployment(deployment.deploymentId)
    }

    async stopDeployment({ deploymentId }: Deployment): Promise<Deployment> {
        await this.service.stop(deploymentId)
        return this.loadDeployment(deploymentId)
    }

    async restartDeployment({ deploymentId }: Deployment): Promise<Deployment> {
        await this.service.restart(deploymentId)
        return this.loadDeployment(deploymentId)
    }

    async resumeSchedule({ deploymentId }: Deployment): Promise<Deployment> {
        await this.service.resumeSchedule(deploymentId)
        return this.loadDeployment(deploymentId)
    }

    async isLaunchEnabled(deployment: Deployment): Promise<boolean> {
        const { results: streams } = await this.streamService.list(deployment.siteId, { pageSize: 100 })
        const streamIds = streams.map((s) => s.streamId)

        if (!deployment.appId) return false
        if (!deployment.app) return false
        if (!deployment.versionId) return false
        if (deployment.streamIds?.length < 1) return false
        if (!deployment.streamIds.every((sid) => streamIds.includes(sid))) return false
        return true
    }

    private initDeployment(data: AnyDto) {
        const [dto, app, version, site, canAutoUpdate] = destructure(data)
        const deployment = new Deployment(dto, this.service)

        setRefs(deployment, app, version, site, canAutoUpdate)

        return deployment
    }
}
