import Button from '@mui/material/Button'
import { ReactElement } from 'react'

export type GreetingButtonProps = {
    /**
     * Use this label to provide a cool name for a warm welcome
     */
    label?: string
}

/**
 * Primary UI component for a nice Greeting
 */
export function GreetingButton({ label = 'world', ...props }: GreetingButtonProps): ReactElement {
    return (
        <Button variant="contained" {...props}>
            Hello {label}
        </Button>
    )
}
