import { Avatar, Stack, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { formatBytes } from 'util/file-size'
import { FileInfo } from './types'

export type FileInfoTagProps = {
    fileInfo?: FileInfo
    initials?: string
}

const getFileInitials = (initials: string | undefined, fileInfo: FileInfo | undefined): string => {
    if (initials) {
        return initials.toUpperCase()
    }

    const fileType = fileInfo?.type
    if (!fileType) {
        return 'UNK'
    }

    const a = fileType.split('/')
    if (a.length > 1) {
        // gets initial from mime type
        return a[a.length - 1].toUpperCase()
    }

    return 'UNK'
}

export function FileInfoTag({ fileInfo, initials }: FileInfoTagProps): ReactElement {
    return (
        <Stack direction="row" spacing={1} justifyContent="center" sx={{ mt: 2 }}>
            <Avatar sx={{ width: '52px', height: '52px' }}>{getFileInitials(initials, fileInfo)}</Avatar>
            <Stack>
                <Typography variant="body1">{fileInfo?.name}</Typography>
                <Typography sx={{ color: 'text.secondary', fontSize: '14px' }}>
                    {formatBytes(fileInfo?.size || 0, 0)}
                </Typography>
            </Stack>
        </Stack>
    )
}
