import { DeleteOutlineOutlined } from '@mui/icons-material'
import { Grid, IconButton, Stack, Typography } from '@mui/material'
import { DeleteDialog } from 'components/dialog'
import { useInviteUser } from 'hooks/user'
import { useDeleteUser } from 'hooks/user/useDeleteUser'
import logger from 'logging/logger'
import { observer } from 'mobx-react-lite'
import { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { User } from 'store/user'
import { dateAsMonthDayYears, dateAsMonthDayYearsTime } from 'util/date-time'

type UserAccountProps = {
    user: User
}

export function getStatus(user: User): string {
    if (user.blocked) {
        return 'profile.userAccounts.disabled'
    }

    if (user.pending) {
        return hasActiveInvitation(user) ? 'profile.userAccounts.pending' : 'profile.userAccounts.invitationExpired'
    }

    return 'profile.userAccounts.enabled'
}

function hasActiveInvitation(user: User): boolean {
    if (!user.invitationCreatedAt || !user.invitationExpiresAt) {
        return false
    }

    const now = Date.now()
    const expires = new Date(user.invitationExpiresAt).getTime()

    return now < expires
}

function UserAccount({ user }: UserAccountProps): ReactElement {
    const { t } = useTranslation()

    const [, inviteUser] = useInviteUser()
    const [inviteSent, setInviteSent] = useState(false)
    const [openDelete, setOpenDelete] = useState<boolean>(false)

    const resendInvite = useCallback(async () => {
        if (inviteSent) {
            return
        }
        logger.debug(`Resending invite for user ${user.userId}`)
        await inviteUser(user.userEmail)
        setInviteSent(true)
    }, [user, inviteSent, inviteUser])

    const deleteUser = useDeleteUser(user)

    return (
        <Grid
            container
            alignContent="center"
            alignItems="flex-start"
            sx={{
                m: 0,
                '& .MuiGrid-item': {
                    p: 2,
                    '&:last-child': {
                        py: 1,
                    },
                },
                boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12);',
            }}
            data-testid={`user-account-${user.userId}`}
        >
            <Grid item xs={4} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Typography noWrap variant="body2">
                    {user.userName}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body2">{dateAsMonthDayYears(user.createdAsDate)}</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body2">{dateAsMonthDayYearsTime(user.lastLoginTimeAsDate)}</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body2" display="inline">
                    {t(getStatus(user))}
                </Typography>
                {!user.blocked && user.pending && (
                    <Typography
                        variant="body2"
                        color="text.primary"
                        onClick={resendInvite}
                        display="inline"
                        marginLeft="4px"
                        sx={[
                            {
                                '&:hover': {
                                    color: 'primary.main',
                                },
                                ...{ cursor: 'pointer' },
                            },
                        ]}
                    >
                        {!inviteSent
                            ? t('profile.userAccounts.resendInvite')
                            : t('profile.userAccounts.invitationResent')}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={1}>
                <Stack direction="row" justifyContent="flex-end">
                    <IconButton
                        size="small"
                        sx={{ color: 'action.active' }}
                        onClick={() => {
                            setOpenDelete(true)
                        }}
                    >
                        <DeleteOutlineOutlined />
                    </IconButton>
                </Stack>
            </Grid>
            {/* delete dialog */}
            <DeleteDialog
                name={user.userName}
                msg={t('delete.message.generic', {
                    item: t('item.this', { value: t('user') }),
                })}
                open={openDelete}
                onCancel={() => setOpenDelete(false)}
                onDeleted={() => {
                    setOpenDelete(false)
                }}
                deleteFn={deleteUser}
            />
        </Grid>
    )
}

export default observer(UserAccount)
