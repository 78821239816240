import axios from 'axios'
import logger from 'logging/logger'

// 'response-content-disposition' overrides the s3.getSignedUrl ResponseContentDisposition, changing it from inline to attachment.
// IMPORTANT! ResponseContentDisposition must be part of the s3.getSignedUrl param
export const requestFile = async (url: string): Promise<string> => {
    const resp = await axios.get(url, {
        params: { 'response-content-disposition': `attachment` },
        responseType: 'blob',
    })

    return URL.createObjectURL(resp.data)
}

export function downloadFromObjectUrl(objectUrl: string, fileName: string): void {
    const a = document.createElement('a')
    a.href = objectUrl
    a.download = fileName
    a.click()

    URL.revokeObjectURL(objectUrl)
}

export async function downloadFile(url: string, fileName?: string): Promise<void> {
    try {
        const { pathname } = new URL(url)
        const urlFileName = pathname.substring(pathname.lastIndexOf('/') + 1)

        const objectUrl = await requestFile(url)
        downloadFromObjectUrl(objectUrl, fileName || urlFileName)
    } catch (err) {
        logger.error(err)
        throw err
    }
}
