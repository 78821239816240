import logger from 'logging/logger'
import { useCallback, useState } from 'react'

export type PendoUserInfo = {
    tenantId: string
    userId: string
    name: string
}

export const usePendo = (): ((info: PendoUserInfo) => void) => {
    logger.info(`usePendo hook`)

    const [initComplete, setInitComplete] = useState<boolean>(false)

    // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
    // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
    // Call this function in your authentication promise handler or callback when your visitor and account id values are available
    // Please use Strings, Numbers, or Bools for value types.

    const initPendo = useCallback(
        (info: PendoUserInfo) => {
            if (initComplete) {
                return
            }

            // pendo is global constant.
            pendo.initialize({
                visitor: {
                    id: info.userId, // Required if user is logged in, default creates anonymous ID
                    // email:        // Recommended if using Pendo Feedback, or NPS Email
                    full_name: info.name,
                    // role:         // Optional

                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                },

                account: {
                    id: info.tenantId, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
                    // name:         // Optional
                    // is_paying:    // Recommended if using Pendo Feedback
                    // monthly_value:// Recommended if using Pendo Feedback
                    // planLevel:    // Optional
                    // planPrice:    // Optional
                    // creationDate: // Optional

                    // You can add any additional account level key-values here,
                    // as long as it's not one of the above reserved names.
                },
            })

            setInitComplete(true)
        },
        [initComplete, setInitComplete],
    )

    return initPendo
}
