import type {
    ApplicationCreate,
    ApplicationUpdate,
    ApplicationVersion,
    ApplicationVersionCreate,
    ApplicationVersionUpdate,
    ApplicationVersionCriteria,
    ApplicationVersionDetails,
    AppModelDefinition,
} from '@kibsi/ks-application-types'
import type { ApplicationKibsiClient, PaginationRequest, PaginationResponse } from '@kibsi/ks-client-sdk'
import { inject, injectable } from 'inversify'
import type { Application, ApplicationCriteriaWithUIOptions } from '@kibsi/ks-ui-types'
import TYPES from '../../config/inversify.types'
import type { RequestStore } from '../../store/request.store'
import type { ApplicationService } from './application.service'
import type { UiApi } from '../ui'

export type ApplicationApi = Pick<
    InstanceType<typeof ApplicationKibsiClient>,
    | 'create'
    | 'delete'
    | 'list'
    | 'read'
    | 'update'
    | 'readModelDefinition'
    | 'updateModelDefinition'
    | 'listVersions'
    | 'listApplicationVersions'
    | 'createVersion'
    | 'readVersion'
    | 'updateVersion'
>

@injectable()
export class ApplicationServiceImpl implements ApplicationService {
    constructor(
        @inject(TYPES.RequestStore) private req: RequestStore,
        @inject(TYPES.ApplicationApi) private api: ApplicationApi,
        @inject(TYPES.UiApi) private ui: UiApi,
    ) {}

    list(request?: PaginationRequest<ApplicationCriteriaWithUIOptions>): Promise<PaginationResponse<Application>> {
        return this.req.once(`application.list.${JSON.stringify(request)}`, () => this.ui.listApplications(request))
    }

    get(id: string): Promise<Application> {
        return this.req.once(`application.get.${id}`, () => this.ui.readApplication(id))
    }

    create(application: ApplicationCreate): Promise<Application> {
        return this.api.create(application)
    }

    update(application: ApplicationUpdate): Promise<Application> {
        return this.api.update(application)
    }

    delete(application: Application, cascade?: boolean): Promise<void> {
        return this.api.delete(application, {
            ...(cascade && {
                params: {
                    cascade,
                },
            }),
        })
    }

    getModelDefinition(id: string): Promise<AppModelDefinition> {
        return this.req.once(`application.def.get.${id}`, () => this.api.readModelDefinition(id))
    }

    setModelDefinition(id: string, def: AppModelDefinition): Promise<void> {
        return this.api.updateModelDefinition(id, def)
    }

    listVersions(
        appId: string,
        request?: PaginationRequest<ApplicationVersionCriteria>,
    ): Promise<PaginationResponse<ApplicationVersionDetails>> {
        return this.req.once(`application.listVersions.${appId}`, () =>
            this.api.listApplicationVersions(appId, request),
        )
    }

    getVersion(id: string): Promise<ApplicationVersion> {
        return this.req.once(`application.version.get.${id}`, () => this.api.readVersion(id))
    }

    createVersion(appId: string, version: ApplicationVersionCreate): Promise<ApplicationVersion> {
        return this.api.createVersion(appId, version)
    }

    updateVersion(id: string, version: ApplicationVersionUpdate): Promise<ApplicationVersion> {
        return this.api.updateVersion(id, version)
    }
}
