import { ReactElement } from 'react'
import { DialogActions, Stack, StackProps } from '@mui/material'

export function DialogFooter(props: StackProps): ReactElement {
    return (
        <DialogActions>
            <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1} {...props} />
        </DialogActions>
    )
}
