import { PropsWithChildren, ReactElement, ReactNode, useState } from 'react'
import { Box, Collapse, Divider, IconButton, Stack, SvgIcon, Typography } from '@mui/material'
import { SxProps, Theme, useTheme } from '@mui/material/styles'
import { AddCircleOutline, CheckCircle, Tune } from '@mui/icons-material'
import { SxObject, asSxArray } from '../../utils/mui'

export type KBListItemProps = {
    name?: string
    description?: string
    icon?: string | ReactElement
    selected?: boolean
    disabled?: boolean
    showAdd?: boolean
    rightAdornment?: ReactNode
    sx?: SxProps
    onClick?: () => void
}

export function KBListItem({
    name = '',
    description = '',
    icon,
    selected = false,
    disabled = false,
    showAdd = false,
    rightAdornment,
    children,
    sx,
    onClick,
}: PropsWithChildren<KBListItemProps>): ReactElement {
    const theme = useTheme()
    const [expandOptions, setExpandOptions] = useState(false)
    const maxHeight = '80px'
    const padding = 2
    const iconColor = theme.palette.action.active
    const imageSize = `calc(${maxHeight} - ${padding * 4 * 4}px)`

    const style: Record<string, SxObject<Theme>> = {
        root: {
            p: padding,
            m: '1px',
            boxShadow: '0 0 0 0.1px rgba(46, 30, 81, 0.25),0 1px 3px 0 rgba(0,0,0,0.12)',
            background: 'white',
            borderRadius: '4px',
            transitionDuration: '0.3',
            '&:hover': {
                cursor: 'pointer',
                background: theme.palette.action.hover,
            },
        },
        disabled: {
            boxShadow: '0 0 0 0.25px rgba(46, 30, 81, 0.25)',
        },
    }

    const onSelect = () => {
        if (!disabled) {
            onClick?.()
        }
    }

    return (
        <Box
            data-testid={`item_${name}`}
            sx={[style.root, disabled && style.disabled, ...asSxArray(sx)]}
            onClick={onSelect}
        >
            <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Stack
                    direction="row"
                    spacing={padding}
                    sx={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                    }}
                >
                    {/* image */}
                    <Box sx={{ height: imageSize, width: imageSize, minWidth: imageSize }}>
                        {icon && (
                            <SvgIcon data-testid="item_icon" sx={{ height: '100%', width: '100%' }}>
                                {icon}
                            </SvgIcon>
                        )}
                    </Box>

                    {/* text */}
                    <Stack justifyContent="center">
                        <Typography data-testid="item_name" color={disabled ? 'text.disabled' : 'text.primary'}>
                            {name}
                        </Typography>
                        <Typography
                            data-testid="item_desc"
                            variant="body2"
                            color={disabled ? 'text.disabled' : 'text.secondary'}
                        >
                            {description}
                        </Typography>
                    </Stack>
                </Stack>

                {/* actions */}
                <Stack direction="row" alignItems="center">
                    {children && (
                        <IconButton
                            data-testid="item_options_icon"
                            sx={{ color: iconColor }}
                            onClick={(e) => {
                                e.stopPropagation()
                                setExpandOptions(!expandOptions)
                            }}
                        >
                            <Tune />
                        </IconButton>
                    )}
                    {selected ? (
                        <CheckCircle data-testid="item_checked_icon" color="success" />
                    ) : (
                        showAdd && <AddCircleOutline data-testid="item_add_icon" sx={{ color: iconColor }} />
                    )}
                    {rightAdornment}
                </Stack>
            </Stack>

            {/* options */}
            <Collapse in={expandOptions}>
                <Divider sx={{ mt: 2 }} />
                <Box data-testid="item_child" sx={{ mt: 1 }}>
                    {children}
                </Box>
            </Collapse>
        </Box>
    )
}
