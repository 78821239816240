import { differenceInSeconds, format } from 'date-fns'
import { t } from 'i18next'
import { HistoryInterval, totalTimeForInterval } from '../service/history/history.service'

const SECONDS_PER_MINUTE = 60
const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60
const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24

export type TimePassedResult = {
    type: 'sec' | 'min' | 'hour' | 'day' | 'week'
    value: number
}

/**
 *
 * @param date
 * @returns return boolean,  but also validates that date is not undefined
 */
const isDateValid = (date?: Date): date is Date => {
    if (!date) return false

    if (Number.isNaN(date.getTime())) return false

    return true
}

export const dateAsShortTimeStr = (date: Date): string => {
    if (!isDateValid(date)) {
        return ''
    }

    const isPM = date.getHours() >= 12
    const formatStr = `h:mm${isPM ? 'a' : ''}`
    return format(date, formatStr).toLowerCase()
}

export const dateAsShortTimeStrWithSeconds = (date: Date): string => {
    if (!isDateValid(date)) {
        return ''
    }

    const isPM = date.getHours() >= 12
    const formatStr = `h:mm:ss${isPM ? 'a' : ''}`
    return format(date, formatStr).toLowerCase()
}

export const dateAsMonthDayYears = (date?: Date): string => {
    if (!isDateValid(date)) {
        return ''
    }

    return format(date, 'M/d/y').toLowerCase()
}

export const dateAsMonthDayYearsTime = (date?: Date): string => {
    if (!isDateValid(date)) {
        return ''
    }

    return `${format(date, 'M/d/y').toLowerCase()} ${dateAsShortTimeStrWithSeconds(date)}`
}

export const copyHoursToDate = (date: Date, hours: Date): Date =>
    new Date(date.setHours(hours.getHours(), hours.getMinutes(), hours.getSeconds()))

export const dateHoursMinutesSecondsAreEqual = (d1?: Date, d2?: Date): boolean =>
    d1?.getHours() === d2?.getHours() && d1?.getMinutes() === d2?.getMinutes() && d1?.getSeconds() === d2?.getSeconds()

export const addIntervalToDate = (date: Date, interval: HistoryInterval): Date => {
    const intervalTime = totalTimeForInterval(interval)
    return new Date(date.getTime() + intervalTime)
}

export function calcDateDistance(currentDate: number | Date, date: number | Date): TimePassedResult {
    const differenceSec = differenceInSeconds(currentDate, date)

    const mins = Math.trunc(differenceSec / SECONDS_PER_MINUTE)

    if (mins < 1) {
        return { type: 'sec', value: differenceSec }
    }

    if (mins < 60) {
        return { type: 'min', value: mins }
    }

    const hours = Math.trunc(differenceSec / SECONDS_PER_HOUR)
    if (hours < 24) {
        return { type: 'hour', value: hours }
    }

    const days = Math.trunc(differenceSec / SECONDS_PER_DAY)
    return { type: 'day', value: days }
}

export function relativeDateTime(date: Date): string {
    const result = calcDateDistance(new Date(), date)

    if (result.type === 'day' && result.value > 7) {
        return format(date, 'MM/dd').toLowerCase()
    }

    // result type of 'sec' will be translated to 'Just now' from i18n
    return t(`dateTime.timePassed.${result.type}`, { count: result.value })
}

export function toTimecode(durationInSec: number): string {
    const duration = Math.floor(durationInSec)
    const hours = Math.floor(duration / SECONDS_PER_HOUR)
    const minutes = Math.floor((duration - hours * SECONDS_PER_HOUR) / SECONDS_PER_MINUTE)
    const seconds = duration - hours * SECONDS_PER_HOUR - minutes * SECONDS_PER_MINUTE

    const h = hours < 10 ? `0${hours}` : hours
    const m = minutes < 10 ? `0${minutes}` : minutes
    const s = seconds < 10 ? `0${seconds}` : seconds

    let timecode = ''
    if (h !== '00') {
        timecode += `${h}:`
    }

    return `${timecode}${m}:${s}`
}
