import { inject, injectable } from 'inversify'
import T from 'config/inversify.types'
import log from 'logging/logger'

import type { RealtimeDataStore, SnapshotManager, VdbClientManager } from '../realtime-data'
import type { Deployment } from './deployment'

@injectable()
export class DeploymentManager {
    private deployment?: Deployment

    constructor(
        @inject(T.VdbManager) private vdb: VdbClientManager,
        @inject(T.RealtimeDataStore) private rtd: RealtimeDataStore,
        @inject(T.SnapshotManager) private snapshotManager: SnapshotManager,
    ) {
        log.info('[DeploymentManager] new')
    }

    start(deployment: Deployment): void {
        log.info('[DeploymentManager] Starting', deployment.deploymentId)
        this.deployment = deployment
        this.vdb.start(deployment)
        this.rtd.start(deployment)
        this.snapshotManager.setDeployment(deployment)
    }

    stop(): void {
        log.info('[DeploymentManager] Stopping', this.deployment?.deploymentId)
        this.rtd.stop()
        this.vdb.stop()
        this.snapshotManager.clearDeployment()
    }
}
