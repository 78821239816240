import { ReactElement } from 'react'
import { Stack, Typography, TypographyProps } from '@mui/material'

export type ErrorInfoProps = {
    title: string
    info?: string
    'data-testid'?: string
}

export function ErrorInfo({ title, info, 'data-testid': dataTestid }: ErrorInfoProps): ReactElement {
    const typography = {
        variant: 'body2',
        color: 'text.secondary',
    } as TypographyProps

    return (
        <Stack direction="row" spacing="3px" sx={{ overflow: 'hidden' }}>
            <Typography {...typography} data-testid={`${dataTestid}-title`}>{`${title}:`}</Typography>
            <Typography
                {...typography}
                sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                data-testid={`${dataTestid}-info`}
            >
                {info}
            </Typography>
        </Stack>
    )
}
