import { injectable } from 'inversify'
import getSimplePasswordPolicy, { charsets, PasswordPolicy, PolicyName, RulesOptions } from 'password-sheriff'

const NONE_OPTION = {
    length: { minLength: 1 },
}

const LOW_OPTION = {
    length: { minLength: 6 },
}

const FAIR_OPTION = {
    length: { minLength: 8 },
    contains: {
        expressions: [charsets.lowerCase, charsets.upperCase, charsets.numbers],
    },
}

const GOOD_OPTION = {
    length: { minLength: 8 },
    containsAtLeast: {
        atLeast: 3,
        expressions: [charsets.lowerCase, charsets.upperCase, charsets.numbers, charsets.specialCharacters],
    },
}

const EXCELLENT_OPTION = {
    length: { minLength: 10 },
    containsAtLeast: {
        atLeast: 3,
        expressions: [charsets.lowerCase, charsets.upperCase, charsets.numbers, charsets.specialCharacters],
    },
    identicalChars: { max: 2 },
}

const PolicyOptions: { [K in PolicyName]: RulesOptions } = {
    none: NONE_OPTION,
    low: LOW_OPTION,
    fair: FAIR_OPTION,
    good: GOOD_OPTION,
    excellent: EXCELLENT_OPTION,
}

@injectable()
export class PasswordValidatorFactory {
    createPasswordValidator(policyName: PolicyName, minLength?: number): PasswordPolicy {
        if (minLength) {
            const newPolicyOption = {
                ...PolicyOptions[policyName],
                length: { minLength },
            }

            return new PasswordPolicy(newPolicyOption)
        }

        return getSimplePasswordPolicy(policyName)
    }
}
