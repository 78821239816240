import { Stream } from 'store/stream'
import { useCallback, useEffect } from 'react'
import { Status, usePromiseState, usePromiseValue } from '../usePromiseState'
import { useStreamStore } from './useStreamStore'

export const getStreamId = (ids: string | string[]): string | undefined => {
    const [id] = [ids].flat()

    return id
}

export function useStream(id: string | string[]): [Status, Stream | undefined] {
    const store = useStreamStore()

    const streamId = getStreamId(id)

    const loadStream = useCallback(
        (sid?: string): Promise<Stream | undefined> => (sid ? store.loadStream(sid) : Promise.resolve(undefined)),
        [store],
    )

    const [status, setStatus] = usePromiseState(() => loadStream(streamId))

    useEffect(() => {
        setStatus(loadStream(streamId))
    }, [streamId, loadStream, setStatus])

    const stream = usePromiseValue(status, undefined)

    return [status, stream]
}
