import ReactDOM from 'react-dom'
import { LicenseInfo } from '@mui/x-license-pro'
import { BrowserRouter } from 'react-router-dom'
import { KBRoutes } from './Routes'
import { Base } from './Base'
import './i18n'

// mui-x license
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE || '')

const app = document.getElementById('app')

// allows for #app element to have 100% height
if (app) app.style.height = '100vh'

ReactDOM.render(
    <BrowserRouter>
        <Base>
            <KBRoutes />
        </Base>
    </BrowserRouter>,
    app,
)
