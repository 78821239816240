import { fromPromise as mobxFromPromise, IPromiseBasedObservable } from 'mobx-utils'

import log from 'logging/logger'

/**
 * Wraps mobx-utils' fromPromise method with rejection logging for simple and familiar debugging.
 *
 * Code paths should handle their own exceptions appropriately, but this is the catch-all for rejected promises.
 *
 * NOTE: This function narrows the type of the original promise from PromiseLike<T> to Promise<T> to gain access to #catch.
 */
export function fromPromise<T>(origPromise: Promise<T>, oldPromise?: PromiseLike<T>): IPromiseBasedObservable<T> {
    const newOriginalPromise = origPromise.catch((error) => {
        log.warn(error)
        // maintain the promise chain so that downstream code may handle the error
        throw error
    })
    return mobxFromPromise(newOriginalPromise, oldPromise)
}
