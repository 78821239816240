import type Konva from 'konva'
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Image as KImage, Layer, Rect, Stage } from 'react-konva'
import { Stack } from '@mui/material'
import { useFileDragHandler } from 'hooks/file'
import { useImageData } from 'hooks/image/useImageData'
import { ImageDropper } from 'components/file'
import { ImageDataBuffer } from 'store/data-buffer'

import { ZoomSlider } from './ZoomSlider'
import { EditorFooter } from './EditorFooter'
import { PhotoOverlay } from './PhotoOverlay'

const width = 512
const height = 600

export interface UserPhotoEditorProps {
    userPhoto?: string
    loading?: boolean
    onClose?: () => void
    onSave?: (buffer: Buffer) => void
}

export function UserPhotoEditor({ userPhoto, loading, onClose, onSave }: UserPhotoEditorProps): ReactElement {
    const { t } = useTranslation()
    const [image, setImage] = useState<HTMLImageElement>()
    const [zoom, setZoom] = useState(1)

    const imageData = useImageData(userPhoto)

    const imageRef = useRef<Konva.Image>(null)
    const layerRef = useRef<Konva.Layer>(null)

    const createImage = useCallback((src: string) => {
        const img = new Image()

        img.onload = () => {
            setZoom(width / img.width)
            setImage(img)
        }

        img.src = src
    }, [])

    const onFile = useCallback(
        async (file?: File) => {
            if (file) {
                const buf = await file.arrayBuffer()

                createImage(new ImageDataBuffer(buf, file.type).dataUrl)
            }
        },
        [createImage],
    )

    useEffect(() => {
        if (imageData) {
            createImage(imageData.dataUrl)
        }
    }, [imageData, createImage])

    const onZoom = useCallback(
        (value: number) => {
            const img = imageRef.current
            const stg = img?.getStage()

            if (img && stg) {
                const r = value / zoom

                const { x: px, y: py } = img.getPosition()
                const { width: sw, height: sh } = stg.getSize()
                const sx = sw / 2
                const sy = sh / 2

                // This re-positions the image so that point at the center of the capture area stays at the center of the
                // capture area.
                const x = sx - (sx - px) * r
                const y = (py - sy) * r + sy

                img.setPosition({ x, y })
            }

            setZoom(value)
        },
        [zoom],
    )

    const onDrag = useCallback(() => {
        const img = imageRef.current
        const stg = img?.getStage()

        if (img && stg) {
            const { x: px, y: py } = img.getPosition()
            const { width: iw, height: ih } = img.getSize()
            const { width: sw, height: sh } = stg.getSize()

            const w = iw * zoom
            const h = ih * zoom
            const w2 = w / 2
            const h2 = h / 2
            const sw2 = sw / 2
            const sh2 = sh / 2

            const x = Math.max(Math.min(sw2 + w2, px), sw2 - w2)
            const y = Math.max(Math.min(sh2 + h2, py), sh2 - h2)

            img.setPosition({ x, y })
        }
    }, [zoom])

    const onSavePhoto = () => {
        const img = layerRef.current

        if (img) {
            const src = img.toDataURL({
                x: 0,
                y: (height - width) / 2,
                width,
                height: width,
                mimeType: 'image/jpeg',
                quality: 0.9,
            })

            const buf = Buffer.from(src.substr('data:image/jpeg;base64,'.length), 'base64')

            onSave?.(buf)
        }
    }

    const { active, ...dragProps } = useFileDragHandler({ onChange: onFile })

    return (
        <Stack gap={3}>
            <Stack gap={1.5}>
                {!image && (
                    <ImageDropper height={`${height}px`} onFileChange={onFile} helperMessage={t('file.dropHelper')} />
                )}
                {image && (
                    <Stack
                        alignSelf="center"
                        justifySelf="center"
                        sx={{
                            border: active ? '1px solid' : '1px dashed',
                            borderColor: active ? 'highlight.main' : 'separation.main',
                            borderRadius: '4px',
                        }}
                        {...dragProps}
                    >
                        <Stage ge width={width} height={height} style={{ borderRadius: 4 }}>
                            <Layer ref={layerRef}>
                                <Rect x={0} y={0} width={width} height={height} fill="white" />
                                {image && (
                                    <KImage
                                        ref={imageRef}
                                        image={image}
                                        x={width / 2}
                                        y={height / 2}
                                        offsetX={image.width / 2}
                                        offsetY={image.height / 2}
                                        scaleX={zoom}
                                        scaleY={zoom}
                                        onDragMove={onDrag}
                                        onDragEnd={onDrag}
                                        draggable
                                    />
                                )}
                            </Layer>
                            <Layer>
                                <PhotoOverlay width={width} height={height} />
                            </Layer>
                        </Stage>
                    </Stack>
                )}

                <Stack alignItems="center" alignSelf="stretch" px={1.25}>
                    <ZoomSlider value={zoom} onChange={onZoom} />
                </Stack>
            </Stack>

            <EditorFooter onClose={onClose} onSave={onSavePhoto} loading={loading} />
        </Stack>
    )
}
