import { ReactElement } from 'react'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'

export type AppTitleProps = {
    title?: string
}

/**
 * Pass a title for the single page application to display in the browser.
 *
 * If the title prop is undefined, no update will occur; a parent title will take precedence.
 *
 * This component may be arbitrarily nested throughout component tree as necessary.
 *
 * Example of valid usage –
 *
 * - parent
 *   - title
 *   - child
 *     - title
 *   - sibling
 *     - title
 *   - sibling
 *     - no title declared; will fall back to parent
 *
 */
export const AppTitle = observer(({ title }: AppTitleProps): ReactElement | null => {
    if (!title) return null
    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    )
})
