import { inject, injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'
import {
    detectorDefToIds,
    type DetectorCreate,
    type DetectorCriteria,
    type Detector as DetectorDto,
    type ItemType as ItemTypeDto,
} from '@kibsi/ks-application-types'
import TYPES from 'config/inversify.types'
import type { DetectorService } from 'service/detector'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from '../domain'
import { createDomainPageAction, PaginatedList, PaginatedListImpl } from '../pagination'
import { Detector } from './detector'

export type DetectorList = PaginatedList<Detector>

function getDetectorId(dto: DetectorDto) {
    return dto.id
}

@injectable()
export class DetectorStore {
    private detectors: AsyncDomainStore<Detector, DetectorDto>

    constructor(@inject(TYPES.DetectorService) private service: DetectorService) {
        this.detectors = new AsyncFromDtoDomainStore<DetectorDto, Detector>(
            (id) => service.getDetector(id),
            (_, data) => new Detector(data, service),
        )

        makeAutoObservable<DetectorStore, 'service'>(this, {
            service: false,
        })
    }

    async loadList(criteria?: DetectorCriteria): Promise<Detector[]> {
        const { detectors, service } = this
        const action = createDomainPageAction(detectors, service.list.bind(service), getDetectorId)

        const list = new PaginatedListImpl(action, { pageSize: 200, sort: ['name'], criteria })

        await list.all()

        return list.items
    }

    async createDetector(create: DetectorCreate, enhancerIds: string[], parentIds: string[]): Promise<Detector> {
        const dto = await this.service.createDetector(create)

        const detector = this.detectors.set(dto.id, dto)

        if (enhancerIds.length) {
            await detector.addEnhancers(enhancerIds)
        }

        if (parentIds) {
            await detector.addParents(parentIds)
        }

        return detector
    }

    loadDetector(detectorId: string): Promise<Detector> {
        return this.detectors.load(detectorId)
    }

    getDetector(detectorId: string): Detector | undefined {
        return this.detectors.get(detectorId)
    }

    async deleteDetector(detectorId: string, cascade?: boolean): Promise<void> {
        await this.service.deleteDetector(detectorId, cascade)
        this.detectors.delete(detectorId)
    }

    async loadDetectorEnhancers(detectorIds: string[]): Promise<Detector[]> {
        const promises = detectorIds.map((dId) =>
            this.service.listDetectorEnhancers(dId, {
                pageSize: 200,
                sort: ['name'],
            }),
        )
        const dtos = await Promise.all(promises)

        const dtoMap: Record<string, DetectorDto> = {}
        dtos.forEach((arrayDto) => {
            arrayDto.forEach((detector) => {
                dtoMap[detector.id] = detector
            })
        })

        return this.setDetectors(Object.values(dtoMap).sort((a, b) => a.name.localeCompare(b.name)))
    }

    async loadModelDetectors(modelId: string): Promise<DetectorList> {
        const { detectors, service } = this
        const action = createDomainPageAction(
            detectors,
            service.getDetectorsForModel.bind(service, modelId),
            getDetectorId,
        )

        const list = new PaginatedListImpl(action, { pageSize: 100 })

        await list.more()

        return list
    }

    async getParentDetectors(childDetectorId: string): Promise<Detector[]> {
        const { results: dtos } = await this.service.list({ pageSize: 100, criteria: { hasEnhancer: childDetectorId } })
        return this.setDetectors(dtos)
    }

    async getEnhancers(item: Pick<ItemTypeDto, 'generationType' | 'detector'>): Promise<Detector[]> {
        const { generationType, detector } = item

        if (generationType === 'detected') {
            return this.loadDetectorEnhancers(detectorDefToIds(detector))
        }

        if (generationType === 'static') {
            return this.loadList({ usage: 'Static' })
        }

        return []
    }

    private setDetectors(dtos: DetectorDto[]): Detector[] {
        return dtos.map((dto) => this.detectors.set(dto.id, dto))
    }
}
