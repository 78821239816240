import { makeAutoObservable } from 'mobx'
import { injectable } from 'inversify'
import { DeploymentFilter } from './deployment.filter'
import { Deployment } from '../deployment'

@injectable()
export class FilterStore {
    private deploymentFilters: Record<string, DeploymentFilter> = {}

    constructor() {
        makeAutoObservable(this)
    }

    getDeploymentFilter(deployment: Deployment): DeploymentFilter {
        if (!this.deploymentFilters[deployment.deploymentId]) {
            this.deploymentFilters[deployment.deploymentId] = new DeploymentFilter(deployment)
        }
        return this.deploymentFilters[deployment.deploymentId]
    }
}
