import { injectable } from 'inversify'
import logger from 'logging/logger'
import { DeploymentUiConfigDto } from 'model/deployment.ui-config'
import { DeploymentUiConfigService } from './deployment.ui-config.service'

const DEPLOYMENT_UI_CONFIG_PREFIX = 'kibsi:deployUIConfig'

@injectable()
export class DeploymentUiConfigServiceImpl implements DeploymentUiConfigService {
    getDeploymentUiKey(deploymentId: string): string {
        return `${DEPLOYMENT_UI_CONFIG_PREFIX}:${deploymentId}`
    }

    get(id: string): DeploymentUiConfigDto {
        const key = this.getDeploymentUiKey(id)
        const strConfig = localStorage.getItem(key)

        try {
            return JSON.parse(strConfig ?? '') as DeploymentUiConfigDto
        } catch (e) {
            logger.info('Cannot get config, returning empty object')
            return {}
        }
    }

    save(id: string, config: DeploymentUiConfigDto): void {
        const key = this.getDeploymentUiKey(id)
        localStorage.setItem(key, JSON.stringify(config))
    }
}
