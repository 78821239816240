import { StaticRegions } from 'store/deployment/static.regions'
import { Mat } from '@techstark/opencv-js'
import { PointOptionsObject, SeriesHeatmapOptions } from 'highcharts'
import { perspectiveTransformWithPoint } from '../../floor-plans/mapper/opencv'

export type HeatmapColors = [number, string][]

export type InterpolationType = 'linear' | 'logarithmic'

function logspace(intervals: number, min: number, max: number) {
    const factor = Math.exp((Math.log(max) - Math.log(min)) / intervals)
    const result = [min]

    for (let i = 1; i < intervals; i++) {
        result.push(result[result.length - 1] * factor)
    }
    result.push(max)
    return result
}

export type HeatmapColorOptions = {
    initialHue: number
    hueOffset: number
    satOffset: number
}

export const DEFAULT_HEATMAP_COLOR_OPTIONS: HeatmapColorOptions = {
    initialHue: 183,
    hueOffset: 2,
    satOffset: 3.5,
}

export function heatmapColors(
    type: InterpolationType = 'logarithmic',
    interval = 0.125,
    { initialHue, hueOffset, satOffset } = DEFAULT_HEATMAP_COLOR_OPTIONS,
): HeatmapColors {
    const stops: [number, string][] = []
    const hueMax = 360
    const minLog = 0.1
    const maxLog = 1.0
    const minAlpha = 0.75

    let hueStep = initialHue >= hueMax ? 0 : initialHue
    let satStep = 50

    function incrementColors() {
        hueStep += hueOffset
        if (hueStep >= hueMax) {
            hueStep -= hueMax
        }
        satStep -= satOffset
    }

    switch (type) {
        case 'linear': {
            let step = 0
            do {
                stops.push([step, `hsl(${hueStep}, 100%, ${satStep}%, ${step === 0 ? minAlpha : 1.0})`])
                step += interval
                incrementColors()
            } while (step <= 1)

            break
        }
        case 'logarithmic': {
            const logSteps = logspace(maxLog / interval, minLog, maxLog)
            logSteps.forEach((step) => {
                stops.push([step, `hsl(${hueStep}, 100%, ${satStep}%, ${step === minLog ? minAlpha : 1.0})`])
                incrementColors()
            })
            break
        }
        default:
            break
    }

    return stops
}

export function transformRegionsWithMatrix(staticRegions: StaticRegions[], matrix?: Mat): StaticRegions[] {
    if (!matrix) {
        return []
    }

    return staticRegions.reduce((list, { id, name, regions, itemType }) => {
        const transformedRegions = [...regions].map((region) => ({
            ...region,
            polygons: region.polygons.map((regionPolygons) => ({
                ...regionPolygons,
                polygon: regionPolygons.polygon.map((p) => perspectiveTransformWithPoint(matrix, p)),
            })),
        }))
        list.push(new StaticRegions(id, name, transformedRegions, itemType))
        return list
    }, [] as StaticRegions[])
}

export function getMinAndMax(heatmapData?: SeriesHeatmapOptions): number[] {
    if (!heatmapData?.data) {
        return [0, 0]
    }
    const values: number[] = heatmapData.data
        .map((v: PointOptionsObject) => v.value)
        .filter((v) => v !== undefined) as number[]
    return [Math.min(...values), Math.max(...values)]
}

export function clipHeatmapData(
    min: number,
    max: number,
    heatmapData?: SeriesHeatmapOptions,
): SeriesHeatmapOptions | undefined {
    if (!heatmapData) {
        return undefined
    }

    const data = heatmapData.data?.filter((v: PointOptionsObject) => v.value && v.value >= min && v.value <= max)
    return {
        ...heatmapData,
        data,
    }
}
