import { injectable } from 'inversify'

/**
 * This helps manage requests. It currently doesn't have state, but this can grow to cache requests and provide other
 * methods for new featurs.
 */
@injectable()
export class RequestStore {
    private requests = new Map<string, Promise<unknown>>()

    /**
     * Only allow 1 in flight request per request id.
     */
    async once<T>(id: string, action: () => Promise<T>): Promise<T> {
        if (this.requests.has(id)) {
            return this.requests.get(id) as Promise<T>
        }

        try {
            const promise = action()

            this.requests.set(id, promise)

            const resp = await promise

            return resp
        } finally {
            // delete the request when the action fails
            this.requests.delete(id)
        }
    }
}
