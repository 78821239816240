import { ARTItemPrimitives, ARTItemState, ARTSnapshot, Primitives, SnapshotInterval } from '@kibsi/ks-history-types'

export type SnapshotPrimitive = {
    id: string
    time: string
} & ARTItemPrimitives

export function parseValue(value: Primitives): Primitives {
    if (typeof value === 'string') {
        try {
            return JSON.parse(value) as Primitives
        } catch (e) {
            // noop
        }
    }

    return value
}

export function parseInto<T extends ARTItemPrimitives>(source: ARTItemPrimitives, dest: ARTItemPrimitives): T {
    for (const [key, value] of Object.entries(source)) {
        dest[key] = parseValue(value)
    }

    return dest as T
}

export function parseSnapshotState(state: ARTItemState): ARTItemState {
    return parseInto(state, {})
}

export function parseSnapshot(snapshot: ARTSnapshot): ARTSnapshot {
    return Object.entries(snapshot).reduce<ARTSnapshot>((acc, [key, values]) => {
        acc[key] = values.map(parseSnapshotState)

        return acc
    }, {})
}

export function parseSnapshotInterval(snapshot: SnapshotInterval): SnapshotInterval {
    return Object.entries(snapshot).reduce<SnapshotInterval>((acc, [key, values]) => {
        acc[key] = values.map(({ id, time, ...rest }) => parseInto(rest, { id, time }))

        return acc
    }, {})
}
