import { ReactElement } from 'react'
import { Path } from 'react-konva'

export interface PhotoOverlayProps {
    width: number
    height: number
}

export function PhotoOverlay({ width: w, height: h }: PhotoOverlayProps): ReactElement {
    const m = h / 2
    const r = w / 2

    return (
        <Path
            x={0}
            y={0}
            data={`M 0 0, L ${w} 0, L ${w} ${m}, A ${r} ${r} 0 0 0 0 ${m}, A ${r} ${r} 0 0 0 ${w} ${m}, L ${w} ${h}, L 0 ${h}, Z`}
            fill="white"
            opacity={0.5}
        />
    )
}
