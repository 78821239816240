export function toPercent(value?: number, defaultValue = 0): number {
    return Math.floor((value ?? defaultValue) * 100)
}

/**
 * same as toPercent, but return undefined if parameter is undefined
 *
 * @param value
 * @returns
 */
export function toOptionalPercent(value?: number): number | undefined {
    return value === undefined ? undefined : toPercent(value)
}

export function fromPercent(value?: number, defaultValue = 0): number {
    return (value ?? defaultValue) / 100
}

/**
 * same as fromPercent, but return undefined if parameter is undefined
 *
 * @param value
 * @returns
 */
export function fromOptionalPercent(value?: number): number | undefined {
    return value === undefined ? undefined : fromPercent(value)
}
