import { Backdrop } from '@mui/material'
import { ReactElement } from 'react'
import { KBLoading } from './KBLoading'

export interface KBLoadingBackdropProps {
    open: boolean
    onClick?: () => void
    size?: number
}

export function KBLoadingBackdrop({ open, onClick, size }: KBLoadingBackdropProps): ReactElement {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={onClick}>
            <KBLoading loading size={size} />
        </Backdrop>
    )
}
