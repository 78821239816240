import { makeAutoObservable, reaction } from 'mobx'
import { inject, injectable } from 'inversify'
import T from 'config/inversify.types'
import type { EventService } from 'service'
import Logger from 'logging/logger'

import { Deployment, DeploymentUIConfig, DeploymentUiConfigStore } from '../deployment'
import { RealtimeDataStore, SnapshotManager } from '../realtime-data'
import { EventGroup } from './event.group'
import { Event } from './event'
import { activeThenStartTimeSort } from './sorting'

const log = Logger.getLogger('LiveViewManager')

@injectable()
export class LiveEventsManager {
    private groups = new Map<string, EventGroup>()
    private deployment?: Deployment
    private config?: DeploymentUIConfig
    private paused = false
    private pausedEvents?: Event[]
    private pausedGroups?: EventGroup[]
    private stopData?: () => void

    constructor(
        @inject(T.SnapshotManager) private snapshotManager: SnapshotManager,
        @inject(T.RealtimeDataStore) private rtd: RealtimeDataStore,
        @inject(T.DeploymentUiConfigStore) private configs: DeploymentUiConfigStore,
        @inject(T.EventService) private eventService: EventService,
    ) {
        makeAutoObservable(this)

        reaction(
            () => rtd.isPlaying,
            (playing) => {
                // reset group counts when resuming from pause
                if (playing) {
                    Array.from(this.groups.values()).forEach((g) => {
                        g.reset()
                    })
                }
            },
        )
    }

    get events(): Event[] {
        if (!this.isRunning) {
            return []
        }

        if (this.isPaused) {
            return this.pausedEvents ?? []
        }

        return this.values
            .filter((g) => !g.isGrouped)
            .flatMap((g) => g.events)
            .sort(activeThenStartTimeSort)
    }

    get eventGroups(): EventGroup[] {
        if (!this.isRunning) {
            return []
        }

        if (this.isPaused) {
            return this.pausedGroups ?? []
        }

        return this.values.filter((g) => g.isGrouped)
    }

    get hasEvents(): boolean {
        return this.isRunning && this.events.length > 0
    }

    get hasGroups(): boolean {
        return this.isRunning && this.eventGroups.length > 0
    }

    get uiConfig(): DeploymentUIConfig | undefined {
        return this.config
    }

    get isPaused(): boolean {
        return this.paused
    }

    get isLive(): boolean {
        return !this.paused
    }

    get isEmpty(): boolean {
        return this.groups.size === 0
    }

    get isRunning(): boolean {
        return this.deployment?.isRunning ?? false
    }

    getDeployment(): Deployment {
        const { deployment } = this

        if (!deployment) {
            throw new Error('DeploymentNotFound')
        }

        return deployment
    }

    setPaused(value: boolean): void {
        if (value) {
            this.pausedEvents = this.events
            this.pausedGroups = this.eventGroups
        } else {
            this.pausedEvents = undefined
            this.pausedGroups = undefined
        }
        this.paused = value
    }

    getGroup(event: Event): EventGroup | undefined {
        return this.groups.get(event.itemTypeId)
    }

    async start(deployment: Deployment): Promise<void> {
        log.info('Starting live events')
        this.deployment = deployment
        this.snapshotManager.setDeployment(deployment)
        this.config = this.configs.get(deployment.deploymentId)

        this.setupGroups()
        this.stopData = this.rtd.startData()

        await this.loadEvents(deployment)
    }

    stop(): void {
        log.info('Stopping live events')
        this.reset()
        this.stopData?.()
    }

    private reset(): void {
        this.pausedEvents = undefined
        this.pausedGroups = undefined
        this.groups.clear()
    }

    private get values(): EventGroup[] {
        return Array.from(this.groups.values())
    }

    private async loadEvents(deployment: Deployment): Promise<void> {
        const events = await this.eventService.list(deployment.deploymentId)

        this.groups.forEach((group) => {
            group.setupEvents(events)
        })
    }

    private setupGroups() {
        const { deployment, groups, eventService, config } = this
        const snapshot = this.snapshotManager.getSnapshot()

        groups.clear()

        if (!deployment || !config || !snapshot) {
            return
        }

        deployment.getEventItemTypes().forEach((item) => {
            this.groups.set(item.id, new EventGroup(item, deployment, eventService, config, snapshot))
        })

        log.info('configured groups: ', this.groups.size)
    }
}
