import logger from 'logging/logger'
import { DragEvent, useState, DOMAttributes } from 'react'

export type FileDraggedHandler = (e: DragEvent<HTMLDivElement>) => void
export type FileDroppedHandler = (e: DragEvent<HTMLDivElement>) => void

export interface FileDragHandlerProps {
    onChange?: (file?: File) => void
}

export interface FileHandler
    extends Pick<DOMAttributes<HTMLDivElement>, 'onDragEnter' | 'onDragOver' | 'onDragLeave' | 'onDrop'> {
    active: boolean
}

export function useFileDragHandler({ onChange }: FileDragHandlerProps): FileHandler {
    const [active, setActive] = useState<boolean>(false)

    const onFile = (file?: File) => {
        logger.debug(`onFile: ${file?.name ?? ''} ${file?.size ?? 0}bytes`)

        onChange?.(file)
    }

    const onFileDragged = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setActive(true)
        } else if (e.type === 'dragleave') {
            setActive(false)
        }
    }

    const onFileDropped = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()

        const { files } = e.dataTransfer

        onFile(files[0])
        setActive(false)
    }

    return {
        active,
        onDragEnter: onFileDragged,
        onDragOver: onFileDragged,
        onDragLeave: onFileDragged,
        onDrop: onFileDropped,
    }
}
