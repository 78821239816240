import { injectable } from 'inversify'
import { LocalRepository } from './local.repo'

@injectable()
export class LocalStorageRepository implements LocalRepository {
    get<T>(key: string): T | undefined {
        const item = localStorage.getItem(key)

        if (item !== null) {
            try {
                return JSON.parse(item) as T
            } catch (e) {
                // ignore
            }
        }

        return undefined
    }

    set<T>(key: string, value?: T): void {
        if (value === undefined) {
            localStorage.removeItem(key)
        } else {
            localStorage.setItem(key, JSON.stringify(value))
        }
    }
}
