import { Button, FormHelperText, Stack, Typography } from '@mui/material'
import { ReactElement, RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { FilePresentOutlined, PhotoLibraryOutlined, VideoLibraryOutlined } from '@mui/icons-material'
import { FileInfo, FileChangeHandler } from './types'

export type UploadFileType = 'video' | 'file' | 'image'

export type FileInputProps = {
    helperText: string
    suggestionText?: string
    suggested?: ReactElement
    fileInfo?: FileInfo
    existingFileName?: string
    inputRef?: RefObject<HTMLInputElement>
    onFileChanged?: FileChangeHandler
    showSuggested?: boolean
    fileType?: UploadFileType
}

export function FileBrowseInput({
    helperText,
    suggestionText,
    suggested,
    fileInfo,
    existingFileName,
    inputRef,
    onFileChanged,
    showSuggested,
    fileType = 'file',
}: FileInputProps): ReactElement {
    const { t } = useTranslation()

    const style = {
        pt: 0,
        pb: '2px',
        pl: '1px',
        textTransform: 'none',
        color: 'primary.main',
        textDecoration: 'underline',
        '&.MuiButtonBase-root:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
    }

    const fileTypeIcon = () => {
        // TODO: provide different icons for type
        switch (fileType) {
            case 'video':
                return <VideoLibraryOutlined color="action" />
            case 'image':
                return <PhotoLibraryOutlined color="action" />
            default:
                return <FilePresentOutlined color="action" />
        }
    }

    const renderFileName = () => {
        if (fileInfo) {
            return fileInfo.name
        }

        if (existingFileName) {
            return existingFileName
        }

        return helperText
    }

    const renderSuggested = (): ReactElement | undefined => {
        if (fileInfo && showSuggested) {
            return <FormHelperText sx={{ mt: 0 }}>{suggestionText}</FormHelperText>
        }
        return undefined
    }

    return (
        <Stack justifyItems="center" alignItems="center" justifyContent="center" data-testid="file-browse-input">
            {fileTypeIcon()}
            <Typography variant="body2">
                {renderFileName()}
                <Button variant="text" component="label" color="primary" sx={style}>
                    {existingFileName || fileInfo ? t('file.replace') : t('file.browse')}
                    <input
                        ref={inputRef}
                        type="file"
                        hidden
                        onChange={(e) => onFileChanged?.(e.target?.files?.[0])}
                        data-testid="upload-browse-input"
                    />
                    <input type="hidden" value={fileInfo?.name ?? ''} data-testid="upload-browse-filename" />
                </Button>
            </Typography>
            {renderSuggested()}
            {suggested}
        </Stack>
    )
}
