import { Box, Checkbox, CheckboxProps } from '@mui/material'
import { forwardRef, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { KBLock } from '../lock'

export type LockableCheckboxProps = {
    locked?: boolean
    message?: string
    'data-testid'?: string
} & CheckboxProps

export const KBLockableCheckBox = forwardRef<HTMLElement, LockableCheckboxProps>(function KBLockableCheckBox(
    { locked, message, 'data-testid': dataTestId, ...props }: LockableCheckboxProps,
    ref,
): ReactElement {
    const { t } = useTranslation()

    if (locked) {
        return (
            <Box ref={ref}>
                <KBLock
                    tooltipPlacement="bottom-end"
                    tooltipLabel={message ?? t('alert.contactToEnable')}
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [-10, -10],
                                },
                            },
                        ],
                    }}
                    data-testid={`${dataTestId}-lock`}
                />
            </Box>
        )
    }

    return (
        <Box ref={ref}>
            <Checkbox sx={{ mr: 0 }} data-testid={`${dataTestId}-checkbox`} {...props} />
        </Box>
    )
})
