import { ReactElement } from 'react'
import { Stack } from '@mui/material'
import { CancelButton, SaveButton } from 'components/button'

export interface EditorFooterProps {
    loading?: boolean
    onClose?: () => void
    onSave?: () => void
}

export function EditorFooter({ loading = false, onClose, onSave }: EditorFooterProps): ReactElement {
    return (
        <Stack direction="row" justifyContent="space-between" flex={1}>
            <CancelButton onCancel={onClose} />
            <SaveButton loading={loading} onClick={onSave} />
        </Stack>
    )
}
