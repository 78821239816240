import { Box, BoxProps, Stack, Typography } from '@mui/material'
import { ReactElement, ReactNode } from 'react'

export type KBPanelHeaderProps = {
    title?: string
    titleElement?: ReactNode
    subTitle?: string
    rightElement?: ReactElement
    children?: ReactNode
} & BoxProps

export function KBPanelHeader({
    title = '',
    titleElement,
    subTitle,
    rightElement,
    children,
    sx,
    ...props
}: KBPanelHeaderProps): ReactElement {
    return (
        <Box sx={{ mt: 2, mx: 2, ...sx }} {...props}>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Stack>
                    {titleElement && titleElement}
                    {title && (
                        <Box>
                            <Typography variant="h5">{title}</Typography>
                        </Box>
                    )}
                    {subTitle && <Typography variant="body2">{subTitle}</Typography>}
                </Stack>
                <Box component="span">{rightElement && rightElement}</Box>
            </Stack>
            <Box>{children}</Box>
        </Box>
    )
}
