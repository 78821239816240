import { Auth0ProviderOptions } from '@auth0/auth0-react'
import axios, { AxiosInstance } from 'axios'
import { injectable, inject } from 'inversify'
import { makeAutoObservable } from 'mobx'
import T from '../config/inversify.types'
import { Environment } from '../config/environment'

const Scope = 'kibsi:api'
const Audience = 'https://api.kibsi.com'

interface AuthCheckResponse {
    success: boolean
    subdomain?: string
    orgId?: string
}

@injectable()
export class AuthConfig {
    private http: AxiosInstance
    private organization?: string
    private redirect?: string

    readonly clientId: string
    readonly cacheLocation: Auth0ProviderOptions['cacheLocation']
    readonly authDomain: string

    domain?: string

    constructor(@inject(T.Environment) private env: Environment) {
        this.http = axios.create({ baseURL: this.env.serviceUrl.replace('{service}', 'tenant') })

        this.clientId = env.authClientId
        this.cacheLocation = env.authCacheLocation
        this.authDomain = env.authDomain

        makeAutoObservable<this, 'http'>(this, {
            checkAuthContext: false,
            clientId: false,
            cacheLocation: false,
            authDomain: false,
            http: false,
        })
    }

    get audience(): string {
        return Audience
    }

    get scope(): string {
        return Scope
    }

    get org(): string | undefined {
        if (this.organization) {
            return this.organization
        }

        return this.env.authOrganization || undefined
    }

    get redirectUri(): string | undefined {
        if (this.redirect) {
            return this.redirect
        }

        if (this.env.authRedirectUrl) {
            return this.env.authRedirectUrl
        }

        const { protocol, host } = window.location

        return `${protocol}//${host}/login`
    }

    /**
     * The Logout URL has to be configured in auth0. the default logout config is `${protocol}//${host}/`
     */
    get logoutUrl(): string | undefined {
        if (this.env.authLogoutUrl) {
            return this.env.authLogoutUrl
        }

        const { protocol, host } = window.location

        return `${protocol}//${host}/`
    }

    async checkAuthContext(token?: string): Promise<boolean> {
        const { data } = await this.http.post<AuthCheckResponse>('/auth/check', undefined, {
            headers: {
                ...(token ? { authorization: `bearer ${token}` } : undefined),
            },
        })

        const { success, orgId, subdomain } = data

        this.setTenantContext(orgId, subdomain)

        return success
    }

    private setTenantContext(org?: string, domain?: string) {
        this.organization = org

        if (domain) {
            this.domain = domain
            this.redirect = `${window.location.protocol}//${domain}/login`
        }
    }
}
