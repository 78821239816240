import { SyntheticEvent } from 'react'

type NativeEvent<T, E> = {
    type: string
    constructor: {
        new (type: string, native: NativeEvent<T, E>): SyntheticEvent<T, E>
    }
}

export function cloneEvent<T, E>(event: SyntheticEvent<T, E>): SyntheticEvent<T, E> {
    // https://github.com/mui/material-ui/blob/master/packages/mui-material/src/Select/SelectInput.js#L284
    const nativeEvent = (event.nativeEvent || event) as unknown as NativeEvent<T, E>
    return new nativeEvent.constructor(nativeEvent.type, nativeEvent)
}
