import { observer } from 'mobx-react-lite'
import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { EditOutlined, LaunchOutlined } from '@mui/icons-material'
import { Button, Grid, IconButton, Stack, Typography } from '@mui/material'
import { KBTimezoneSelect } from '@kibsi/ui-components'
import { SelfUserUpdate } from '@kibsi/ks-tenant-types'
import { useBrowserTimezone } from 'hooks/ui/useBrowserTimezone'
import { usePromiseStateLater } from 'hooks/usePromiseState'
import { User } from 'store/user'
import logger from 'logging/logger'

type ProfileContentProps = {
    profile: User
    onOpenChangePassword?: () => void
}

export const ProfileContent = observer(function ProfileContent({
    profile,
    onOpenChangePassword,
}: ProfileContentProps): ReactElement {
    const { t, i18n } = useTranslation()
    const [, setStatus] = usePromiseStateLater()
    const browserTimezone = useBrowserTimezone()

    const setTimezone = (userTimezone: string) => {
        if (profile) {
            setStatus(profile.update({ userTimezone }))
        }
    }

    useEffect(() => {
        const defaults: Partial<SelfUserUpdate> = {}

        if (!profile.userTimezone) {
            defaults.userTimezone = browserTimezone
        }

        if (!profile.userLanguage) {
            defaults.userLanguage = 'en'

            // TODO:  Need a story on how to select/use languages
            i18n.changeLanguage('en').catch((err) => {
                logger.error(err)
            })
        }

        if (Object.keys(defaults).length > 0) {
            setStatus(profile.update(defaults))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile])

    return (
        <Stack>
            <Grid
                container
                alignContent="center"
                alignItems="flex-start"
                sx={{
                    m: 0,
                    '& .MuiGrid-item': {
                        p: 2,
                        '&:last-child': {
                            py: 1,
                        },
                    },
                    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12);',
                }}
            >
                <Grid item xs={2}>
                    <Typography variant="subtitle2">{t('profile.profile.email')}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="body2">{profile.userEmail}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                        <IconButton size="small" sx={{ color: 'action.active' }}>
                            <EditOutlined />
                        </IconButton>
                    </Stack>
                </Grid>
            </Grid>
            {!profile.enterpriseIdp && (
                <Grid
                    container
                    alignItems="center"
                    sx={{
                        m: 0,
                        '& .MuiGrid-item': {
                            p: 2,
                            '&:last-child': {
                                py: 1,
                            },
                        },
                        boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12);',
                    }}
                >
                    <Grid item xs={2}>
                        <Typography variant="subtitle2">{t('profile.profile.password')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Button variant="link" color="primary" onClick={onOpenChangePassword}>
                            <Typography variant="body2">{t('profile.profile.changePassword')}</Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Stack direction="row" justifyContent="flex-end">
                            <IconButton size="small" sx={{ color: 'action.active' }} onClick={onOpenChangePassword}>
                                <LaunchOutlined />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            )}
            <Grid
                container
                alignItems="center"
                sx={{
                    m: 0,
                    '& .MuiGrid-item': {
                        p: 2,
                    },
                }}
            >
                <Grid item xs={2}>
                    <Typography variant="subtitle2">{t('profile.profile.timezone')}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <KBTimezoneSelect priority={browserTimezone} value={profile.userTimezone} onChange={setTimezone} />
                </Grid>
            </Grid>

            {/* <Grid
                container
                alignItems="center"
                sx={{
                    m: 0,
                    '& .MuiGrid-item': {
                        p: 2,
                    },
                }}
            >
                <Grid item xs={2}>
                    <Typography variant="subtitle2">{t('profile.profile.language')}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Select
                        variant="standard"
                        value={profile.userLanguage}
                        onChange={(event: SelectChangeEvent) => setLanguage(event.target.value)}
                    >
                        {supportedLanguages.map((lang) => (
                            <MenuItem key={lang.code} value={lang.code}>
                                {lang.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid> */}
        </Stack>
    )
})
