import { KBDrawer } from '@kibsi/ui-components'
import { ReactElement } from 'react'
import InviteUser from './InviteUser'

type InviteUserPanelProps = {
    open: boolean
    onClose: () => void
}

export function InviteUserPanel({ open, onClose }: InviteUserPanelProps): ReactElement {
    return (
        <KBDrawer open={open} variant="persistent" belowToolbar>
            <InviteUser onClose={onClose} />
        </KBDrawer>
    )
}
