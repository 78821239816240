import type { User as UserDto } from '@kibsi/ks-tenant-types'
import { inject, injectable } from 'inversify'
import T from 'config/inversify.types'
import type { UserService } from 'service/user'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from 'store/domain'
import { User } from './user'

@injectable()
export class UserStore {
    private users: AsyncDomainStore<User, UserDto>

    constructor(@inject(T.UserService) private service: UserService) {
        this.users = new AsyncFromDtoDomainStore<UserDto, User>(
            (id) => service.get(id),
            (_, data) => new User(data, service),
        )
    }

    async loadList(): Promise<User[]> {
        const dtos = await this.service.list()

        return this.users.setAll(dtos.map((dto) => [dto.userId, dto]))
    }

    async loadSelf(): Promise<User> {
        const dto = await this.service.getSelf()

        const self = this.users.set(dto.userId, dto)
        self.isSelf = true

        return self
    }

    async inviteUser(email: string, userName?: string): Promise<UserDto> {
        const dto = await this.service.create({ email, userName })

        return this.users.set(dto.userId, dto)
    }

    async deleteUser(userId: string): Promise<void> {
        await this.service.delete(userId)
        this.users.delete(userId)
    }
}
