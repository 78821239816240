import { Box, Typography, Stack, SvgIconProps } from '@mui/material'
import { FunctionComponent, ReactElement, ReactNode } from 'react'
import { useDisabledOpacity } from '../../hooks'

export type EmptyApplicationsMessageProps = {
    icon?: FunctionComponent<SvgIconProps>
    image?: string
    imageAlt: string
    title?: string
    message?: string
    width?: string | number
    disabled?: boolean
    children?: ReactNode
}

export function KBImageMessage({
    icon: Icon,
    image,
    title = '',
    message = '',
    imageAlt,
    width = '230px',
    disabled,
    children,
}: EmptyApplicationsMessageProps): ReactElement {
    const opacity = useDisabledOpacity(disabled)

    return (
        <Stack sx={{ width, opacity }} justifyContent="center" alignItems="center">
            {Icon && <Icon sx={{ fontSize: '30px', color: 'action.active' }} />}
            {image && <Box component="img" alt={imageAlt} src={image} />}
            <Typography sx={{ mt: 2 }} variant="h6" align="center" lineHeight="32px">
                {title}
            </Typography>
            <Typography sx={{ mt: 2 }} variant="body1" align="center" color="text.secondary">
                {message}
            </Typography>
            {children && children}
        </Stack>
    )
}
