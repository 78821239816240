import { User } from 'store/user'
import { Status, usePromiseState, usePromiseValue } from '../usePromiseState'
import { useUserStore } from './useUserStore'

export function useSelfUser(): [Status, User | undefined, () => void] {
    const store = useUserStore()
    const [status, setStatus] = usePromiseState(() => store.loadSelf())
    const user = usePromiseValue(status, undefined)

    const refresh = () => {
        setStatus(store.loadSelf())
    }

    return [status, user, refresh]
}
