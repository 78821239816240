import { GlobalStyles } from '@mui/material'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto-mono/400.css'

/**
 * Global CSS Overrides
 */
export const globalStyles = (
    <GlobalStyles
        styles={(theme) => ({
            html: {
                height: '100%',
            },
            body: {
                height: '100%',
                fontFamily: 'Roboto',
                backgroundColor: theme.palette.grey[50],
            },
            '.MuiSwitch-switchBase:not(.Mui-checked) .MuiSwitch-thumb': {
                color: theme.palette.offWhite.main,
                // NOTE: we cannot simply use boxShadow: 1 here for some reason
                // ALSO: figma calls for two box shadows of 1, but this would require another element or pseudo-element; we'll simplify and use a value of 2
                boxShadow: theme.shadows[2],
            },
        })}
    />
)
