import { ReactElement, useMemo, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Outlet } from 'react-router-dom'
import { AppBar } from './components/navigation/app-bar'
import { AppBarContext, AppBarContextValue } from './components/navigation/app-bar/AppBar.context'
import { AppBarSettings } from './components/navigation/app-bar/AppBarSettings'

/**
 * The main ReactElement that holds all the render elements of the App
 *
 * @returns {ReactElement} <App />
 */
export function App(): ReactElement {
    const theme = useTheme()
    const [settings] = useState(() => new AppBarSettings())
    const fabRef = useRef<HTMLDivElement>(null)

    const appBarContextValue: AppBarContextValue = useMemo(
        () => ({
            fabRef,
            settings,
        }),
        [settings],
    )

    return (
        <Box display="flex" flexDirection="row" sx={{ height: '100vh', width: '100vw' }}>
            <Box flexGrow={0}>
                <AppBar settings={settings} fabRef={fabRef} />
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    background: theme.palette.grey[50],
                    overflowY: 'auto',
                }}
                flexGrow={1}
            >
                {/* renders all the children of <App> see Routes.tsx */}
                <AppBarContext.Provider value={appBarContextValue}>
                    <Outlet />
                </AppBarContext.Provider>
            </Box>
        </Box>
    )
}
