import { useCallback } from 'react'
import { IdToken, useAuth0 } from '@auth0/auth0-react'
import logger from 'logging/logger'
import { useInjection } from 'inversify-react'
import { AuthConfig } from 'auth/auth.config'
import T from 'config/inversify.types'

const checkAuthTime = (idTokenClaims: IdToken | undefined, authTime?: number): boolean => {
    if (!authTime) {
        // if no authTime specified, then no need to validate the id token auth time
        return true
    }

    logger.debug(`validating with auth time with value ${authTime}`)
    const idAuthTime = idTokenClaims?.auth_time as string
    if (idAuthTime) {
        const currentEpochSeconds = Date.now() / 1000
        if (currentEpochSeconds - parseInt(idAuthTime, 10) < authTime) {
            return true
        }
    }

    logger.info(`need to re-authorize.  Auth Time: ${authTime}, Id Auth Time ${idAuthTime}`)

    return false
}

export const useAuthCheck = (authTime?: number): (() => Promise<boolean>) => {
    const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0()
    const config = useInjection<AuthConfig>(T.AuthConfig)

    const authCheck = useCallback(async () => {
        const token = isAuthenticated ? await getAccessTokenSilently() : undefined

        const success = await config.checkAuthContext(token)
        const isAuthTimeValid = checkAuthTime(await getIdTokenClaims(), authTime)

        if (success && isAuthTimeValid) {
            // user is already authenticated and the subdomain matches the tenant
            // nothing left to do
            return true
        }

        return false
    }, [authTime, config, getAccessTokenSilently, getIdTokenClaims, isAuthenticated])

    return authCheck
}
