import { EdgeDeviceKibsiClient, PaginationRequest } from '@kibsi/ks-client-sdk'
import { Device, DeviceUpdate } from '@kibsi/ks-tenant-types'
import { inject, injectable } from 'inversify'
import log from 'logging/logger'
import { devices } from 'service/api/mocks/devices.mock'
import { RequestStore } from 'store/request.store'
import TYPES from '../../config/inversify.types'
import { DeviceService } from './device.service'

export type DeviceApi = Pick<InstanceType<typeof EdgeDeviceKibsiClient>, 'list' | 'claimDevice' | 'update'>

@injectable()
export class DeviceServiceImpl implements DeviceService {
    constructor(
        @inject(TYPES.RequestStore) private req: RequestStore,
        @inject(TYPES.DeviceApi) private api: DeviceApi,
    ) {}

    async list(siteId: string, request: PaginationRequest<Partial<Device>>): Promise<Device[]> {
        return this.req.once(`device.list.${siteId}`, async () => {
            const { results } = await this.api.list({ ...request, criteria: { siteId } })
            return results
        })
    }

    async get(id: string): Promise<Device> {
        const device = devices.find((d) => d.id === id)

        if (!device) {
            throw Error('not found ')
        }

        return device
    }

    async claim(siteId: string, code: string): Promise<Device> {
        log.info('claiming device', siteId, code)
        return this.api.claimDevice({ siteId, code })
    }

    async update(update: DeviceUpdate): Promise<Device> {
        return this.api.update(update)
    }

    async delete(deviceId: string, cascade?: boolean | undefined): Promise<void> {
        log.info('deleting', deviceId, cascade)
    }
}
