import { useCallback } from 'react'

export type RedirectTarget = '_blank'

export function useRedirect(): (url: string, target?: RedirectTarget) => void {
    return useCallback((url: string, target?: RedirectTarget) => {
        if (target) {
            window.open(url, target)
            return
        }
        window.location.href = url
    }, [])
}
