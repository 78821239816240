import { ReactElement, useCallback, useState } from 'react'
import { Action, DependencyGraph } from '@kibsi/ks-dependency-types'
import { observer } from 'mobx-react-lite'
import { ConfirmationDialog } from './ConfirmationDialog'
import { useDeleteDialogContent } from '../../hooks/delete/useDeleteDialogContent'
import { usePromiseStateLater } from '../../hooks/usePromiseState'
import { KibsiHttpError } from '../../service/api/http.error'

export type DeleteDialogProps = {
    open: boolean
    name?: string
    msg?: string
    deleteFn: (cascade?: boolean) => Promise<void>
    onCancel: () => void
    onDeleted: () => void
    action?: Action
}

export const DeleteDialog = observer(function DeleteDialog({
    open,
    name,
    msg,
    onCancel,
    deleteFn,
    onDeleted,
    action,
}: DeleteDialogProps): ReactElement {
    const [dependencyGraph, setDependencyGraph] = useState<DependencyGraph>()
    const { title, message, confirm, cancel } = useDeleteDialogContent(name || '', msg, dependencyGraph, action)
    const [status, setStatus] = usePromiseStateLater((error: KibsiHttpError) => {
        // confirm if its dependency graph structure
        const dg = error.additionalDetails as DependencyGraph
        if (dg.id && dg.entityType) {
            setDependencyGraph(dg)
        } else {
            throw error
        }
    })

    const deleteAction = useCallback(() => {
        const promise = deleteFn(dependencyGraph?.action === 'cascade' || dependencyGraph?.action === 'orphan').then(
            () => onDeleted(),
        )
        setStatus(promise)
        return promise
    }, [deleteFn, dependencyGraph?.action, onDeleted, setStatus])

    const confirmDelete = async () => {
        if ((dependencyGraph && dependencyGraph.action === 'block') || action === 'block') {
            onCancel()
            return
        }

        await deleteAction()
    }

    return (
        <ConfirmationDialog
            open={open}
            title={title}
            message={message}
            confirm={confirm}
            cancel={cancel}
            confirming={status?.state === 'pending'}
            onCancel={onCancel}
            onConfirm={confirmDelete}
        />
    )
})
