import { PaginationRequest, PaginationResponse } from '@kibsi/ks-client-sdk'
import { Status } from 'hooks/usePromiseState'
import { makeAutoObservable, runInAction } from 'mobx'
import { fromPromise } from 'util/mobx'
import { Paginator, PageAction } from './interfaces'

export class PaginatorImpl<T, C> implements Paginator<T, C> {
    private complete = false
    private req?: PaginationRequest<C>
    private token?: string
    private promise?: Status<T[]>

    constructor(private fetch: PageAction<T, C>) {
        makeAutoObservable(this)
    }

    get status(): Status<T[]> | undefined {
        return this.promise
    }

    get isComplete(): boolean {
        return this.complete
    }

    get isLoading(): boolean {
        return this.pending !== undefined
    }

    set request(value: PaginationRequest<C>) {
        this.req = {
            ...this.req,
            ...value,
        }

        this.complete = false
        this.token = undefined
        this.pending = undefined
    }

    async more(): Promise<T[]> {
        if (this.complete) {
            return []
        }

        if (this.promise === undefined) {
            this.promise = fromPromise(this.getPage())

            try {
                return await this.promise
            } finally {
                runInAction(() => {
                    this.promise = undefined
                })
            }
        }

        return this.promise
    }

    private get pending(): Status<T[]> | undefined {
        return this.promise
    }

    private set pending(value: Status<T[]> | undefined) {
        this.promise = value
    }

    private async getPage(): Promise<T[]> {
        const req: PaginationRequest<C> = {
            ...this.req,
            continuationToken: this.token,
        }

        const page = await this.fetch(req)

        this.addPage(page)

        return page.results
    }

    private addPage(page: PaginationResponse<T>): void {
        this.token = page.nextContinuationToken
        this.complete = this.token === undefined
    }
}
