import { ChangeEvent, ReactElement } from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { ThemeMode } from '../../hooks'

interface ThemeSelectProps {
    themeMode: ThemeMode
    onChange: (themeMode: ThemeMode) => void
}

export function ThemeSelect({ themeMode, onChange }: ThemeSelectProps): ReactElement {
    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value as ThemeMode)
    }

    return (
        <FormControl>
            <RadioGroup sx={{ m: 1 }} row value={themeMode} onChange={onChangeHandler}>
                <FormControlLabel value={ThemeMode.Light} control={<Radio />} label="Light" />
                <FormControlLabel value={ThemeMode.Dark} control={<Radio />} label="Dark" />
                <FormControlLabel value={ThemeMode.Auto} control={<Radio />} label="Auto" />
            </RadioGroup>
        </FormControl>
    )
}
