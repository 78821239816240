import type { ApplicationCreate as AppEditable, Audit } from '@kibsi/ks-application-types'
import { comparer, makeAutoObservable, reaction, runInAction, toJS } from 'mobx'
import { IDisposer } from 'mobx-utils'
import { fromPromise } from 'util/mobx'
import { Application as AppDto } from '@kibsi/ks-ui-types'
import { Tag } from 'model/tag'
import type { ApplicationService } from 'service'
import { ResourceType } from '@kibsi/ks-search-tags-types'
import type { FromDto, ToDto } from '../interfaces'
import { initStatus } from '../utils'
import { AppHistory } from './history'
import { TaggableResource } from '../tag/tag.store'
import { tagsFromDto } from '../tag/util'

export class Application implements ToDto<AppDto>, FromDto<AppDto>, TaggableResource {
    private reactions: IDisposer[] = []
    private skipUpdate = false
    private skipHistory = false
    readonly tags = new Map<string, Tag>()
    readonly resourceType: ResourceType = 'application'

    readonly id: string

    status = initStatus()

    constructor(private dto: AppDto, private service: ApplicationService, readonly history: AppHistory) {
        this.id = dto.id
        tagsFromDto(this.tags, dto)

        makeAutoObservable<Application, 'service' | 'reactions'>(this, {
            id: false,
            service: false,
            reactions: false,
        })

        this.bindReactions()
    }

    get name(): string {
        return this.dto.name
    }

    set name(name: string) {
        this.dto.name = name
    }

    get description(): string | undefined {
        return this.dto.description
    }

    set description(desc: string | undefined) {
        this.dto.description = desc
    }

    get created(): Audit {
        return this.dto.created
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    get editable(): AppEditable {
        return {
            name: this.name,
            description: this.description,
        }
    }

    get deploymentCounts(): number {
        return this.dto.deploymentCounts ?? 0
    }

    get isPublished(): boolean {
        return this.dto.isPublished
    }

    toDto(): AppDto {
        return toJS(this.dto)
    }

    fromDto(dto: AppDto): void {
        this.dto = { ...dto, id: this.id }
        tagsFromDto(this.tags, dto)
        this.skipUpdate = true
        this.skipHistory = true
    }

    private bindReactions(): void {
        this.reactions.push(
            // update the service and history when name or desc changes
            reaction(
                () => this.editable,
                (partial) => {
                    if (!this.skipUpdate) {
                        this.update(partial).catch(() => {})
                    }

                    if (!this.skipHistory) {
                        // don't update if the source of the change was triggerd by history
                        this.history.push({ app: partial })
                    }
                },
            ),
            // listen for changes in history
            reaction(
                () => ({
                    source: this.history.source,
                    app: this.history.current?.app,
                }),
                ({ source, app }) => {
                    if (['undo', 'redo'].includes(source) && app) {
                        runInAction(() => {
                            this.fromDto({ ...this.dto, ...app })
                            this.skipUpdate = false
                        })
                    }
                },
                {
                    equals: comparer.structural,
                },
            ),
            reaction(
                () => this.skipUpdate || this.skipHistory,
                (skip) => {
                    if (skip) {
                        runInAction(() => {
                            this.skipUpdate = false
                            this.skipHistory = false
                        })
                    }
                },
            ),
        )
    }

    private async update(dto: AppEditable): Promise<void> {
        const action = this.service.update({ ...dto, id: this.id })

        this.status = fromPromise(action, this.status)

        const resp = await action

        runInAction(() => {
            this.dto.lastUpdated = resp.lastUpdated
        })
    }
}
