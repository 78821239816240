import { useMemo } from 'react'
import type { Timezone } from './types'
import { useAvailableTimezones } from './useAvailableTimezones'
import { toTimezone } from './utils'

export const usePriorityTimezones = (priority?: string | string[]): [Timezone[], Timezone[]] => {
    const timezones = useAvailableTimezones()

    const [priorityList, restList] = useMemo<[Timezone[], Timezone[]]>(() => {
        const getPriorityList = () => {
            if (!priority) {
                return []
            }

            return Array.isArray(priority) ? priority : [priority]
        }

        const priorityStrList: string[] = getPriorityList()

        const priorityZoneData = priorityStrList.map(toTimezone)

        const filteredTimeZones = timezones.filter((tz) => !priorityStrList.includes(tz))
        const zonesMetadata = filteredTimeZones
            .map(toTimezone)
            .sort((a: Timezone, b: Timezone) => a.offset - b.offset || a.label.localeCompare(b.label))

        return [priorityZoneData, zonesMetadata]
    }, [priority, timezones])

    return [priorityList, restList]
}
