import { DataGrid, DataGridProps } from '@mui/x-data-grid'
import { ReactElement } from 'react'
import { alpha, useTheme } from '@mui/material/styles'

export type KBDataGridProps = {
    selectionEnabled: boolean
} & DataGridProps

export function KBDataGrid({ selectionEnabled = false, sx, ...props }: KBDataGridProps): ReactElement {
    const theme = useTheme()

    const hoverColor = alpha(theme.palette.highlight.main, 0.1)

    const hoverStyle = {
        backgroundColor: hoverColor,
        boxShadow: `inset 0px 0px 0px 1px ${theme.palette.highlight.main};`,
    }

    const selectionStyle = {
        backgroundColor: 'transparent',
    }

    const style = {
        border: 0,
        backgroundColor: 'white',
        '.MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-row:nth-of-type(even)': {
            backgroundColor: theme.palette.grey[50],
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottomWidth: 0,
        },
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
        },
        '& .MuiDataGrid-row:hover': hoverStyle,
        '& .MuiDataGrid-row.Mui-selected': {
            ...(selectionEnabled ? hoverStyle : selectionStyle),
            '&:hover': {
                backgroundColor: hoverColor,
            },
        },
        ...sx,
    }

    return <DataGrid sx={style} density="compact" autoHeight disableColumnMenu hideFooter {...props} />
}
