import { ContainerModule } from 'inversify'
import { RealtimeDataStore, SnapshotManager, VdbClientManager } from 'store/realtime-data'
import TYPES from '../../config/inversify.types'
import { RealtimeDataServiceImpl } from './realtime-data.service.impl'

export const RealtimeDataModule = new ContainerModule((bind) => {
    bind(TYPES.RealtimeDataService).to(RealtimeDataServiceImpl).inSingletonScope()
    bind(TYPES.RealtimeDataStore).to(RealtimeDataStore).inSingletonScope()
    bind(TYPES.SnapshotManager).to(SnapshotManager).inSingletonScope()
    bind(TYPES.VdbManager).to(VdbClientManager).inSingletonScope()
})
