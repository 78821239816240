import { CustomArchitecture, Yolov5 } from '@kibsi/ks-application-types'

export type ArchitectureKey = Yolov5 | CustomArchitecture

export type ModelArchitecture = 'yolov5'

export type ArchitectureItem = {
    id: ModelArchitecture
    name: string
}
export const DEFAULT_ARCHITECTURES: ArchitectureItem[] = [
    {
        id: 'yolov5',
        name: 'YOLOv5',
    },
]
