import { inject, injectable } from 'inversify'
import { RealtimeDataKibsiClient } from '@kibsi/ks-client-sdk'
import type { DeploymentARTSnapshot, Subscription, SubscriptionCreate } from '@kibsi/ks-realtime-data-types'
import type { RealtimeDataService } from './realtime-data.service'
import type { RequestStore } from '../../store/request.store'

import TYPES from '../../config/inversify.types'

export type RealtimeDataApi = Pick<
    InstanceType<typeof RealtimeDataKibsiClient>,
    'endpoint' | 'createSubscription' | 'deploymentSnapshot'
>

@injectable()
export class RealtimeDataServiceImpl implements RealtimeDataService {
    constructor(
        @inject(TYPES.RequestStore) private req: RequestStore,
        @inject(TYPES.RealtimeDataApi) private api: RealtimeDataApi,
    ) {}

    endpoint(): Promise<string> {
        return this.req.once('realtime-data.endpoint', async () => {
            const { endpoint } = await this.api.endpoint()
            return endpoint
        })
    }

    createSubscription(subscription: SubscriptionCreate): Promise<Subscription> {
        return this.req.once('realtime-data.createSubscription', async () => this.api.createSubscription(subscription))
    }

    deploymentSnapshot(deploymentId: string, itemType?: string): Promise<DeploymentARTSnapshot> {
        return this.req.once(`realtime-data.deploymentSnapShot.${deploymentId}.${itemType}`, () =>
            this.api.deploymentSnapshot(deploymentId, itemType),
        )
    }
}
