import { ReactElement } from 'react'
import { Box, BoxProps, Divider, IconButton, Stack, Typography } from '@mui/material'
import { ClearAllOutlined, HelpOutline } from '@mui/icons-material'
import { asSxArray, KBStandardButton } from '@kibsi/ui-components'
import { useTranslation } from 'react-i18next'
import { ErrorItem } from './ErrorItem'
import { KibsiError } from '../../store/error/error'

export type ErrorListProps = {
    errors?: KibsiError[]
    onGetHelpClicked?: () => void
    onClear?: () => void
} & BoxProps

export function ErrorList({ errors = [], onClear, onGetHelpClicked, sx, ...props }: ErrorListProps): ReactElement {
    const { t } = useTranslation()
    const width = 476
    const listMaxHeight = 500

    return (
        <Box sx={[{ width }, ...asSxArray(sx)]} {...props}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mx: 2, my: 1.5 }}>
                <Typography sx={{ ml: 0.5 }}>{t('error.errorMessages')}</Typography>
                <IconButton size="small" onClick={onClear} data-testid="error-list-clear-button">
                    <ClearAllOutlined />
                </IconButton>
            </Stack>

            <Divider />

            <Box sx={{ maxHeight: listMaxHeight, overflowY: 'auto' }}>
                {errors.map((error) => (
                    <ErrorItem key={error.date.getTime()} error={error} data-testid="error-item" />
                ))}
            </Box>

            <Divider />

            <Stack direction="row" sx={{ mx: 2, my: 1.5 }} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    <HelpOutline sx={{ color: 'info.main' }} />
                    <Typography variant="body1">{t('support.weAreHere')}</Typography>
                </Stack>

                <KBStandardButton size="small" onClick={onGetHelpClicked} sx={{ mt: 0 }} data-testid="get-help-button">
                    {t('support.getHelp')}
                </KBStandardButton>
            </Stack>
        </Box>
    )
}
