import { Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { ReactElement } from 'react'

export type KBTextButtonProps = {
    /**
     * Button Title
     */
    title?: string
    color?: string
    onClick?: () => void
}

export function KBTextButton({ title = '', color = '#000000', onClick }: KBTextButtonProps): ReactElement {
    const hoverColor = color.includes('text') ? '' : alpha(color, 0.5)
    const style = {
        color,
        cursor: 'pointer',
        '&:hover': {
            color: hoverColor,
        },
    }

    return (
        <Typography onClick={onClick} sx={style} variant="button" textTransform="none" noWrap>
            {title}
        </Typography>
    )
}
