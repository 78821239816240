import { SpeedDial, SpeedDialAction, SpeedDialIcon, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ReactElement, ReactNode, useState } from 'react'

export type KBSpeedDialAction = {
    icon: ReactNode
    name: string
    action?: () => void
}

export type KBSpeedDialProps = {
    ariaLabel: string
    actions?: KBSpeedDialAction[]
    sx?: SxProps<Theme>
}

export function KBSpeedDial({ ariaLabel, actions = [], sx }: KBSpeedDialProps): ReactElement {
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(!open)
    }

    return (
        <SpeedDial
            direction="right"
            ariaLabel={ariaLabel}
            icon={<SpeedDialIcon />}
            sx={{
                ...sx,
            }}
            onClick={handleOpen}
            open={open}
        >
            {actions.map(({ icon, name, action }) => (
                <SpeedDialAction
                    sx={{ color: 'primary' }}
                    key={name}
                    icon={icon}
                    tooltipTitle={name}
                    FabProps={{
                        sx: {
                            color: (theme: Theme) => theme.palette.grey[500],
                        },
                    }}
                    onClick={() => action?.()}
                />
            ))}
        </SpeedDial>
    )
}
