import { ReactElement, ReactNode, Ref } from 'react'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Close } from '@mui/icons-material'

export declare type KBDrawerSize = 'small' | 'medium' | 'large'

export type KBDrawerContainerProps = {
    size?: KBDrawerSize
    showHeader?: boolean
    header?: ReactElement
    subheader?: string
    description?: string
    children?: ReactNode
    footer?: ReactElement
    padding?: string | number
    topRightIcon?: ReactElement
    topRightAnchor?: Ref<HTMLButtonElement>
    onTopRightIconClicked?: () => void
    'data-testid'?: string
}

export function KBDrawerContainer({
    size = 'small',
    showHeader = true,
    header,
    subheader,
    description,
    children,
    footer,
    padding = 3,
    topRightIcon = <Close />,
    topRightAnchor,
    onTopRightIconClicked,
    'data-testid': dataTestId,
}: KBDrawerContainerProps): ReactElement {
    const theme = useTheme()
    const footerHeight = '85px'

    const resolveSize = (drawerSize: KBDrawerSize): string => {
        let width
        switch (drawerSize) {
            case 'small':
                width = theme.drawer.small
                break
            case 'medium':
                width = theme.drawer.medium
                break
            case 'large':
                width = theme.drawer.large
                break
            default:
                width = drawerSize
                break
        }
        return width
    }

    return (
        <Stack data-testid={dataTestId} sx={{ width: resolveSize(size), overflow: 'hidden' }}>
            {/* header */}
            {showHeader && (
                <Stack data-testid="kb-header" flex={0} sx={{ width: '100%' }}>
                    <Box
                        sx={{
                            pt: padding,
                            px: padding,
                            pb: 2,
                        }}
                    >
                        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                            {header}
                            <IconButton
                                ref={topRightAnchor}
                                onClick={onTopRightIconClicked}
                                data-testid="drawer-close-btn"
                            >
                                {topRightIcon}
                            </IconButton>
                        </Stack>

                        {subheader && (
                            <Typography variant="h5" sx={{ mt: 2 }}>
                                {subheader}
                            </Typography>
                        )}

                        {description && (
                            <Typography variant="subtitle1" sx={{ mt: 2, color: 'text.secondary' }}>
                                {description}
                            </Typography>
                        )}
                    </Box>
                </Stack>
            )}

            {/* body */}
            <Stack data-testid="kb-body" flex={1} sx={{ px: padding, overflowY: 'auto', overflowX: 'hidden' }}>
                <Stack flex={1} overflow="auto" sx={{ overflowX: 'hidden' }}>
                    {children}
                </Stack>
            </Stack>

            {/* footer */}
            <Stack
                data-testid="kb-footer"
                flex={0}
                justifyContent="flex-end"
                sx={{
                    height: footerHeight,
                    padding: 3,
                }}
            >
                {footer}
            </Stack>
        </Stack>
    )
}
