import { AppBar, Box, Toolbar } from '@mui/material'
import { FunctionComponent, ReactElement, ReactNode } from 'react'
import { KBNavItemProps } from '../nav-item'

export type KBNavigation = {
    title: string
    path: string
}

export type KBNavigationHandler = (page: KBNavigation) => void

export type KBNavItemElementBuilder = (active: boolean) => ReactElement

export type KBAppBarProps = {
    /**
     * List of KBNavigation items
     */
    navigations?: KBNavigation[]

    /**
     * Add something to the left part of the app bar
     */
    startElement?: ReactElement

    /**
     * AppBar background color
     */
    color?: string

    /**
     * How navigation objects are rendered
     */
    navItem?: FunctionComponent<KBNavItemProps>

    /**
     * Provide your own custom NavItems if you don't want to provide a list of KBNavigation items
     */
    children?: ReactNode

    /**
     * Elevation
     */
    elevation?: number
}

export function KBAppBar({
    navigations = [],
    // onNavItemClicked = () => {},
    startElement,
    color = 'white',
    navItem: NavItem,
    elevation = 1,
    children,
}: KBAppBarProps): ReactElement {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                elevation={elevation}
                sx={{
                    position: 'fixed',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: color,
                }}
            >
                <Toolbar disableGutters sx={{ pl: '10px', pr: '10px' }} variant="dense">
                    {startElement && startElement}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'flex' } }}>
                        <Box display="flex">
                            {NavItem &&
                                navigations?.map((navigation) => (
                                    // without a span here, we are unable to pass data-testid
                                    <span key={navigation.title} data-testid={navigation.path}>
                                        <NavItem path={`/${navigation.path}`}>{navigation.title}</NavItem>
                                    </span>
                                ))}
                            {children && children}
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
}
