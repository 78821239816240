export type SocketConnection = {
    connected: boolean
    code?: number
    reason?: string
}

export const SOCKET_CONNECTION_EVENT = 'socket/connection'
export const SOCKET_MESSAGE_EVENT = 'socket/message'

export type SocketConnectionEvent = typeof SOCKET_CONNECTION_EVENT
export type SocketConnectionListener = (connection: SocketConnection) => void

export type SocketMessageEvent = typeof SOCKET_MESSAGE_EVENT
export type SocketMessageListener = (message: unknown) => void

export interface SocketService {
    host: string | undefined

    connect(host: string): void
    disconnect(): void
    socket(): WebSocket | undefined

    listen(event: SocketConnectionEvent, listener: SocketConnectionListener): void
    listen(event: SocketMessageEvent, listener: SocketMessageListener): void

    removeListener(event: SocketConnectionEvent, listener: SocketConnectionListener): void
    removeListener(event: SocketMessageEvent, listener: SocketMessageListener): void
}
