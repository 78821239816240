import { Stack, StackProps } from '@mui/material'
import { ReactElement, ReactNode } from 'react'

export type KBDrawerFooterProps = {
    children: ReactNode
    justifyContent?: 'space-between' | 'start' | 'end' | 'flex-end'
    padding?: number
} & StackProps

export function KBDrawerFooter({
    children,
    justifyContent = 'space-between',
    ...props
}: KBDrawerFooterProps): ReactElement {
    return (
        <Stack direction="column" justifyContent={justifyContent} alignItems="flex-start" {...props}>
            {children}
        </Stack>
    )
}
