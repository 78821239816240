import { ReactElement } from 'react'
import { Avatar, AvatarProps } from '@mui/material'
import { useDisabledOpacity } from '@kibsi/ui-components'

export type IconAvatarProps = {
    color?: string
    size?: number
    disabled?: boolean
} & AvatarProps

export function IconAvatar({ size = 40, color, disabled, sx, children, ...props }: IconAvatarProps): ReactElement {
    const opacity = useDisabledOpacity(disabled)

    return (
        <Avatar
            sx={{
                width: size,
                height: size,
                mr: 2,
                backgroundColor: color,
                fontSize: '0.9em',
                opacity,
                ...(disabled && { filter: 'grayscale(100%)' }),
                ...sx,
            }}
            imgProps={{ referrerPolicy: 'no-referrer' }}
            {...props}
        >
            {children}
        </Avatar>
    )
}
