export const MB_SIZE_INDEX = 2

export function formatBytes(bytes: number, decimals = 2): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const [value, i] = bytesToSize(bytes)
    const size = sizes[i]

    if (value === 0) return `0 ${size}`
    const dm = decimals < 0 ? 0 : decimals
    return `${value.toFixed(dm)} ${sizes[i]}`
}

/**
 *
 * @param bytes
 * @param sizeIndex enforces to one of these sizes ie: 0: Bytes, 1: KB, 2: MB
 */
export function bytesToSize(bytes: number, sizeIndex?: number): [number, number] {
    if (bytes === 0) return [0, 0]
    const k = 1024
    const i = sizeIndex ?? Math.floor(Math.log(bytes) / Math.log(k))
    return [bytes / k ** i, i]
}
