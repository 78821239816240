import { Audit, ConnectedStatus, Device as DeviceDto, DeviceType } from '@kibsi/ks-tenant-types'
import { makeAutoObservable, toJS } from 'mobx'
import { DeviceService } from 'service/device'
import { FromDto, ToDto } from '../interfaces'

export type DeviceUpdate = {
    name: string
    description?: string
}

export class Device implements ToDto<DeviceDto>, FromDto<DeviceDto>, DeviceDto {
    readonly deviceId: string

    constructor(private dto: DeviceDto, private service: DeviceService) {
        this.deviceId = dto.id

        makeAutoObservable<Device, 'service' | 'reactions'>(this, {
            deviceId: false,
            service: false,
            reactions: false,
        })
    }

    get id(): string {
        return this.dto.id
    }

    get name(): string {
        return this.dto.name ?? 'new'
    }

    set name(newName: string | undefined) {
        this.dto.name = newName
    }

    get description(): string | undefined {
        return this.dto.description
    }

    set description(newDescription: string | undefined) {
        this.dto.description = newDescription
    }

    get type(): DeviceType {
        return this.dto.type
    }

    get siteId(): string | undefined {
        return this.dto.siteId
    }

    get connectedStatus(): ConnectedStatus {
        return this.dto.connectedStatus
    }

    get connectedTime(): string | undefined {
        return this.dto.connectedTime
    }

    get created(): Audit {
        return this.dto.created
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    toDto(): DeviceDto {
        return toJS(this.dto)
    }

    fromDto(dto: DeviceDto): void {
        this.dto = { ...dto, id: this.deviceId }
    }

    async update(update: DeviceUpdate): Promise<void> {
        const dto = await this.service.update({ id: this.deviceId, ...update })

        this.fromDto(dto)
    }
}
