import axios from 'axios'
import { useEffect, useState } from 'react'
import { ImageDataBuffer } from 'store/data-buffer'
import log from 'logging/logger'

export function useImageData(url?: string): ImageDataBuffer | undefined {
    const [buffer, setBuffer] = useState<ImageDataBuffer>()

    useEffect(() => {
        if (url && buffer === undefined) {
            axios
                .get(url, { responseType: 'arraybuffer' })
                .then(({ data }) => {
                    if (data instanceof ArrayBuffer) {
                        return new ImageDataBuffer(data)
                    }

                    throw new Error('unexpected response data')
                })
                .then(setBuffer)
                .catch((e) => {
                    log.error(e)
                })
        }
    }, [url, buffer])

    return buffer
}
