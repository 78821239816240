import { IDisposer } from 'mobx-utils'
import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { Range } from 'model/date'
import { Dimension } from 'util/dimension'
import { HeatmapOptions as HeatmapOptionsDto } from '@kibsi/ks-tenant-types'
import { HistoryService } from 'service/history'
import logger from '../../logging/logger'
import { HeatmapOptions } from './heatmap.options'
import { HeatmapValue } from './heatmap.value'

export class HeatmapData {
    private reactions: IDisposer[] = []
    startEnd?: Range
    values: HeatmapValue[] = []
    loading = false

    constructor(
        private heatmapId: string,
        private service: HistoryService,
        public heatmapOptions?: HeatmapOptions,
        public dimension?: Dimension,
    ) {
        this.startEnd = { start: 0, end: 0 }
        makeAutoObservable(this)
        this.bindReactions()
    }

    get dashboardId(): string {
        return this.heatmapId
    }

    private reactionUpdate() {
        return {
            ...this.heatmapOptions?.toDto(),
            range: this.range,
        }
    }

    set range(value: Range | undefined) {
        this.startEnd = value
    }

    get range(): Range | undefined {
        return this.startEnd
    }

    refresh(): void {
        this.updateState(this.heatmapOptions, this.range)
    }

    private updateState(options?: HeatmapOptions, r?: Range): void {
        if (!options) {
            return
        }

        const { floorPlanId, streamId, metric, deploymentIds, itemTypes, gridSize } = options
        if (
            (!floorPlanId && !streamId) ||
            !metric ||
            !r?.start ||
            !r.end ||
            itemTypes?.length === 0 ||
            deploymentIds?.length === 0
        ) {
            this.values = []
            return
        }

        this.loading = true
        this.values = this.values.filter((v) => itemTypes.includes(v.itemTypeId))

        itemTypes.forEach((itemTypeId, index) => {
            const hmv = this.getHeatmapValue(itemTypeId)
            hmv.loading = true

            const id = floorPlanId || streamId || ''

            this.service
                .floorPlanHeatmap(
                    id,
                    new Date(r.start).toISOString(),
                    new Date(r.end).toISOString(),
                    {
                        metric,
                        deploymentIds,
                        itemTypes: [itemTypeId],
                        grid: gridSize || 'small',
                    },
                    floorPlanId ? 'floorPlan' : 'stream',
                )
                .then(({ heatmap, gridPixels }) => {
                    runInAction(() => {
                        if (hmv) {
                            hmv.loading = false
                            hmv.gridValues = heatmap
                            hmv.gridPixels = gridPixels
                        }

                        if (index === itemTypes.length - 1) {
                            this.loading = false
                        }
                    })
                })
                .catch((e) => {
                    logger.error(e)
                    runInAction(() => {
                        this.loading = false
                    })
                })
        })
    }

    private getHeatmapValue(itemTypeId: string): HeatmapValue {
        let value = this.values.find((v) => v.itemTypeId === itemTypeId)
        if (!value) {
            value = new HeatmapValue(itemTypeId, this.dimension)
            this.values.push(value)
        }
        return value
    }

    private bindReactions(): void {
        this.reactions.push(
            reaction(
                () => this.reactionUpdate(),
                ({ range, ...options }) => {
                    if (!options) {
                        return
                    }
                    this.updateState(new HeatmapOptions(options as HeatmapOptionsDto), range)
                },
            ),
        )
    }
}
