import { Device } from '@kibsi/ks-tenant-types'

export const devices: Device[] = [
    {
        id: 'device:1',
        name: 'Device 1',
        description: 'Device 1 Description',
        siteId: 'site1',
        type: 'Jetson',
        connectedStatus: 'online',
        connectedTime: '2023-05-20 02:22:22.0',
        created: {
            principal: 'John Doe',
            timestamp: '2022-02-01 02:22:22.0',
        },
        lastUpdated: {
            principal: 'Jane Doe',
            timestamp: '2022-02-01 02:22:22.0',
        },
    },
    {
        id: 'device:2',
        name: 'Device 2',
        description: 'Device 2 Description',
        siteId: 'site1',
        type: 'Generic',
        connectedStatus: 'offline',
        created: {
            principal: 'John Doe',
            timestamp: '2022-02-01 02:22:22.0',
        },
        lastUpdated: {
            principal: 'Jane Doe',
            timestamp: '2022-02-01 02:22:22.0',
        },
    },
    {
        id: 'device:3',
        name: 'Device 3',
        description: 'Device 3 Description',
        siteId: 'site1',
        type: 'Jetson',
        connectedStatus: 'provisioning',
        created: {
            principal: 'John Doe',
            timestamp: '2022-02-01 02:22:22.0',
        },
        lastUpdated: {
            principal: 'Jane Doe',
            timestamp: '2022-02-01 02:22:22.0',
        },
    },
    {
        id: 'device:4',
        name: 'Device 4',
        description: 'Device 4 Description',
        siteId: 'site1',
        type: 'Generic',
        connectedStatus: 'error',
        created: {
            principal: 'John Doe',
            timestamp: '2022-02-01 02:22:22.0',
        },
        lastUpdated: {
            principal: 'Jane Doe',
            timestamp: '2022-02-01 02:22:22.0',
        },
    },
]
