import { globalStyles } from '@kibsi/ui-components'
import CssBaseline from '@mui/material/CssBaseline'
import { Provider as ContainerProvider } from 'inversify-react'
import { KPAuthProvider } from 'auth/KPAuthProvider'
import { ReactElement } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { ThemeModeProvider } from './context/theme-mode-provider'
import container from './config/inversify.config'

import '@jsplumbtoolkit/browser-ui/css/jsplumbtoolkit.css'
import { UnhandedErrorProvider } from './components/error/UnhandledErrorProvider'
import { ErrorPage } from './pages/error/ErrorPage'

function Fallback({ error }: FallbackProps) {
    return <ErrorPage error={error as Error} />
}

type BaseProps = {
    children: ReactElement
}

export function Base({ children }: BaseProps): ReactElement {
    return (
        <ThemeModeProvider>
            <ErrorBoundary FallbackComponent={Fallback}>
                <CssBaseline />
                {globalStyles}
                <ContainerProvider key={container.id} container={container}>
                    <UnhandedErrorProvider>
                        <KPAuthProvider>{children}</KPAuthProvider>
                    </UnhandedErrorProvider>
                </ContainerProvider>
            </ErrorBoundary>
        </ThemeModeProvider>
    )
}
