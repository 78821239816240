import { Stack, StackProps } from '@mui/material'
import { PropsWithChildren, ReactElement } from 'react'

export type KBPanelProps = {
    width?: string
    fullHeight?: boolean
} & StackProps

export function KBPanel({
    width = '150px',
    fullHeight = false,
    children,
    sx,
    ...props
}: PropsWithChildren<KBPanelProps>): ReactElement {
    return (
        <Stack sx={{ ...sx, width, height: fullHeight ? '100%' : 'auto' }} {...props}>
            {children}
        </Stack>
    )
}
