import { injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'

import { Application } from './application'
import { ApplicationDef } from './application.def'
import { EditableApplicationDef } from './application.def.editable'
import { AppVersion } from './version'

@injectable()
export class ApplicationManager {
    private app?: Application
    private appDef?: ApplicationDef
    private appVersion?: AppVersion

    constructor() {
        makeAutoObservable(this)
    }

    set application(value: Application | undefined) {
        if (this.app?.id !== value?.id) {
            this.appDef = undefined
            this.appVersion = undefined
        }

        this.app = value
    }

    get definition(): ApplicationDef | undefined {
        return this.appVersion?.applicationDefinition ?? this.appDef
    }

    set definition(value: ApplicationDef | undefined) {
        this.appDef = value
    }

    get version(): AppVersion | undefined {
        return this.appVersion
    }

    set version(value: AppVersion | undefined) {
        this.appVersion = value
    }

    get isReadonly(): boolean {
        return !this.isEditable
    }

    get isEditable(): boolean {
        return this.app !== undefined && this.appDef !== undefined && this.appVersion === undefined
    }

    get isEmpty(): boolean {
        const items = this.appDef?.itemTypes

        return items !== undefined && items.length === 0
    }

    restore(): void {
        const { appDef } = this
        const version = this.appVersion
        const versionAppDefDto = version?.versionDefinition?.definition

        if (appDef instanceof EditableApplicationDef && versionAppDefDto) {
            appDef.replace(versionAppDefDto)
        }

        this.appVersion = undefined
    }
}
