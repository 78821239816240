import { MappingPoint } from 'store/floor-plan'
import { Mat, findHomography, perspectiveTransform, CV_32FC2, RANSAC, matFromArray } from '@techstark/opencv-js'
import { Point } from 'model/draw'

/**
 * You can read more about findHomography here: https://scottsuhy.com/2021/02/01/image-alignment-feature-based-in-opencv-js-javascript/
 * findHomography returns a matrix that we can use to predict a destination point using perspectiveTransform
 *
 * @param src
 * @param dst
 * @returns Mat
 */
export function homographyMatrix(src: MappingPoint[] = [], dst: MappingPoint[] = []): Mat {
    const srcPoints = src?.map((m) => m.point).flat() || []
    const dstPoints = dst?.map((m) => m.point).flat() || []
    const srcMat = matFromArray(src.length, 1, CV_32FC2, srcPoints)
    const dstMat = matFromArray(dst.length, 1, CV_32FC2, dstPoints)

    const homography = findHomography(srcMat, dstMat, RANSAC)

    if (homography.empty()) {
        throw new Error('homography matrix is empty')
    }

    return homography
}

/**
 * If we have a homography generated from two sets of points, we can predict the location of a destination point using perspectiveTransform
 *
 * Example: We provide 4 sets of points for both a floor plan (src) and a snapshot (dst). It's important that the points are adjusted and
 * placed correctly, otherwise homography may return an empty matrix. homographyMatrix(floorPlanPoint[], streamPoint[]) will return a matrix
 * we can use to predict a destination point. Note that we provided floor plan points as the src when generating the matrix.  When calling
 * perspectiveTransformWithPoint(), you will need to pass in a source point into the function to predict the destination point. In our example,
 * we'll need to pass in a new floor plan point into the perspectiveTransform function to calculate the new destination point (stream point).
 * perspectiveTransformPoint(homographyMatrix(floorPlanPoint[], streamPoint[]), floorPlanPoint) => streamPoint
 *
 * @param homography homographyMatrix(floorPlanPoint[], streamPoint[])
 * @param point floorPlanPoint
 * @returns Point streamPoint
 */
export function perspectiveTransformWithPoint(homography: Mat, point: Point): Point {
    const pointMat = matFromArray(1, 1, CV_32FC2, point)
    const outputMat = new Mat(1, 1, CV_32FC2)
    perspectiveTransform(pointMat, outputMat, homography)

    return [outputMat.data32F[0], outputMat.data32F[1]]
}
