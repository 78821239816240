import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { useInjection } from 'inversify-react'
import { observer } from 'mobx-react-lite'
import TYPES from 'config/inversify.types'
import { ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { KibsiAuthConfigProvider } from 'service/api/kibsi.auth.config'
import { AuthConfig } from './auth.config'

type KPAuthProviderProps = {
    children: ReactElement
}

export function KPAuthProviderWrapper({ children }: KPAuthProviderProps): ReactElement {
    const configProvider = useInjection<KibsiAuthConfigProvider>(TYPES.KibsiApiConfig)

    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        configProvider.setTokenProvider(getAccessTokenSilently)
    }, [configProvider, getAccessTokenSilently])

    return children
}

function AuthProvider({ children }: KPAuthProviderProps): ReactElement {
    const config = useInjection<AuthConfig>(TYPES.AuthConfig)
    const navigate = useNavigate()

    const onRedirectCallback = (appState: AppState = {}) => {
        const { returnTo, ...state } = appState

        if (returnTo) {
            navigate(returnTo, { state })
        }
    }

    return (
        <Auth0Provider
            domain={config.authDomain}
            clientId={config.clientId}
            cacheLocation={config.cacheLocation}
            onRedirectCallback={onRedirectCallback}
            authorizationParams={{
                organization: config.org,
                scope: config.scope,
                audience: config.audience,
                redirect_uri: config.redirectUri,
            }}
        >
            <KPAuthProviderWrapper>{children}</KPAuthProviderWrapper>
        </Auth0Provider>
    )
}

export const KPAuthProvider = observer(AuthProvider)
