import { ReactElement } from 'react'
import { Dialog } from '@mui/material'
import { User } from 'store/user'
import { usePromiseStateLater } from 'hooks/usePromiseState'
import { UserPhotoEditor } from './Editor'
import { EditorHeader } from './EditorHeader'

export interface UserPhotoEditorDialogProps {
    user?: User
    onClose?: () => void
}

export function UserPhotoEditorDialog({ user, onClose }: UserPhotoEditorDialogProps): ReactElement {
    const [status, setStatus] = usePromiseStateLater()

    const onSave = (buffer: Buffer) => {
        setStatus(
            user?.updatePicture(buffer).then(() => {
                onClose?.()
            }),
        )
    }

    return (
        <Dialog maxWidth="sm" fullWidth open>
            <EditorHeader onClose={onClose} />

            <UserPhotoEditor
                userPhoto={user?.userPicture}
                loading={status?.state === 'pending'}
                onClose={onClose}
                onSave={onSave}
            />
        </Dialog>
    )
}
