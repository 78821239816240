import { ChangeEvent, ReactElement, useState } from 'react'
import { useLocation } from 'react-router'
import { observer } from 'mobx-react-lite'
import { Avatar, Badge, Fab, Stack, Typography } from '@mui/material'
import { EditOutlined } from '@mui/icons-material'
import { InlineEdit, KBLoading } from '@kibsi/ui-components'
import { useSelfUser } from 'hooks/user/useSelfUser'
import { usePromiseStateLater } from 'hooks/usePromiseState'
import { getInitals } from 'util/avatar'
import { UserPhotoEditorDialog } from 'components/user/photo'
import { AccountContainer } from './AccountContainer'
import { ProfileContent } from './ProfileContent'
import logger from '../../logging/logger'
import { CHANGE_PASSWORD_FLOW } from './types'
import ChangePasswordPanel from './ChangePasswordPanel'

export const UserProfile = observer(function UserProfile(): ReactElement | null {
    const [status, profile, refresh] = useSelfUser()
    const [userStatus, setUserStatus] = usePromiseStateLater()
    const location = useLocation()
    const [editPhoto, setEditPhoto] = useState(false)
    const [editPassword, setEditPassword] = useState<boolean>(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
        const { flow } = (location?.state as any) ?? {}
        logger.debug(`loc flow: ${flow}`)
        return flow === CHANGE_PASSWORD_FLOW
    })

    if (status.state === 'pending') {
        return <KBLoading loading sx={{ height: '50vh' }} />
    }

    if (profile === undefined) {
        return null
    }

    const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        if (profile) {
            setUserStatus(profile.update({ userName: e.target.value }))
        }
    }

    const onOpenChangePassword = () => {
        setEditPassword(true)
    }

    const onClosePassword = () => {
        setEditPassword(false)
    }

    const onEditPicture = () => {
        setEditPhoto(true)
    }

    const onEditPictureClose = () => {
        setEditPhoto(false)
        refresh()
    }

    return (
        <>
            <AccountContainer>
                <Stack gap={2}>
                    <Stack alignItems="center" gap={2}>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                <Fab size="small" sx={{ bgcolor: 'white' }} onClick={onEditPicture}>
                                    <EditOutlined />
                                </Fab>
                            }
                        >
                            <Avatar
                                sx={{ width: 112, height: 112, fontSize: 40 }}
                                src={profile.userPicture}
                                imgProps={{ referrerPolicy: 'no-referrer' }}
                            >
                                {getInitals(profile.userName)}
                            </Avatar>
                        </Badge>

                        {profile.enterpriseIdp && <Typography variant="h5">{profile.userName}</Typography>}
                        {!profile.enterpriseIdp && (
                            <InlineEdit
                                readOnly={userStatus?.state === 'pending'}
                                value={profile.userName}
                                onChange={onChangeName}
                                sx={{ mt: 2, typography: 'h5', textAlign: 'center' }}
                                inputProps={{
                                    sx: {
                                        textAlign: 'center',
                                    },
                                }}
                            />
                        )}
                    </Stack>

                    <ProfileContent profile={profile} onOpenChangePassword={onOpenChangePassword} />
                </Stack>
            </AccountContainer>

            {editPassword && <ChangePasswordPanel open={editPassword} onClose={onClosePassword} />}

            {editPhoto && <UserPhotoEditorDialog user={profile} onClose={onEditPictureClose} />}
        </>
    )
})
