import type { FromDto } from '../interfaces'
import type { DomainFactory } from './store'
import { DomainStoreImpl } from './store.impl'
import { populate } from './fromdto'

export class FromDtoDomainStore<Dto, Domain extends FromDto<Dto>> extends DomainStoreImpl<Dto, Domain> {
    constructor(factory: DomainFactory<Dto, Domain>) {
        super(factory, populate)
    }
}
