import type {
    Audit,
    Model as ModelDto,
    ModelCreate as ModelEditable,
    ModelDefinition,
    ModelDetectionType,
    ModelFile,
    ObjectMappingDef,
} from '@kibsi/ks-application-types'
import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { IDisposer } from 'mobx-utils'
import { FromDto, ToDto } from '../interfaces'
import { ModelArchitecture } from './model.arch'
import { DetectorService } from '../../service/detector'
import logger from '../../logging/logger'
import { initStatus } from '../utils'
import { fromPromise } from '../../util/mobx'

export class Model implements ToDto<ModelDto>, FromDto<ModelDto>, ModelDto {
    private reactions: IDisposer[] = []
    private skipUpdate = false

    readonly modelId: string
    architecture: ModelArchitecture

    status = initStatus()

    constructor(private dto: ModelDto, private service: DetectorService) {
        this.modelId = dto.modelId
        this.architecture = new ModelArchitecture(dto.architecture)

        makeAutoObservable<Model, 'service' | 'reactions'>(this, {
            modelId: false,
            service: false,
            reactions: false,
        })

        this.bindReaction()
    }

    get created(): Audit {
        return this.dto.created
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    get tenantId(): string | undefined {
        return this.dto.tenantId
    }

    get name(): string {
        return this.modelName
    }

    get modelName(): string {
        return this.dto.modelName
    }

    set modelName(name: string) {
        this.dto.modelName = name
    }

    get description(): string | undefined {
        return this.dto.description
    }

    set description(description: string | undefined) {
        this.dto.description = description
    }

    get detectionType(): ModelDetectionType {
        return this.dto.detectionType
    }

    get classes(): ObjectMappingDef {
        return this.dto.objectMapping || []
    }

    get confidenceThreshold(): number | undefined {
        return this.architecture.postProcessor?.confidenceThreshold
    }

    get agnosticNMS(): boolean | undefined {
        return this.architecture.postProcessor?.agnosticNMS
    }

    get imageSize(): number | undefined {
        return this.architecture.preProcessor?.imageSize
    }

    get weightsFile(): string | undefined {
        return this.dto.files?.['weightsFile.npz']?.location?.key
    }

    get weightsFileName(): string {
        const parts = this.weightsFile?.split('/') || []
        return parts[parts.length - 1] || ''
    }

    get modelDef(): ModelDefinition | undefined {
        return this.dto.modelDef
    }

    get files(): Record<string, ModelFile> | undefined {
        return this.dto.files
    }

    get enhancer(): string | undefined {
        return this.dto.enhancer
    }

    get isEditable(): boolean {
        return this.architecture.key !== 'custom'
    }

    toDto(): ModelDto {
        return {
            ...this.dto,
            objectMapping: this.classes,
            architecture: this.architecture.toDto(),
            detectionType: this.detectionType || 'object',
            enhancer: this.enhancer || 'ObjectDetector',
        }
    }

    fromDto({ architecture, ...dto }: ModelDto): void {
        this.dto = { ...dto, architecture, modelId: this.modelId }
        this.architecture = new ModelArchitecture(architecture)

        this.skipUpdate = true
    }

    async update(update: ModelEditable): Promise<void> {
        this.fromDto({ ...this.dto, ...update })

        const action = this.service.updateModel({ modelId: this.modelId, ...update })
        this.status = fromPromise(action)
        const resp = await action

        this.fromDto(resp)
    }

    private bindReaction(): void {
        this.reactions.push(
            reaction(
                () => this.toDto(),
                (partial) => {
                    if (!this.skipUpdate) {
                        logger.info('editing model', partial)
                        const { created, lastUpdated, ...update } = partial
                        this.service.updateModel(update).catch((e) => logger.error(e))
                    }
                },
            ),
            reaction(
                () => this.skipUpdate,
                (skip) => {
                    if (skip) {
                        runInAction(() => {
                            this.skipUpdate = false
                        })
                    }
                },
            ),
        )
    }
}
