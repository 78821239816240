/* eslint-disable no-bitwise */
import { useCallback, useEffect, useRef, ReactElement, useState } from 'react'
import type m from 'monaco-editor'
import Editor, { EditorProps, useMonaco } from '@monaco-editor/react'

type IEditorOpts = m.editor.IEditorOptions

const singleLineOpts = {
    acceptSuggestionOnEnter: 'off' as IEditorOpts['acceptSuggestionOnEnter'],
    automaticLayout: true,
    contextmenu: false,
    cursorStyle: 'line-thin' as IEditorOpts['cursorStyle'],
    find: {
        addExtraSpaceOnTop: false,
        autoFindInSelection: 'never' as m.editor.IEditorFindOptions['autoFindInSelection'],
    },
    fontWeight: 'normal' as IEditorOpts['fontWeight'],
    folding: false,
    glyphMargin: false,
    hideCursorInOverviewRuler: true,
    hover: { enabled: false },
    lineDecorationsWidth: 0,
    lineNumbers: 'off' as IEditorOpts['lineNumbers'],
    lineNumbersMinChars: 0,
    links: false,
    occurrencesHighlight: false,
    overviewRulerLanes: 0,
    overviewRulerBorder: false,
    renderLineHighlight: 'none' as IEditorOpts['renderLineHighlight'],
    roundedSelection: false,
    minimap: { enabled: false },
    wordBasedSuggestions: false,
    wordWrap: 'on' as IEditorOpts['wordWrap'],
    scrollbar: {
        horizontal: 'hidden' as m.editor.IEditorScrollbarOptions['horizontal'],
        vertical: 'hidden' as m.editor.IEditorScrollbarOptions['vertical'],
        alwaysConsumeMouseWheel: false,
    },
    scrollBeyondLastColumn: 0,
    scrollBeyondLastLine: false,
}

export type SingleLineMonacoEditorProps = Omit<EditorProps, 'options' | 'onMount'> & {
    cursor?: number
    options?: Omit<m.editor.IStandaloneEditorConstructionOptions, keyof typeof singleLineOpts>
    onCursor?: (column: number) => void
}

export function SingleLineMonacoEditor(props: SingleLineMonacoEditorProps): ReactElement {
    const { options, value, cursor, height, onCursor, ...monacoProps } = props
    const monaco = useMonaco()
    const editorRef = useRef<m.editor.IStandaloneCodeEditor>()

    const [calcHeight, setCalcHeight] = useState(height)

    // Make sure no newlines are passed in
    const singleLineValue = value?.replace(/[\n\r]/g, '')
    const wrap = editorRef.current?.getLayoutInfo().wrappingColumn

    const initEditor = useCallback(() => {
        const editor = editorRef.current

        if (!monaco || !editor) {
            return
        }

        // These disable some of the builtin commands
        editor.addCommand(monaco.KeyCode.F1, noop)
        editor.addCommand(monaco.KeyMod.WinCtrl | monaco.KeyCode.Space, noop)

        // This disables the enter button.
        // We may need to change this to trigger some other submit action. Not sure yet.
        editor.addCommand(monaco.KeyCode.Enter, noop)
        editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, noop)

        if (onCursor) {
            editor.onDidChangeCursorPosition((e) => {
                if (e.source === 'keyboard') {
                    onCursor(e.position.column)
                }
            })
        }
    }, [monaco, onCursor])

    const onMount = (editor: m.editor.IStandaloneCodeEditor) => {
        editorRef.current = editor
        initEditor()
    }

    useEffect(() => {
        initEditor()
    }, [initEditor])

    useEffect(() => {
        editorRef.current?.focus()

        if (cursor !== undefined) {
            editorRef.current?.setPosition({ lineNumber: 1, column: cursor })
        }
    }, [singleLineValue, cursor])

    useEffect(() => {
        if (singleLineValue && wrap && typeof height === 'number') {
            setCalcHeight(height * Math.ceil(singleLineValue.length / wrap))
        }
    }, [height, singleLineValue, wrap])

    return (
        <Editor
            {...monacoProps}
            onMount={onMount}
            options={{ ...singleLineOpts, ...options, padding: { bottom: 0, top: 0 } }}
            value={singleLineValue}
            height={calcHeight}
        />
    )
}

function noop() {}
