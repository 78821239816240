import { EventEmitter2 } from 'eventemitter2'
import { inject, injectable } from 'inversify'
import type { LocalRepository } from 'repositories/local'
import { CanvasRepo } from 'repositories/canvas.repo'
import { NodePosition, PanZoom } from 'model/canvas'
import T from 'config/inversify.types'
import { ItemType } from './item.type'
import { ApplicationDef } from './application.def'

@injectable()
export class AppCanvasRepo extends CanvasRepo {
    constructor(@inject(T.LocalRepository) storage: LocalRepository, @inject(T.AppEventBus) eventbus: EventEmitter2) {
        super(storage)

        eventbus.on('itemIdChanged', this.onItemIdChanged.bind(this))
    }

    getItemPosition(item: ItemType): NodePosition | undefined {
        return this.getPosition(getPositionKey(item.appId, item.id))
    }

    setItemPosition(item: ItemType, position: NodePosition): void {
        this.setPosition(getPositionKey(item.appId, item.id), position)
    }

    getPanZoom(app: ApplicationDef): PanZoom | undefined {
        return this.getPanAndZoom(getPanZoomKey(app.id))
    }

    setPanZoom(app: ApplicationDef, value: PanZoom): void {
        this.setPanAndZoom(getPanZoomKey(app.id), value)
    }

    private onItemIdChanged(from: string, to: string, item: ItemType): void {
        const fromKey = getPositionKey(item.appId, from)
        const toKey = getPositionKey(item.appId, to)

        const pos = this.getPosition(fromKey)

        if (pos) {
            this.setPosition(fromKey)
            this.setPosition(toKey, pos)
        }
    }
}

function getPositionKey(appId: string, itemId: string): string {
    return `ITEM_TYPE_NODE_POS:${appId}:${itemId}`
}

function getPanZoomKey(appId: string): string {
    return `APP_CANVAS_PZ:${appId}`
}
