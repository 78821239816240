import { KBSuspense } from '@kibsi/ui-components'
import { AddCircleOutlined } from '@mui/icons-material'
import { Grid, IconButton, Stack, Typography } from '@mui/material'
import { useUsers } from 'hooks/user'
import { observer } from 'mobx-react-lite'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UserAccount from './UserAccount'
import { AccountContainer } from './AccountContainer'
import { InviteUserPanel } from './InviteUserPanel'

function UserAccounts(): ReactElement {
    const { t } = useTranslation()
    const [status, users, refresh] = useUsers()
    const [openInvite, setOpenInvite] = useState<boolean>(false)

    const onOpenInvite = () => {
        setOpenInvite(true)
    }

    const onCloseInvite = () => {
        refresh()
        setOpenInvite(false)
    }

    return (
        <AccountContainer>
            <Typography variant="h5">{t('profile.userAccounts.header')}</Typography>
            <Grid
                container
                alignContent="center"
                alignItems="flex-start"
                sx={{
                    m: 0,
                    '& .MuiGrid-item': {
                        p: 2,
                        '&:last-child': {
                            py: 1,
                        },
                    },
                    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12);',
                }}
            >
                <Grid item xs={4}>
                    <Typography variant="subtitle2">{t('profile.userAccounts.name')}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="subtitle2">{t('profile.userAccounts.created')}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="subtitle2">{t('profile.userAccounts.lastLogin')}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="subtitle2">{t('profile.userAccounts.status')}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Stack direction="row" justifyContent="flex-end">
                        <IconButton size="small" sx={{ color: 'action.active' }} onClick={onOpenInvite}>
                            <AddCircleOutlined />
                        </IconButton>
                    </Stack>
                </Grid>
            </Grid>

            <KBSuspense state={status.state} rejectedText={String(status?.value)}>
                {users.map((user) => (
                    <UserAccount key={user.userId} user={user} />
                ))}
            </KBSuspense>

            <InviteUserPanel open={openInvite} onClose={onCloseInvite} />

            {/* Hide Show More */}
            {/* <Grid
                container
                alignContent="center"
                alignItems="flex-end"
                sx={{
                    m: 0,
                    '& .MuiGrid-item': {
                        p: 2,
                        '&:last-child': {
                            py: 1,
                        },
                    },
                }}
            >
                <Grid item xs={10}>
                    &nbsp;
                </Grid>
                <Grid item xs={2} sx={{ textAlign: 'end' }}>
                    <Button
                        variant="text"
                        component="label"
                        color="primary"
                        sx={(theme) => ({
                            pt: 0,
                            pb: '2px',
                            pl: '1px',
                            textTransform: 'none',
                            color: theme.palette.text.secondary,
                            textDecoration: 'underline',
                            '&.MuiButtonBase-root:hover': {
                                backgroundColor: 'transparent',
                                textDecoration: 'underline',
                            },
                        })}
                    >
                        Show more
                    </Button>
                </Grid>
            </Grid> */}
        </AccountContainer>
    )
}

export default observer(UserAccounts)
