import { ReactElement } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import OtterSvg from '../../assets/svg/otter.svg'
import { useRedirect } from '../../hooks/useRedirect'

export type ErrorPageProps = {
    error: Error
}

export function ErrorPage({ error }: ErrorPageProps): ReactElement {
    const { t } = useTranslation()
    const redirect = useRedirect()
    const location = window.location.href

    const onRefresh = () => {
        redirect(location)
    }

    return (
        <Stack
            sx={{ maxWidth: 700, paddingTop: '18vh', margin: '0 auto 0', width: 1, overflow: 'hidden' }}
            data-testid="error-boundary"
        >
            <OtterSvg />

            <Typography color="text.primary" variant="h4" sx={{ mt: 7, fontSize: '30px' }}>
                {t('error.sorry')}
            </Typography>

            <Typography color="text.secondary" variant="body1" sx={{ mt: 3 }} data-testid="error-boundary-message">
                {t('error.message', { value: error.message })}
            </Typography>

            <Typography color="text.tertiary" variant="body2" sx={{ mt: 1 }}>
                {location}
            </Typography>

            <Button variant="contained" sx={{ mt: 8, width: 120 }} onClick={onRefresh}>
                <Typography variant="inherit" textTransform="none" fontWeight={400} fontSize="15px">
                    {t('action.refresh')}
                </Typography>
            </Button>
        </Stack>
    )
}
