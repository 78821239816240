import { FunctionComponent, MouseEventHandler, ReactElement, useState, MouseEvent, RefObject } from 'react'
import { Stack, Box, SvgIconProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { KBSuspense } from '@kibsi/ui-components'
import { NavLink } from 'react-router-dom'
import { useSelfUser } from 'hooks/user/useSelfUser'
import { useMainNavigations } from 'hooks/navigation'
import { observer } from 'mobx-react-lite'
import { getInitals } from 'util/avatar'
import { Apps, Radar, SatelliteOutlined, SpokeOutlined } from '@mui/icons-material'
import { buildLinearGradient } from 'util/color'
import { MenuItem } from './MenuItem'
import KibsiLogoDarkIcon from '../../../assets/svg/kibsi-logo-dark.svg'
import { SupportMenu } from '../../support/SupportMenu'
import { IconAvatar } from '../../icon'
import { ArrowButton } from './ArrowButton'
import { AppBarSettings } from './AppBarSettings'
import { getPathColor } from '../utils'

export type AppBarProps = {
    settings: AppBarSettings
    fabRef?: RefObject<HTMLDivElement>
}

export const AppBar = observer(function AppBar({ settings, fabRef }: AppBarProps): ReactElement {
    const theme = useTheme()
    const [status, profile] = useSelfUser()
    const navigations = useMainNavigations()

    const { width, collapsed } = settings
    const [showControl, setShowControl] = useState(false)
    const showControlThreshold = 10

    const getPathIcon = (path: string): FunctionComponent<SvgIconProps> | undefined => {
        switch (path) {
            case 'playground':
                return SpokeOutlined
            case 'sites':
                return SatelliteOutlined
            case 'applications':
                return Apps
            case 'models':
                return Radar
            default:
                return undefined
        }
    }

    const toggleCollapse = () => {
        settings.toggle()
        setShowControl(false)
    }

    const onMouseMove = (event: MouseEvent<HTMLDivElement>) => {
        setShowControl(event.clientX > width - showControlThreshold)
    }

    const onMouseLeave: MouseEventHandler = () => {
        setShowControl(false)
    }

    return (
        <Stack
            height={1}
            justifyContent="space-between"
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
            sx={{
                position: 'relative',
                boxShadow: 8,
                backgroundImage: buildLinearGradient(),
                transition: 'width 250ms',
                width,
                zIndex: theme.zIndex.appBar + 1,
            }}
            aria-expanded={!settings.collapsed}
            data-testid="app-bar"
        >
            {/* logo */}
            <Stack sx={{ width: 1, alignItems: 'center', mt: 2 }}>
                <KibsiLogoDarkIcon />
            </Stack>

            {/* placeholder for FAB */}
            <Box ref={fabRef} sx={{ position: 'absolute', top: 85, right: -15, zIndex: theme.zIndex.speedDial }} />

            {/* navigations */}
            <Box>
                {navigations.map(({ path, title }) => (
                    <MenuItem
                        key={path}
                        path={`/${path}`}
                        title={title}
                        icon={getPathIcon(path)}
                        color={getPathColor(path)}
                        collapsed={collapsed}
                    />
                ))}
            </Box>

            <Stack sx={{ width: 1, alignItems: 'center', mb: 2 }} gap={2}>
                {/* support */}
                <SupportMenu />

                {/* profile */}
                <KBSuspense size={20} state={status.state} sx={{ height: undefined, m: 0 }}>
                    <NavLink to="/account">
                        {({ isActive }) => (
                            <IconAvatar
                                size={24}
                                src={profile?.userPicture}
                                sx={{ color: isActive ? 'primary.main' : 'text.secondary', mr: 0 }}
                            >
                                {getInitals(profile?.userName)}
                            </IconAvatar>
                        )}
                    </NavLink>
                </KBSuspense>
            </Stack>

            <ArrowButton
                direction={collapsed ? 'right' : 'left'}
                onClick={() => toggleCollapse()}
                show={showControl}
                sx={{ position: 'absolute', bottom: '110px', right: -10 }}
            />
        </Stack>
    )
})
