import { PropsWithChildren, PropsWithRef, ReactElement, ReactNode } from 'react'
import {
    FormControl,
    FormControlProps,
    FormHelperText,
    FormHelperTextProps,
    InputLabel,
    Select,
    SelectProps,
} from '@mui/material'
import { sxs } from '../../utils/mui'
import { KBLoading } from '../loading'

type KBSelectPropsBase<T> = SelectProps<T> & {
    error?: boolean
    helperText?: ReactNode
    formHelperTextProps?: FormHelperTextProps
    formControlProps?: FormControlProps
    loading?: boolean
}

export type KBSelectControlProps<T> = PropsWithRef<PropsWithChildren<KBSelectPropsBase<T>>>

export function KBSelectControl<T>({
    value,
    error,
    label,
    children,
    helperText,
    formControlProps,
    formHelperTextProps,
    loading,
    sx,
    ...rest
}: KBSelectControlProps<T>): ReactElement {
    const loader = () => <KBLoading sx={{ my: 0, ml: 0, mr: 0.5 }} size={15} loading={loading ?? false} />

    return (
        <FormControl variant="standard" {...formControlProps} error={error}>
            <InputLabel>{label}</InputLabel>

            <Select
                sx={sxs({ minWidth: '160px' }, sx)}
                size="small"
                value={value ?? ('' as unknown as T)}
                {...(loading && { IconComponent: loader })}
                {...rest}
            >
                {children}
            </Select>

            {helperText && (
                <FormHelperText {...formHelperTextProps} error>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    )
}
