import { AppModelDefinition as AppDef, GenerationType, ItemType } from '@kibsi/ks-application-types'
import camelCase from 'camelcase'

export type EventType = 'event-occurrence' | 'event-span'

export type ItemTypeVariant = 'detected' | 'static' | 'event-occurrence' | 'event-span' | 'default' | GenerationType

export type ItemTypeIconVariant = GenerationType | ItemTypeVariant | EventType

export const ID = /^[a-zA-Z]+[a-zA-Z0-9_]*$/
const DENY = /[^a-zA-Z0-9_]/g
const DENY_FIRST = /^[^a-zA-Z]+/

export function getAttributeScope(itemType?: ItemType): string[] {
    return itemType?.attributes?.map((a) => a.id) ?? []
}

export function getItemTypeScope(appDef?: AppDef): string[] {
    return appDef?.itemTypes.map((i) => i.id) ?? []
}

export function getActionScope(itemType?: ItemType): string[] {
    return itemType?.event?.actions?.map((a) => a.id) ?? []
}

export function validateScope(id: string, scope: string[] = []): boolean {
    return !scope.includes(id)
}

export function generateId(name: string, scope: string[] = []): string {
    let id = camelCase(name.replace(DENY_FIRST, '')).replace(DENY, '')

    if (id === 'undefined') {
        // Note: jsplumb breaks with 'undefined' ids.
        // It's also unclear how KEL handles 'undefined' identifiers inits expressions. i.e. `people.undefined.bar`
        // This should make sure the string 'undefined' isn't ever interpreted as `typeof undefined`.
        id = '_undefined_'
    }

    if (id.length === 0) {
        throw new Error('InvalidNameForId: No valid characters')
    }

    if (scope.includes(id)) {
        id = findNextIncrement(id, scope)
    }

    return id
}

export function generateAttrId(name: string, item?: ItemType): string {
    return generateId(name, getAttributeScope(item))
}

export function generateItemId(name: string, appDef?: AppDef): string {
    return generateId(name, getItemTypeScope(appDef))
}

export function generateActionId(name: string, item?: ItemType): string {
    return generateId(name, getActionScope(item))
}

/**
 * returns a name appended with the next available index.
 *
 * If a scope already contains foobar:
 *
 * ```
 * expect(findNextIncrement('foobar', ['foobar'])).toBe('foobar2')
 * expect(findNextIncrement('foobar', ['foobar', 'foobar5'])).toBe('foobar6')
 * ```
 */
export function findNextIncrement(id: string, scope: string[]): string {
    const regex = new RegExp(`^${id}(\\d*)$`)

    let max = 0

    scope.forEach((s) => {
        const match = s.match(regex)
        if (match) {
            const idx = parseInt(match[1], 10)
            if (idx > max) {
                max = idx
            }
        }
    })

    // start indexing at 2 since the non indexed name is technically 1 and not starting at 0.
    return `${id}${max ? max + 1 : 2}`
}

export function getItemNodeType(itemType: ItemType): ItemTypeVariant {
    switch (itemType.generationType) {
        case 'static':
            return 'static'
        case 'detected':
            return 'detected'
        case 'event':
            return itemType.event?.eventType === 'span' ? 'event-span' : 'event-occurrence'
        default:
            return 'default'
    }
}
