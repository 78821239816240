import { DescriptionOutlined } from '@mui/icons-material'
import { IconButton, Popover, Table, TableBody } from '@mui/material'
import { ReactElement, useState } from 'react'
import { WithDataProps } from '../../types'
import { ValueRenderer } from './types'
import { KBDictionaryRow } from './KBDictionaryRow'

export type KBObjectPopupProps = {
    value: unknown
    renderValue: ValueRenderer
}

export function KBObjectPopup({
    value,
    'data-testid': dataTestId = 'kb-object-popup',
    renderValue,
}: WithDataProps<KBObjectPopupProps>): ReactElement | null {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()
    const [open, setOpen] = useState(false)

    if (value === null) {
        return null
    }

    const values = Array.isArray(value) ? value : [value]

    if (!values.length) {
        return null
    }

    return (
        <>
            <IconButton
                size="small"
                onClick={(e) => {
                    setAnchorEl(e.currentTarget)
                    setOpen(true)
                }}
                data-testid={`${dataTestId}-button`}
                sx={{ pr: 0 }}
            >
                <DescriptionOutlined fontSize="small" />
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                    setOpen(false)
                    setAnchorEl(undefined)
                }}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                PaperProps={{
                    sx: {
                        minWidth: '400px',
                        maxWidth: '450px',
                        maxHeight: '400px',
                    },
                }}
            >
                <Table>
                    {values.map((v, i) => (
                        <TableBody
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            sx={{
                                borderTop: i > 0 ? 1 : 0,
                                borderColor: 'rgba(0,0,0,0.12)',
                            }}
                        >
                            {/* for primitive type (string or boolean) */}
                            {typeof v !== 'object' && (
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                <KBDictionaryRow value={v} key={v} renderValue={renderValue} />
                            )}

                            {/* for objects */}
                            {typeof v === 'object' &&
                                Object.entries(v).map(([k, val]) => (
                                    <KBDictionaryRow
                                        value={val}
                                        key={k}
                                        name={!Array.isArray(v) ? k : undefined}
                                        renderValue={renderValue}
                                    />
                                ))}
                        </TableBody>
                    ))}
                </Table>
            </Popover>
        </>
    )
}
