import { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { KBLoadingButton, KBLoadingButtonProps, sxs } from '@kibsi/ui-components'

export interface SaveButtonProps extends Omit<KBLoadingButtonProps, 'children'> {
    label?: ReactNode
}

export function SaveButton({ label, sx, ...props }: SaveButtonProps): ReactElement {
    const { t } = useTranslation()
    const text = label ?? t('action.save')

    return (
        <KBLoadingButton size="large" sx={sxs({ mt: 0 }, sx)} {...props}>
            {text}
        </KBLoadingButton>
    )
}
