import { ContainerModule } from 'inversify'
import Types from '../../config/inversify.types'
import { ApplicationServiceImpl } from './application.service.impl'
import { ApplicationsStore, AppVersionsStore, ApplicationManager } from '../../store/app'

export const ApplicationModule = new ContainerModule((bind) => {
    bind(Types.ApplicationManager).to(ApplicationManager).inSingletonScope()
    bind(Types.ApplicationService).to(ApplicationServiceImpl).inSingletonScope()
    bind(Types.ApplicationStore).to(ApplicationsStore).inSingletonScope()
    bind(Types.ApplicationVersionStore).to(AppVersionsStore).inSingletonScope()
})
