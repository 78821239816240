import { makeAutoObservable } from 'mobx'
import { NamedPolygons } from 'model/draw'
import { ItemType } from '@kibsi/ks-application-types'

export class StaticRegions {
    regions: NamedPolygons[]
    itemType?: ItemType

    constructor(public id: string, public name: string, regions: NamedPolygons[], itemType?: ItemType) {
        this.regions = regions
        this.itemType = itemType
        makeAutoObservable(this)
    }

    get regionIds(): string[] {
        return this.regions.map((r) => r.id)
    }

    get itemTypeName(): string {
        return this.itemType?.name || ''
    }

    hasSameRegions(regions?: StaticRegions): boolean {
        return (
            this.regionIds.length === regions?.regionIds.length &&
            this.regionIds.every((a) => regions?.regionIds.includes(a))
        )
    }
}
