export type ModelSizeType = 'nano' | 'small' | 'medium' | 'large'

export function getArchitectureSizeText(size: ModelSizeType): string {
    switch (size) {
        case 'nano':
            return 'n'
        case 'small':
            return 's'
        case 'medium':
            return 'm'
        case 'large':
            return 'l'
        default:
            throw new Error('UnsupportedArchitectureSize')
    }
}

export function getModelSizeTypeFromString(size: string): ModelSizeType {
    switch (size) {
        case 'n':
            return 'nano'
        case 's':
            return 'small'
        case 'm':
            return 'medium'
        case 'l':
            return 'large'
        default:
            throw new Error('UnsupportedSize')
    }
}
