import { makeAutoObservable } from 'mobx'
import { KibsiHttpError } from '../../service/api/http.error'

export class KibsiError {
    date: Date
    summary?: string
    errorCode?: number
    isRead = false
    details: Record<string, string | undefined> = {}

    constructor() {
        this.date = new Date()
        makeAutoObservable(this)
    }

    static fromError(err: Error): KibsiError {
        const error = new KibsiError()

        if (err instanceof KibsiHttpError) {
            error.errorCode = err.statusCode
            error.details.url = err.url
            error.details.requestId = err.requestId
            error.details.code = err.errorCode
        }

        error.summary = err.message

        return error
    }

    read(): void {
        this.isRead = true
    }

    unread(): void {
        this.isRead = false
    }
}
