import type { Audit, Tenant as TenantDto } from '@kibsi/ks-tenant-types'
import { makeAutoObservable, toJS } from 'mobx'
import { FromDto, ToDto } from 'store/interfaces'

export class Tenant implements ToDto<TenantDto>, FromDto<TenantDto>, TenantDto {
    readonly tenantId: string

    constructor(private dto: TenantDto) {
        this.tenantId = dto.tenantId

        makeAutoObservable<Tenant>(this, {
            tenantId: false,
        })
    }

    get tenantName(): string {
        return this.dto.tenantName
    }

    get tenantSubdomain(): string {
        return this.dto.tenantSubdomain
    }

    get tenantOrgId(): string {
        return this.dto.tenantOrgId
    }

    get allowSupport(): boolean {
        return this.dto.allowSupport
    }

    get created(): Audit {
        return this.dto.created
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    toDto(): TenantDto {
        return toJS(this.dto)
    }

    fromDto(dto: TenantDto): void {
        this.dto = { ...dto, tenantId: this.tenantId }
    }
}
