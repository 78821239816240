import { Button, ButtonProps, Typography } from '@mui/material'
import { ReactElement } from 'react'

export type KBStandardButtonProps = {
    title?: string
} & ButtonProps

export function KBStandardButton({ title = '', children, sx, onClick, ...props }: KBStandardButtonProps): ReactElement {
    return (
        <Button sx={{ mt: 3, ...sx }} variant="contained" onClick={onClick} {...props}>
            <Typography variant="inherit" textTransform="none">
                {title && title}
                {children && children}
            </Typography>
        </Button>
    )
}
