import { useAuth0 } from '@auth0/auth0-react'
import { KBLoading } from '@kibsi/ui-components'
import { useAuthCheck } from 'hooks/auth/useAuthCheck'
import { useLogin } from 'hooks/auth/useLogin'
import { usePendo } from 'hooks/pendo/usePendo'
import { PropsWithChildren, ReactElement, useEffect, useState } from 'react'
import logger from '../logging/logger'

type RequireAuthProps = {
    /**
     * optional property to validate the auth time of the id token
     */
    authTime?: number

    /**
     * optional flow.  short description on who/why redirect happened
     */
    flow?: string
}

export function RequireAuth({ children, authTime, flow }: PropsWithChildren<RequireAuthProps>): ReactElement | null {
    const { isAuthenticated, isLoading, user } = useAuth0()

    const authCheck = useAuthCheck(authTime)
    const login = useLogin(authTime, flow)
    const initPendo = usePendo()

    const [state, setState] = useState<string>()

    useEffect(() => {
        const checkAuthContext = async () => {
            setState('checking')
            const success = await authCheck()
            if (success) {
                // user is already authenticated and the subdomain matches the tenant
                // nothing left to do
                setState('allow')
            } else {
                // login
                await login()
            }
        }

        if (isLoading) {
            logger.debug('Waiting for auth0...')
            return
        }

        if (state === undefined) {
            checkAuthContext().catch((e) => {
                logger.error('checkAuthContext failure', e)
            })
        }
    }, [isLoading, state, authCheck, login])

    useEffect(() => {
        if (state === 'allow') {
            initPendo({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                tenantId: user?.org_id ?? '',
                userId: user?.sub ?? '',
                name: user?.name ?? '',
            })
        }
    }, [state, user, initPendo])

    if (isLoading || state !== 'allow') {
        return <KBLoading loading sx={{ height: '50vh' }} />
    }

    if (!isAuthenticated) {
        return null
    }

    return <> {children}</>
}
