import { ReactElement } from 'react'
import { useMonaco } from '@monaco-editor/react'
import { SingleLineMonacoEditor, SingleLineMonacoEditorProps } from './SingleLineEditor'
import { useKibsiLanguage, KibsiId } from './useKibsiLanguage'
import { useMarkers, Marker } from './useMarkers'

export type SingleLineKibsiEditorProps = Omit<SingleLineMonacoEditorProps, 'language' | 'theme'> & {
    markers?: Marker[]
}

export function SingleLineKibsiEditor(props: SingleLineKibsiEditorProps): ReactElement {
    const { markers } = props
    const monaco = useMonaco()

    useKibsiLanguage(monaco)
    useMarkers(monaco, markers)

    return <SingleLineMonacoEditor {...props} language={KibsiId} theme={KibsiId} />
}
