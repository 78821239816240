import TYPES from 'config/inversify.types'
import { inject, injectable } from 'inversify'
import { ApiClient } from './api-client'

interface TimeResponse {
    time: string
}

@injectable()
export class TimeAPI {
    constructor(@inject(TYPES.ApiClient) readonly apiClient: ApiClient) {}

    async getTime(): Promise<string> {
        const timeResponse = await this.apiClient.get<TimeResponse>('time')
        return timeResponse.time
    }
}
