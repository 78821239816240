import { PaginationRequest } from '@kibsi/ks-client-sdk'
import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import type { Status } from 'hooks/usePromiseState'
import type { PageAction, Paginator, PaginatedList, SearchCriteria, SearchCriteriaDtoFactory } from './interfaces'
import { PaginatorImpl } from './paginator'

export class PaginatedListImpl<T, C> implements PaginatedList<T> {
    private pages: Paginator<T, C>
    private list: T[] = []
    private criteria?: SearchCriteria<T>
    private searchMode: 'client' | 'server'
    private requestId?: string

    constructor(
        fetch: PageAction<T, C>,
        request?: PaginationRequest<C>,
        private criteriaDto?: SearchCriteriaDtoFactory<C>,
    ) {
        this.pages = new PaginatorImpl(fetch)
        this.pages.request = request ?? {}
        this.searchMode = this.supportsServerCriteria ? 'server' : 'client'

        makeAutoObservable(this)
    }

    get status(): Status | undefined {
        return this.pages.status
    }

    get items(): T[] {
        const { criteria, searchMode } = this

        if (searchMode === 'client' && criteria !== undefined) {
            return this.list.filter((i) => criteria.matches(i))
        }

        return this.list
    }

    get isEmpty(): boolean {
        return this.items.length === 0
    }

    get isComplete(): boolean {
        return this.pages.isComplete
    }

    get isLoading(): boolean {
        return this.pages.isLoading
    }

    async search(criteria: SearchCriteria<T> | undefined): Promise<void> {
        this.criteria = criteria

        if (this.searchMode === 'server') {
            this.list = []
            this.pages.request = {
                criteria: this.criteriaDto?.(criteria?.term, criteria?.tags),
            }
            this.requestId = undefined

            // Request the first page of the new search terms
            await this.more()
        }
    }

    async more(): Promise<void> {
        if (this.requestId !== undefined) {
            // A pending request is in flight
            return
        }

        const requestId = nanoid()
        this.requestId = requestId

        const page = await this.pages.more()

        runInAction(() => {
            if (this.requestId !== undefined && this.requestId === requestId) {
                this.requestId = undefined
                this.list.push(...page)

                if (this.isComplete && this.criteria === undefined) {
                    // We can only support client side search if the entire results are loaded from the server without
                    // search criteria
                    this.searchMode = 'client'
                }
            }
        })
    }

    async refresh(): Promise<void> {
        this.requestId = undefined
        this.list = []
        this.pages.request = {}
        this.searchMode = this.supportsServerCriteria ? 'server' : 'client'

        await this.more()
    }

    async all(): Promise<void> {
        while (!this.isComplete) {
            // eslint-disable-next-line no-await-in-loop
            await this.more()
        }
    }

    delete(item: T): void {
        const idx = this.list.indexOf(item)

        if (idx >= 0) {
            this.list.splice(idx, 1)
        }
    }

    private get supportsServerCriteria(): boolean {
        return this.criteriaDto !== undefined
    }
}
