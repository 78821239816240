/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import type { Action, DependencyGraph } from '@kibsi/ks-dependency-types'
import { useTranslation } from 'react-i18next'
import { DialogContent } from '../../components/dialog/DialogContent'

function flattenDependencies(graph: DependencyGraph[]): DependencyGraph[] {
    return graph.map((g) => [g, ...flattenDependencies(g.dependents || [])]).flat()
}

const dependencyTypeName = (dependencyGraph: DependencyGraph): string =>
    `${dependencyGraph.entityType} ${dependencyGraph.metadata.name}`

const buildDependencyListStr = (dependencyGraphs: DependencyGraph[]): string => {
    if (dependencyGraphs.length === 1) {
        return dependencyTypeName(dependencyGraphs[0])
    }

    if (dependencyGraphs.length === 2) {
        return `${dependencyTypeName(dependencyGraphs[0])} and ${dependencyTypeName(dependencyGraphs[1])}`
    }

    if (dependencyGraphs.length > 2) {
        const nameTypes = dependencyGraphs.map((dep) => dependencyTypeName(dep))
        const last = nameTypes.pop()
        return `${nameTypes.join(', ')}, and ${last}`
    }

    return ''
}

export function useDeleteDialogContent(
    name: string,
    msg?: string,
    dependencyGraph?: DependencyGraph,
    action?: Action,
): DialogContent {
    const { t } = useTranslation()

    const dependencies = flattenDependencies(dependencyGraph ? [dependencyGraph] : [])
    dependencies.shift()
    const cascades = dependencies.filter((d) => d.action === 'cascade')
    const orphaned = dependencies.filter((d) => d.action === 'orphan')
    const blocked = dependencies.filter((d) => d.action === 'block')

    const title = (): string => {
        switch (dependencyGraph?.action || action) {
            case 'orphan':
            case 'cascade':
                return t('delete.title.orphanCascade', { name })
            case 'block':
                return t('delete.title.blocked', { name })
            default:
                return t('delete.title.default', { name })
        }
    }

    const message = (): string => {
        if (!dependencyGraph) {
            return msg || ''
        }

        let mes = ''
        if (cascades.length) {
            mes += `${t('delete.message.cascade', { dep: buildDependencyListStr(cascades) })}. `
        }

        if (orphaned.length) {
            mes += `${t('delete.message.orphaned', { dep: buildDependencyListStr(orphaned) })}.`
        }

        if (blocked.length) {
            mes += `${t('delete.message.blocked', { dep: buildDependencyListStr(blocked) })}.`
        }

        return mes
    }

    const confirm = dependencyGraph?.action === 'block' || action === 'block' ? t('action.ok') : t('action.yes')

    const cancel = action === 'block' ? undefined : t('action.no')

    return { title: title(), message: message(), confirm, cancel }
}
