import { makeAutoObservable } from 'mobx'
import { EventEmitter2 } from 'eventemitter2'
import { ARTItemState, ARTSnapshot } from '@kibsi/common'
import Logger from 'logging/logger'
import { Deployment } from '../deployment'

const log = Logger.getLogger('ArtSnapshot')

export type ArtItemState = {
    frameTime: number
    state: ARTItemState
}

export type ArtItemStateEvent = {
    lifecycle: 'INSERT' | 'MODIFY' | 'REMOVE'
    frameTime: number
    itemType: string
    item: ARTItemState
}

export type ArtItemStateListener = (state: ArtItemState) => void

export class ArtSnapshot {
    private eventbus = new EventEmitter2({ delimiter: '/' })
    private items: Map<string, Map<string, ArtItemState>> = new Map()
    private closed = false

    constructor(deployment: Deployment) {
        log.info(`Creating snapshot ${deployment.name}`)

        deployment.getItemTypes().forEach((item) => {
            this.items.set(item.id, new Map())
        })

        makeAutoObservable<this, 'eventbus'>(this, {
            eventbus: false,
        })
    }

    getItems(itemType: string): ArtItemState[] {
        this.checkClosed()

        return Array.from(this.items.get(itemType)?.values() ?? [])
    }

    // Replace the entire snapshot
    setSnapshot(frameTime: number, dto: ARTSnapshot): void {
        this.checkClosed()

        Object.entries(dto).forEach(([key, states]) => {
            const items = this.items.get(key)
            const ids = new Set(items?.keys())

            items?.clear()

            states.forEach((state) => {
                const item: ArtItemState = {
                    frameTime,
                    state,
                }

                items?.set(state.id, item)

                if (ids.has(state.id)) {
                    this.eventbus.emit(`${key}/${state.id}`, item)
                } else {
                    this.eventbus.emit(`${key}/added`, item)
                }
            })
        })
    }

    update({ lifecycle, frameTime, itemType, item }: ArtItemStateEvent): void {
        this.checkClosed()

        switch (lifecycle) {
            case 'INSERT':
                this.items.get(itemType)?.set(item.id, { frameTime, state: item })
                this.eventbus.emit(`${itemType}/added`, { frameTime, state: item })
                break
            case 'MODIFY':
                this.items.get(itemType)?.set(item.id, { frameTime, state: item })
                this.eventbus.emit(`${itemType}/${item.id}`, { frameTime, state: item })
                break
            case 'REMOVE':
                this.items.get(itemType)?.delete(item.id)
                this.eventbus.removeAllListeners(`${itemType}/${item.id}`)
                break
            default:
        }
    }

    onItemAdded(itemType: string, listener: ArtItemStateListener): void {
        if (this.items.has(itemType)) {
            this.eventbus.on(`${itemType}/added`, listener)
        }
    }

    onItemUpdated(itemType: string, itemId: string, listener: ArtItemStateListener): void {
        if (this.items.has(itemType)) {
            this.eventbus.on(`${itemType}/${itemId}`, listener)
        }
    }

    clear(): void {
        this.items.forEach((item) => {
            item.clear()
        })
    }

    close(): void {
        this.items.clear()
        this.eventbus.removeAllListeners()
    }

    private checkClosed() {
        if (this.closed) {
            log.warn('snapshot has been closed')
        }
    }
}
