import {
    Audit,
    Integration as CloudIntegrationDto,
    KinesisSubscriptionTarget,
    FirehoseSubscriptionTarget,
} from '@kibsi/ks-tenant-types'
import { makeAutoObservable, toJS } from 'mobx'
import { CloudIntegrationService } from 'service/cloud-integration/cloud-integration.service'
import { FromDto, ToDto } from '../interfaces'

export class CloudIntegration implements ToDto<CloudIntegrationDto>, FromDto<CloudIntegrationDto>, CloudIntegrationDto {
    readonly id: string

    constructor(private dto: CloudIntegrationDto, private service: CloudIntegrationService) {
        this.id = dto.integrationId

        makeAutoObservable<CloudIntegration, 'service' | 'reactions'>(this, {
            id: false,
            service: false,
            reactions: false,
        })
    }

    get integrationId(): string {
        return this.dto.integrationId
    }

    get integrationName(): string {
        return this.dto.integrationName
    }

    set integrationName(integrationName: string) {
        this.dto.integrationName = integrationName
    }

    get integrationType(): 'Subscription' {
        return this.dto.integrationType
    }

    set integrationType(type: 'Subscription') {
        this.dto.integrationType = type
    }

    get integrationSubtype(): 'AWS' {
        return this.dto.integrationSubtype
    }

    set integrationSubtype(type: 'AWS') {
        this.dto.integrationSubtype = type
    }

    get integrationData(): FirehoseSubscriptionTarget | KinesisSubscriptionTarget {
        return this.dto.integrationData
    }

    set integrationData(data: FirehoseSubscriptionTarget | KinesisSubscriptionTarget) {
        this.dto.integrationData = data
    }

    get integrationDescription(): string | undefined {
        return this.dto.integrationDescription
    }

    set integrationDescription(integrationDescription: string | undefined) {
        this.dto.integrationDescription = integrationDescription
    }

    get created(): Audit {
        return this.dto.created
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    toDto(): CloudIntegrationDto {
        return toJS(this.dto)
    }

    fromDto(dto: CloudIntegrationDto): void {
        this.dto = { ...dto, integrationId: this.id }
    }

    async update(): Promise<CloudIntegration> {
        await this.service.update(this.dto)

        return this
    }
}
