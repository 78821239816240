import { Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { ArrowRightAlt, SvgIconComponent } from '@mui/icons-material'
import { ReactElement } from 'react'
import { KBItemTypeCard, KBItemTypeCardProps } from './KBItemTypeCard'

export type KBItemTypePropertyProps = {
    icon?: SvgIconComponent | ReactElement
    iconColor?: string
    connection?: boolean
    last?: boolean
} & Omit<KBItemTypeCardProps, 'showAdd' | 'leftIcon' | 'containerStyle' | 'typeStyle' | 'children' | 'onAdd'>

export function KBItemTypeProperty({
    connection = false,
    icon,
    iconColor = 'secondary.light',
    last = false,
    ...props
}: KBItemTypePropertyProps): ReactElement {
    const theme = useTheme()
    const background = theme.palette.primary.outlinedHoverBackground

    return (
        <KBItemTypeCard
            leftIcon={
                <Stack direction="row" spacing={1} sx={{ color: iconColor }}>
                    {icon}
                    {connection && <ArrowRightAlt />}
                </Stack>
            }
            containerStyle={{
                borderRadius: 0,
                boxShadow: 0,
                width: '100%',
            }}
            typeStyle={{
                typography: 'body2',
                color: 'text.secondary',
            }}
            cardStyle={{
                ...(last && { borderRadius: '0 0 4px 4px' }),
                ...(props.active && { background }),
                '&:hover': {
                    background,
                },
            }}
            showAdd={false}
            {...props}
        />
    )
}
