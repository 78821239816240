import { inject, injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'
import type { LocalRepository } from 'repositories/local'
import T from '../../config/inversify.types'

const MEDIA_SHOW_DETECTIONS = 'media.showDetections'
const MEDIA_SHOW_REGIONS = 'media.showRegions'

@injectable()
export class MediaViewerSettings {
    private detections: boolean
    private regions: boolean

    constructor(@inject(T.LocalRepository) private repo: LocalRepository) {
        makeAutoObservable(this)

        this.detections = this.repo.get(MEDIA_SHOW_DETECTIONS) ?? false
        this.regions = this.repo.get(MEDIA_SHOW_REGIONS) ?? false
    }

    get showDetections(): boolean {
        return this.detections
    }

    set showDetections(value: boolean) {
        this.detections = value
        this.repo.set(MEDIA_SHOW_DETECTIONS, value)
    }

    get showRegions(): boolean {
        return this.regions
    }

    set showRegions(value: boolean) {
        this.regions = value
        this.repo.set(MEDIA_SHOW_REGIONS, value)
    }
}
