import type {
    ARTItemState,
    CounterAggregateResult,
    DeploymentARTSnapshot,
    HeuristicQueryResult,
    HistorySnapshotInterval,
    ItemAggregateResult,
    ItemCountResult,
    DistinctItemsRequest,
    FloorPlanHeatmapParams,
    FloorPlanHeatmapResult,
    GridEventParams,
    GridEventResult,
} from '@kibsi/ks-history-types'

export type HistoryInterval =
    | '10s'
    | '15s'
    | '30s'
    | '1m'
    | '2m'
    | '3m'
    | '5m'
    | '10m'
    | '15m'
    | '30m'
    | '1h'
    | '2h'
    | '3h'
    | '4h'
    | '6h'
    | '8h'
    | '12h'
    | '1d'

export const totalTimeForInterval = (interval: HistoryInterval): number => {
    let duration = parseInt(interval.substring(0, interval.length - 1), 10)
    const unit = interval.substring(interval.length - 1)

    /* eslint-disable no-fallthrough */
    switch (unit) {
        case 'd':
            duration *= 24
        case 'h':
            duration *= 60
        case 'm':
            duration *= 60
        case 's':
            duration *= 1000
            break
        default:
            break
    }

    return duration
}

export const intervalForExpectedMaxCount = (
    startTime: number,
    endTime: number,
    expectedMaxCount = 100,
): HistoryInterval => {
    const intervals: HistoryInterval[] = [
        '10s',
        '15s',
        '30s',
        '1m',
        '2m',
        '3m',
        '5m',
        '10m',
        '15m',
        '30m',
        '1h',
        '2h',
        '3h',
        '4h',
        '6h',
        '8h',
        '12h',
        '1d',
    ]

    let interval: HistoryInterval = intervals[0]
    const intervalPerTick = (endTime - startTime) / expectedMaxCount

    for (let i = 0; i < intervals.length; i += 1) {
        const currentInterval = intervals[i]
        const totalTime = totalTimeForInterval(currentInterval)

        if (totalTime === intervalPerTick) {
            interval = intervals[i]
        } else if (totalTime > intervalPerTick && i > 0) {
            // pick the previous interval if totalTimeForInterval is more than start and end total time duration
            interval = intervals[i - 1]
            break
        }
    }

    return interval
}

export interface HistoryService {
    itemAggregates(
        deploymentId: string,
        startTime: string,
        endTime: string,
        interval: HistoryInterval,
    ): Promise<ItemAggregateResult>
    distinctItems(
        deploymentId: string,
        startTime: string,
        endTime: string,
        interval: HistoryInterval,
        queryRequest?: DistinctItemsRequest,
    ): Promise<ItemCountResult>
    counterAggregates(
        deploymentId: string,
        itemType: string,
        name: string,
        counter: string,
        startTime: string,
        endTime: string,
        interval: HistoryInterval,
    ): Promise<CounterAggregateResult>
    itemCounts(deploymentId: string, starTime: string, endTime: string): Promise<ItemCountResult>
    heuristicCounts(deploymentId: string, startTime: string, endTime: string): Promise<HeuristicQueryResult>
    snapshot(deploymentId: string, time: string): Promise<DeploymentARTSnapshot>
    snapshotInterval(deploymentId: string, startTime: string, endTime: string): Promise<HistorySnapshotInterval>
    itemDetails(deploymentId: string, startTime: string, endTime: string, id: string): Promise<ARTItemState[]>
    floorPlanHeatmap(
        floorPlanId: string,
        startTime: string,
        endTime: string,
        queryRequest?: FloorPlanHeatmapParams,
        imageType?: 'floorPlan' | 'stream',
    ): Promise<FloorPlanHeatmapResult>
    heatmapGridEvents(
        floorPlanId: string,
        startTime: string,
        endTime: string,
        queryRequest: GridEventParams,
        imageType?: 'floorPlan' | 'stream',
    ): Promise<GridEventResult>
}
