import { SxProps, Typography } from '@mui/material'
import { ReactElement } from 'react'

export type KBDrawerTitleProps = {
    title?: string
    children?: ReactElement | string
    sx?: SxProps
}

export function KBDrawerTitle({ title = '', children, sx }: KBDrawerTitleProps): ReactElement {
    return (
        <Typography variant="h4" sx={sx}>
            {title}
            {children && children}
        </Typography>
    )
}
