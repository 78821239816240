import { ReactElement } from 'react'
import LinearProgress, { linearProgressClasses, LinearProgressProps } from '@mui/material/LinearProgress'
import { useTheme } from '@mui/material/styles'

export function RoundedLinearProgress({ sx, ...props }: LinearProgressProps): ReactElement {
    const theme = useTheme()
    const style = {
        height: 5,
        borderRadius: 2,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[200],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: 'primary.main',
        },
        ...sx,
    }
    return <LinearProgress color="primary" sx={style} variant="determinate" {...props} />
}
