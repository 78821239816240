import { ReactElement } from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { KibsiError } from '../../store/error/error'
import { ErrorInfo } from './ErrorInfo'

export type ErrorDetailsProps = {
    error: KibsiError
}

export function ErrorDetails({ error }: ErrorDetailsProps): ReactElement {
    const { t } = useTranslation()

    return (
        <Stack sx={{ pl: 3.75, overflow: 'hidden' }} onClick={(e) => e.stopPropagation()}>
            {error.details.code && (
                <ErrorInfo title={t('error.code')} info={error.details.code} data-testid="error-details-code" />
            )}
            {error.details.url && (
                <ErrorInfo title={t('error.url')} info={error.details.url} data-testid="error-details-url" />
            )}
            {error.details.requestId && (
                <ErrorInfo
                    title={t('error.requestId')}
                    info={error.details.requestId}
                    data-testid="error-details-request-id"
                />
            )}
        </Stack>
    )
}
