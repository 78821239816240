import { keywordsContains } from 'util/search'
import { Tag } from 'model/tag'
import { SearchCriteria } from '../pagination'

export interface HasCommonCriteria {
    name?: string
    description?: string
    lastUpdated?: {
        principal?: string
    }
    tags?: Map<string, Tag>
}

export interface CommonCriteria {
    q?: string
    tagIds?: string[]
}

export class CommonSearchCriteria<T extends HasCommonCriteria> implements SearchCriteria<T> {
    private readonly terms: string[]
    private readonly tagIds: string[]

    constructor(readonly term: string, readonly tags: Tag[] = []) {
        this.terms = term.split(/\s+/).filter((t) => t)
        this.tagIds = tags.map((t) => t.tagId)
    }

    matches(item: T): boolean {
        const itemTagIds = Array.from(item.tags?.keys() || [])
        if (this.terms.length && !this.tagIds.length) {
            return [item.name, item.description, item.lastUpdated?.principal].some((i) =>
                keywordsContains(this.terms, i),
            )
        }

        if (!this.terms.length && this.tagIds.length) {
            return this.tagIds.some((i) => itemTagIds.includes(i))
        }

        return (
            [item.name, item.description, item.lastUpdated?.principal].some((i) => keywordsContains(this.terms, i)) &&
            this.tagIds.some((i) => itemTagIds.includes(i))
        )
    }
}
