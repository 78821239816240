import type { Site as SiteDto, SiteCreate, SiteCriteria } from '@kibsi/ks-tenant-types'
import { inject, injectable } from 'inversify'
import type { SiteService } from 'service/site'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from 'store/domain'
import { PaginatedList, PaginatedListImpl, createDomainPageAction } from 'store/pagination'
import T from '../../config/inversify.types'
import { Site } from './site'

export type SiteList = PaginatedList<Site>

@injectable()
export class SiteStore {
    private sites: AsyncDomainStore<Site, SiteDto>

    constructor(@inject(T.SiteService) private service: SiteService) {
        this.sites = new AsyncFromDtoDomainStore<SiteDto, Site>(
            (id) => service.get(id),
            (_, data) => new Site(data, service),
        )
    }

    async loadList(criteria?: SiteCriteria): Promise<SiteList> {
        const { sites, service } = this
        const action = createDomainPageAction(sites, service.list.bind(service), (dto) => dto.siteId)

        const list = new PaginatedListImpl(action, { pageSize: 100, criteria })

        await list.more()

        return list
    }

    loadSite(id: string): Promise<Site> {
        return this.sites.load(id)
    }

    getSite(id: string): Site | undefined {
        return this.sites.get(id)
    }

    async createSite(deployment: SiteCreate): Promise<Site> {
        const dto = await this.service.create(deployment)

        return this.sites.set(dto.siteId, dto)
    }

    async deleteSite(siteId: string, cascade?: boolean): Promise<void> {
        await this.service.delete(siteId, cascade)
        this.sites.delete(siteId)
    }
}
