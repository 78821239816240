import { KBDrawerContainer, KBDrawerTitle, KBLoadingButton, KBSuspense } from '@kibsi/ui-components'
import { Alert, AlertTitle, Stack, TextField, Typography } from '@mui/material'
import { usePasswordValidator } from 'hooks/password/usePasswordValidator'
import { useValidatePassword as useValidatePasswordRule } from 'hooks/password/useValidatePasswordRule'
import { useIdToken } from 'hooks/useIdToken'
import { useChangePassword } from 'hooks/user'
import logger from 'logging/logger'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { Controller, FieldError, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type ChangePasswordFormData = {
    newPassword: string
    repeatPassword: string
}

type PasswordErrorMessageProps = {
    error?: FieldError
}

function PasswordErrorMessage({ error }: PasswordErrorMessageProps): ReactElement | null {
    if (!error?.types) {
        return null
    }

    return (
        <Stack sx={{ mt: 0 }} spacing={0}>
            {Object.entries(error.types).map(([ruleKey, val]) => (
                <Typography
                    key={ruleKey}
                    component="div"
                    variant="caption"
                    sx={(theme) => ({
                        color: theme.palette.error.main,
                    })}
                >
                    {val}
                </Typography>
            ))}
        </Stack>
    )
}

type ChangePasswordProps = {
    onClose: () => void
}

function ChangePassword({ onClose }: ChangePasswordProps): ReactElement | null {
    const { t } = useTranslation()
    const [validatorStatus, passwordPolicy] = usePasswordValidator()
    const validatePasswordRule = useValidatePasswordRule(passwordPolicy)
    const [changePasswordStatus, changePassword] = useChangePassword()
    const [idToken] = useIdToken()

    const { control, handleSubmit, getValues } = useForm<ChangePasswordFormData>({
        defaultValues: { newPassword: '', repeatPassword: '' },
        criteriaMode: 'all',
    })

    const onSubmit = () => {
        handleSubmit(
            async (data) => {
                logger.debug('newPassword,repeatPassword', data)

                await changePassword({
                    idToken,
                    newPassword: data.newPassword,
                })
                logger.info('password successfully changed')
                onClose()
            },
            (e) => logger.warn('error on submit', e),
        )().catch((e) => logger.error(e))
    }

    const rejectedErrorToString = (): string | undefined => {
        if (changePasswordStatus?.state !== 'rejected') {
            return undefined
        }

        const errorVal = changePasswordStatus?.value

        logger.warn('status?.value', errorVal)

        if (!errorVal) {
            return 'unknown'
        }

        if (errorVal instanceof Error) {
            return errorVal.message
        }

        return errorVal as string
    }

    return (
        <KBSuspense state={validatorStatus.state}>
            <KBDrawerContainer
                size="medium"
                header={<KBDrawerTitle title={t('profile.password.header')} />}
                description={t('profile.password.description')}
                onTopRightIconClicked={onClose}
                footer={
                    <Stack direction="row" justifyContent="flex-end">
                        <KBLoadingButton
                            loading={changePasswordStatus?.state === 'pending'}
                            variant="contained"
                            size="large"
                            sx={{ textTransform: 'none' }}
                            onClick={onSubmit}
                            data-testid="change-password-save"
                        >
                            {t('action.save')}
                        </KBLoadingButton>
                    </Stack>
                }
            >
                <Stack
                    spacing={3}
                    sx={{
                        '& .MuiStack-root': {
                            margin: 4,
                            mt: 0,
                        },
                    }}
                >
                    {changePasswordStatus?.state === 'rejected' && (
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {rejectedErrorToString()}
                        </Alert>
                    )}
                    <Controller
                        name="newPassword"
                        control={control}
                        rules={{
                            required: t('profile.password.newPassword.required'),
                            validate: validatePasswordRule,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <Stack>
                                <TextField
                                    {...field}
                                    type="password"
                                    fullWidth
                                    variant="standard"
                                    inputProps={{
                                        'data-testid': 'new-password',
                                    }}
                                    label={t('profile.password.newPassword.label')}
                                    error={!!error}
                                />
                                <PasswordErrorMessage error={error} />
                            </Stack>
                        )}
                    />
                    <Controller
                        name="repeatPassword"
                        control={control}
                        rules={{
                            required: t('profile.password.repeatPassword.required'),
                            validate: (val) =>
                                val !== getValues('newPassword') ? t('profile.password.repeatPassword.noMatch') : true,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                type="password"
                                fullWidth
                                variant="standard"
                                inputProps={{
                                    'data-testid': 'repeat-password',
                                }}
                                label={t('profile.password.repeatPassword.label')}
                                error={!!error}
                                helperText={error?.message}
                            />
                        )}
                    />
                </Stack>
            </KBDrawerContainer>
        </KBSuspense>
    )
}

export default observer(ChangePassword)
