import { SvgIconProps, Tab, TabProps } from '@mui/material'
import { lighten, useTheme } from '@mui/material/styles'
import { FunctionComponent, ReactElement } from 'react'

export type KBTabProps = {
    icon?: FunctionComponent<SvgIconProps>
} & Omit<TabProps, 'icon'>

export function KBTab({ icon: Icon, color, ...props }: KBTabProps): ReactElement {
    const theme = useTheme()
    const primaryColor = color || theme.palette.primary.main
    const activeColor = lighten(primaryColor, 0.9)

    const styles = {
        pointerEvents: 'auto',
        '&.MuiTab-root': {
            minHeight: 0,
            minWidth: 0,
            textTransform: 'none',
            px: '10px',
            py: '7px',
            cursor: 'pointer',
            color: 'text.secondary',
            borderRadius: '3px',
            mr: 1,
        },
        '&.Mui-selected': {
            backgroundColor: activeColor,
            color: primaryColor,
        },
        '&:hover': {
            backgroundColor: activeColor,
            color: primaryColor,
        },
    }

    return <Tab icon={Icon && <Icon sx={{ fontSize: '16px' }} />} iconPosition="start" sx={styles} {...props} />
}
