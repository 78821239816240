import type { Action as ActionDto, ActionConfig, ActionStatus, ActionTrigger } from '@kibsi/ks-event-types'
import { ActionType } from './action.type'

export class EventAction implements ActionDto {
    readonly id: string
    readonly definitionId: string
    readonly actionDate?: Date

    constructor(private dto: ActionDto) {
        this.id = dto.id
        this.definitionId = dto.definitionId
        if (dto.actionTime) {
            this.actionDate = new Date(dto.actionTime)
        }
    }

    get actionTime(): string | undefined {
        return this.dto.actionTime
    }

    get config(): ActionConfig {
        return this.dto.config
    }

    get interval(): number | undefined {
        return this.dto.interval
    }

    get status(): ActionStatus {
        return this.dto.status
    }

    get actionType(): ActionType | undefined {
        return this.config.actionType
    }

    get trigger(): ActionTrigger {
        return this.dto.trigger
    }
}
