import { inject, injectable } from 'inversify'
import { makeAutoObservable, runInAction } from 'mobx'
import { FloorPlan as FloorPlanDto, FloorPlanCreate } from '@kibsi/ks-tenant-types'
import TYPES from 'config/inversify.types'
import type { FloorPlanService } from 'service/floor-plan'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from '../domain'
import { FloorPlan } from './floor-plan'

@injectable()
export class FloorPlanStore {
    private floorPlans: AsyncDomainStore<FloorPlan, FloorPlanDto>

    constructor(@inject(TYPES.FloorPlanService) private service: FloorPlanService) {
        this.floorPlans = new AsyncFromDtoDomainStore<FloorPlanDto, FloorPlan>(
            (id) => service.get(id),
            (_, data) => new FloorPlan(data, service),
        )

        makeAutoObservable<FloorPlanStore, 'service'>(this, {
            service: false,
        })
    }

    async list(siteId: string): Promise<FloorPlan[]> {
        const dtos = await this.service.list(siteId, { pageSize: 100 })
        return runInAction(() => dtos.map((dto) => this.floorPlans.set(dto.floorPlanId, dto)))
    }

    getFloorPlan(id: string): Promise<FloorPlan> {
        return this.floorPlans.load(id)
    }

    async createFloorPlan(floorPlan: FloorPlanCreate, file?: File): Promise<FloorPlan> {
        const dto = await this.service.create(floorPlan)

        if (file) {
            const b = await file.arrayBuffer()
            await this.service.saveImage(dto.floorPlanId, b, file.type)
        }

        return this.floorPlans.set(dto.floorPlanId, dto)
    }

    async deleteFloorPlan(floorPlanId: string, cascade?: boolean): Promise<void> {
        await this.service.delete(floorPlanId, cascade)
        this.floorPlans.delete(floorPlanId)
    }
}
