import type { Stream as StreamDto, StreamCreate } from '@kibsi/ks-camera-types'
import { inject, injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'
import type { StreamService } from 'service/stream'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from 'store/domain'
import { PaginatedList, PaginatedListImpl, createDomainPageAction } from 'store/pagination'

import TYPES from '../../config/inversify.types'
import { Stream } from './stream'
import { ImageDataBuffer } from '../data-buffer'
import { commonSearchDtoFactory } from '../search'

export type StreamList = PaginatedList<Stream>

@injectable()
export class StreamStore {
    private streams: AsyncDomainStore<Stream, StreamDto>

    constructor(@inject(TYPES.StreamService) private service: StreamService) {
        this.streams = new AsyncFromDtoDomainStore<StreamDto, Stream>(
            (id) => service.get(id),
            (_, data) => new Stream(data, service),
        )

        makeAutoObservable<StreamStore, 'service'>(this, {
            service: false,
        })
    }

    async loadList(siteId: string): Promise<StreamList> {
        const { streams, service } = this
        const action = createDomainPageAction(streams, service.list.bind(service, siteId), (dto) => dto.streamId)

        const list = new PaginatedListImpl(action, { pageSize: 100 }, commonSearchDtoFactory)

        await list.more()

        return list
    }

    loadStream(id: string): Promise<Stream> {
        return this.streams.load(id)
    }

    getStream(id: string): Stream | undefined {
        return this.streams.get(id)
    }

    async createStream(input: StreamCreate): Promise<Stream> {
        const dto = await this.service.create(input)

        const stream = this.streams.set(dto.streamId, dto)

        return stream
    }

    async deleteStream(streamId: string, cascade?: boolean): Promise<void> {
        await this.service.delete(streamId, cascade)
        this.streams.delete(streamId)
    }

    async loadSnapshot(id: string): Promise<ImageDataBuffer | undefined> {
        const buffer = await this.service.getSnapshot(id)
        return new ImageDataBuffer(buffer)
    }
}
