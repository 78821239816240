import { ReactElement } from 'react'
import { ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material'
import { lighten, useTheme } from '@mui/material/styles'

export type KBToggleGroupProps = {
    selectedColor?: string
    onSelected?: (selected: string) => void
} & ToggleButtonGroupProps

export function KBToggleButtonGroup({ sx, selectedColor, onSelected, ...props }: KBToggleGroupProps): ReactElement {
    const theme = useTheme()

    const color = selectedColor || theme.palette.primary.main

    const style = {
        ...sx,
        boxShadow: 1,
        backgroundColor: 'white',
        '& .MuiToggleButtonGroup-grouped': {
            color: theme.palette.text.secondary,
            margin: 1,
            border: 0,
            '&.Mui-disabled': {
                border: 0,
            },
            '&.Mui-selected': {
                color: 'white',
                backgroundColor: color,
            },
            '&.Mui-selected:hover': {
                color: 'white',
                backgroundColor: lighten(color, 0.5),
            },
            '&:not(:first-of-type), &:first-of-type': {
                borderRadius: '4px',
            },
        },
    }
    return (
        <ToggleButtonGroup
            exclusive
            size="small"
            onChange={(_, change) => {
                // prevents toggle from being un-toggled
                if (!change) {
                    return
                }
                onSelected?.(change)
            }}
            sx={style}
            {...props}
        />
    )
}
