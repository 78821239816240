import { useState, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, ButtonProps, Stack, Typography } from '@mui/material'
import { Code, Javascript } from '@mui/icons-material'
import { emptyExpression } from '@kibsi/ks-application-types'
import { AdvancedEditor, AdvancedEditorProps } from './AdvancedEditor'
import { VisualEditor } from './VisualEditor'
import { Expression } from './types'

export interface ExpressionEditorProps {
    expression?: Expression
    markers?: AdvancedEditorProps['markers']
    description?: string
    errorMessage?: string
    allowJavascript?: boolean
    cursor?: number
    advancedContent?: ReactElement
    onChange?: (value: Expression) => void
    onCursor?: (column: number) => void
}

const ButtonStyles = {
    color: 'common.black',
    fontSize: '13px',
    '& .MuiButton-startIcon': {
        marginLeft: 0,
    },
}

interface ExpressionModeButtonProps extends ButtonProps {
    title: string
    selected: boolean
}

function ExpressionModeButton({ title, selected = false, ...buttonProps }: ExpressionModeButtonProps): ReactElement {
    return (
        <Button
            {...buttonProps}
            sx={{
                ...(selected && { backgroundColor: 'primary.outlinedHoverBackground' }),
                ...ButtonStyles,
            }}
        >
            {title}
        </Button>
    )
}

export function ExpressionEditor({
    expression = emptyExpression(),
    markers,
    description = '',
    errorMessage,
    allowJavascript = false,
    cursor,
    advancedContent,
    onChange,
    onCursor,
}: ExpressionEditorProps): ReactElement | null {
    const { t } = useTranslation()

    const [mode, setMode] = useState<string>(() => {
        if (expression.extType === 'javascript') {
            return 'js'
        }

        if (errorMessage) {
            return 'advanced'
        }

        return 'advanced'
    })

    const useJavascript = () => {
        if (mode !== 'js') {
            setMode('js')
            onChange?.({ extType: 'javascript', plainText: '' })
        }
    }

    const useAdvanced = () => {
        if (mode !== 'advanced') {
            setMode('advanced')

            if (mode === 'js') {
                onChange?.({ extType: 'kibsi', plainText: '' })
            }
        }
    }

    const onAdvanced = (plainText: string) => {
        onChange?.({ ...expression, plainText })
    }

    const editor =
        mode === 'visual' ? (
            <VisualEditor expression={expression} />
        ) : (
            <AdvancedEditor
                expression={expression}
                onChange={onAdvanced}
                onCursor={onCursor}
                markers={markers}
                cursor={cursor}
            />
        )

    return (
        <Stack direction="column" spacing={3} overflow="hidden">
            <Box>
                <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={0}>
                    <Typography variant="body2" sx={{ fontSize: '12px' }}>
                        {t('expression.title')}
                    </Typography>

                    <Stack direction="row" spacing={2}>
                        <ExpressionModeButton
                            startIcon={<Code />}
                            title={t('expression.advanced')}
                            selected={mode === 'advanced'}
                            onClick={useAdvanced}
                            data-testid="advanced-expr-mode-button"
                        />
                        {allowJavascript && (
                            <ExpressionModeButton
                                startIcon={<Javascript />}
                                title={t('expression.javascript')}
                                selected={mode === 'js'}
                                onClick={useJavascript}
                                data-testid="javascript-expr-mode-button"
                            />
                        )}
                    </Stack>
                </Stack>

                <Box sx={{ borderBottom: '2px solid black', mt: 1, pb: 1 }}>{editor}</Box>

                {errorMessage && (
                    <Typography
                        variantMapping={{ body2: 'pre' }}
                        variant="body2"
                        sx={{ fontSize: '12px', fontFamily: '"Roboto Mono"', mt: 1, whiteSpace: 'pre-wrap' }}
                        color="error"
                        data-testid="expr-error"
                    >
                        {errorMessage}
                    </Typography>
                )}

                <Typography variant="body2" sx={{ fontSize: '12px', color: 'text.secondary', mt: 1 }}>
                    {description}
                </Typography>
            </Box>

            {mode === 'advanced' && advancedContent}
        </Stack>
    )
}
