import type { Audit, SelfUserUpdate, User as UserDto } from '@kibsi/ks-tenant-types'
import { makeAutoObservable, toJS } from 'mobx'
import { UserService } from 'service/user'
import { FromDto, ToDto } from 'store/interfaces'

export class User implements ToDto<UserDto>, FromDto<UserDto>, UserDto {
    private self = false

    readonly userId: string

    constructor(private dto: UserDto, private service: UserService) {
        this.userId = dto.userId

        makeAutoObservable<User, 'service'>(this, {
            userId: false,
            service: false,
        })
    }

    get userName(): string {
        return this.dto.userName
    }

    get userEmail(): string {
        return this.dto.userEmail
    }

    get userPicture(): string | undefined {
        return this.dto.userPicture
    }

    get userLanguage(): string | undefined {
        return this.dto.userLanguage
    }

    get userTimezone(): string | undefined {
        return this.dto.userTimezone
    }

    get role(): string {
        return this.dto.role
    }

    get pending(): boolean {
        return this.dto.pending
    }

    get invitationCreatedAt(): string | undefined {
        return this.dto.invitationCreatedAt
    }

    get invitationExpiresAt(): string | undefined {
        return this.dto.invitationExpiresAt
    }

    get blocked(): boolean {
        return this.dto.blocked
    }

    get enterpriseIdp(): boolean {
        return this.dto.enterpriseIdp
    }

    get lastLoginTime(): string | undefined {
        return this.dto.lastLoginTime
    }

    get lastLoginTimeAsDate(): Date | undefined {
        return this.dto.lastLoginTime ? new Date(this.dto.lastLoginTime) : undefined
    }

    get created(): Audit {
        return this.dto.created
    }

    get createdAsDate(): Date | undefined {
        return this.dto.created.timestamp ? new Date(this.dto.created.timestamp) : undefined
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    get isSelf(): boolean {
        return this.self
    }

    set isSelf(value: boolean) {
        this.self = value
    }

    async update(updates: Partial<SelfUserUpdate>): Promise<void> {
        if (this.self) {
            const { userName, userTimezone, userLanguage } = this

            const req = {
                userName,
                userTimezone,
                userLanguage,
                ...updates,
            }

            await this.service.updateSelf(req)

            this.fromDto({ ...this.dto, ...req })
        }
    }

    async updatePicture(buffer: Buffer): Promise<void> {
        if (this.self) {
            await this.service.updateSelfPicture(buffer)
        }
    }

    toDto(): UserDto {
        return toJS(this.dto)
    }

    fromDto(dto: UserDto): void {
        this.dto = { ...dto, userId: this.userId }
    }
}
