import { MouseEventHandler, useState } from 'react'

export function useHover(): [boolean, MouseEventHandler, MouseEventHandler] {
    const [hover, setHover] = useState(false)

    const onMouseEnter: MouseEventHandler = () => {
        setHover(true)
    }

    const onMouseLeave: MouseEventHandler = () => {
        setHover(false)
    }

    return [hover, onMouseEnter, onMouseLeave]
}
