import { useAuth0 } from '@auth0/auth0-react'
import { ReactElement, useEffect } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { KBLoading } from '@kibsi/ui-components'
import logger from '../../logging/logger'
import { LoginErrorPage } from './LoginErrorPage'

/**
 * Login Page
 *
 * @returns {ReactElement} <LoginPage />
 */
export function LoginPage(): ReactElement {
    const [searchParams] = useSearchParams()
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()

    const code = searchParams.has('code')

    useEffect(() => {
        if (!isLoading && !isAuthenticated && !code) {
            loginWithRedirect().catch((e) => logger.warn(e))
        }
    }, [isLoading, isAuthenticated, code, loginWithRedirect])

    if (isLoading) {
        return <KBLoading loading sx={{ height: '50vh' }} />
    }

    if (searchParams.has('error')) {
        return <LoginErrorPage />
    }

    if (isAuthenticated) {
        // This means that the user directly access this page.
        return <Navigate to="/" />
    }

    return <div>Waiting to Redirect</div>
}
