import { Box, IconButton, Tabs, TabScrollButtonProps, TabsProps } from '@mui/material'
import { ReactElement } from 'react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { sxs } from '../../utils/mui'

const scrollButtonComp = ({ disabled, direction, onClick }: TabScrollButtonProps) =>
    disabled ? (
        <Box />
    ) : (
        <IconButton sx={{ p: 0.5 }} onClick={onClick}>
            {direction === 'left' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
    )

export function KBTabs(props: TabsProps): ReactElement {
    const { sx, ...rest } = props

    return (
        <Tabs
            variant="scrollable"
            TabIndicatorProps={{
                style: { display: 'none' },
            }}
            ScrollButtonComponent={scrollButtonComp}
            sx={sxs(
                {
                    display: 'inline-flex', // used to not go 100% of width
                    alignItems: 'center',
                },
                sx,
            )}
            {...rest}
        />
    )
}
