import { ReactElement } from 'react'
import { Box, Dialog, DialogContent, DialogContentText, DialogProps, Zoom } from '@mui/material'
import { CancelButton, SaveButton } from 'components/button'
import { DialogHeader } from './DialogHeader'
import { DialogFooter } from './DialogFooter'

export interface ConfirmationDialogProps extends Omit<DialogProps, 'title' | 'open'> {
    // content
    title: string | ReactElement
    message?: string | ReactElement
    cancel?: string
    confirm?: string
    contents?: ReactElement

    // flags
    confirming?: boolean
    open?: boolean

    // callbacks
    onCancel: () => void
    onConfirm: () => void
}

export function ConfirmationDialog({
    title = '',
    message = '',
    confirm,
    cancel,
    confirming = false,
    open = false,
    onCancel,
    onConfirm,
    contents,
    ...props
}: ConfirmationDialogProps): ReactElement | null {
    if (!open) {
        return null
    }

    return (
        <Dialog open={open} onClose={onCancel} TransitionComponent={Zoom} maxWidth="sm" fullWidth {...props}>
            <DialogHeader title={title} onClose={onCancel} />
            <DialogContent>
                <DialogContentText id="confirm-dialog-slide-description">{message}</DialogContentText>
                {contents}
            </DialogContent>
            <DialogFooter>
                {cancel && <CancelButton label={cancel} onCancel={onCancel} data-testid="confirmation-dialog-cancel" />}
                <Box />
                {confirm && (
                    <SaveButton
                        label={confirm}
                        loading={confirming}
                        onClick={onConfirm}
                        data-testid="confirmation-dialog-continue"
                    />
                )}
            </DialogFooter>
        </Dialog>
    )
}
