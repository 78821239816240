import { LaunchSchedule as Dto, ScheduleStatus } from '@kibsi/ks-deployment-types'
import { makeAutoObservable } from 'mobx'
import { DateTime } from 'luxon'

export type ScheduleAction = 'Stop' | 'Start'

export class LaunchSchedule {
    constructor(private dto: Dto) {
        makeAutoObservable(this)
    }

    get scheduleStatus(): ScheduleStatus {
        return this.dto.scheduleStatus || 'No Schedule'
    }

    get nextScheduleEventTime(): DateTime | undefined {
        const time = this.dto.nextScheduleEventTime
        if (!time) {
            return undefined
        }
        return DateTime.fromISO(time, { zone: this.dto.scheduleTimezone })
    }

    get nextScheduleAction(): ScheduleAction | undefined {
        return this.dto.nextScheduleAction as ScheduleAction
    }
}
