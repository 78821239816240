import { inject, injectable } from 'inversify'
import { makeAutoObservable } from 'mobx'
import type { ModelCriteria, Model as ModelDto, ModelCreate, UploadUrl } from '@kibsi/ks-application-types'
import TYPES from 'config/inversify.types'
import type { DetectorService } from 'service/detector'
import { AsyncDomainStore, AsyncFromDtoDomainStore } from '../domain'
import { PaginatedList, PaginatedListImpl, createDomainPageAction } from '../pagination'
import { Model } from './model'

export type ModelList = PaginatedList<Model>

@injectable()
export class ModelStore {
    private models: AsyncDomainStore<Model, ModelDto>

    constructor(@inject(TYPES.DetectorService) private service: DetectorService) {
        this.models = new AsyncFromDtoDomainStore<ModelDto, Model>(
            (id) => service.getModel(id),
            (_, data) => new Model(data, service),
        )

        makeAutoObservable<ModelStore, 'service'>(this, {
            service: false,
        })
    }

    async loadModels(criteria?: ModelCriteria): Promise<ModelList> {
        const { models, service } = this
        const action = createDomainPageAction(models, service.listModels.bind(service), (dto) => dto.modelId)

        const list = new PaginatedListImpl(action, { pageSize: 100, criteria })

        await list.more()

        return list
    }

    get listModels(): Model[] {
        return this.models.values()
    }

    async createModel(model: ModelCreate): Promise<Model> {
        const dto = await this.service.createModel(model)
        return this.models.set(dto.modelId, dto)
    }

    async loadModel(modelId: string): Promise<Model> {
        const dto = await this.service.getModel(modelId)
        return this.models.set(dto.modelId, dto)
    }

    getModel(modelId: string): Model | undefined {
        return this.models.get(modelId)
    }

    async deleteModel(modelId: string, cascade?: boolean): Promise<void> {
        await this.service.deleteModel(modelId, cascade)
        this.models.delete(modelId)
    }

    getModelWeightFileUploadUrl(modelId: string): Promise<UploadUrl> {
        return this.service.getModelUploadUrl(modelId)
    }
}
