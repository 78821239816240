import axios, { AxiosInstance } from 'axios'
import { Environment } from 'config/environment'
import TYPES from 'config/inversify.types'
import { inject, injectable } from 'inversify'
import logger from '../../logging/logger'

@injectable()
export class ApiClient {
    private baseUrl: string

    private instance: AxiosInstance

    private tokenInterceptor: number | undefined

    constructor(@inject(TYPES.Environment) readonly environment: Environment) {
        this.baseUrl = environment.serviceUrl.replace('{service}', 'tenant')
        this.instance = axios.create({
            baseURL: this.baseUrl,
            timeout: 5000,
        })
    }

    setTokenProvider(getTokenFunction: () => Promise<string>): void {
        if (this.tokenInterceptor) {
            this.instance.interceptors.request.eject(this.tokenInterceptor)
        }

        if (getTokenFunction) {
            logger.info('setting token provider')
            this.tokenInterceptor = this.instance.interceptors.request.use(async (config) => {
                logger.info(`calling interceptor`)
                const token = await getTokenFunction()
                logger.info(`new token ${token}`)

                config.headers = {
                    Authorization: `Bearer ${token}`,
                }
                return config
            })
        }
    }

    async get<T>(path: string): Promise<T> {
        try {
            logger.debug(`client GET ${this.baseUrl}/${path}`)
            const result = await this.instance.get(path)

            logger.debug(`Status: ${result.status}`)
            return result.data as T
        } catch (e) {
            logger.error(e)
            throw e
        }
    }
}
