import { ReactElement, MouseEvent } from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import { Error, ErrorOutline } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { KibsiError } from '../../store/error/error'

export type ErrorCodeProps = {
    error: KibsiError
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

export const ErrorCode = observer(({ error, onClick }: ErrorCodeProps): ReactElement => {
    const style = {
        color: error.isRead ? 'action.active' : 'error.main',
    }

    return (
        <Stack direction="row" alignItems="center" sx={style} spacing={0.5}>
            <IconButton size="small" onClick={onClick} sx={style} data-testid="error-code-button">
                {error.isRead ? <ErrorOutline /> : <Error />}
            </IconButton>
            {error?.errorCode && <Typography>{error.errorCode}</Typography>}
        </Stack>
    )
})
