import { ReactElement, ReactNode } from 'react'
import { DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

export interface DialogHeaderProps {
    title: string | ReactNode
    onClose?: () => void
}

export function DialogHeader({ title, onClose }: DialogHeaderProps): ReactElement {
    return (
        <DialogTitle>
            <Stack direction="row" justifyContent="space-between" alignItems="center" overflow="hidden">
                <Typography variant="h5" textOverflow="ellipsis" noWrap>
                    {title}
                </Typography>
                <IconButton onClick={onClose} sx={{ p: 0.5 }}>
                    <Close fontSize="large" />
                </IconButton>
            </Stack>
        </DialogTitle>
    )
}
