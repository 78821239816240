import type { Optional } from '@kibsi/common'
import {
    isRelationshipAttr,
    Application as AppDto,
    EventItemType,
    GenerationType,
    ItemType,
    RelationshipAttr as AppRelationshipAttr,
} from '@kibsi/ks-application-types'
import type {
    Audit,
    DeploymentStatus,
    Deployment as UpdateDto,
    DeploymentStatusReason,
    DeploymentUpdate,
    EnhancerAttr,
    RegionAttachment,
    RegionAttr,
    StaticItem,
    VdbEndpoint,
    DetectableItemConfig,
    DetectableItemConfigOptions,
    Region,
    ActionConfig,
    RelationshipAttr,
    ProximityAnchorOverride,
    Schedule,
    Definition,
} from '@kibsi/ks-deployment-types'
import { Deployment as DeploymentUI } from '@kibsi/ks-ui-types'
import type { Site as SiteDto } from '@kibsi/ks-tenant-types'
import type {
    AppRef,
    Deployment as GetDto,
    DeploymentDetails as ListDto,
    SiteRef,
    VersionRef,
    OptionalVersionUpgradeDetails,
} from '@kibsi/ks-ui-types'
import log from 'logging/logger'
import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx'
import { IDisposer } from 'mobx-utils'
import { nanoid } from 'nanoid'
import { DateTime } from 'luxon'
import { NamedPolygon } from 'model/draw'
import { getStaticRegions, AttrValue, getRegionsForStream } from 'model/static.item'
import { parseErrorMessage } from 'util/error'
import { fromPromise } from 'util/mobx'
import { Tag } from 'model/tag'
import { ResourceType } from '@kibsi/ks-search-tags-types'
import type { DeploymentService } from '../../service'
import type { FromDto, ToDto } from '../interfaces'
import { initStatus } from '../utils'
import { AppVersionDto } from '../app'
import { getStreamId } from '../../hooks/stream/useStream'
import { StaticRegions } from './static.regions'
import { RelationshipAttribute } from './relationship.attr'
import { LaunchSchedule } from './launch.schedule'
import { TaggableResource } from '../tag/tag.store'
import { tagsFromDto } from '../tag/util'

export type AnyDto = GetDto | ListDto

export type Dto = Optional<UpdateDto, 'deploymentDefinition' | 'isTemplate'> & Pick<DeploymentUI, 'tags'>

export type BulkAddStaticItemsType = { [itemTypeId: string]: number | undefined | null }

type DeploymentEditable = Omit<DeploymentUpdate, 'deploymentId'>

export type UpdateAppVersionUpdateStatus = 'success' | 'approvalNeeded' | 'uninitialized'

export type UpdateAppVersionResult = {
    status: UpdateAppVersionUpdateStatus
    appVersion: AppVersionDto
    staticItemsToDelete?: string[]
    // add more validation results here
}

export type LaunchTargetType = 'Kibsi_SAAS' | 'Edge' | 'Customer_Cloud'
export type LaunchTarget = {
    type: LaunchTargetType
    edgeDeviceId?: string
}

export class Deployment implements ToDto<Dto>, FromDto<Dto>, TaggableResource {
    private reactions: IDisposer[] = []
    private skipUpdate = false
    private selectedItemId?: string
    readonly tags = new Map<string, Tag>()

    readonly deploymentId: string
    readonly resourceType: ResourceType = 'deployment'

    status = initStatus()

    app?: AppDto
    version?: AppVersionDto
    site?: SiteDto
    vdbEndpoint?: VdbEndpoint
    autoUpdate?: boolean

    constructor(
        private dto: Dto,
        private service: DeploymentService,
        private updateDelay: number = 0, // this parameter is the delay to the reactions before calling update
    ) {
        this.deploymentId = dto.deploymentId
        tagsFromDto(this.tags, dto)

        makeAutoObservable<Deployment, 'service' | 'reactions'>(this, {
            deploymentId: false,
            service: false,
            reactions: false,
        })

        this.bindReactions()
    }

    /**
     * returns if this deployment mobx object is editable. e.g. if the deploymentDefinition is included.
     *
     */
    isEditable(): boolean {
        return !!this.dto.deploymentDefinition
    }

    get id(): string {
        return this.deploymentId
    }

    get name(): string {
        return this.deploymentName
    }

    get deploymentName(): string {
        return this.dto.deploymentName
    }

    set deploymentName(deploymentName: string) {
        this.dto.deploymentName = deploymentName
    }

    get description(): string | undefined {
        return this.deploymentDescription
    }

    get deploymentDescription(): string | undefined {
        return this.dto.deploymentDescription
    }

    set deploymentDescription(desc: string | undefined) {
        this.dto.deploymentDescription = desc
    }

    get deploymentStatus(): DeploymentStatus {
        return this.dto.deploymentStatus
    }

    get launchSchedule(): LaunchSchedule | undefined {
        if (this.dto.currentLaunchSchedule) {
            return new LaunchSchedule(this.dto.currentLaunchSchedule)
        }

        return undefined
    }

    get deploymentStatusReason(): DeploymentStatusReason | undefined {
        return this.dto.deploymentStatusReason
    }

    get deploymentStatusTime(): DateTime | undefined {
        if (this.dto.deploymentStatusTime) {
            return DateTime.fromISO(this.dto.deploymentStatusTime)
        }

        return undefined
    }

    get streamIds(): string[] {
        return this.dto.deploymentDefinition?.streamIds ?? []
    }

    set streamIds(newStreamIds: string[]) {
        if (!this.dto.deploymentDefinition) {
            throw new Error('cannot set streamIds. deploymentDefinition is not defined')
        }

        this.dto.deploymentDefinition.streamIds = newStreamIds

        if (newStreamIds.length > 0) {
            const [streamId] = newStreamIds

            const regionAttachments: RegionAttachment[] = this.staticItems.flatMap((si) =>
                si.attributes.filter((a): a is RegionAttr => a.valueType === 'region').flatMap((a) => a.value),
            )

            regionAttachments.forEach((ra) => {
                ra.streamId = streamId
            })

            this.detectableItemConfigs.forEach((d) => {
                d.streamId = streamId
            })

            const relationAttachments: RelationshipAttr[] = this.staticItems.flatMap((si) =>
                si.attributes.filter((a): a is RelationshipAttr => a.valueType === 'relationship'),
            )

            relationAttachments.forEach((ra) =>
                ra.value.forEach((pao) => {
                    pao.streamId = streamId
                }),
            )
        }
    }

    get launchTargets(): LaunchTarget {
        return this.dto.deploymentDefinition?.launchTargets.drt ?? { type: 'Kibsi_SAAS' }
    }

    set launchTargets(target: LaunchTarget) {
        if (!this.dto.deploymentDefinition) {
            throw new Error('cannot set launchTargets. deploymentDefinition is not defined')
        }

        this.dto.deploymentDefinition.launchTargets = {
            art: target,
            drt: target,
            vrt: target,
        }
    }

    get staticItems(): StaticItem[] {
        return this.dto.deploymentDefinition?.staticItems ?? []
    }

    set staticItems(newStaticItems: StaticItem[]) {
        if (!this.dto.deploymentDefinition) {
            throw new Error('cannot set staticItems. deploymentDefinition is not defined')
        }
        this.dto.deploymentDefinition.staticItems = newStaticItems
    }

    get detectableItemConfigs(): DetectableItemConfig[] {
        return this.dto.deploymentDefinition?.detectableItemConfigs ?? []
    }

    get appId(): string {
        return this.dto.appId
    }

    set appId(appId: string) {
        this.dto.appId = appId
    }

    get siteId(): string {
        return this.dto.siteId
    }

    get versionId(): string {
        return this.dto.versionId
    }

    get created(): Audit {
        return this.dto.created
    }

    get lastUpdated(): Audit {
        return this.dto.lastUpdated
    }

    get lastUpdatedDate(): Date {
        return new Date(this.lastUpdated.timestamp)
    }

    get isDraft(): boolean {
        return this.dto.deploymentDraftModified
    }

    get integrationId(): string | undefined {
        return this.dto.deploymentDefinition?.integrationId
    }

    set integrationId(integrationId: string | undefined) {
        if (!this.dto.deploymentDefinition) {
            throw new Error('cannot set cloud integration id. deploymentDefinition is not defined')
        }
        this.dto.deploymentDefinition.integrationId = integrationId
    }

    get frameRate(): number | undefined {
        return this.dto.deploymentDefinition?.frameRate
    }

    set frameRate(frameRate: number | undefined) {
        if (!this.dto.deploymentDefinition) {
            throw new Error('cannot set frame rate. deploymentDefinition is not defined')
        }
        this.dto.deploymentDefinition.frameRate = frameRate
    }

    get isTemplate(): boolean | undefined {
        return this.dto.isTemplate
    }

    set isTemplate(isTemplate: boolean) {
        this.dto.isTemplate = isTemplate
    }

    get actionConfigs(): ActionConfig[] | undefined {
        return this.dto.deploymentDefinition?.actionConfigs
    }

    set actionConfigs(actionConfigs: ActionConfig[] | undefined) {
        if (!this.dto.deploymentDefinition) {
            throw new Error('cannot set cloud action configs. deploymentDefinition is not defined')
        }

        this.dto.deploymentDefinition.actionConfigs = actionConfigs
    }

    get schedule(): Schedule[] | undefined {
        return this.dto.deploymentDefinition?.schedule
    }

    set schedule(schedule: Schedule[] | undefined) {
        if (!this.dto.deploymentDefinition) {
            throw new Error('cannot set schedule. deploymentDefinition is not defined')
        }
        this.dto.deploymentDefinition.schedule = schedule
    }

    get deploymentDefinition(): Definition | undefined {
        return this.dto.deploymentDefinition
    }

    get canAutoUpdate(): boolean {
        return this.autoUpdate === true
    }

    get isRunning(): boolean {
        const { deploymentStatus } = this
        return deploymentStatus === 'Running' || deploymentStatus === 'EOF'
    }

    get selectedItem(): string | undefined {
        return this.selectedItemId
    }

    set selectedItem(id: string | undefined) {
        if (this.selectedItemId === id) {
            this.selectedItemId = undefined
        } else {
            this.selectedItemId = id
        }
    }

    async getLaunchId(): Promise<string | undefined> {
        return this.dto.currentLaunchConfigId
    }

    /**
     * This will return the item types defined by the deployed app version
     */
    getItemTypes(): ItemType[] {
        return this.version?.versionDefinition?.definition?.itemTypes ?? []
    }

    getItemTypeById(id?: string): ItemType | undefined {
        if (id === undefined) {
            return undefined
        }

        return this.getItemTypes().find((i) => i.id === id)
    }

    getItemTypesByGenerationType(generationType: GenerationType): ItemType[] {
        return this.getItemTypes().filter((item) => item.generationType === generationType)
    }

    getStaticItemTypes(): ItemType[] {
        return this.getItemTypesByGenerationType('static')
    }

    getOrderedItemTypes(): ItemType[] {
        return [...this.getStaticItemTypes(), ...this.getDetectedItemTypes(), ...this.getEventItemTypes()]
    }

    getDetectedItemTypes(): ItemType[] {
        return this.getItemTypesByGenerationType('detected')
    }

    getEventItemTypes(): EventItemType[] {
        return this.getItemTypesByGenerationType('event') as EventItemType[]
    }

    getStaticRegions(streamId?: string): NamedPolygon[] {
        if (!streamId) {
            return []
        }

        return getRegionsForStream(this.staticItems, this.getItemTypes(), streamId)
    }

    getStaticRegionList(): StaticRegions[] {
        const streamId = getStreamId(this.streamIds)

        if (!streamId) {
            return []
        }

        return this.staticItems
            .map((si) => getStaticRegions(si, this.getItemTypeById(si.itemTypeId), streamId))
            .filter((r) => r.regions.length > 0)
    }

    getStaticItemTypesWithCounterAttr(): ItemType[] {
        return this.getStaticItemTypes().filter((item) =>
            item.attributes?.some((attr) => attr.value.valueType === 'count'),
        )
    }

    getEventAndSourceItemType(eventTypeId: string): [ItemType | undefined, ItemType | undefined] {
        const eventItemType = this.getItemTypeById(eventTypeId)
        const eventSourceItemType = eventItemType?.event?.sourceItemId
            ? this.getItemTypeById(eventItemType.event.sourceItemId)
            : undefined
        return [eventItemType, eventSourceItemType]
    }

    getDetectableItemConfig(itemTypeId?: string): DetectableItemConfig | undefined {
        return this.detectableItemConfigs.find((detectableItem) => detectableItem.itemTypeId === itemTypeId)
    }

    updateDetectableOptions(itemTypeId: string, streamId?: string, options?: DetectableItemConfigOptions): void {
        log.info('updating', itemTypeId, streamId, options)
        if (!streamId) {
            // update all DetectableItemConfig,  get from deployment
            this.streamIds.forEach((iStreamId) => {
                this.updateDetectableOptionsWithStream(itemTypeId, iStreamId, options)
            })
        } else {
            this.updateDetectableOptionsWithStream(itemTypeId, streamId, options)
        }
    }

    updateProximityRelationAnchorPosition(
        itemTypeId: string,
        streamId: string,
        relAttribute: AppRelationshipAttr,
        overrides: ProximityAnchorOverride[],
    ): void {
        const {
            itemTypeId: targetItemTypeId,
            relationType,
            attributeId: targetAttributeId,
        } = relAttribute.value.relation

        const detectedItem = this.getItemTypeById(itemTypeId)
        if (!detectedItem) {
            throw new Error(`itemType with ID ${itemTypeId} not found.`)
        }
        const targetItem = this.getItemTypeById(targetItemTypeId)
        if (!targetItem) {
            throw new Error(`relationship taraget itemType with ID ${itemTypeId} not found.`)
        }

        if (relationType === 'proximity') {
            const detectableItem = this.detectableItemConfigs.find(
                (dic) => dic.itemTypeId === itemTypeId && dic.streamId === streamId,
            )

            const attributes = detectableItem?.attributes?.filter((attr) => attr.id !== relAttribute.id) || []
            if (overrides.length > 0) {
                attributes.push({
                    id: relAttribute.id,
                    valueType: 'relationship',
                    value: overrides,
                })
            }

            if (!detectableItem) {
                this.detectableItemConfigs.push({
                    itemTypeId,
                    streamId,
                    detectable: true,
                    attributes,
                })
            } else {
                detectableItem.attributes = attributes.length > 0 ? attributes : undefined
            }
        } else if (targetItem?.generationType === 'static') {
            if (!targetAttributeId) {
                throw new Error(`relationship target attribute undefined. Cannot assign overrides to static instance.`)
            }

            const instanceOverrideMap = overrides.reduce<Record<string, ProximityAnchorOverride[]>>((prev, current) => {
                const { staticInstanceId } = current
                if (!staticInstanceId) {
                    return prev
                }

                if (!prev[staticInstanceId]) {
                    prev[staticInstanceId] = []
                }
                prev[staticInstanceId].push(current)
                return prev
            }, {})

            const staticInstances = this.staticItems.filter((si) => si.itemTypeId === targetItemTypeId)
            staticInstances.forEach((si) => {
                // remove any existing attribute override from si's overrides
                const otherAttrs = si.attributes?.filter((attr) => attr.id !== targetAttributeId)
                const relOverrideAttr = si.attributes?.find(
                    (attr) => attr.id === targetAttributeId && attr.valueType === 'relationship',
                ) as RelationshipAttr

                // preserve other stream condfigurations
                const otherStreamOverrides = relOverrideAttr?.value.filter((pao) => pao.streamId !== streamId) || []
                if (instanceOverrideMap[si.id]) {
                    // add new relationship attr to `otherAttrs`
                    otherAttrs.push({
                        id: targetAttributeId,
                        valueType: 'relationship',
                        value: [...otherStreamOverrides, ...instanceOverrideMap[si.id]],
                    })
                } else if (otherStreamOverrides.length > 0) {
                    otherAttrs.push({
                        id: targetAttributeId,
                        valueType: 'relationship',
                        value: otherStreamOverrides,
                    })
                }

                si.attributes = otherAttrs
            })
        } else {
            throw new Error(
                `Invalid proximity anchor update. Bi-directoral target of detected item may only update static items`,
            )
        }
    }

    private updateDetectableOptionsWithStream(
        itemTypeId: string,
        streamId: string,
        options?: DetectableItemConfigOptions,
    ) {
        const detectableItem = this.detectableItemConfigs.find(
            (item) => item.itemTypeId === itemTypeId && item.streamId === streamId,
        )
        if (!detectableItem) {
            this.detectableItemConfigs.push({
                itemTypeId,
                streamId,
                detectable: true,
                options,
            })
        } else {
            detectableItem.options = options
        }
    }

    updateDetectableRegion(itemTypeId: string, streamId: string, region?: Region): void {
        const detectableItem = this.detectableItemConfigs.find(
            (item) => item.itemTypeId === itemTypeId && item.streamId === streamId,
        )
        if (!detectableItem) {
            this.detectableItemConfigs.push({
                itemTypeId,
                streamId,
                detectable: true,
                region,
            })
        } else {
            detectableItem.region = region
        }
    }

    getProximityRelationsAttributes(itemType: ItemType, streamId?: string): RelationshipAttribute[] {
        if (!streamId) {
            return []
        }

        return itemType.attributes?.flatMap((attr) => {
            if (isRelationshipAttr(attr)) {
                const { itemTypeId, relationType } = attr.value.relation

                if (relationType === 'proximity') {
                    return [new RelationshipAttribute(this, itemType.id, attr, streamId)]
                }

                if (relationType === 'bidirectional') {
                    const relatedItemType = this.getItemTypeById(itemTypeId)
                    const relatedAttr = relatedItemType?.attributes?.find(
                        (a) => a.id === attr.value.relation?.attributeId,
                    )
                    if (
                        relatedItemType &&
                        relatedItemType.generationType === 'static' &&
                        isRelationshipAttr(relatedAttr) &&
                        relatedAttr.value.relation.relationType === 'proximity'
                    ) {
                        return [new RelationshipAttribute(this, itemType.id, attr, streamId)]
                    }
                }
            }

            return []
        }) as RelationshipAttribute[]
    }

    /**
     * calculates if this deployment is a new deployment
     */
    isNewDeployment(): boolean {
        return !this.dto.deploymentStatus || this.dto.deploymentStatus === 'Created'
    }

    /**
     * determines if the deployment has stopped
     */
    isStopped(): boolean {
        return this.dto.deploymentStatus === 'Stopped' || this.dto.deploymentStatus === 'Error'
    }

    async discard(): Promise<void> {
        const dto = await this.service.discard(this.deploymentId)

        runInAction(() => {
            populate(this, dto)
        })
    }

    updateVersion(appVersion: AppVersionDto, userApproved = false): UpdateAppVersionResult {
        const orphanStaticItems = this.findOrphanStaticItems(appVersion)
        if (orphanStaticItems.length > 0 && !userApproved) {
            return {
                status: 'approvalNeeded',
                appVersion,
                staticItemsToDelete: orphanStaticItems,
            }
        }

        // actual update App version
        this.staticItems = this.staticItems.filter((item) => !orphanStaticItems.includes(item.id))
        this.dto.versionId = appVersion.versionId
        this.version = appVersion

        return {
            status: 'success',
            appVersion,
        }
    }

    saveStaticItem(staticItem: StaticItem): void {
        // remove attributes that do not have any values
        const sanitizedStaticItem = {
            ...staticItem,
            attributes: staticItem.attributes.filter(
                (attr) => attr.value !== undefined && attr.value !== null && attr.value !== '',
            ),
        }
        // find the static item that has changed
        const existingItem = this.staticItems.find((item) => item.id === staticItem.id)
        if (!existingItem) {
            // push the new item on the static items array
            this.staticItems.push(sanitizedStaticItem)
        } else {
            // updates the existing item
            Object.assign(existingItem, sanitizedStaticItem)
        }
    }

    deleteStaticItem(staticItemToDelete: StaticItem): void {
        this.staticItems = this.staticItems.filter((item) => item.id !== staticItemToDelete.id)
    }

    bulkAddStaticItems(bulkAddRequest: BulkAddStaticItemsType): void {
        const staticItemTypes = this.getStaticItemTypes()
        staticItemTypes.forEach((iType) => {
            const val = bulkAddRequest[iType.id] || 0
            if (val > 0) {
                for (let i = 0; i < val; i++) {
                    const newStaticItem = {
                        id: nanoid(),
                        itemTypeId: iType.id,
                        attributes: [],
                    }

                    this.staticItems.push(newStaticItem)
                }
            }
        })
    }

    findOrphanStaticItems(newAppVersion: AppVersionDto): string[] {
        const itemTypes = newAppVersion.versionDefinition?.definition?.itemTypes ?? []
        return this.staticItems
            .filter((si) => itemTypes.findIndex((it) => it.id === si.itemTypeId) === -1)
            .map((si) => si.id)
    }

    async loadVdbEndpoint(): Promise<VdbEndpoint> {
        const newVdbEndpoint = await this.service.getVdbEndpoint(this.deploymentId)
        return runInAction(() => {
            this.vdbEndpoint = newVdbEndpoint
            return newVdbEndpoint
        })
    }

    async refreshStatus(): Promise<DeploymentStatus> {
        const newDto = await this.service.getDto(this.deploymentId)
        return runInAction(() => {
            this.dto.deploymentStatus = newDto.deploymentStatus
            this.dto.lastUpdated = newDto.lastUpdated
            this.dto.deploymentStatusReason = newDto.deploymentStatusReason
            this.dto.currentLaunchConfigId = newDto.currentLaunchConfigId
            this.dto.currentLaunchSchedule = newDto.currentLaunchSchedule

            return newDto.deploymentStatus
        })
    }

    async restart(): Promise<void> {
        try {
            await this.service.restart(this.deploymentId)
        } catch (err) {
            throw new Error(parseErrorMessage(err))
        }
        await this.refreshStatus()
    }

    async resumeSchedule(): Promise<void> {
        try {
            await this.service.resumeSchedule(this.deploymentId)
            await new Promise((resolve) => {
                setTimeout(resolve, 10_000)
            })
            await this.refreshStatus()
        } catch (err) {
            throw new Error(parseErrorMessage(err))
        }
    }

    toDto(): Dto {
        return toJS(this.dto)
    }

    fromDto(dto: Dto): void {
        this.dto = { ...dto, deploymentId: this.deploymentId }
        this.skipUpdate = true
    }

    /**
     * these are fields that are editable by the ui
     */
    get editable(): DeploymentEditable {
        if (!this.dto.deploymentDefinition) {
            throw new Error('trying to update deployment, but no deploymentDefinition is defined')
        }

        return {
            deploymentName: this.deploymentName,
            deploymentDescription: this.deploymentDescription,
            versionId: this.dto.versionId,
            deploymentDefinition: this.dto.deploymentDefinition,
            isTemplate: this.dto.isTemplate ?? false,
        }
    }

    private get deepEditable() {
        try {
            return JSON.stringify(this.editable)
        } catch (e) {
            return undefined
        }
    }

    private bindReactions(): void {
        this.reactions.push(
            // to check if the deployment needs to update via reactions, we have to access all the properties that are updatable
            reaction(
                () => this.deepEditable,
                () => {
                    if (!this.skipUpdate) {
                        this.update(this.editable).catch((e) => {
                            log.error('auto deployment update failure:', e)
                        })
                    }
                },
                {
                    // delay before we save
                    delay: this.updateDelay,
                },
            ),
            reaction(
                () => this.skipUpdate,
                (skip) => {
                    if (skip) {
                        this.resetSkip()
                    }
                },
            ),
        )
    }

    private async update(editable: DeploymentEditable): Promise<void> {
        const action = this.service.update({ ...editable, deploymentId: this.deploymentId })

        this.status = fromPromise(action, this.status)

        await action
    }

    private resetSkip() {
        this.skipUpdate = false
    }
}

function isApp(app: AppDto | AppRef | null): app is AppDto {
    return app != null && 'name' in app
}

function isVersion(version: AppVersionDto | VersionRef | null): version is AppVersionDto {
    return version != null && 'versionId' in version
}

function isSite(site: SiteDto | SiteRef | null): site is SiteDto {
    return site != null && 'siteName' in site
}

export function isRegionAttr(attr: AttrValue): attr is RegionAttr {
    return attr.valueType === 'region'
}

export function isEnhancerAttr(attr: AttrValue): attr is EnhancerAttr {
    const enhancerAttr = attr as EnhancerAttr
    return enhancerAttr.value !== undefined && enhancerAttr.value.enhancerId !== undefined
}

export function destructure(
    dto: AnyDto,
): [
    Dto,
    AppDto | AppRef | null,
    AppVersionDto | VersionRef | null,
    SiteDto | SiteRef | null,
    OptionalVersionUpgradeDetails | undefined,
] {
    const { app, version, site, upgradeDetails, ...rest } = dto

    const data: Dto = {
        ...rest,
        appId: app?.id ?? '',
        versionId: version?.versionId ?? '',
        siteId: site?.siteId ?? '',
    }

    return [data, app, version, site, upgradeDetails]
}

export function setRefs(
    deployment: Deployment,
    app: AppDto | AppRef | null,
    version: AppVersionDto | VersionRef | null,
    site: SiteDto | SiteRef | null,
    upgradeDetails?: OptionalVersionUpgradeDetails,
): void {
    if (isApp(app)) {
        deployment.app = app
    }

    if (isVersion(version)) {
        deployment.version = version
    }

    if (isSite(site)) {
        deployment.site = site
    }

    if (upgradeDetails) {
        deployment.autoUpdate = upgradeDetails.canAutoUpdate
    }
}

export function populate(deployment: Deployment, data: AnyDto): void {
    const [dto, app, version, site, upgradeDetails] = destructure(data)

    deployment.fromDto(dto)

    setRefs(deployment, app, version, site, upgradeDetails)
}

// this is the sort order of the deployment status
const DEPLOYMENT_STATUS_SORT_ORDER = ['Created', 'Error', 'Stopped']

const getDeploymentSortIndex = (status: DeploymentStatus): number => {
    switch (status) {
        case 'Attempt Retry':
        case 'In Progress':
        case 'Pending':
        case 'Running':
            return 0
        default:
            return DEPLOYMENT_STATUS_SORT_ORDER.indexOf(status) + 1
    }
}

export const deploymentListSorter = (a: Deployment, b: Deployment): number =>
    getDeploymentSortIndex(a.deploymentStatus) - getDeploymentSortIndex(b.deploymentStatus) ||
    b.lastUpdated.timestamp.localeCompare(a.lastUpdated.timestamp)
