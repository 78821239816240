import { ContentCopy } from '@mui/icons-material'
import { Icon, IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { useHover } from '../../hooks'
import { copyTextToClipboard } from '../../utils/clipboard'
import { ValueRenderer } from './types'

export type KBDictionaryRowProps = {
    value: unknown
    name?: string
    renderValue: ValueRenderer
}

export function KBDictionaryRow({ name, value, renderValue }: KBDictionaryRowProps): ReactElement {
    const [hover, onMouseEnter, onMouseLeave] = useHover()

    const copyEvent = () => {
        // copy to clipboard.
        copyTextToClipboard(`${value}`).catch(() => {
            // noop
        })
    }

    return (
        <TableRow hover onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {name && (
                <TableCell
                    sx={{
                        border: 0,
                        py: 1,
                        pl: 3.5,
                        pr: 2,
                    }}
                >
                    <Typography variant="body2" color="text.secondary">
                        {name}
                    </Typography>
                </TableCell>
            )}
            <TableCell
                sx={{
                    border: 0,
                    py: 1,
                    pl: 2,
                    pr: 0,
                }}
            >
                <Typography variant="body2" color="text.secondary">
                    {renderValue(value)}
                </Typography>
            </TableCell>
            <TableCell
                sx={{
                    border: 0,
                    py: 1,
                    px: 0.5,
                    textAlign: 'right',
                }}
            >
                <Tooltip title="Copy">
                    <IconButton onClick={copyEvent} size="small">
                        {hover ? <ContentCopy fontSize="small" /> : <Icon fontSize="small" />}
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}
