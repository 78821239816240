import { FunctionComponent, MouseEvent, ReactElement } from 'react'
import {
    Box,
    IconButton,
    ListItem,
    ListItemProps,
    ListItemText,
    Stack,
    SvgIconProps,
    SxProps,
    Typography,
} from '@mui/material'
import { KBTooltip } from '../tooltip'
import { asSxArray } from '../../utils/mui'
import { useHover } from '../../hooks'

export type KBPanelActionItem = {
    id: string
    icon: FunctionComponent<SvgIconProps>
    iconSx?: SxProps
    onClick?: (event: MouseEvent<HTMLElement>) => void
    disabled?: boolean
    visible?: boolean
    tooltip?: string
    dataTestId?: string
}

export interface KBPanelItemProps extends Omit<ListItemProps, 'title'> {
    icon?: FunctionComponent<SvgIconProps>
    title?: string | ReactElement
    description?: string | ReactElement
    actions?: KBPanelActionItem[]
}

export function KBPanelItem({
    icon: LeadingIcon,
    title,
    description,
    actions = [],
    ...props
}: KBPanelItemProps): ReactElement {
    const [hover, onMouseEnter, onMouseLeave] = useHover()

    const iconColor = 'action.active'
    const iconSize = '20px'

    const styles = {
        listItem: {
            py: '2px',
            pl: 2,
            pr: 2,
            '&:hover': {
                textDecoration: 'none',
                backgroundColor: 'rgba(64, 33, 136, 0.04)',
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
        icons: {
            color: iconColor,
            fontSize: iconSize,
        },
    }

    return (
        <ListItem
            {...props}
            disableGutters
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            sx={styles.listItem}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="start" sx={{ width: '100%' }}>
                {LeadingIcon && (
                    <Typography variant="body2" sx={{ mr: 1.75, pt: '4px' }}>
                        <LeadingIcon sx={styles.icons} />
                    </Typography>
                )}
                <ListItemText
                    sx={{ mt: '2px', mb: '2px' }}
                    primary={
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Box sx={{ typography: 'body2' }}>{title}</Box>
                            <Stack direction="row">
                                {actions.map(
                                    ({
                                        id,
                                        onClick,
                                        icon: Icon,
                                        iconSx,
                                        disabled,
                                        visible,
                                        dataTestId,
                                        tooltip = '',
                                    }) => (
                                        <KBTooltip key={id} title={tooltip}>
                                            <IconButton
                                                sx={[
                                                    {
                                                        p: '12px',
                                                        ml: '4px',
                                                        width: '8px',
                                                        height: '8px',
                                                        visibility: hover || visible ? 'visible' : 'hidden',
                                                    },
                                                    ...asSxArray(iconSx),
                                                ]}
                                                onClick={onClick}
                                                data-testid={dataTestId}
                                                disabled={disabled}
                                            >
                                                {Icon && (
                                                    <Icon
                                                        sx={{
                                                            fontSize: iconSize,
                                                        }}
                                                    />
                                                )}
                                            </IconButton>
                                        </KBTooltip>
                                    ),
                                )}
                            </Stack>
                        </Stack>
                    }
                    secondary={
                        <Typography color="text.secondary" variant="caption">
                            {description}
                        </Typography>
                    }
                />
            </Stack>
        </ListItem>
    )
}
