import { ReactElement } from 'react'
import { Stack, TooltipProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import LockIcon from '@mui/icons-material/Lock'
import { KBTooltip, KBTooltipProps } from '../tooltip'

export type KBLockProps = {
    tooltipLabel?: string
    tooltipPlacement?: TooltipProps['placement']
} & Omit<KBTooltipProps, 'children' | 'title' | 'placement'>

export function KBLock({ tooltipLabel = '', tooltipPlacement = 'top', ...props }: KBLockProps): ReactElement {
    const theme = useTheme()
    const tooltipColor = '#8E84A4'

    return (
        <KBTooltip title={tooltipLabel} placement={tooltipPlacement} color={tooltipColor} maxWidth={200} {...props}>
            <Stack alignItems="center" sx={{ p: '9px' }}>
                <LockIcon sx={{ color: theme.palette.action.active }} />
            </Stack>
        </KBTooltip>
    )
}
