export function getPathColor(path: string): string | undefined {
    switch (path) {
        case 'playground':
            return '#8164FF'
        case 'sites':
            return '#FF4E96'
        case 'applications':
            return '#5ACEFF'
        case 'models':
            return '#FFC961'
        default:
            return undefined
    }
}
