import { ItemType } from '@kibsi/ks-application-types'
import { getItemNodeType } from 'model/app'
import { ReactElement } from 'react'
import { DetectorIcon } from './DetectorIcon'
import { ItemTypeIcon } from './ItemTypeIcon'
import { getIconTypeFromId } from './helper'

export type DetectorIconOrItemTypeIconProps = {
    itemType: ItemType
    color?: string
}

export function DetectorIconOrItemTypeIcon({ itemType, color }: DetectorIconOrItemTypeIconProps): ReactElement {
    const { detector } = itemType

    if (detector) {
        if (!Array.isArray(detector)) {
            return <DetectorIcon type={getIconTypeFromId(detector.detectorId)} color={color} />
        }

        if (Array.isArray(detector) && detector.length === 1) {
            // if there is only one detector
            return <DetectorIcon type={getIconTypeFromId(detector[0].detectorId)} color={color} />
        }
    }

    return <ItemTypeIcon variant={getItemNodeType(itemType)} color={color} />
}
