import { useInjection } from 'inversify-react'
import { UserService } from 'service/user'
import T from 'config/inversify.types'
import { Status, usePromiseState } from '../usePromiseState'

export function useProfilePicture(userId: string): [Status, string] {
    const service = useInjection<UserService>(T.UserService)
    const [status] = usePromiseState(
        () => service.getProfilePictureUrl(userId),
        () => {},
    )

    return [status, status.value as string]
}
