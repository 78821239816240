import logger from 'logging/logger'
import { KibsiHttpError } from 'service/api/http.error'

type ValidationError = {
    message: string
    path?: string
}

const parseValidationErrors = (validationErrors?: ValidationError[]): string | undefined => {
    if (!validationErrors) {
        return undefined
    }
    const validationErrorsMsg = validationErrors.reduce<string>((prev: string, validationError: ValidationError) => {
        if (prev) {
            return `${prev}; ${validationError.message}`
        }
        return validationError.message || ''
    }, '')

    return validationErrorsMsg
}

export const parseErrorMessage = (error: unknown): string | undefined => {
    if (error instanceof KibsiHttpError) {
        const { message, statusCode, errorCode, additionalDetails } = error
        logger.info(statusCode, message, errorCode, additionalDetails)
        if (errorCode && errorCode === 'SCHEMA_VALIDATION_ERROR') {
            const validationErrorsMsg = parseValidationErrors(additionalDetails as ValidationError[])
            if (validationErrorsMsg) {
                return validationErrorsMsg
            }
        }

        return error.message // additional details are not returned at this time.
    }

    if (error instanceof Error) {
        logger.warn(error)
        return error.message
    }

    logger.warn(error)
    return JSON.stringify(error)
}
