import { ReactElement, useEffect, useRef, useState } from 'react'
import { Box, FormHelperText, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHover } from '@kibsi/ui-components'
import { FileWell } from './FileWell'
import { FileBrowseInput } from './FileBrowseInput'
import { FileChangeHandler } from './types'
import logger from '../../logging/logger'
import { ImageDataBuffer } from '../../store/data-buffer'

export interface ImageDropperProps {
    height?: string
    file?: File
    helperMessage?: string
    onFileChange?: FileChangeHandler
    showPreview?: boolean
}

export function ImageDropper({
    file,
    height,
    helperMessage = '',
    onFileChange,
    showPreview,
}: ImageDropperProps): ReactElement {
    const { t } = useTranslation()
    const [fileImage, setFileImage] = useState<ImageDataBuffer>()
    const [imageHeight, setImageHeight] = useState<string>()
    const imageRef = useRef<HTMLImageElement>()
    const [hover, onMouseEnter, onMouseLeave] = useHover()

    useEffect(() => {
        if (!file || !showPreview) {
            return
        }

        file.arrayBuffer()
            .then((data) => {
                setFileImage(new ImageDataBuffer(data))
            })
            .catch((e) => {
                logger.warn(e)
            })
    }, [file, showPreview])

    const onLoad = () => {
        if (!imageRef.current) {
            return
        }
        setImageHeight(`${imageRef.current.clientHeight}px`)
    }

    return (
        <Stack sx={{ position: 'relative' }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {showPreview && (
                <Box
                    ref={imageRef}
                    component="img"
                    src={fileImage?.dataUrl}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        maxWidth: '100%',
                        ...(fileImage && showPreview && { opacity: hover ? 0.5 : 1 }),
                    }}
                    onLoad={onLoad}
                />
            )}
            <FileWell height={imageHeight ?? height} onChange={onFileChange} sx={{ zIndex: 1 }}>
                <Stack
                    justifyItems="center"
                    alignItems="center"
                    sx={{ ...(fileImage && showPreview && { opacity: hover ? 1 : 0 }) }}
                >
                    <FileBrowseInput
                        fileType="image"
                        fileInfo={file}
                        helperText={helperMessage}
                        onFileChanged={onFileChange}
                    />
                    <FormHelperText>{t('file.suggested')}</FormHelperText>
                </Stack>
            </FileWell>
        </Stack>
    )
}
