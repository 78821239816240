import { KibsiClientSDKParams } from '@kibsi/ks-client-sdk'
import { Environment } from 'config/environment'
import logger from 'logging/logger'

import { UiKibsiClientSdk } from '../ui'
import { errorHandler } from './error.handler'

export type KibsiAPI = UiKibsiClientSdk

export function createKibsiApiClient(
    env: Environment,
    axiosConfigProvider: KibsiClientSDKParams['axiosConfigProvider'],
): UiKibsiClientSdk {
    return new UiKibsiClientSdk({
        basePathTemplate: env.serviceUrl,
        logger,
        errorHandler,
        axiosConfigProvider,
    })
}
