import { User as UserDto } from '@kibsi/ks-tenant-types'
import { Status, usePromiseStateLater } from 'hooks/usePromiseState'
import { useUserStore } from './useUserStore'

export function useInviteUser(): [undefined | Status<UserDto>, (email: string, userName?: string) => Promise<UserDto>] {
    const userStore = useUserStore()
    const [status, setStatus] = usePromiseStateLater<UserDto>()

    const inviteUser = (email: string, userName?: string): Promise<UserDto> => {
        const promise = userStore.inviteUser(email, userName)
        setStatus(promise)
        return promise
    }

    return [status, inviteUser]
}
