import { Box, CircularProgress, StackProps } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import { ReactElement } from 'react'

export type KBLoadingProps = {
    loading: boolean
    size?: number | string
    sx?: SxProps<Theme>
} & StackProps

export function KBLoading({ loading, size, sx, ...props }: KBLoadingProps): ReactElement {
    const styles = { m: 3 }

    if (loading) {
        return (
            <Box
                aria-label="KBLoading"
                sx={[...(Array.isArray(styles) ? styles : [styles]), ...(Array.isArray(sx) ? sx : [sx])]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                {...props}
            >
                <CircularProgress size={size} />
            </Box>
        )
    }

    return <Box />
}
