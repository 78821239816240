import { createTheme, Theme, ThemeProvider } from '@mui/material/styles'
import { createContext, ReactElement, ReactNode, useMemo, useState } from 'react'
import { ThemeMode, useTheme } from '@kibsi/ui-components'

type ThemeContextProps = {
    theme: Theme
    themeMode: ThemeMode
    setThemeMode: (mode: ThemeMode) => void
}

export const ThemeContext = createContext<ThemeContextProps>({
    theme: createTheme(),
    themeMode: ThemeMode.Auto,
    setThemeMode: () => {},
})

type ThemeModeProviderProps = {
    children: ReactNode
}

/**
 * Provides theming context
 *
 * @param {ReactElement} children Any child element that requires theme context
 * @returns {ReactElement} <ThemeModeProvider><ThemeModeProvider/>
 */
export function ThemeModeProvider({ children }: ThemeModeProviderProps): ReactElement {
    const [themeMode, setThemeMode] = useState<ThemeMode>(ThemeMode.Light)
    const theme = useTheme(themeMode)
    const themeContextValue = useMemo(() => ({ theme, themeMode, setThemeMode }), [theme, themeMode, setThemeMode])

    return (
        <ThemeProvider theme={theme}>
            <ThemeContext.Provider value={themeContextValue}>{children}</ThemeContext.Provider>
        </ThemeProvider>
    )
}
